import React, { useState, useRef, useEffect } from "react";
import classes from '../Signup/NewLogin2.module.css'
import backgroundImg from '../../assets/promix/LogIn.png'
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crossedEyeIcon from '../../assets/promix/eye-slash.png';
import { BASE_URL } from "../../Pages/api/api";
import Swal from "sweetalert2";


function OTP() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [bearer, setBearer] = useState('');
  const [data, setData] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [user, setUser] = useState('');
  const [admin, setAdmin] = useState("");



  const inputRefs = useRef([]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return;

    let newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field if not the last input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };
  // const handleContinue = () => {
  //   navigate('/welcome');
  // }


  const handleContinue = async () => {
    setLoading(true);
    try {
      // console.log(data)
      // return;
      const otpCode = otp.join('');
      const response = await axios.post(`${BASE_URL}/verify-otp`,
        {
          token: otpCode
        },
        { headers }
      );


      navigate('/welcome');

    } catch (error) {
      let errorMessage = error.response?.data?.message || 'An error occurred';
      if (error.message === 'Network Error') {
        errorMessage = 'Connection error. Please check your internet connection.';
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
      handleContinue();
    }
  };

  const isButtonDisabled = !otp;



  return (
<div>
  <>
    <div className={classes.background}>
      <ToastContainer />
      <div className={classes.mainContainerx}>
        <div className={classes.thecntnt}>
          <div className={classes.head}>
            <p className={classes.SignUptxt1}>Enter Code</p>
            <p className={classes.SignUptxtsub1}>Enter the six digit code sent to your e-mail to continue</p>
          </div>
          <Form>
            <Form.Group className={classes.otpGroup}>
              <div className={classes.otpInputs}>
                {otp.map((data, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    name="otp"
                    maxLength="1"
                    className={classes.otpInput}
                    value={data}
                    onKeyPress={handleKeyPress}
                    onChange={e => handleChange(e.target, index)}
                    onKeyDown={e => handleKeyDown(e, index)}
                    ref={el => inputRefs.current[index] = el}
                    autoFocus={index === 0}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff" }}
                  />
                ))}
              </div>
            </Form.Group>
          </Form>
          <Button onClick={handleContinue} className={classes.snupbtn} disabled={isButtonDisabled}>
            {loading ? (
              <>
                <Spinner size='sm' />
                <span style={{ marginLeft: '5px' }}>Verifying...</span>
              </>
            ) : (
              "Verify OTP"
            )}
          </Button>
          {/* <p className={classes.lgin}>Don't have an account? <Link to={'/signup'} style={{ textDecoration: 'none' }}><span>Sign Up</span></Link></p> */}
        </div>
      </div>
    </div>
    <div className={classes.backgroundMobile}>
                
                <ToastContainer />
                <div className={classes.mainContainerMobile}>
                <div className={classes.thecntnt}>
          <div className={classes.head}>
            <p className={classes.SignUptxt1}>Enter Code</p>
            <p className={classes.SignUptxtsub1}>Enter the six digit code sent to your e-mail to continue</p>
          </div>
          <Form>
            <Form.Group className={classes.otpGroup}>
              <div className={classes.otpInputs}>
                {otp.map((data, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    name="otp"
                    maxLength="1"
                    className={classes.otpInput}
                    value={data}
                    onKeyPress={handleKeyPress}
                    onChange={e => handleChange(e.target, index)}
                    onKeyDown={e => handleKeyDown(e, index)}
                    ref={el => inputRefs.current[index] = el}
                    autoFocus={index === 0}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff" }}
                  />
                ))}
              </div>
            </Form.Group>
          </Form>
          <Button onClick={handleContinue} className={classes.snupbtn} disabled={isButtonDisabled}>
            {loading ? (
              <>
                <Spinner size='sm' />
                <span style={{ marginLeft: '5px' }}>Verifying...</span>
              </>
            ) : (
              "Verify OTP"
            )}
          </Button>
          {/* <p className={classes.lgin}>Don't have an account? <Link to={'/signup'} style={{ textDecoration: 'none' }}><span>Sign Up</span></Link></p> */}
        </div>
                </div>
                </div>
    </>
    </div>
  );

}
export default OTP;