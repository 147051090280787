import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Active from '../../assets/NewDashImg/ActiveIcon.svg';
import Icon1 from '../../assets/NewDashImg/Icon1.svg';
import Icon2 from '../../assets/NewDashImg/Icon2.svg';
import Icon3 from '../../assets/NewDashImg/Icon3.svg';
import Icon4 from '../../assets/NewDashImg/Icon4.svg';
import Icon5 from '../../assets/NewDashImg/Icon5.svg';
import Icon6 from '../../assets/NewDashImg/Icon6.svg';
import classes from "./LatestMenuNew.module.css"

// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/api';
import Select from 'react-select';

export default function LatestAdminMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [createLoading, setCreateLoading] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [subCat, setSubcat] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [navOpen, setNavOpen] = useState(false);




  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNav = () => setNavOpen(!navOpen);

  const navToDashboard = () => {
    navigate('/admin')
  }



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const uploadStatement = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('date', selectedDate);
      formData.append('file', selectedImage[0]);

      const response = await axios.post(
        `${BASE_URL}/quotes/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response.data?.message, "heeee");
      handleClose();
      // Reset form fields
      setSelectedImage("");
      setSelectedDate("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const opt = subCat.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  useEffect(() => {
    if (bearer) {
      fetchSubCat();
    }
  }, [bearer]);

  return (
    <div>
      <div className={classes.navSection}>
        <div className={classes.hamburger} onClick={toggleNav}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* <div className={classes.navSectionHead}>
                <span>
                    <small>Welcome</small>
                    <h4>Eniola Lawanson</h4>
                </span>
                <span>
                    <h4>Dashboard</h4>
                </span>
            </div> */}

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Statement of Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: 20 }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  // autoFocus
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label className={classes.formLabelCustom}>Bank</Form.Label>
                <Select
                  className={classes.formControl}
                  options={opt}
                  placeholder="Select Bank"
                  onChange={handleAccountChange}
                  menuPortalTarget={document.body}
                  styles={{
                    width: "100%",
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '100px',
                      overflowY: 'auto',

                    }),
                  }}
                />


                <div style={{ marginTop: 10 }} />
                <Form.Label>File</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Choose file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleImageChange}

                />






              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            <Button variant="success" onClick={uploadStatement}>
              {createLoading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                </>
              ) : (
                "Upload"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className={`$classes.navSectionNavCont} ${navOpen ? 'active' : ''}`}>
          <div className={classes.navSectionNav}>
            {/* <Dropdown> */}
            {/* <Dropdown.Toggle variant='success' className={classes.Dropdowns} id="dropdown-dashboard"> */}
            <Link to={'/admin'}>
              <p className={classes.Dropdowns}>
                <span>
                  {/* <img src={Icon3} alt='icon'/> */}
                  Dashboard</span>
              </p>
            </Link>
            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{}}>
                <span >
                  {/* <img src={Icon2} alt='icon'/> */}
                  Setup</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/admin/exchange_rate">Manage Currency Rate</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/tax">Manage Tax</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/continent">Manage Continents</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/region">Manage Region</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/province">Manage Province</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{}}>
                <span >
                  {/* <img src={Icon2} alt='icon'/> */}
                  Users Management</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/admin/users">Manage Users</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/assigned_users">Assigned Users to Province</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/change_working_month_approvals">Change Working Month Approvals</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/roles_and_permissions">Roles and Permission</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <Link to={'/admin/charts_of_accounts'}>
            <p className={classes.Dropdowns}>
              <span> */}
            {/* <img src={Icon3} alt='icon'/> */}
            {/* Charts of Account</span>
            </p>
            </Link> */}


            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{}}>
                <span >
                  Province Records</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/admin/customers">Customers</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/supplier">Suppliers</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/loan">Loan Beneficiaries</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>


            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{}}>
                <span >
                  {/* <img src={Icon2} alt='icon'/> */}
                  Accounts</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/admin/account_categories">Account Categories</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/charts_of_account">Charts of Account</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/bank_statement">Bank Statements</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{}}>
                <span >
                  {/* <img src={Icon2} alt='icon'/> */}
                  Fixed Asset Register</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/admin/fixed_asset_report">Fixed Asset Report</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-report1">
                <span>Reports</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`${classes.DropdownMenu} ${classes.reportDropdownMenu}`}>
                {/* Nested dropdown for Financial Report */}
                <Dropdown >

                  <Dropdown.Toggle as='div' className={classes.SubDropdownToggle}>
                    <span style={{ fontSize: 14, paddingLeft: 17 }}>Financial Reports</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end" className={classes.SubDropdownMenu}>
                    <Dropdown.Item as={Link} to="/accounting/cashbook_admin">Cashbook</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/trialbalance_admin">Trial Balance</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/general_ledger_admin">General Ledger</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/income_statement_admin">Income Statement - Summary</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/income_statement_admin_detail">Income Statement - Detailed</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/activity_report">Account Activity Report</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/aged_analysis_payables_admin">Age Analysis - Payables</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/aged_analysis_receivables_admin">Age Analysis - Receivables</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/statement_of_financial_position_admin">Statement of Financial Position - Summary</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/admin_financial_position_detail">Statement of Financial Position - Detailed</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/bank_position_summary_admin">Bank Position Summary</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/statement_of_cashflow_summary_admin">Statement of Cash Flow</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown.Item as={Link} to="/accounting/sales_report">Sales Report</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/purchase_report">Purchases Report</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/reconciliation_summary_report">Reconciliation Summary Report</Dropdown.Item>

                {/* {(admin === "Super Admin" || admin === "Continental Accountant" || admin === "Regional Accountant") && (
  <Dropdown>
    <Dropdown.Toggle as="div" className={classes.SubDropdownToggle}>
      <span style={{ fontSize: 14, paddingLeft: 17 }}>Consolidated Reports</span>
    </Dropdown.Toggle>

    <Dropdown.Menu align="end" className={classes.SubDropdownMenu}>
      {admin === "Super Admin" && (
        <>
          <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Regional Reports</Dropdown.Item>
          <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Continental Reports</Dropdown.Item>
        </>
      )}
      {admin === "Continental Accountant" && (
        <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Continental Reports</Dropdown.Item>
      )}
      {admin === "Regional Accountant" && (
        <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Regional Reports</Dropdown.Item>
      )}
    </Dropdown.Menu>
  </Dropdown>
)} */}

              </Dropdown.Menu>

            </Dropdown>
            {/* 
                        <Link
                            to={'/admin/exchange_rate'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                                Exchange Rate </span>
                            </p>
                        </Link>

                        <Link
                            to={'/admin/tax'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                                Tax </span>
                            </p>
                        </Link>
                        <Link
                            to={'/admin/continent'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                                Continents </span>
                            </p>
                        </Link>
            
                        <Link
                            to={'/admin/region'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                                Region </span>
                            </p>
                        </Link>

                        <Link
                            to={'/admin/province'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                              Province </span>
                            </p>
                        </Link>
                        <Link
                            to={'/admin/zone'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                               Zone </span>
                            </p>
                        </Link>
                        <Link
                            to={'/admin/area'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                                Area </span>
                            </p>
                        </Link>
                        <Link
                            to={'/admin/parish'}
                        >
                            <p className={classes.Dropdowns}>
                                <span>
                                Parish </span>
                            </p>
                        </Link> */}


          </div>
        </div>


      </div>
    </div>
  )
}