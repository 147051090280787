import React, { useState, useEffect } from 'react';
import classes from './PrintBalances.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';


export default function PrintConsolidatedBalances() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `${day}/${month}/${year} `;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const admins = await AsyncStorage.getItem('admin');

            if (value !== null) {
                setBearer(value);
                // setAuthenticated(true);
            }
            if (value1 !== null) {
                setCompany(value1);

            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (admins !== null) {
                setUserType(admins);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();

    }, []);

    const { accounts, selectedEndDate, selectedDate, inputss } = location.state || {};
    // const startDate = new Date(inputss.start_date);
    // const endDate = new Date(inputss.end_date);
   console.log(accounts, inputss);
    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Get the day with the appropriate suffix
        const day = date.getDate();
        const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'TH'; // Handles 11th to 19th
            switch (day % 10) {
                case 1: return 'ST';
                case 2: return 'ND';
                case 3: return 'RD';
                default: return 'TH';
            }
        };

        const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
        const year = date.getFullYear();

        return `${day}${daySuffix(day)} ${month.toUpperCase()} ${year}`;
    };

    const totalCombined = [accounts[1], accounts[2]].reduce((sum, item) => {
        return sum + item.categories.reduce((catSum, category) => catSum + parseFloat(category.total), 0);
    }, 0);

    return (
        <div className={classes.a4}>
            <h2 className={classes.headerTop}>THE REDEEMED CHRISTIAN CHURCH OF GOD</h2>
            {/* <h4 className={classes.headerTwoo}>{inputss?.name ? inputss.name : province}</h4> */}
            <h4 className={classes.headerTwo}>CONSOLIDATED STATEMENT OF FINANCIAL POSITION REPORT {selectedDate === '' ? `AS AT ${currentDateTime}` : `BETWEEN ${formatDate(selectedDate)}  AND ${formatDate(selectedEndDate)}`}</h4>

            <table className={classes.tableDivF}>
        <thead>
            <tr>
                <th></th>
                <th className={classes.noteDiv}>Notes</th>
                <th style={{ width: "70px", whiteSpace: 'wrap', textAlign: "center", width: 200 }}>AS AT {selectedDate === '' ? currentDateTime : formatDate(selectedEndDate)}</th>
                <th></th>
                <th style={{ width: "70px", whiteSpace: 'wrap', textAlign: "center", width: 200 }}>AS AT {selectedDate === '' ? currentDateTime : formatDate(selectedEndDate)}</th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th style={{ textAlign: "center" }}>N'000</th>
                <th></th>
                <th style={{ textAlign: "center" }}>N'000</th>
            </tr>
        </thead>

        <tbody>
        {accounts && accounts.map((item, index) => {
                const totalItem = item.categories.reduce((sum, category) => sum + parseFloat(category.total), 0);

                return (
                    <React.Fragment key={index}>
                        <tr>
                            <th style={{ textDecoration: "underline", fontSize: 18 }} colSpan={4}>{item.description.toUpperCase()}</th>
                        </tr>
                        {item.categories?.map((category, catIndex) => (
                            <React.Fragment key={catIndex}>
                                <tr>
                                    <th style={{ textDecoration: "underline", fontSize: 16, paddingLeft: 20 }} colSpan={4}>{category.description}</th>
                                </tr>
                                {category.postings.map((posting, postIndex) => (
                                    <tr key={postIndex}>
                                        <td style={{ paddingLeft: 40 }}>{posting.name}</td>
                                        <td></td>
                                        <td style={{ textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5 }}>
                                            {parseFloat(posting.amount).toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </td>
                                        <td style={{ width: 20 }}></td>
                                        <td style={{ textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5 }}>
                                            {parseFloat(posting.amount).toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={2} style={{ fontWeight: 'bold', paddingLeft: 20, fontSize: 16 }} className={classes.totalTh}>
                                        Total {category.description}
                                    </td>
                                    <td style={{ textAlign: "right", fontWeight: 'bold', backgroundColor: "rgb(236, 233, 233)", padding: 5, borderTop: "1px solid black", borderBottom: "1px solid black" }} className={classes.totalTh}>
                                        {parseFloat(category.total).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                    </td>
                                    <td></td>
                                    <td style={{ textAlign: "right", fontWeight: 'bold', backgroundColor: "rgb(204, 201, 201)", padding: 5, borderTop: "1px solid black", borderBottom: "1px solid black" }} className={classes.totalTh}>
                                        {parseFloat(category.total).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                    </td>
                                </tr>
                                <tr><td colSpan={4}>&nbsp;</td></tr>
                            </React.Fragment>
                        ))}
                        <tr>
                            <td colSpan={2} style={{ fontWeight: 'bold', fontSize: 16 }} className={classes.totalTh}>
                                TOTAL {item.description.toUpperCase()}
                            </td>
                            <td style={{ textAlign: "right", fontWeight: 'bold', backgroundColor: "rgb(236, 233, 233)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh}>
                                {totalItem.toLocaleString('en-US', {
                                    minimumIntegerDigits: 1,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </td>
                            <td></td>
                            <td style={{ textAlign: "right", fontWeight: 'bold', backgroundColor: "rgb(204, 201, 201)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh}>
                                {totalItem.toLocaleString('en-US', {
                                    minimumIntegerDigits: 1,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </td>
                        </tr>
                        <tr><td colSpan={4}>&nbsp;</td></tr>
                    </React.Fragment>
                );
            })}
            <tr>
                <td colSpan={2} style={{ fontWeight: 'bold', fontSize: 16 }} className={classes.totalTh}>
                TOTAL {accounts[1].description.toUpperCase()} & {accounts[2].description.toUpperCase()}
                </td>
                <td style={{ textAlign: "right", fontWeight: 'bold', backgroundColor: "rgb(236, 233, 233)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh}>
                    {totalCombined.toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </td>
                <td></td>
                <td style={{ textAlign: "right", fontWeight: 'bold', backgroundColor: "rgb(204, 201, 201)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh}>
                    {totalCombined.toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </td>
            </tr>
            <tr><td colSpan={4}>&nbsp;</td></tr>
        </tbody>
    </table>
            <h4 className={classes.btmPrg}>These Financial Statements were approved by the Governing Council at a meeting held on __________________ and were signed on its behalf by:</h4>
            <div className={classes.sigNatures}>
                <span>National Overseer</span>
                <span>National Treasurer</span>
            </div>
        </div>
    )
}
