import React, { useState, useEffect } from "react";
import classes from "./ManageRole.module.css";
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Accordion } from "react-bootstrap";
// import Folder from '../../Images/folder-2.svg';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from "../Main Dashboard/MainDashoard";
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../api/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CoopDashboard from "../Cooperative Dashboard/CoopDashboard";
import ReportDashboard from "../ReportDashboard/ReportDashboard";
import LatestDash from "../LatestDashboard/LatestDash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import LatestAdminDash from "../LatestDashboard/LatestAdminDash";
import LatestBudgetDash from "../LatestDashboard/LatestBudgetDash";
import Select from "react-select";
import { PiNotePencilBold } from "react-icons/pi";

export default function ManageUserBudget() {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => {
    setTableData5([]);  // Clear the provinces data when closing the modal
    setSelectedProvinces([]);  // Clear the provinces data when closing the modal
    setShow(false);     // Close the modal
  };
  const handleClose2 = () => {
    setTableData5([]);  // Clear the provinces data when closing the modal
    setSelectedProvinces([]);  // Clear the provinces data when closing the modal
    setShow2(false);     // Close the modal
  };
  const handleClose1 = () => setShow1(false);
   
  const handleShow = (user) => {
    setSelectedUser(user); // Set the selected user data
    setShow(true); // Show the modal
  };

  const handleShow1 = (user) => {
    setSelectedUser(user); // Set the selected user data
    setShow2(true); // Show the modal
  };
  
  // const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [contLoading, setContLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent, setSelectedContinent] = useState(null);
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [tableData45, setTableData45] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [userTypes, setUserTypes] = useState("");
  const [userProvinces, setUserProvinces] = useState([]);
  // const [searchTerm, setSearchTerm] = useState(null);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value2 = await AsyncStorage.getItem("permissions");
      const value3 = await AsyncStorage.getItem("admin");
      const admins = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserTypes(admins);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  // Filter data based on search term
  // const filteredSearchedResult = searchedResult
  //   .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  //   // .sort((a, b) => a.name.localeCompare(b.name));

  // const filteredTableData = tableData
  //   .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  //   .sort((a, b) => a.name.localeCompare(b.name)
  //   );

  const fetchData = async () => {
    setRoleLoading(true);
    try {
      // const response = await axios.get(
      //   `${BASE_URL}/users/fetch-all?page=${currentPage}`,
      //   { headers }
      // );
      const response = await axios.get(`${BASE_URL}/users/get-all-compliances`, { headers });
     
      const results = response.data?.data;
      // const resultx = response.data?.data?.total;
      // setTotalEntries(resultx);
      // console.log(results)
      setTableData(results);
      // console.log(results);
      // const total = response.data?.data?.last_page || 1;
      // setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setSelectOptions([]);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchContinents();
    }
  }, [bearer]);

  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
      let res;
      if (searchTerm.trim() === "") {
        res = tableData1;
      } else {
        const response = await axios.get(`${BASE_URL}/users/search`, {
          params: { variable: searchTerm },
          headers,
        });
        res = response.data?.data;
      }
      setSearchedResult(res);
      console.log(res);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        let errorMessage = "An error occurred. Please try again.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (typeof error.response.data.message === "string") {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join("; ");
          } else if (typeof error.response.data.message === "object") {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          console.log(error);
        }
        setSearchedResult([]);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/all-usertype`, { headers });
      const typeList = response.data?.data;
      console.log("lists:", typeList);
      setUserType(typeList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  const fetchSubContinents = async (selectedContinent) => {
    setSubContLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`,
        { headers }
      );
      // console.log(response);
      const subCont = response.data?.data;
      const options = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData4(options);
      console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      setTableData4([]);
    } finally {
      setSubContLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  const fetchSubRegion = async (selectedRegion) => {
    setSubRegLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`,
        { headers }
      );
      // console.log(response);
      const subReg = response.data?.data;
      const options = subReg.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData5(options);
      console.log(subReg, "heeeer");
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      setTableData5([]);
    } finally {
      setSubRegLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer) {
      fetchData1();
    }
  }, [bearer]);

  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer, currentPage]);

  const selectedUserProvinces = selectedUser?.assigned_provinces.map((item) => ({
    label: item.province_name,
    value: item.province_id,
  }));

  const fetchProvince = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/provinces`, { headers });

      // console.log(response);
      const subCont15 = response.data?.data.provinces;
      const options25 = subCont15.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData45(options25);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchProvince();
    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);
    const mergedProvinces = [
      ...selectedProvinces.map((province) => province.value),
      // ...userProvinces.map((province) => province.value),
    ];
  
    try {
      const response = await axios.post(
        `${BASE_URL}/assign-compliance-to-province`,
        {
         user_id: selectedUser?.user_id,
         province_id: mergedProvinces
        },
        { headers }
      );
      console.log(response);
      fetchData();
      setSelectedProvinces([]);
      handleClose();
      
      // return
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const createUser1 = async () => {
    setLoading(true);
    const mergedProvinces = [
      ...userProvinces.map((province) => province.value),
      // ...userProvinces.map((province) => province.value),
    ];
  
    try {
      const response = await axios.post(
        `${BASE_URL}/deassign-compliance-from-province`,
        {
         user_id: selectedUser?.user_id,
         province_id: mergedProvinces
        },
        { headers }
      );
      console.log(response);
      fetchData();
      handleClose2();
      
      // return
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  //filter function
  const filteredData = tableData?.filter((item) =>
    item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData?.slice(currentPage - 1, totalEntries);

  const handleEyeClick = (id) => {
    const foundUser = tableData.find((item) => item.id === id);

    if (foundUser) {
      navigate("/accounting/update_user", { state: { userData: foundUser } });
    }
  };
  const handleEyeClick1 = (id) => {
    const foundUser = searchedResult.find((item) => item.id === id);

    if (foundUser) {
      navigate("/accounting/update_user", { state: { userData: foundUser } });
    }
  };

  useEffect(() => { }, [selectedUser]);

  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/users/update-user`,
        {
          name: fullName1,
          id: selectedId,
          email: email1,
          phone_number: phone1,
          type: selectedRole1,
          continent_id: selectedContinent1,
          province_id: selectedProvince1,
          user_id: selectedUser,
        },
        { headers }
      );

      fetchData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/users/delete?id=${id}`, {
        headers,
      });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion(null);
    // setSelectedProvinces([]);
  };
  const handleContinentChange1 = (event) => {
    setSelectedContinent1(event.target.value);
  };
  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption.value)
  }
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value);
  };

  const handleProvinceChange = (e, province) => {
  const checked = e.target.checked;
  if (checked) {
    // Add province to selectedProvinces if not already present
    setSelectedProvinces((prev) => {
      if (!prev.some((p) => p.value === province.value)) {
        return [...prev, { value: province.value, label: province.label }];
      }
      return prev; // If it's already present, return unchanged
    });
  } else {
    // Remove province from selectedProvinces
    setSelectedProvinces((prev) => prev.filter((p) => p.value !== province.value));
  }
};


const handleSelectedUserProvinceChange = (e, province) => {
  const isChecked = e.target.checked;

  if (isChecked) {
    // Add province to userProvinces if it isn't already present
    setUserProvinces((prev) => {
      // Prevent duplicates and add the current one
      if (!prev.some((p) => p.value === province.value)) {
        return [...prev, { value: province.value, label: province.label }];
      }
      return prev; // Return unchanged if already present
    });
  } else {
    // If unchecked, remove the province from userProvinces
    setUserProvinces((prev) =>
      prev.filter((p) => p.value !== province.value)
    );
  }
};

    console.log(selectedProvinces, userProvinces); // To see the selected provinces
 
  const handleProvinceChange1 = (event) => {
    setSelectedProvince1(event.target.value);
  };

  const handleRoleChange1 = (event) => {
    setSelectedRole1(event.target.value);
  };

  const handleCreateUser = () => {
    navigate("/accounting/create_users");
  };

  return (
    <div>
      <LatestBudgetDash />

      <div className={classes.finishedbodyCont}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Assigned Users</h3>
            <p>View and assign users</p>
          </div>
          <div style={{ zIndex: 1 }}>
            {/* <div className={classes.formIntBtn}>
                <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new user</Tooltip>}
                >
                  <Button variant="success" onClick={handleCreateUser} className={classes.btn2}> Add New User</Button>
                </OverlayTrigger>

              </div> */}
          </div>
        </div>

        <div className={classes.mainform}>
          <div className={classes.loandgrantcards}>
            <div className={classes.loandethead}>
              <div className={classes.formLabel}></div>
              {/* <div className={classes.formIntBtn}>
                <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new user</Tooltip>}
                >
                  <Button variant="success" onClick={handleCreateUser} className={classes.btn2}> Add New User</Button>
                </OverlayTrigger>

              </div> */}
            </div>
           
            <Modal show={show} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Assign Provinces to {selectedUser?.user_name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Form.Label>Continent</Form.Label>
                        <Select
                          onChange={(selectedOption) =>
                            handleContinentChange(selectedOption)
                          }
                          options={selectOptions}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: "300px",
                              maxWidth: "450px",
                              overflowY: "auto",
                            }),
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-1">
                        <Form.Label>Region</Form.Label>
                        <Select
                          onChange={(selectedOption) =>
                            handleRegionChange(selectedOption)
                          }
                          options={tableData4}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: "300px",
                              maxWidth: "450px",
                              overflowY: "auto",
                            }),
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                
                  <div style={{ marginTop: 10 }} />

<Accordion >
  <Accordion.Item >
    <Accordion.Header>Assigned Provinces</Accordion.Header>
    <Accordion.Body>
    <div className="row">
  {selectedUserProvinces && selectedUserProvinces.length > 0 ? (
  selectedUserProvinces.map((province) => {
    // Determine if the province is checked based on userProvinces state
    // const isChecked = userProvinces.some((p) => p.value === province.value);

    return (
      <div key={province.value} className="col-md-4 mb-3">
        <label className="d-flex align-items-center">
          {/* <input
            type="checkbox"
            value={province.value}
            className="me-2"
           
            onChange={(e) => handleSelectedUserProvinceChange(e, province)} // Handle checkbox change
          /> */}
          <span>{province.label}</span>
        </label>
      </div>
    );
  })
) : (
  <p>No provinces have been assigned to this user. Please select a continent and region to start with.</p>
)}


      </div>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item >
<Accordion.Header>Assign User to Province</Accordion.Header>
<Accordion.Body>
<div className="row">
{selectedProvinces && selectedProvinces.length > 0 && (
        <>
          <h6 style={{fontWeight: 700}}>Selected Provinces</h6>
          {selectedProvinces.map((province) => (
            <div key={province.value} className="col-md-4 mb-3">
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  value={province.value}
                  className="me-2"
                  checked={true}
                  onChange={(e) => handleProvinceChange(e, province)} // Pass the whole object
                />
                {/* Display the label stored in selectedProvinces */}
                <span>{province.label}</span>
              </label>
            </div>
          ))}
          <hr /> {/* Separator between selected and unselected provinces */}
        </>
      )}
      <div style={{marginTop: 10}}/>
      <h6 style={{fontWeight: 700}}>Provinces</h6>
      {tableData5 && tableData5.length > 0 ? (
  tableData5.map((province) => {
    // Determine if this province is selected
    const isSelected = selectedProvinces.some((p) => p.value === province.value);

    return (
      <div key={province.value} className="col-md-4 mb-3">
        <label className="d-flex align-items-center">
          <input
            type="checkbox"
            value={province.value}
            className="me-2"
            checked={isSelected} // Reflect the selectedProvinces state here
            onChange={(e) => handleProvinceChange(e, province)} // Handle checkbox change
          />
          <span>{province.label}</span>
        </label>
      </div>
    );
  })
) : (
  <p>No provinces to display. Please select a continent and region to start with.</p>
)}
      </div>
                  </Accordion.Body>
                  </Accordion.Item>
</Accordion>



             


                </Form>
                <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Go back
              </Button>
              <Button variant="success" onClick={createUser}>
                {loading ? (
                  <>
                    <Spinner size="sm" />
                    <span style={{ marginLeft: "5px" }}>
                      Saving Changes, Please wait...
                    </span>
                  </>
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Modal.Footer>



         
              </Modal.Body>
            
            </Modal>
            
            <Modal show={show2} onHide={handleClose2} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Unassign Provinces </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                
                  <div style={{ marginTop: 10 }} />

<Accordion >
  <Accordion.Item >
    <Accordion.Header>Assigned Provinces</Accordion.Header>
    <Accordion.Body>
    <div className="row">
  {selectedUserProvinces && selectedUserProvinces.length > 0 ? (
  selectedUserProvinces.map((province) => {
    // Determine if the province is checked based on userProvinces state
    const isChecked = userProvinces.some((p) => p.value === province.value);

    return (
      <div key={province.value} className="col-md-4 mb-3">
        <label className="d-flex align-items-center">
          <input
            type="checkbox"
            value={province.value}
            className="me-2"
            // checked={isChecked} // Check based on userProvinces state
            onChange={(e) => handleSelectedUserProvinceChange(e, province)} // Handle checkbox change
          />
          <span>{province.label}</span>
        </label>
      </div>
    );
  })
) : (
  <p>No provinces have been assigned to this user. Please select a continent and region to start with.</p>
)}


      </div>
    </Accordion.Body>
  </Accordion.Item>
</Accordion>



             


                </Form>
                <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Go back
              </Button>
              <Button variant="success" onClick={createUser1}>
                {loading ? (
                  <>
                    <Spinner size="sm" />
                    <span style={{ marginLeft: "5px" }}>
                      Saving Changes, Please wait...
                    </span>
                  </>
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Modal.Footer>



         
              </Modal.Body>
            
            </Modal>

          <Modal show={show1} onHide={handleClose1} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form style={{ marginTop: 20 }}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    // autoFocus
                    value={fullName1}
                    onChange={(e) => setFullName1(e.target.value)}
                  />
                </Form.Group>
                <div style={{ marginTop: 10 }} />
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email Address"
                    // autoFocus
                    value={email1}
                    onChange={(e) => setEmail1(e.target.value)}
                  />
                </Form.Group>

                <div style={{ marginTop: 10 }} />
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    // autoFocus
                    value={phone1}
                    onChange={(e) => setPhone1(e.target.value)}
                  />
                </Form.Group>
                <div style={{ marginTop: 10 }} />
                <Form.Group className="mb-3">
                  <Form.Label>Select Type</Form.Label>
                  <Form.Select
                    className="form-control"
                    as="select"
                    value={selectedRole1}
                    onChange={handleRoleChange1}
                  >
                    <option value="" disabled>
                      Select Type
                    </option>
                    {userType.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {selectedRole1 === "1" && (
                  <>
                    <div style={{ marginTop: 10 }} />
                    <Form.Group className="mb-3">
                      <Form.Label>Select Continent</Form.Label>
                      <Form.Select
                        className="form-control"
                        as="select"
                        value={selectedContinent1}
                        onChange={handleContinentChange1}
                      >
                        <option value="" disabled>
                          Select Continent
                        </option>
                        {continents.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Form.Select>
                      <div style={{ marginTop: 10 }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Select Region</Form.Label>
                      <Form.Select
                        className="form-control"
                        as="select"
                        value={selectedRegion1}
                        onChange={handleRegionChange1}
                      >
                        <option value="" disabled>
                          Select Region
                        </option>
                        {tableData4.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <div style={{ marginTop: 10 }} />
                    <Form.Group className="mb-3">
                      <Form.Label>Select Province</Form.Label>
                      <Form.Select
                        className="form-control"
                        as="select"
                        value={selectedProvince1}
                        onChange={handleProvinceChange1}
                      >
                        <option value="" disabled>
                          Select Province
                        </option>
                        {tableData5.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </>
                )}
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={handleClose1}>
                Go back
              </Button>
              <Button variant="success" onClick={editUser}>
                {updateLoading ? (
                  <>
                    <Spinner size="sm" />
                    <span style={{ marginLeft: "5px" }}>
                      Updating user, Please wait...
                    </span>
                  </>
                ) : (
                  "Update User"
                )}
              </Button>
            </Modal.Footer>
          </Modal>

            <div
              className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}
            >
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                <Button variant='success' style={{backgroundColor:'#2d995f',borderColor:'#2d995f'}} >Copy</Button>
                 <Button variant='success' style={{backgroundColor:'#2d995f',borderColor:'#2d995f'}}>Excel</Button>
                 <Button variant='success' style={{backgroundColor:'#2d995f',borderColor:'#2d995f'}}>PDF</Button>
                 <Button variant='success' style={{backgroundColor:'#2d995f',borderColor:'#2d995f'}}>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select
                      name="DataTables_Table_0_length"
                      aria-controls="DataTables_Table_0"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      //  value={entriesPerPage}
                      //     onChange={(e) => {
                      //     setEntriesPerPage(parseInt(e.target.value));
                      //     setCurrentPage(1);
                      //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div
                  id="DataTables_Table_0_filter"
                  className="dataTables_filter"
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* <Button
                      style={{ marginLeft: 10 }}
                      variant="success"
                      onClick={() => fetchSearch(searchTerm)}
                      disabled
                    >
                      {searchLoading ? (
                        <>
                          <Spinner size="sm" />
                        </>
                      ) : (
                        "Search"
                      )}
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {roleLoading ? (
                <p>Fetching users...</p>
              ) : (
                <div className="table-responsive">
                 <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
  <thead style={{ whiteSpace: "nowrap" }}>
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Email</th>
      <th>Phone Number</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody style={{ whiteSpace: "nowrap" }}>
    {tableData?.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item?.user_name}</td>
        <td>{item?.user_email}</td>
        <td>{item?.user_phone_number}</td>
        <td style={{ padding: 5,display:'flex',gap:30 }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">Click to assign this user</Tooltip>}
          >
            <PiNotePencilBold style={{height: 22, color: "#2d995f", cursor: "pointer"}} onClick={() => handleShow(item)}/>
          </OverlayTrigger>
      
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">Click to unassign this user</Tooltip>}
          >
            <PiNotePencilBold style={{height: 22, color: "red", cursor: "pointer"}} onClick={() => handleShow1(item)}/>
          </OverlayTrigger>
        </td>
      </tr>
    ))}
  </tbody>
</table>

              </div>
            )}
          </div>

          <div className={classes.endded}>
            <p>
              Showing {startIndexx} to {endIndexx} of {totalEntries} entries
            </p>
            <div style={{ display: "flex" }}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "gainsboro",
                  borderRadius: 3,
                  height: "2.5rem",
                  width: "100px",
                  fontWeight: 500,
                  fontSize: 14,
                  padding: "0.5rem",
                  fontFamily: "nunito",
                  color: "#000",
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, page) => {
                // Show only 5 pages or less if available
                if (
                  page < 5 ||
                  page === currentPage - 1 ||
                  page === totalPages - 1
                ) {
                  return (
                    <button
                      key={page + 1}
                      style={{
                        marginLeft: "0.4rem",
                        marginRight: "0.4rem",
                        fontSize: "14px",
                        fontFamily: "nunito",
                        fontWeight: 400,
                        color: page + 1 === currentPage ? "#ffffff" : "#000",
                        backgroundColor:
                          page + 1 === currentPage ? "#2d995f" : "gainsboro",
                        height: "2.5rem",
                        borderRadius: "89px",
                        padding: "0.5rem",
                        border: "none",
                        width: "40px",
                        cursor: "pointer",
                      }}
                      onClick={() => setCurrentPage(page + 1)}
                    >
                      {page + 1}
                    </button>
                  );
                }
                return null;
              })}
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "gainsboro",
                  borderRadius: 3,
                  height: "2.5rem",
                  width: "100px",
                  fontWeight: 500,
                  fontSize: 14,
                  padding: "0.5rem",
                  fontFamily: "nunito",
                  color: "#000",
                  marginLeft: 10,
                }}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div >
  );
}
