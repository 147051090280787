import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import JournalDash from '../Journal Dashboard/JournalDash';
import LatestDash from '../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



function UploadStatementOpening() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, accountName: '', accountCode: '', DR_CR: '', amount: '', description: '', date: '' }]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [balances, setBalances] = useState([]);
  const [totalAmountCredit, setTotalAmountCredit] = useState('');
  const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState(false);
  const [isEqual, setIsEqual] = useState(false);
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [subCat, setSubcat] = useState([]);
  const [period, setPeriod] = useState([]);
  const [provinceID, setProvinceID] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const provinceId = await AsyncStorage.getItem('provinceID');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);

      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (provinceId !== null) {
        setProvinceID(provinceId);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  //   console.log("user:", user)

  useEffect(() => {
    readData();
  }, []);


  const calculateTotalDebit = () => {
    const total = balances.reduce((acc, item) => {
      return item.debit ? acc + parseFloat(item.debit || 0) : acc;
    }, 0);

    const formattedTotal1 = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountDebit(formattedTotal1);
  };
  const calculateTotalCredit = () => {
    const total1 = balances.reduce((acc, item) => {
      return item.credit ? acc + parseFloat(item.credit || 0) : acc;
    }, 0);

    const formattedTotal11 = total1.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountCredit(formattedTotal11);
  };




  useEffect(() => {
    calculateTotalDebit();
    calculateTotalCredit();

  }, [balances]);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };









  useEffect(() => {
    // Compare the total debit and total credit values
    setIsEqual(totalAmountDebit === totalAmountCredit);
  }, [totalAmountDebit, totalAmountCredit]);

  const [selectedDate, setSelectedDate] = useState('');
  const [show, setShow] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [createLoading, setCreateLoading] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };


  const uploadStatement = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('bank_id', selectedAccount);
      formData.append('period', selectedPeriod);
      // formData.append('date', selectedDate);

      const response = await axios.post(
        `${BASE_URL}/payment_voucher/upload-continent-bank-statement`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response, "heeee");
      setBalances(response.data.data);
      handleClose();
      // Reset form fields
      setSelectedImage("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };
  const uploadStatement1 = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('bank_id', selectedAccount);
      formData.append('period', selectedPeriod);
      // formData.append('date', selectedDate);

      const response = await axios.post(
        `${BASE_URL}/payment_voucher/upload-region-bank-statement`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response, "heeee");
      setBalances(response.data.data);
      handleClose();
      // Reset form fields
      setSelectedImage("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };
  const uploadStatement2 = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('bank_id', selectedAccount);
      formData.append('period', selectedPeriod);
      formData.append('date', selectedDate);

      const response = await axios.post(
        `${BASE_URL}/account/upload-bank-statement-opening-balance`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response?.data?.data);
      setBalances(response.data.data);
      handleClose();
      // Reset form fields
      setSelectedImage("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleProcess = () => {
    if (userType === 'Super Admin') {
      return; 
    } else if (userType === 'Continental Accountant') {
      uploadStatement();
    } else if (userType === 'Regional Accountant') {
      uploadStatement1();
    } else if (userType === 'Provincial Accountant') {
      uploadStatement2();
    }
  };
  

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const handlePeriodChange = (selectedOption) => {
    setSelectedPeriod(selectedOption.value);
  };


  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/bank-statement-accounts`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      // console.log(results)
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchPeriod = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/provinces/get-province-months-for-bank-statement`,
        {  
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setPeriod(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const opt = subCat.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  const opt12 = period.map((item) => ({
    label: item.description,
    value: item.description,
  }));

  useEffect(() => {
    if (bearer) {
      fetchSubCat();
      fetchPeriod();
    }
  }, [bearer]);


  const postBalances = async () => {
    setBalanceLoading(true);
    const accountNames = balances.map((row) => row.period).filter((name) => name !== undefined);
    const accountCodes = balances.map((row) => row.description).filter((name) => name !== undefined);
    const debits = balances.map((row) => row.debit).filter((name) => name !== undefined);
    const credits = balances.map((row) => row.credit).filter((name) => name !== undefined);

    console.log(accountNames, accountCodes, debits, credits, totalAmountCredit, totalAmountDebit)
    try {
      //  return;
      const response = await axios.post(
        `${BASE_URL}/payment_voucher/save-region-bank-statement`,
        {
          total_debit: totalAmountDebit,
          total_credit: totalAmountCredit,
          uuid: balances[0]?.uuid,        },
        { headers }
      );


      console.log(response.data?.message, "heeee");
      setBalances([]);
      setSelectedDate('');

      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });

      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setBalanceLoading(false);
    }
  };
  const postBalances1 = async () => {
    setBalanceLoading(true);
    const accountNames = balances.map((row) => row.period).filter((name) => name !== undefined);
    const accountCodes = balances.map((row) => row.description).filter((name) => name !== undefined);
    const debits = balances.map((row) => row.debit).filter((name) => name !== undefined);
    const credits = balances.map((row) => row.credit).filter((name) => name !== undefined);

    console.log(accountNames, accountCodes, debits, credits, totalAmountCredit, totalAmountDebit)
    try {
      //  return;
      const response = await axios.post(
        `${BASE_URL}/payment_voucher/save-continent-bank-statement`,
        {
          total_debit: totalAmountDebit,
          total_credit: totalAmountCredit,
          uuid: balances[0]?.uuid,        },
        { headers }
      );


      console.log(response.data?.message, "heeee");
      setBalances([]);
      setSelectedDate('');

      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });

      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setBalanceLoading(false);
    }
  };
  const postBalances2 = async () => {
    setBalanceLoading(true);
    const accountNames = balances.map((row) => row.period).filter((name) => name !== undefined);
    const accountCodes = balances.map((row) => row.description).filter((name) => name !== undefined);
    const debits = balances.map((row) => row.debit).filter((name) => name !== undefined);
    const credits = balances.map((row) => row.credit).filter((name) => name !== undefined);

    console.log(accountNames, accountCodes, debits, credits, totalAmountCredit, totalAmountDebit)
    try {
      //  return;
      const response = await axios.post(
        `${BASE_URL}/account/save-bank-statement-opening-balance`,
        {
          total_debit: totalAmountDebit,
          total_credit: totalAmountCredit,
          uuid: balances[0]?.uuid,        },
        { headers }
      );


      console.log(response.data?.message, "heeee");
      setBalances([]);
      setSelectedDate('');

      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });

      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setBalanceLoading(false);
    }
  };

  const handlePost = () => {
    if (userType === 'Super Admin') {
      return; 
    } else if (userType === 'Continental Accountant') {
      postBalances1();
    } else if (userType === 'Regional Accountant') {
      postBalances();
    } else if (userType === 'Provincial Accountant') {
      postBalances2();
    }
  };

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash />
            <div className={classes.finishedbodyCont}>
              <div className='newBody'>
                {/* <div className={` ${classes.newWidth} ${classes.mainform} `}> */}
                <div className={`row ${classes.awrow1}`} >
                  <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  20px" }}>Upload Statement Opening Balance</h3>
                      <p style={{ margin: " 0 10px 20px 20px" }}>Upload, View, Save balances</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", zIndex: 1 }}>

                      <div style={{ display: "flex", justifyContent: "flex-end", gap: 5 }}>
                        {/* <OverlayTrigger
                                      placement="top"
                                      className={classes.btndf1}
                                      overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to upload opening balance</Tooltip>}
                                    > */}
                        <Button
                          style={{ width: 100, height: "30px", padding: "0", borderRadius: 5, marginLeft: -70 }} variant='success' onClick={handleShow}>
                          Upload
                        </Button>
                        {/* </OverlayTrigger> */}
                        <OverlayTrigger
                          placement="top"
                          className={classes.btndf1}
                          overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to download template</Tooltip>}
                        >

                          <a style={{ marginLeft: 10 }} href={`https://account-api.patna.ng/api/v1/download-bank-statement-opening-balance?province_id=${provinceID}`} download>
                            <Button
                              className={classes.btn1}
                              style={{ width: 200, height: "30px", padding: "0", borderRadius: 5 }} variant='success' >
                              Download Template
                            </Button>
                          </a>

                        </OverlayTrigger>


                      </div>
                    </div>
                  </div>

                  <div className={classes.mainform}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 5, marginLeft: 20 }}>
                      {/* <div className={`col-md-4 ${classes.mblrow}`}>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`} style={{ marginLeft: 5 }}>Opening Balance as at:</label>
                            <div className="col-sm-8">
                              <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate} style={{ width: "200px", marginLeft: 5 }} />
                            </div>
                          </div>
                        </div> */}


                    </div>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont}`}>
                        <div className={`card-body ${classes.awrow112}`} style={{ border: 'none' }}>


                          <div className={`row ${classes.awrow1}`} >
                            {/* <Modal show={show} onHide={handleClose} animation={false}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Upload Opening Balance</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Form style={{ marginTop: 20 }}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <div style={{ marginTop: 10 }} />
                                      <Form.Label>Opening Balance as at:</Form.Label>
                                      <Form.Control
                                        type="date"
                                        onChange={handleDateChange}
                                        value={selectedDate}

                                      />
                                      <div style={{ marginTop: 10 }} />
                                      <Form.Label>File</Form.Label>
                                      <Form.Control
                                        type="file"
                                        placeholder="Choose file"
                                        accept=".xlsx"
                                        onChange={handleImageChange}

                                      />
                                    </Form.Group>
                                  </Form>
                                </Modal.Body>

                                <Modal.Footer>
                                  <Button variant="danger" onClick={handleClose}>
                                    Go back
                                  </Button>
                                  <Button variant="success" onClick={uploadStatement}>
                                    {createLoading ? (
                                      <>
                                        <Spinner size='sm' />
                                        <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                                      </>
                                    ) : (
                                      "Upload"
                                    )}
                                  </Button>
                                </Modal.Footer>
                              </Modal> */}

                            <Modal show={show} onHide={handleClose} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Statement of Account</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                      type="date"
                                      placeholder=""
                                      // autoFocus
                                      value={selectedDate}
                                      onChange={(e) => setSelectedDate(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    {/* <Form.Label className={classes.formLabelCustom}>Bank</Form.Label>
                                    <Select
                                      className={classes.formControl}
                                      options={opt}
                                      placeholder="Select Bank"
                                      onChange={handleAccountChange}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        width: "100%",
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '100px',
                                          overflowY: 'auto',

                                        }),
                                      }}
                                    /> */}
                                    {/* <div style={{ marginTop: 10 }} />
                                    <Form.Label className={classes.formLabelCustom}>Period</Form.Label>
                                    <Select
                                      className={classes.formControl}
                                      options={opt12}
                                      placeholder="Select Period"
                                      onChange={handlePeriodChange}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        width: "100%",
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '100px',
                                          overflowY: 'auto',

                                        }),
                                      }}
                                    /> */}

                                    <Form.Label>File</Form.Label>
                                    <Form.Control
                                      type="file"
                                      placeholder="Choose file"
                                      accept=".xls,.xlsx"
                                      onChange={handleImageChange}

                                    />






                                  </Form.Group>
                                </Form>
                              </Modal.Body>

                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={handleProcess}>
                                  {createLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                                    </>
                                  ) : (
                                    "Upload"
                                  )}
                                </Button>
                              </Modal.Footer>
                            </Modal>



                            <div className={`row ${classes.awrow1}`}>



                              {/* <div style={{ marginTop: 20 }} /> */}
                              <div className={classes.mainTable}>
                                {/* <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}> */}
                                <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto', }}>
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                    <thead style={{ whiteSpace: "nowrap", textAlign: "left", }}>
                                      <tr>
                                        <th >GL Name</th>
                                        <th >GL Code</th>
                                        <th >Amount</th>
                                        {/* <th style={{ textAlign: "right", width: "50px" }}>Debit</th>
                                        <th style={{ textAlign: "right", width: "50px" }}>Credit</th> */}
                                      </tr>
                                    </thead>

                                    <tbody style={{}}>
                                      {balances.map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            {item.gl_name}
                                          </td>
                                          <td>
                                            {item.gl_code}
                                          </td>
                                          <td>
                                            {item.amount}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>

                                  </table>

                                </div>
                                {/* </div> */}
                              </div>

                            </div>

                            <div style={{ marginTop: 20 }} />
                            <div className={classes.form_align}>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Debit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountDebit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right' }}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Credit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountCredit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right' }}
                                />
                              </Form.Group>
                            </div>


                            <div class={`modal-footer ${classes.overlaybtn100}`} style={{ marginTop: '20px', paddingRight: '20px' }}>
                              <Button
                                className={classes.longbtn}
                                variant='success'
                                onClick={handlePost}
                                disabled={!isEqual}
                                style={{ padding: '5px 30px' }}>
                                {balanceLoading ? (
                                  <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                                  </>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>













              </div>
            </div>
            {/* <!--/.main content--> */}
          </div>
        </div>
        {/* <InfoFooter /> */}
        {/* <!--/.footer content--> */}
        <div className="overlay"></div>
      </div>
      {/* <!--/.wrapper--> */}


    </div>


  );
}

export default UploadStatementOpening;