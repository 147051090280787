import React, { useState, useEffect } from 'react';
import classes from './PrintBalances.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintNewIncomeConsoloditedDetailed() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');
    const [overallTotal, setOverallTotal] = useState(0);


    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `${day}/${month}/${year} `;

        setCurrentDateTime(formattedDateTime);
    }, []);


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const admins = await AsyncStorage.getItem('admin');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setCompany(value1);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (admins !== null) {
                setUserType(admins);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const { accounts, selectedEndDate, selectedDate, inputss, ressult } = location.state || {};

    // const totalIncome = item[0].cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total), 0);
    // const totalExpenses = item[1].cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total), 0);



    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const day = date.getDate();
        const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'TH';
            switch (day % 10) {
                case 1: return 'ST';
                case 2: return 'ND';
                case 3: return 'RD';
                default: return 'TH';
            }
        };

        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        return `${day}${daySuffix(day)} ${month.toUpperCase()} ${year}`;
    };

    useEffect(() => {
        if (accounts) {
            const firstTotal = accounts[0]?.cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total || 0), 0);
            const secondTotal = accounts[1]?.cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total || 0), 0);

            setTotalDebit(firstTotal);
            setTotalCredit(secondTotal);
            setOverallTotal(firstTotal - secondTotal); // Adjust if needed for your calculation logic
        }
    }, [accounts]);



    return (
        <div className={classes.a4}>
            <h2 className={classes.headerTop}>THE REDEEMED CHRISTIAN CHURCH OF GOD</h2>
            {/* <h4 className={classes.headerTwoo}>{ressult?.name ? ressult.name : province}</h4> */}
            <h4 className={classes.headerTwo}>CONSOLIDATED INCOME STATEMENT REPORT {selectedDate === '' ? `AS AT ${currentDateTime}` : `BETWEEN ${formatDate(selectedDate)}  AND ${formatDate(selectedEndDate)}`}</h4>

            <table className={classes.tableDivF}>
                <thead>
                    <tr>
                        <th></th>
                        <th className={classes.noteDiv}>Notes</th>
                        <th style={{ whiteSpace: 'wrap', textAlign: "center", width: 200 }}>AS AT {selectedDate === '' ? currentDateTime : formatDate(selectedEndDate)}</th>
                        <th></th>
                        <th style={{ whiteSpace: 'wrap', textAlign: "center", width: 200 }}>AS AT {selectedDate === '' ? currentDateTime : formatDate(selectedEndDate)}</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th style={{ textAlign: "center" }}>N'000</th>
                        <th></th>
                        <th style={{ textAlign: "center" }}>N'000</th>
                    </tr>
                </thead>

                <tbody>
                    {accounts && accounts.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {/* Render the item description as a heading */}
                                <tr>
                                    <th style={{ textDecoration: "underline", fontSize: 18 }} colSpan={4}>{item.description.toUpperCase()}</th>
                                </tr>

                                {/* Render the categories and their postings */}
                                {item.cat_accounts?.map((category, catIndex) => (
                                    <React.Fragment key={catIndex}>
                                        {/* Render the category description */}
                                        <tr>
                                            <th style={{ textDecoration: "underline", paddingLeft: 20, fontSize: 16 }} colSpan={4}>{category.description}</th>
                                        </tr>
                                        {category.postings.map((posting, postIndex) => (
                                            <tr key={postIndex}>
                                                <td style={{ paddingLeft: 40 }}>{posting.name}</td>
                                                <td></td>
                                                <td style={{ textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5 }}>
                                                    {parseFloat(posting.amount).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 1,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </td>
                                                <td style={{ width: 20 }}></td>
                                                <td style={{ textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5 }}>
                                                    {parseFloat(posting.amount).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 1,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </td>
                                            </tr>
                                        ))}

                                        {/* Render the total for the category */}
                                        <tr>
                                            <td colSpan={2} style={{ fontWeight: 'bold', paddingLeft: 20, fontSize: 16 }} className={classes.totalTh}>
                                                TOTAL {category.description.toUpperCase()}
                                            </td>
                                            <td style={{
                                                textAlign: "right",
                                                fontWeight: 'bold',
                                                backgroundColor: "rgb(236, 233, 233)",
                                                padding: 5,
                                                borderTop: "1px solid black",
                                                borderBottom: "1px solid black"
                                            }}>
                                                {parseFloat(category.total).toLocaleString('en-US', {
                                                    minimumIntegerDigits: 1,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </td>
                                            <td></td>
                                            <td style={{
                                                textAlign: "right",
                                                fontWeight: 'bold',
                                                backgroundColor: "rgb(204, 201, 201)",
                                                padding: 5,
                                                borderTop: "1px solid black",
                                                borderBottom: "1px solid black"
                                            }}>
                                                {parseFloat(category.total).toLocaleString('en-US', {
                                                    minimumIntegerDigits: 1,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </td>
                                        </tr>
                                        <td style={{ width: 50 }}>&nbsp;&nbsp;&nbsp;</td>
                                    </React.Fragment>
                                ))}

                                {/* Render the overall totals for income and expenses */}
                                <tr>
                                    <td colSpan={2} style={{ fontWeight: 'bold',  fontSize: 16 }}>TOTAL {item.description.toUpperCase()}</td>
                                    <td style={{
                                        textAlign: "right",
                                        fontWeight: 'bold',
                                        backgroundColor: "rgb(236, 233, 233)",
                                        padding: 5,
                                        borderTop: "2px solid black",
                                        borderBottom: "2px solid black"
                                    }}>
                                        {index === 0
                                            ? totalDebit.toLocaleString("en-US", {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })
                                            : totalCredit.toLocaleString("en-US", {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                    </td>
                                    <td></td>
                                    <td style={{
                                        textAlign: "right",
                                        fontWeight: 'bold',
                                        backgroundColor: "rgb(204, 201, 201)",
                                        padding: 5,
                                        borderTop: "2px solid black",
                                        borderBottom: "2px solid black"
                                    }}>
                                        {index === 0
                                            ? totalDebit.toLocaleString("en-US", {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })
                                            : totalCredit.toLocaleString("en-US", {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                    </td>
                                </tr>
                                <td style={{ width: 50 }}>&nbsp;&nbsp;&nbsp;</td>
                                <p></p>
                            </React.Fragment>
                        );
                    })}
                     <td style={{ width: 50 }}>&nbsp;&nbsp;&nbsp;</td>
                    <tr>
                        <td colSpan={2} style={{ fontWeight: 'bold', fontSize: 16 }} className={classes.totalTh}>
                            SURPLUS BEFORE DEPRECIATION AND FINANCE
                        </td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(236, 233, 233)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                        <td></td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(204, 201, 201)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ fontWeight: 'bold', fontSize: 16 }} className={classes.totalTh}>
                            SURPLUS FOR THE PERIOD
                        </td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(236, 233, 233)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                        <td></td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(204, 201, 201)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 className={classes.btmPrg}>These Financial Statements were approved by the Governing Council at a meeting held on __________________ and were signed on its behalf by:</h4>
            <div className={classes.sigNatures}>
                <span>National Overseer</span>
                <span>National Treasurer</span>
            </div>
        </div>
    )
}
