import React, {useState,useEffect} from 'react';
import { Button, Modal, Form, Spinner, ToastContainer } from 'react-bootstrap';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import { InfoFooter } from '../../InfoFooter';
import classes from './Customers.module.css';
// import favicon from '../../Images/faviconn.png';
import MainDashoard from '../Main Dashboard/MainDashoard';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import axios from 'axios';
import { BASE_URL } from '../api/api';
import LatestBudgetDash from '../LatestDashboard/LatestBudgetDash';
import { NavLink, useNavigate } from 'react-router-dom';
import LatestAdminDash from '../LatestDashboard/LatestAdminDash';
import Swal from 'sweetalert2';



function AdminAccountUi({
  show,
  user,
  stat,
  show1,
  show56,
  handleShow,
  handleShow56,
  handleShow1,
  handleClose,
  handleClose56,
  statLoading,
  handleClose1,
  createCustomer,
  editCustomer,
  isLoading,
  searchTerm,
  setSearchTerm,
  entriesPerPage,
  setEntriesPerPage,
  currentPage,
  setCurrentPage,
  totalEntries,
  totalPages,
  startIndexx,
  benLoading,
  endIndexx,
  displayedData,
  uploadLoading,
  handlePrevPage,
  handleNextPage,
  handleEyeClick,
  handleTrashClick,
  fullName,
  setFullName,
  address,
  setAddress,
  office_address,
  setOfficeAddress,
  phone,
  setPhone,
  email,
  setEmail,
  fullName1,
  setFullName1,
  phone1,
  setPhone1,
  email1,
  setEmail1,
  tableData,
  formatDate,
  handleFileChange,
  uploadExcel,
  fetchSearch,
  setSearchedResult,
  searchedResult,
  searchLoading,
  handleEyeClick1,
  accName,
  bankName,
  accNo,
  setAccName,
  setBankName,
  setAccNo,
  bearer
}) {
  const navigate = useNavigate();
  const [tableData4, setTableData4] = useState([]);
  const [tableData3, setTableData3] = useState([]);
const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [load, setLoad] = useState(false);
  const [custLoading, setCustLoading] = useState(false);

  const formattedTotalEntries = totalEntries.toLocaleString();
  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion('');
    setSelectedProvince('');
  };

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption.value)
  }


  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption.value)
  }

  const handleSubCatChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };


  const fetchContinents = async () => {
    console.log("From Continent")
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
      console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

   const fetchSubRegion = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

      // console.log(response);
      const subCont1 = response.data?.data;
      const options2 = subCont1.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData4(options2);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchSubContinents = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });

      // console.log(response);
      const subCont = response.data?.data;
      const options1 = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData3(options1);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);


  useEffect(() => {
    if (bearer) {
      fetchContinents();
    }
  }, [bearer]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);


  const createUser = async () => {
    setCustLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/customer/fetch-all`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`, // Ensure bearer token is defined
          },
          params: {
           continent_id: selectedContinent || "",
           region_id: selectedRegion || "",
           province_id: selectedProvince || ""
          },
        }
      );
  
      console.log(response);
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response?.data?.message) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.error(error);
    } finally {
      setCustLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
    <LatestAdminDash />
    
    <div className={classes.finishedbodyCont}>
            {/* <div className={classes.formLabel}> */}
            {/* <h3 className={classes.productText1}>Customers</h3> */}
            <h3 className={classes.createPrdLg} style={{fontWeight: 700}}>Customers</h3>
              <p className={classes.subPrdLg}>Upload, View, Edit and Delete Customer</p>
            {/* </div> */}
      <div className={classes.mainform}>
      <div className={`row ${classes.awrow}`} style={{marginBottom:20,marginLeft:20}}>
                            <div className={`row ${classes.awrow}`} style={{ marginTop: 10 }}>

                            {/* {admin === "Continental Accountant" && (
                                    <> */}

                              <div className="col-md-4" style={{ }}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Continent:</label>
                                  <div className="col-sm-8">
                                  <Select
                                      onChange={(selectedOption) => handleContinentChange(selectedOption)}
                                      options={selectOptions}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                           

                              <div className="col-md-4" style={{ marginLeft: '-125px' }}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
                                  <div className={`col-sm-8  ${classes.lblrow}`}>
                                  <Select
                                      onChange={(selectedOption) => handleRegionChange(selectedOption)}
                                      options={tableData3}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* </>
                               )} */}
                            {/* {admin === "Regional Accountant" && (
                                    <> */}

                              {/* <div className="col-md-4" style={{ }}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
                                  <div className="col-sm-8">
                                  <Select
                                      onChange={(selectedOption) => handleRegionChange(selectedOption)}
                                      options={tableData3}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                           

                              <div className="col-md-4" style={{ marginLeft: -125}}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Province:</label>
                                  <div className={`col-sm-8  ${classes.lblrow}`}>
                                  <Select
                                      onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                                      options={tableData4}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <Button style={{marginLeft: 10,marginTop:90, backgroundColor:'#2d995f',borderColor:'#2d995f', color: '#fff', maxWidth:'fit-content',maxHeight:'fit-content',marginTop:35,marginLeft:'-80px'}}  onClick= {() => createUser()}>
                {custLoading ? (
                    <>
                    <Spinner  size='sm' /> 
                   
  </>
) : (
              "Process"
            )}
                </Button>
                              {/* </>
                               )} */}
                             
                            </div>

                          </div>

        <div className={classes.loandgrantcards}>
         
          <Modal show={show56} onHide={handleClose56} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Upload Customer</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Upload Excel File</Form.Label>
                            <Form.Control
                              type="file"
                              accept=".xlsx, .xls, .csv"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>







                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose56}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={uploadExcel}>
                          {uploadLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                            </>
                          ) : (
                            "Upload"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Customer</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Full Name"
                              // autoFocus
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              // autoFocus
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Office Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter office Address"
                              // autoFocus
                              value={office_address}
                              onChange={(e) => setOfficeAddress(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              // autoFocus
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email Address"
                              // autoFocus
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Account Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Account Name"
                              // autoFocus
                              value={accName}
                              onChange={(e) => setAccName(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Account Number"
                              // autoFocus
                              value={accNo}
                              onChange={(e) => setAccNo(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Bank Name"
                              // autoFocus
                              value={bankName}
                              onChange={(e) => setBankName(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createCustomer}>
                          {loading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Creating Customer, Please wait...</span>
                            </>
                          ) : (
                            "Create Customer"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>


          <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
            <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
              <div className={classes.actionsBtns}>
              <Button  style={{backgroundColor:'#2d995f',borderColor:'#2d995f', }} >Copy</Button>
                 <Button  style={{backgroundColor:'#2d995f',borderColor:'#2d995f', }}>Excel</Button>
                 <Button  style={{backgroundColor:'#2d995f',borderColor:'#2d995f', }}>PDF</Button>
                 <Button  style={{backgroundColor:'#2d995f',borderColor:'#2d995f', }}>Column visibility</Button>
              </div>
              <div className={classes.show}>
                <label className="d-flex justify-content-start align-items-center">
                  Show
                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                  //  value={entriesPerPage}
                  //     onChange={(e) => {
                  //     setEntriesPerPage(parseInt(e.target.value));
                  //     setCurrentPage(1);
                  //     }}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  entries
                </label>
              </div>
            </div>
            <div className="text-right modal-effect ">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="mr-2">Search:</div>
                  <input
  type="search"
  value={searchTerm}
  className="form-control form-control-sm"
  placeholder=""
  aria-controls="DataTables_Table_0"
  onChange={(e) => setSearchTerm(e.target.value)}
/>
                  <Button style={{marginLeft: 10, backgroundColor:'#2d995f',borderColor:'#2d995f', color: "#fff"}}  onClick= {() => fetchSearch(searchTerm)}>
                {searchLoading ? (
                    <>
                    <Spinner  size='sm' /> 
                   
  </>
) : (
              "Search"
            )}
                </Button>
                </div>
                

              </div>
            </div>
          </div>
          <div className={classes.mainTable}>
          {benLoading ? (
                                <p>Fetching Customers...</p>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                    <thead style={{ whiteSpace: 'nowrap' }}>
                                      <tr>
                                        <th>S/N</th>
                                        <th>Number</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Created At</th>
                                        <th>Outstanding Balance</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: 'nowrap' }}>
                                      {(searchTerm.trim() ? searchedResult : tableData).length === 0 ? (
                                        <tr>
                                          <td colSpan="7">No data available</td>
                                        </tr>
                                      ) : (
                                        (searchTerm.trim() ? searchedResult : tableData).map((item, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.employee_no}</td>
                                            <td>{item.name}</td>
                                            <td style={{ textAlign: "left" }}>{item.email}</td>
                                            <td>{item.phone}</td>
                                            <td>{formatDate(item.created_at)}</td>
                                            <td style={{textAlign: "right"}}> {parseFloat(item.balance).toLocaleString('en-US', {
                                                  minimumIntegerDigits: 1,
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                                })}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              <div onClick={() => handleEyeClick(item.id)} style={{ padding:'2px 10px'}} className="btn btn-success-soft btn-sm mr-1">
                                                <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                              </div>
                                              <div onClick={() => handleTrashClick(item.id)} style={{ padding:0}} className="btn btn-danger-soft btn-sm">
                                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
          </div>

          <div className={classes.endded}>
                              <p>
                                Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#2d995f' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                            <Modal show={show1} onHide={handleClose1} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Edit User</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Full Name"
                                      // autoFocus
                                      value={fullName1}
                                      onChange={(e) => setFullName1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Email Address"
                                      // autoFocus
                                      value={email1}
                                      onChange={(e) => setEmail1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Phone Number"
                                      // autoFocus
                                      value={phone1}
                                      onChange={(e) => setPhone1(e.target.value)}
                                    />

                                  </Form.Group>
                                </Form>
                              </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={editCustomer} >                             {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                                </Button>
                              </Modal.Footer>
                            </Modal>
        </div>
      </div>

    </div>

 


  

  </div >
  );
}

export default AdminAccountUi;