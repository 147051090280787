import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import LatestDash from '../../Pages/LatestDashboard/LatestDash';
import Arrow from '../../assets/promix/dArrow-down.svg'
import MobileMenu from '../../Component/Mobile/MobileNav';



function StaffLoanss() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [openNav,setOpenNav] = useState(false);


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  // specify header
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };


  const fetchBooking = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/fetch-staff-loan?page=${currentPage}`, { headers });
      const results = response.data?.data?.data;
      const resultx = response.data?.data?.total;
      setTotalEntries(resultx);
      setTableData(results);
      console.log(response);
      const total = response.data?.data?.last_page || 1;
      setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(error);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchBooking();

    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/create`,
        {
          name: fullName,
          email: email,
          phone_no: phone,
          role: selectedRole
        },
        { headers }
      );
      fetchBooking();
      handleClose();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      const errorStatus = error.response.name;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: error.response.name,
      });
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const handleEyeClick = (id) => {

    const foundLoan = tableData.find(item => item.id === id);
    navigate('/accounting/receivables/loan_and_advances/edit_loan_advance', { state: { selectedLoan: foundLoan } });
    setEyeClicked(true);
  };


  const handleTrashClick = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/destroy?id=${id}`, { headers });
      fetchBooking();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      console.log(errorStatus);
    }
  };

  const editUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/update`,
        {
          name: fullName1,
          // id: deptId, 
          email: email1,
          phone_no: phone1,
          role: selectedRole
        },
        { headers }
      );

      fetchBooking();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      // console.log(response.data);
    } catch (error) {
      const errorStatus = error.response?.data?.message || 'An error occurred';

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = Array.isArray(tableData)
    ? tableData.filter(item =>
      item.principal_amount &&
      typeof item.principal_amount === 'string' &&
      item.principal_amount.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  // const filteredData = tableData.filter(item => item.principal_amount.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleCreate = () => {
    navigate('/accounting/receivables/loan_and_advances/create_loan');
  };

  const formattedTotalEntries = totalEntries.toLocaleString();
  return (

    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash setOpenNav={setOpenNav} openNav={openNav} />
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div className={classes.topPadding}>
                  {/* <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  12px" }}>Staff Loan</h3> */}
                  {/* <p style={{margin:" 0 10px 20px 12px" }}>Add, View, Edit, and Delete Purchase Order</p> */}

                  <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                      <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 50px 10px 0  70px" }}>Staff Loan</h3>
                      <p style={{ margin: " 0 10px 0  70px" }}>View, Create, Approve/Disapprove Staff Loans</p>
                      {/* <small>Create and view your loan accounts...</small> */}
                    </div>

                    <div className={`${classes.formIntBtnnew} ${classes.formIntBtnUnitss}`} style={{ zIndex: 1, }}>
                      <Button variant="success" onClick={handleCreate} className={classes.btn1}>  Create New Loan  </Button>
                    </div>

                  </div>

                  {/* <div className={classes.analysis} style={{marginBottom:'30px'}}>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL INCOME</p>
                        <h5>N0.00</h5>
                        
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL LODGE</p>
                        <h5>N0.00</h5>
                        
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL OUTSTANDING</p>
                        <h5>N0.00</h5>
                        
                    </div>
                </div> */}
                </div>

                <div className={classes.boddypadding}>
                  {/* <!--Content Header (Page header)--> */}
                  <div className="content-header row align-items-center m-0">
                    {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                    {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}

                    {/* )} */}

                    <div className="col-sm-8 header-title p-0">
                      <div className="media">
                        {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                        <div className="media-body">
                          {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                          {/* <small>Create and view your Savings Accounts...</small> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--/.Content Header (Page header)--> */}
                  <div className="body-content">

                    {/* <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add New Beneficiaries</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Full Name"
                              // autoFocus
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email Address"
                              // autoFocus
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Phone Number"
                              // autoFocus
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Input Address"
                              // autoFocus
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />

                          </Form.Group>
                        </Form>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createBeneficiary}>
                          {loading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Creating Beneficiary, Please wait...</span>
                            </>
                          ) : (
                            "Create Beneficiary"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal> */}

                    <div className="row">



                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">

                            <div className="table-resposive">
                              <div className={`d-flex justify-content-between align-items-center ${classes.flxshi}`}>
                                <div className={`${classes.greenbtn} ${classes.flxshii}`} >
                                  <div>
                                    <button>Copy</button>
                                    <button>Excel</button>
                                    <button>PDF</button>
                                    <button className={classes.diffbtn}>Column visibility</button>
                                  </div>
                                  <div>
                                    <label className="d-flex justify-content-start align-items-center">
                                      Show
                                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                        onChange={(e) => {
                                          setEntriesPerPage(parseInt(e.target.value));
                                          setCurrentPage(1);
                                        }}>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                      </select>
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="text-right modal-effect ">
                                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <div className="mr-2">Search:</div>
                                      <input
                                        type="search"
                                        value={searchTerm}
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-controls="DataTables_Table_0"
                                        onChange={(e) => {
                                          setSearchTerm(e.target.value);
                                          // setCurrentPage(1);
                                        }}
                                      />
                                    </div>

                                  </div>
                                </div>
                              </div>


                              {isLoading ? (
                                <p>Fetching Loans...</p>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                    <thead style={{ whiteSpace: 'nowrap' }}>
                                      <tr>
                                        <th>S/N</th>
                                        <th>Date</th>
                                        <th>Staff Name</th>
                                        <th>Loan Name</th>
                                        {/* <th>Loan Prefix</th> */}
                                        <th>Duration</th>
                                        <th>Principal Amount</th>
                                        <th>Interest Amount</th>
                                        <th>Total Loan</th>
                                        {/* <th>Monthly Deduction</th> */}
                                        {/* <th>Total Repayment</th> */}
                                        <th>Balance</th>
                                        {/* <th>Approval Status</th> */}
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: 'nowrap' }}>
                                      {tableData.map((item, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item.transaction_date}</td>
                                          <td>{item.beneficiary?.name}</td>
                                          <td>{item.loan?.description}</td>
                                          {/* <td>{item.prefix}</td> */}
                                          <td>{item.duration}</td>
                                          <td style={{ textAlign: "right" }}>{parseFloat(item.principal_amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
                                          <td style={{ textAlign: "right" }}>{parseFloat(item.interest_amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
                                          <td style={{ textAlign: "right" }}>{parseFloat(item.total_repayment).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
                                          {/* <td style={{textAlign: "right"}}>{parseFloat(item.monthly_deduction).toLocaleString('en-US', {
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}</td> */}
                                          {/* <td style={{textAlign: "right"}}>{parseFloat(item.total_repayment).toLocaleString('en-US', {
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}</td> */}
                                          <td style={{ textAlign: "right" }}>{parseFloat(item.balance).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>

                                          {/* <td><Badge bg={item.approved === "0" ? "warning" : item.approved === "1" ? "success" : item.approved === "2" ? "danger" : "null"}>{item.approved === "0" ? "Pending" : item.approved === "1" ? "Approved" : item.approved === "2" ? "Disapproved" : "null"}</Badge></td> */}

                                          <td style={{ textAlign: "left" }}>
                                            <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                              <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                            </div>
                                            <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              <div className={classes.endded}>
                                <p>
                                  Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                                </p>
                                <div style={{ display: 'flex' }}>
                                  <button
                                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                  >
                                    Previous
                                  </button>
                                  {[...Array(totalPages)].map((_, page) => {
                                    // Show only 5 pages or less if available
                                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                      return (
                                        <button
                                          key={page + 1}
                                          style={{
                                            marginLeft: '0.4rem',
                                            marginRight: '0.4rem',
                                            fontSize: '14px',
                                            fontFamily: 'nunito',
                                            fontWeight: 400,
                                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                                            backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                            height: '2.5rem',
                                            borderRadius: '89px',
                                            padding: '0.5rem',
                                            border: 'none',
                                            width: '40px',
                                            cursor: "pointer"
                                          }}
                                          onClick={() => setCurrentPage(page + 1)}
                                        >
                                          {page + 1}
                                        </button>
                                      );
                                    }
                                    return null;
                                  })}
                                  <button
                                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>

                              {/* <Modal show={show1} onHide={handleClose1} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Edit User</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Full Name"
                                      // autoFocus
                                      value={fullName1}
                                      onChange={(e) => setFullName1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Email Address"
                                      // autoFocus
                                      value={email1}
                                      onChange={(e) => setEmail1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Phone Number"
                                      // autoFocus
                                      value={phone1}
                                      onChange={(e) => setPhone1(e.target.value)}
                                    />

                                  </Form.Group>
                                </Form>
                              </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={editUser}>
                                  {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                                </Button>
                              </Modal.Footer>
                            </Modal> */}


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--/.body content--> */}
                </div>
              </div>
              {/* <!--/.main content--> */}
            </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default StaffLoanss;