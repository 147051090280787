import React, { useState, useEffect } from 'react';
import classes from '../Manage Continent/Continent.module.css';
// import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL, BASE_URL1, TOKEN } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import LatestAdminDash from '../LatestDashboard/LatestAdminDash';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import LatestFixedDash from '../LatestDashboard/LatestFixedDash';
import Select from 'react-select';
import LatestFixedDashes from '../LatestDashboard/LatestFixedDashes';


export default function AreasAdmin() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [provinceLoading, setProvinceLoading] = useState(false);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
const [selectedZonee, setSelectedZonee] = useState(null);
const [selectedZone, setSelectedZone] = useState(null);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [eyeClicked1, setEyeClicked1] = useState(false);
  const [trashClicked1, setTrashClicked1] = useState(false);
  const [eyeClicked2, setEyeClicked2] = useState(false);
  const [trashClicked2, setTrashClicked2] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage1, setEntriesPerPage1] = useState(100);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [entriesPerPage2, setEntriesPerPage2] = useState(100);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [description, setDescription] = useState('')
  const [description1, setDescription1] = useState('')
  const [accountantName, setAccountantName] = useState("");
  const [accountantName1, setAccountantName1] = useState("");
  const [accountantPhone, setAccountantPhone] = useState("");
  const [accountantPhone1, setAccountantPhone1] = useState("");
  const [accountantEmail, setAccountantEmail] = useState("");
  const [accountantEmail1, setAccountantEmail1] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedZone1, setSelectedZone1] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [continent, setContinent] = useState('');
  const [admin, setAdmin] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [totalEntries, setTotalEntries] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
const [totalPages, setTotalPages] = useState(1);
const [areas, setAreas] = useState([]);
const [zones, setZones] = useState([]);
const [processLoading, setProcessLoading] = useState(false);
const [provLoading, setProvLoading] = useState(false);
const [provinceID, setProvinceID] = useState(null);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const value4 = await AsyncStorage.getItem('provinceID');
     

      if (value !== null) {
        setBearer(value);
      }
      if (value4 !== null) {
        setProvinceID(value4);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  const readData1 = async () => {
    try {
      const value4 = await AsyncStorage.getItem('provinceID');
      const value5 = await AsyncStorage.getItem('regionID');
      const value6 = await AsyncStorage.getItem('continentID');

     
      if (value4 !== null) {
        setProvince(value4);
      }
      if (value5 !== null) {
        setRegion(value5);
      }
      if (value6 !== null) {
        setContinent(value6);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (bearer)
    readData1();
  }, [bearer]);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TOKEN}`
  };


  const fetchAreas = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL1}/areas/paginate?page=${currentPage}`, {
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json'
      }, 
        
    });

    const results = response.data?.data?.areas?.data;
    setTableData(results);
    const resultx = response.data?.data?.areas?.total;
  setTotalEntries(resultx);
  const total = response.data?.data?.areas?.last_page || 1;
  setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        // navigate('/login');
      }else{
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
      setTableData([]);
    }
    console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleZoneChange = (selectedOption) => {
    setSelectedZonee(selectedOption.value)

  }

  const fetchZones = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL1}/zones/select`, {
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json'
      }, 
        
    });

    const res = response.data?.data?.zones;
    const opts = res.map((item) => ({
      label: item.description,
      value: item.id,
    }));
    setZones(opts);
    
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        // navigate('/login');
      }else{
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
      setTableData([]);
    }
    console.log(error);
    } finally {
      setIsLoading(false);
    }
  };




  useEffect(() => {
    if (TOKEN) {
      fetchZones();
    
    }
  }, [TOKEN]);

  useEffect(() => {
    if (TOKEN) {
      fetchAreas();
    
    }
  }, [TOKEN, currentPage]);

  const createZones = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL1}/areas/create-by-type`,  
        {
          description: description,  
          continent_id: continent,
          region_id: region,
          province_id: province,
          zone_id: selectedZone.value
        },
        {
          headers: {
            'Authorization': `Bearer ${TOKEN}`, 
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Fetch updated zones after successful creation
      fetchAreas();
      handleClose();
      
      // Show success toast with response message
      toast.success(response.data.message);
      console.log(response);
  
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      
      // Check and handle specific error message
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

 


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {
    const foundArea = tableData.find(item => item.id === id);
    setSelectedArea(foundArea);
    setDescription1(foundArea?.description || '');
    setSelectedZone1(foundArea?.zone?.id || '');
    setShow1(true);
    setEyeClicked(true);
  };

 

 
  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this area. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`${BASE_URL1}/areas/delete_area?id=${id}`,  {
          headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json'
        }, 
     });
      fetchAreas();
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
                // toast.error(errorMessage);
                Swal.fire({
                  icon: 'error',
                  title: 'Failed',
                  text: errorMessage,
                });
            }
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The deletion was cancelled.',
    });
  }
  };

 

  


  const editContinent = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL1}/areas/update_area`,  
        {
          description: description1,
          // zone_id: selectedZone.value || '',
          id: selectedArea.id  
       
        },
        {
          headers: {
            'Authorization': `Bearer ${TOKEN}`, 
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Fetch updated zones after successful creation
      fetchAreas();
      handleClose1();
      
      // Show success toast with response message
      toast.success(response.data.message);
      console.log(response);
  
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      
      // Check and handle specific error message
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };
 
  
  const handleZone = (selectedOption) => {
    setSelectedZone(selectedOption.value);
  };
 
  const handleProcess = async () => {
    setProcessLoading(true);
  
    try {
      const response = await axios.get(`${BASE_URL1}/zones/getareaszone`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${TOKEN}`
        },
        params: {
          zone_id: selectedZone || '',
       
        }
      });
  
      const results = response.data?.data;
  setTableData(results);
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
  
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
    } finally {
      setProcessLoading(false);
    }
  };

  return (
    <div>
      <LatestFixedDashes />

      <div className={classes.finishedbodyCont}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center', marginTop: '2rem'}}>
          <div >
          <h3>Manage Areas</h3>
          <p>Add, View, Edit, and Delete Areas</p>
          </div>
          <div style={{zIndex:1}}>
          <div className={classes.formIntBtn}>
               <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Area</Button>
             </div>
          </div>
        </div>
     


        <div className={classes.mainform}>

        <div className={classes.loandgrantcards}>
        <div className="col-md-4">
          <div className="form-group row">
            {/* <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Zone:</label> */}
            <div className="col-sm-9">
              <Select
              placeholder='Select Zone'
                onChange={(selectedOption) => handleZone(selectedOption)}
                options={zones}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
            
          <div className="col-md-4" style={{marginLeft: -35}}>
        <Button
                                  style={{ borderRadius: 0, marginLeft: "20px" }} variant='success' onClick={handleProcess}>
                                  
                                  
                                
                                  {processLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      {/* <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span> */}
                                    </>
                                  ) : (
                                    "Process"
                                  )}
                                </Button>
      
        </div>
          </div>
        </div>
       
        
           <div className={classes.loandethead}>
             <div className={classes.formLabel}>
             </div>
             {/* <div className={classes.formIntBtn}>
               <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Continent</Button>
             </div> */}
           </div>
           <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Area</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Zone</Form.Label>
                            <Select
                                          onChange={setSelectedZone}
                                          options={tableData4}
                                          value={selectedZone}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              maxHeight: '300px',
                                              maxWidth: '100%',
                                              overflowY: 'auto',
                                            }),
                                          }}
                                        />
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Description"
                              // autoFocus
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                            
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createZones}>
                    {loading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Creating Area, Please wait...</span>
    </>
  ) : (
                "Create Area"
                      )}
                    </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={show1} onHide={handleClose1} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Area</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Zone</Form.Label>
                          <Select
                                          onChange={setSelectedZone1}
                                          options={tableData4}
                                          value={selectedZone1}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              maxHeight: '300px',
                                              maxWidth: '100%',
                                              overflowY: 'auto',
                                            }),
                                          }}
                                        />
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Description"
                              // autoFocus
                              value={description1}
                              onChange={(e) => setDescription1(e.target.value)}
                            />
                            
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={editContinent} >
                                  {loading ? ( <> <Spinner  size='sm' />
                                    <span style={{ marginLeft: '5px' }}>
                                      Editing Area, Please wait...
                                    </span>
                                    </>) : ("Save Changes")}
                                </Button>
                               

                               
                            </Modal.Footer>
                          </Modal>



           <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
             <div className={classes.greenbtn} style={{ display: 'flex', gap: '20px' }}>
               <div className={classes.actionsBtns}>
                 <Button variant='success'>Copy</Button>
                 <Button variant='success'>Excel</Button>
                 <Button variant='success'>PDF</Button>
                 <Button variant='success'>Column visibility</Button>
               </div>
               <div>
                 <label className="d-flex justify-content-start align-items-center">
                   Show
                   <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    value={entriesPerPage}
                       onChange={(e) => {
                       setEntriesPerPage(parseInt(e.target.value));
                       setCurrentPage(1);
                       }}
                   >
                     <option value={10}>10</option>
                     <option value={25}>25</option>
                     <option value={50}>50</option>
                     <option value={100}>100</option>
                   </select>
                   entries
                 </label>
               </div>
             </div>
             <div className="text-right modal-effect ">
               <div id="DataTables_Table_0_filter" className="dataTables_filter">
                 <div className="d-flex justify-content-start align-items-center">
                   <div className="mr-2">Search:</div>
                   <input
   type="search"
   value={searchTerm}
   className="form-control form-control-sm"
   placeholder=""
   aria-controls="DataTables_Table_0"
   onChange={(e) => setSearchTerm(e.target.value)}
/>
                   {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                 </div>
                 

               </div>
             </div>
           </div>
           <div className={classes.mainTable}>
           {(isLoading || processLoading) ? (
                              <p>Fetching Areas...</p>
                            ) : (
                             <div className="table-responsive">
                               <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                               <thead style={{ whiteSpace: 'nowrap' }}>
                               <tr>
                                      <th>S/N</th>
                                      <th>Areas</th>
                                      <th>Created At</th>
                                      <th>Updated At</th>
                                      <th>Actions</th>
                                    </tr>
                         </thead>
                         <tbody style={{ whiteSpace: 'nowrap',  }}>
                          
                         {tableData.map((item, index) => (
                                      <tr key={index}>
                                        <td style={{paddingLeft: 10}}>{index + 1}</td>
                                        <td style={{paddingLeft: 10}}>{item.description}</td>
                                        <td style={{paddingLeft: 10}}>{formatDate(item.created_at)}</td>
                                        <td style={{paddingLeft: 10}}>{formatDate(item.updated_at)}</td>
                                        <td>
                                          <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                          <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                          <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                          <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                         </tbody>
                               </table>
                             </div>
                           )}
           </div>

           <div className={classes.endded}>
             <p>
               Showing {startIndexx} to {endIndexx} of {totalEntries} entries
             </p>
             <div style={{ display: 'flex' }}>
               <button
                 style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                 onClick={handlePrevPage}
                 disabled={currentPage === 1}
               >
                 Previous
               </button>
               {[...Array(totalPages)].map((_, page) => {
                 // Show only 5 pages or less if available
                 if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                   return (
                     <button
                       key={page + 1}
                       style={{
                         marginLeft: '0.4rem',
                         marginRight: '0.4rem',
                         fontSize: '14px',
                         fontFamily: 'nunito',
                         fontWeight: 400,
                         color: page + 1 === currentPage ? '#ffffff' : '#000',
                         backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                         height: '2.5rem',
                         borderRadius: '89px',
                         padding: '0.5rem',
                         border: 'none',
                         width: '40px',
                         cursor: "pointer"
                       }}
                       onClick={() => setCurrentPage(page + 1)}
                     >
                       {page + 1}
                     </button>
                   );
                 }
                 return null;
               })}
               <button
                 style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                 onClick={handleNextPage}
                 disabled={currentPage === totalPages}
               >
                 Next
               </button>
             </div>
           </div>

         </div>
        </div>

      </div>

   


    

    </div >
)
}