import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './ScheduleReceivables.module.css'
import MainDashboard from '../../Main Dashboard/MainDashoard';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import PaymentDash from '../../Payment Dashboard/PaymentDash';
import Select from 'react-select';
import PayablesDash from '../../Payables Dashboard/PayablesDash';
import LatestDash from '../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestBudgetDash from '../../LatestDashboard/LatestBudgetDash';



function PayableScheduleBudget() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [receivablesLoading, setReceivablesLoading] = useState(false);
  const [province, setProvince] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchedResult, setSearchedResult] = useState([]);
  


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchSchedule = async () => {
    setReceivablesLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/schedule-of-payable-by-date`, {
        params: {
          start_date: selectedDate,
          end_date: selectedEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
     
      const ress = response.data?.data?.payables || [];
      setAccounts(ress);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setReceivablesLoading(false);
    }
  };

  const fetchSchedule1 = async () => {
    setReceivablesLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/schedule-of-payable-continent`, {
        params: {
          continent_id: selectedContinent, 
          start_date: selectedDate,
          end_date: selectedEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
     
      const ress = response.data?.data?.payables || [];
      setAccounts(ress);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setReceivablesLoading(false);
    }
  };
  const fetchSchedule2 = async () => {
    setReceivablesLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/schedule-of-payable-region`, {
        params: {
          continent_id: selectedContinent, 
          region_id: selectedRegion,
          start_date: selectedDate,
          end_date: selectedEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
     
      const ress = response.data?.data?.payables || [];
      setAccounts(ress);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setReceivablesLoading(false);
    }
  };
  const fetchSchedule3 = async () => {
    setReceivablesLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/schedule-of-payable-province`, {
        params: {
          continent_id: selectedContinent, 
          region_id: selectedRegion,
         province_id: selectedProvince,
          start_date: selectedDate,
          end_date: selectedEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
     
      const ress = response.data?.data?.payables || [];
      setAccounts(ress);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setReceivablesLoading(false);
    }
  };

  const handleProcess = () => {
    if (selectedContinent && selectedRegion && selectedProvince) {
      fetchSchedule3();
    } else if (selectedContinent && selectedRegion) {
      fetchSchedule2();
    } else if (selectedContinent) {
      fetchSchedule1();
    } else if (selectedDate && selectedEndDate) {
      fetchSchedule();
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData(opt1);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();
    }
  }, [bearer]);



  const filteredData = inputss.filter(item => item.gl_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);


  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);





  const fetchGeneralLedger = async () => {
    setGeneralLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setGeneralLoading(false);
    }
  };













  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };



  const fetchSubContinents = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });

      // console.log(response);
      const subCont = response.data?.data;
      const options1 = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData3(options1);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };


  const fetchSubRegion = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

      // console.log(response);
      const subCont1 = response.data?.data;
      const options2 = subCont1.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData4(options2);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchProvince = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/provinces`, { headers });

      // console.log(response);
      const subCont15 = response.data?.data.provinces;
      const options25 = subCont15.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData45(options25);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
        let res;
        if (searchTerm.trim() === "") {
            res = tableData;
        } else {
            const response = await axios.get(`${BASE_URL}/customer/search`, {
                params: { variable: searchTerm },
                headers
            });
            res = response.data?.data;
        }
        setSearchedResult(res);
        console.log(res, "searched");
       
    } catch (error) {
        if (error.response && error.response.status === 401) {
            navigate('/login');
        } else {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
          setSearchedResult([]);
        }
    } finally {
        setSearchLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);


  useEffect(() => {
    if (bearer) {
      fetchContinents();
    }
  }, [bearer]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);
 

  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion('');
    setSelectedProvince('');
  };


  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption.value)
  }

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption.value)
    if (admin === 'Continental Accountant') {
      setProvinceOptions(tableData4);
    }
  }


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const totalIncome = accounts.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  const totalExpenses = inputss.reduce((acc, item) => acc + parseFloat(item.amount), 0);


  return (
    <div>
     <LatestBudgetDash />

     <div className={classes.finishedbodyCont}>



{/* <h3 className={classes.productText}>Schedule Of Receivables</h3> */}
{/* <h3 style={{fontSize: 30, fontWeight: 700, }}>Schedule Of Receivables</h3> */}
<h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Schedule of Payables</h3>
<p style={{ margin: " 0 10px 20px 15px" }}>Kindly select date interval to view schedule of payables</p>

<div className={classes.mainform}>
  <div className={`row ${classes.awrow}`} style={{ marginBottom: 20, marginLeft: 20 }}>
    <div className={`row ${classes.awrow}`} style={{ marginTop: 10 }}>

      <div className="col-md-4" style={{}}>
        <div className="form-group row">
          <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Continent:</label>
          <div className="col-sm-8">
            <Select
              onChange={(selectedOption) => handleContinentChange(selectedOption)}
              options={selectOptions}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '300px',
                  maxWidth: '300px',
                  overflowY: 'auto',
                }),
              }}
            />
          </div>
        </div>
      </div>


      <div className="col-md-4" style={{ marginLeft: '-125px' }}>
        <div className="form-group row">
          <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
          <div className={`col-sm-8  ${classes.lblrow}`}>
            <Select
              onChange={(selectedOption) => handleRegionChange(selectedOption)}
              options={tableData3}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '300px',
                  maxWidth: '300px',
                  overflowY: 'auto',
                }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4" style={{ marginLeft: -125 }}>
        <div className="form-group row">
          <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Province:</label>
          <div className={`col-sm-8  ${classes.lblrow}`}>
            <Select
              onChange={(selectedOption) => handleProvinceChange(selectedOption)}
              options={tableData4}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '300px',
                  maxWidth: '300px',
                  overflowY: 'auto',
                }),
              }}
            />
          </div>
        </div>
      </div>
   
    

    </div>
    <div className={`row ${classes.awrow}`} style={{ marginTop: 10 }}>

      <div className="col-md-4" style={{}}>
        <div className="form-group row">
          <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Start Date:</label>
          <div className="col-sm-8">
          <Form.Control
                  className={classes.formControl}
                  type="date"
                  onChange={handleDateChange}
                  value={selectedDate}

                />
          </div>
        </div>
      </div>


      <div className="col-md-4" style={{ marginLeft: '-125px' }}>
        <div className="form-group row">
          <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>End Date:</label>
          <div className={`col-sm-8  ${classes.lblrow}`}>
          <Form.Control
                  className={classes.formControl}
                  type="date"
                  onChange={handleDateChange1}
                  value={selectedEndDate}

                />
          </div>
        </div>
        <Button
          disabled={!selectedDate || !selectedEndDate}
          onClick={handleProcess}
                style={{ borderRadius: 0, marginLeft: -230, marginTop: 20}} variant='success' >
                {receivablesLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                  </>
                ) : (
                  "Process"
                )}
              </Button>
      </div>
     
     
    

    </div>
    
  </div>


  <div className={classes.loandgrantcards}>

    <div className={classes.formLabel}>
    </div>


   
    <div className={classes.buttonsGroup}>


      <div className={classes.bttn}>
      <div style={{ marginLeft: 10, }}>
                        {accounts.length > 0 && (
                          <button onClick={() => navigate('/payable/schedule_of_payable_printactivityp', { state: { documents: accounts, selectedDate, selectedEndDate} })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#198754", color: "#fff", marginBottom: 10,  }}>Print Report</button>
                        )}
                      </div>
      </div>
    </div>
   

    <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ marginTop: -70 }}>

      <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
        <div className={classes.actionsBtns}>
          <Button variant='success'>Copy</Button>
          <Button variant='success'>Excel</Button>
          <Button variant='success'>PDF</Button>
          <Button variant='success'>Column visibility</Button>
        </div>
        <div className={classes.show}>
          <label className="d-flex justify-content-start align-items-center">
            Show
            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
            //  value={entriesPerPage}
            //     onChange={(e) => {
            //     setEntriesPerPage(parseInt(e.target.value));
            //     setCurrentPage(1);
            //     }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            entries
          </label>
        </div>
      </div>
      <div className="text-right modal-effect ">
        <div id="DataTables_Table_0_filter" className="dataTables_filter">
          <div className="d-flex justify-content-start align-items-center">
            <div className="mr-2">Search:</div>
            <input
              type="search"
              // value={searchTerm}
              className="form-control form-control-sm"
              placeholder=""
              aria-controls="DataTables_Table_0"
            // onChange={(e) => {
            // setSearchTerm(e.target.value);


            // }}
            />
          </div>

        </div>
      </div>
    </div>
    <div className={classes.mainTable}>
      {receivablesLoading ? (
        <p>Fetching accounts...</p>
      ) : (
        <div className="table-responsive">
          <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

          <thead>
    <tr>
      <th style={{ textAlign: 'left', width: '30px' }}>Transaction Date</th>
      <th style={{ textAlign: 'left', width: '10px' }}>Description</th>
      <th style={{ textAlign: 'left', width: '2px' }}>Particulars</th>
      <th style={{ textAlign: 'left', width: '2px' }}>Invoice Number</th>
      <th style={{ textAlign: 'left', width: '2px' }}>Age</th>
      <th style={{ textAlign: 'left', width: '2px', textAlign: 'right' }}>Amount</th>
    </tr>
  </thead>
  <tbody>
                      {accounts.map((item, index) => {
const totalAmount = item.transactions.reduce((sum, trans) => sum + parseFloat(trans.amount || 0), 0);
                        const closingBalance = totalAmount - item.opening_balance;
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td colSpan="2" style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{item?.gl_name}</td>
                              <td colSpan="3" style={{ fontWeight: "bold", textAlign: "right" }}>Beginning Balance</td>
                              <td style={{ fontWeight: "bold", textAlign: "right" }}>{parseFloat(item.opening_balance).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}</td>
                              {/* <td style={{ fontWeight: "bold", textAlign: "right" }}>{item.opening_balance}</td> */}

                            </tr>
                            {item.payables && item.payables.map((payables, payablesIndex) => (
                              <tr key={payablesIndex}>
                                <td>{payables.transaction_date}</td>
                                <td>{payables.description}</td>
                                <td>{payables?.to?.name}</td>
                                <td>{payables.invoice_number}</td>
                                <td>{payables.age_report}</td>
                                <td style={{ textAlign: 'right' }}>{parseFloat(payables?.transactions?.amount).toLocaleString('en-US', {
                                  minimumIntegerDigits: 1,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}</td>
                                {/* <td style={{textAlign:'right'}}>{receivable.amount}</td> */}
                              </tr>
                            ))}
                            <tr>
                              <td colSpan="5  " style={{ fontWeight: "bold", textAlign: "right" }}>Total</td>
                              <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                {totalAmount.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </td>
                              {/* <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                  {totalAmount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td> */}
                            </tr>
                            <tr>
                              <td colSpan="5" style={{ fontWeight: "bold", textAlign: "right" }}>Closing Balance</td>
                              <td style={{ fontWeight: "bold", textAlign: "right" }}>{totalAmount.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}</td>
                            </tr>
                            <tr>

                            </tr>
                            <div style={{ marginTop: 30 }} />
                          </React.Fragment>
                        );
                      })}
                    </tbody>
          </table>
        </div>
      )}
    </div>

    <div className={classes.endded}>
      <p>
        Showing {startIndexx} to {endIndexx} of {totalEntries} entries
      </p>
      <div style={{ display: 'flex' }}>
        <button
          style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {[...Array(totalPages)].map((_, page) => {
          // Show only 5 pages or less if available
          if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
            return (
              <button
                key={page + 1}
                style={{
                  marginLeft: '0.4rem',
                  marginRight: '0.4rem',
                  fontSize: '14px',
                  fontFamily: 'nunito',
                  fontWeight: 400,
                  color: page + 1 === currentPage ? '#ffffff' : '#000',
                  backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                  height: '2.5rem',
                  borderRadius: '89px',
                  padding: '0.5rem',
                  border: 'none',
                  width: '40px',
                  cursor: "pointer"
                }}
                onClick={() => setCurrentPage(page + 1)}
              >
                {page + 1}
              </button>
            );
          }
          return null;
        })}
        <button
          style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>







  </div>
</div>

</div>






    </div >
  )
}
export default PayableScheduleBudget;