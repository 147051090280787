import React, { useState, useEffect } from 'react';
import classes from './TrialBalReport.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function TrialBalReport() {

    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [totalClosing, setTotalClosing] = useState('');
    const [totalOpening, setTotalOpening] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `${day}/${month}/${year} `;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const admins = await AsyncStorage.getItem('admin');

            if (value !== null) {
                setBearer(value);
                // setAuthenticated(true);
            }
            if (value1 !== null) {
                setCompany(value1);

            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (admins !== null) {
                setUserType(admins);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();

    }, []);

    const { accounts, inputss } = location.state || {};
    const startDate = new Date(accounts.start_date);
    const endDate = new Date(accounts.end_date);
    console.log(inputss, accounts);
    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
            ? 'th'
            : suffixes[v % 10] || 'th';
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };


    useEffect(() => {
        if (inputss) {
            const creditTotal = inputss
                .filter((item) => !isNaN(item.nCredit) && item.nCredit !== "")
                .reduce((total, item) => total + parseFloat(item.nCredit), 0);

            const debitTotal = inputss
                .filter((item) => !isNaN(item.nDebit) && item.nDebit !== "")
                .reduce((total, item) => total + parseFloat(item.nDebit), 0);

            const openingTotal = inputss
                .filter((item) => !isNaN(item.opening_balance) && item.opening_balance !== "")
                .reduce((total, item) => total + parseFloat(item.opening_balance), 0);

            const closingTotal = inputss
                .filter((item) => !isNaN(item.closing_balance) && item.closing_balance !== "")
                .reduce((total, item) => total + parseFloat(item.closing_balance), 0);

            // Format the numbers with commas and two decimal places
            const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            const formattedOpeningTotal = openingTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            const formattedClosingTotal = closingTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            setTotalDebit(formattedDebitTotal);
            setTotalCredit(formattedCreditTotal);
            setTotalOpening(formattedOpeningTotal);
            setTotalClosing(formattedClosingTotal);
        }
    }, [inputss]);

    const totalOpeningDebit = inputss
  .filter(item => item.type === "DB")
  .reduce((sum, item) => sum + parseFloat(item.opening_balance || 0), 0);

  const formattedOpeningDebit = totalOpeningDebit.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

// Calculate total credit for opening balance (sum of opening_balance for items with type "CR")
const totalOpeningCredit = inputss
  .filter(item => item.type === "CR")
  .reduce((sum, item) => sum + parseFloat(item.opening_balance || 0), 0);

  const formattedOpeningCredit = totalOpeningCredit.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});


    const totalClosingDebit = inputss
  .filter(item => item.type === "DB")
  .reduce((sum, item) => sum + parseFloat(item.closing_balance || 0), 0);

  const formattedClosingDebit = totalClosingDebit.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

// Calculate total credit for opening balance (sum of closing_balance for items with type "CR")
const totalClosingCredit = inputss
  .filter(item => item.type === "CR")
  .reduce((sum, item) => sum + parseFloat(item.closing_balance || 0), 0);

  const formattedClosingCredit = totalClosingCredit.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const openingDifferences = totalOpeningDebit - totalOpeningCredit;
const closingDifferences = totalClosingDebit - totalClosingCredit;
const debitCreditDifference = Number(totalDebit.replace(/,/g, '')) - Number(totalCredit.replace(/,/g, ''));

console.log( totalClosingCredit);

    return (
        <div className={classes.a4}>
        <div className={classes.report}>
            <h2 className={classes.headerTop}>THE REDEEMED CHRISTIAN CHURCH OF GOD</h2>
            <h4 className={classes.headerTwoo}>{accounts?.name ? accounts?.name : province}</h4>
            {/* <h4 className={classes.headerTwoo}>{province === 'undefined' ? "Super Admin"  : province}</h4> */}
            <h4 className={classes.headerTwo}>TRIALBALANCE REPORT FROM {formatDate(startDate)}  TO {formatDate(endDate)}</h4>

            <table className={classes.tableDivF}>
                <thead>
                    <tr className={classes.tableDivTr} style={{backgroundColor: "#c4c4c4"}}>
                        <th style={{  fontSize: '14px', textAlign: 'left', padding: '10px' }}>ACCOUNT</th>
                        <th style={{fontSize: '14px', textAlign: 'left', padding: '10px' }}>TYPE</th>
                        <th style={{ fontSize: '14px', textAlign: 'right', width: '250px', padding: '10px' }}>BEGINNING BALANCE</th>
                        <th style={{ fontSize: '14px', textAlign: 'right', width: '200px', padding: '10px' }}>DEBIT</th>
                        <th style={{ fontSize: '14px', textAlign: 'right', width: '200px', padding: '10px' }}>CREDIT</th>
                        <th style={{fontSize: '14px', textAlign: 'right', width: '200px', padding: '10px' }}>ENDING BALANCE</th>
                    </tr>
                </thead>
                <tbody className={classes.tableBodyFirst}>
                    {inputss && inputss.map((item, index) => (
                        <tr key={index} className={classes.tableBodyTR}>
                            <td style={{ whiteSpace: "wrap", width: 400, fontSize: 14 }}>{item.name}</td>
                            <td style={{ whiteSpace: "nowrap", fontSize: 14 }}>{item.type}</td>
                            {/* <td></td>
        <td></td>
        <td></td> */}

                            <td style={{ textAlign: "right", fontSize: 14}}>{item.opening_balance === "" ? '0.00' :
                                (isNaN(item.opening_balance)
                                    ? '0.00'
                                    : parseFloat(item.opening_balance).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }))}</td>

                            <td style={{ textAlign: "right",fontSize: 14, width: 200 }}>{item.nDebit === "" ? '0.00' :
                                (isNaN(item.nDebit)
                                    ? '0.00'
                                    : parseFloat(item.nDebit).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }))}</td>
                            <td style={{ textAlign: "right",fontSize: 14, width: 200 }}>{item.nCredit === "" ? '0.00' :
                                (isNaN(item.nCredit)
                                    ? '0.00'
                                    : parseFloat(item.nCredit).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }))}</td>
                            <td style={{ textAlign: "right",fontSize: 14 }}>{item.closing_balance === "" ? '0.00' :
                                (isNaN(item.closing_balance)
                                    ? '0.00'
                                    : parseFloat(item.closing_balance).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }))}</td>

                        </tr>
                    ))}

                </tbody>
                <div style={{marginTop: 20}}/>
                <thead className={classes.bottomHeader}>
                    <tr >
                        <td style={{  fontSize: '16px', textAlign: 'left', padding: '2px', fontWeight: 700 }}> TOTAL DEBIT</td>
                        <td style={{  border: '0' }}></td>
                        <td style={{   fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px', fontWeight: 700 }}>{formattedOpeningDebit}</td>
                        <td style={{  fontSize: '16px', textAlign: 'right', width: '120px', padding: '2px', fontWeight: 700 }}>{totalDebit}</td>
                        <td style={{  fontSize: '16px', textAlign: 'right', width: '120px', padding: '2px', fontWeight: 700 }}>{totalCredit}</td>
                        <td style={{  fontSize: '16px', textAlign: 'right', width: '90px', padding: '2px', fontWeight: 700 }}>{formattedClosingDebit}</td>
                    </tr>
                </thead>
                <div style={{marginTop: 5}}/>
                <thead className={classes.bottomHeader}>
                    <tr >
                        <td style={{  fontSize: '16px', textAlign: 'left', padding: '2px', fontWeight: 700 }}> TOTAL CREDIT</td>
                        <td style={{  border: '0' }}></td>
                        <td style={{   fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px', fontWeight: 700 }}>{formattedOpeningCredit}</td>
                        <td style={{  fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px', fontWeight: 700 }}>{totalCredit}</td>
                        <td style={{   fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px', fontWeight: 700 }}>{totalDebit}</td>
                        <td style={{  fontSize: '16px', textAlign: 'right', width: '90px', padding: '2px', fontWeight: 700 }}>{formattedClosingCredit}</td>
                    </tr>
                </thead>
                <div style={{marginTop: 5}}/>
                <thead className={classes.bottomHeader}>
                    <tr >
                        <td style={{  fontSize: '16px', textAlign: 'left', padding: '2px', fontWeight: 700 }}> DIFFERENCES</td>
                        <td style={{  border: '0' }}></td>
                        <td style={{   fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px', fontWeight: 700 }}>{openingDifferences.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})}</td>
                        <td style={{  fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px', fontWeight: 700 }}>{debitCreditDifference.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})}</td>
                        <td style={{   fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px', fontWeight: 700 }}>{debitCreditDifference.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})}</td>
                        <td style={{  fontSize: '16px', textAlign: 'right', width: '90px', padding: '2px', fontWeight: 700 }}>{closingDifferences.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})}</td>
                    </tr>
                </thead>
            </table>

        </div>
        </div>
    )
}
