import React, { useState, useEffect } from 'react';
import classes from './PrintBalances.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintNewIncomeConsolidated() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');
    const [overallTotal, setOverallTotal] = useState(0);


    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `Date Printed: ${day}/${month}/${year} `;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('companyName');
          const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
           const admins = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setCompany(value1);
         
          }
          if (continentName !== null) {
            setContinent(continentName);
        }
        if (regionName !== null) {
            setRegion(regionName);
        }
        if (provinceName !== null) {
            setProvince(provinceName);
        }
        if (admins !== null) {
          setUserType(admins);
      }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };

      useEffect(() => {
        readData();

    }, []);

    const { accounts, inputss, selectedDate, selectedEndDate, ressult } = location.state || {};
    console.log(accounts, inputss)
    // const startDate = new Date(inputss.start_date);
    // const endDate = new Date(inputss.end_date);
    useEffect(() => {
        if (accounts) {
            const firstTotal = accounts?.postings?.reduce((sum, category) => sum + parseFloat(category.total || 0), 0);
            const secondTotal = inputss?.postings?.reduce((sum, category) => sum + parseFloat(category.total || 0), 0);

            setTotalDebit(firstTotal);
            setTotalCredit(secondTotal);
            setOverallTotal(firstTotal - secondTotal); // Adjust if needed for your calculation logic
            console.log( firstTotal, secondTotal);
        }
    }, [accounts]);

    console.log( accounts, inputss, selectedDate, selectedEndDate, ressult);
const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Get the day with the appropriate suffix
    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'TH'; // Handles 11th to 19th
      switch (day % 10) {
        case 1: return 'ST';
        case 2: return 'ND';
        case 3: return 'RD';
        default: return 'TH';
      }
    };
  
    const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
    const year = date.getFullYear();
  
    return `${day}${daySuffix(day)} ${month.toUpperCase()} ${year}`;
  };

//   const calculateTotals = (data) => {
//     return data.reduce((totals, item) => {
//         totals.current += parseFloat(item.current) || 0;
//         totals.opening_balance += parseFloat(item.opening_balance) || 0;
//         totals.total += parseFloat(item.total) || 0;
//         return totals;
//     }, { current: 0, opening_balance: 0, total: 0 });
// };

// const incomeTotals = calculateTotals(accounts);
// const expensesTotals = calculateTotals(inputss);

// const netIncome = incomeTotals.total - expensesTotals.total;
// const netIncomeCurrent = incomeTotals.current - expensesTotals.current;
// const netIncomeOpeningBalance = incomeTotals.opening_balance - expensesTotals.opening_balance;


    return (
        <div className={classes.report}>
        <h2 className={classes.headerTop}>THE REDEEMED CHRISTIAN CHURCH OF GOD</h2>
        <h4 className={classes.headerTwoo}>{province}</h4>
        <h4 className={classes.headerTwo}>INCOME STATEMENT FROM {formatDate(selectedDate)} TO {formatDate(selectedEndDate)}</h4>

        <table className={classes.tableDivF}>
        <thead>
        <tr>
            <th></th>
            <th className={classes.noteDiv}>Notes</th>
            <th style={{whiteSpace: 'wrap', textAlign: "center"}}>As at 31st Aug 2016</th>
            <th></th>
            <th style={{whiteSpace: 'wrap', textAlign: "center"}}>As at 31st Aug 2015</th>
            </tr>
            <tr>
            <th></th>
            <th></th>
            <th style={{ textAlign: "center" }}>N'000</th>
            <th></th>
            <th style={{ textAlign: "center" }}>N'000</th>
            </tr>
            </thead>

            <tbody>
                        {accounts?.postings?.length > 0 && (
                            <tr>
                                <td style={{textDecoration: "underline", fontWeight: 'bold', fontSize: 18}} colSpan={4}>REVENUE</td>
                            </tr>
                        )}
                        {accounts?.postings?.map((item, index) => (
                            <tr key={index}>
                                <td style={{whiteSpace: "nowrap", paddingLeft: 20}}>{item.name}</td>
                               <td></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5,}}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 20}}></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5  }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        ))}
                          {accounts?.postings?.length > 0 && (
                            <tr>
                                <td style={{  fontWeight: 'bold',  whiteSpace: "nowrap", }} >TOTAL REVENUE</td>
                                <td></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5, borderTop: "1px solid black", borderBottom: "1px solid black" }} className={classes.totalTh} >
                                    {parseFloat(accounts?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 50}}>&nbsp;&nbsp;&nbsp;</td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5, borderTop: "1px solid black", borderBottom: "1px solid black" }} className={classes.totalTh} >
                                    {parseFloat(accounts?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                               
                            </tr>
                            
                        )}
                        <div style={{marginTop: 30}}/>
                        {accounts?.postings?.length > 0 && inputss?.postings?.length > 0 && (
                            <tr>
                                <td style={{textDecoration: "underline", fontWeight: 'bold', fontSize: 18}} colSpan={4}>EXPENSES</td>
                                
                            </tr>
                        )}
                        {inputss?.postings?.map((item, index) => (
                            <tr key={index + accounts.length}>
                               <td style={{whiteSpace: "nowrap", paddingLeft: 20}}>{item.name}</td>
                               <td></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5 }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 50}}></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5  }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        ))}
                      {inputss?.postings?.length > 0 && (
                            <tr>
                                <td style={{  fontWeight: 'bold',  whiteSpace: "nowrap", }} >TOTAL EXPENSES</td>
                                <td></td>                                
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5, borderTop: "1px solid black", borderBottom: "1px solid black" }} className={classes.totalTh} >
                                    {parseFloat(inputss?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 50}}></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5, borderTop: "1px solid black", borderBottom: "1px solid black" }} className={classes.totalTh} >
                                    {parseFloat(inputss?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                               
                            </tr>
                            
                        )}

<tr>
                        <td colSpan={2} style={{ fontWeight: 'bold' }} className={classes.totalTh}>
                            SURPLUS BEFORE DEPRECIATION AND FINANCE
                        </td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(236, 233, 233)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                        <td></td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(204, 201, 201)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ fontWeight: 'bold' }} className={classes.totalTh}>
                            SURPLUS FOR THE PERIOD
                        </td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(236, 233, 233)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                        <td></td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(204, 201, 201)",
                            padding: 5,
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                    </tr>
                      
                       
                    </tbody>

        </table>
        <h4 className={classes.btmPrg}>These Financial Statements were approved by the Governing Council at a meeting held on __________________ and were signed on its behalf by:</h4>
        <div className={classes.sigNatures}>
            <span>National Overseer</span>
            <span>National Treasurer</span>
        </div>
        </div>
    )
}
