import React from 'react'
import classes from './SelectCompany.module.css'
// import SideImg from '../../assets/TeamMemberImg/TeamMemberSideImg.svg'
import TopImage from './TopImage.svg';
import Bullets from './Bullets.svg';
import Profilessss from './Profile.svg';
import Fullbullets from './Fullfeatures.svg';
import Backarrow from './Arrwbck.svg';
import { Button, ProgressBar } from 'react-bootstrap';


export default function SelectComp() {
  return (
    <div className={classes.maincont1234}>
        {/* <div className={classes.sideNavContainer}>
            <h4> Add your Team <br /> members seamlessly</h4>
            <p> Add as many team members as you want </p>
        </div> */}
        
        <div className={classes.formSection}>
            <div className={classes.textMain}>
                <p>Complete your account setup</p>
                <div className={classes.normdsfdfalp}>
                <img src={Backarrow} alt='icon' className={classes.Imageshowdfsf213} />
                <p>Back</p>
                </div>
            </div>
            <div className={classes.normdfalp}>

                <img src={Fullbullets} alt='icon' className={classes.Imageshowda213} />

                <div className={classes.formSection2345}>
                    {/* <img src={TopImage} alt='icon' className={classes.Imageshow213} /> */}
                    <h4>Add Team Members</h4>
                    <p>Add your Team members</p>
                    <div className={classes.formSectidafdon23fa45}>
                    <div className={classes.formSection23fa45}>
                        <img src={Profilessss} alt='icon' className={classes.Imageshowdfsf213} />
                        <div>
                            <h5>Maranatha Alyson</h5>
                            <p>maranathaaly@gmail.com show up here</p>
                        </div>
                    </div>
                    <div className={classes.formSection23fa45}>
                        <img src={Profilessss} alt='icon' className={classes.Imageshowdfsf213} />
                        <div>
                            <h5>Oyinlola Matthew</h5>
                            <p>oyinm@gmail.com show up here</p>
                        </div>
                    </div>
                    <div className={classes.formSection23fa45}>
                        <img src={Profilessss} alt='icon' className={classes.Imageshowdfsf213} />
                        <div>
                            <h5>Lara Lamide</h5>
                            <p>lara@gmail.com show up here</p>
                        </div>
                    </div>
                    </div>
                    <Button variant="success" className={classes.snupbtn} >
                        Add Team Members
                    </Button>

                    <Button variant="success" className={classes.snupbtndfa} >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    </div>

  )
}