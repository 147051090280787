import React, { useState, useEffect } from 'react';
import classes from './CreateSalesAcct.module.css';
import "../../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

import LatestDash from '../../LatestDashboard/LatestDash';
import CurrencyInput from 'react-currency-input-field';
import LatestEventDash from '../../LatestDashboard/LatestEventDash';

const initialState = () => {
  const unitPrice = '';
  const qty = '';
  return [{ items: '', unitPrice, qty, totalPrice: unitPrice * qty }];
};

export default function CreateAdvancedPayment() {
  const [user, setUser] = useState("");
  const [roleLoading, setRoleLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [debitGl, setDebitGl] = useState('');
  const [glMethod, setGlMethod] = useState([]);
  const [sICode, setSICode] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [debitCode, setDebitCode] = useState('');
  const [roless, setRoless] = useState([]);
  const [debitAmount, setDebitAmount] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
  const [formData, setFormData] = useState(initialState);
  const [outstanding, setOutstanding] = useState('');
  const [totalCharge, setTotalCharge] = useState("");
  const [itemList, setItemList] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [amount, setAmount] = useState('');
  const [tellerNumber, setTellerNumber] = useState("")
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("")
  const [paymentMode, setPaymentMode] = useState([]);
  const [amountToPay, setAmountToPay] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [newData, setNewData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  const handleSalesChange = (selectedOption) => {
    const selectedId = selectedOption.value;

    setSelectedInvoice(selectedOption.value);

    const intselectedId = parseInt(selectedId);
    const selectedInvoice = newData.find(item => item.id == intselectedId);
    setDescription(selectedInvoice?.description || '');
    setAmount(selectedInvoice?.amount || '');
    setOutstanding(selectedInvoice?.balance || '');

  };

  const formattedOutstanding = isNaN(parseFloat(outstanding)) ? '0.00' : parseFloat(outstanding).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAmount = isNaN(parseFloat(amount)) ? '0.00' : parseFloat(amount).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const handleValueChange = (value, name, values) => {
    setAmountToPay(value);

  };

  const handleAccountChange = (selectedOption) => {
    setSelectedDebitAccount(selectedOption.value);
  }


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const handleGlChange = (event) => {
    setDebitGl(event.target.value);
  }


  const handleCustomerChange = (event) => {
    const selectedCustomerId = event.target.value;
    setSelectedCustomer(selectedCustomerId);

    const selectedCus = customerList.find((item) => item.id === parseInt(selectedCustomerId));
    setAddress(selectedCus ? selectedCus.address : "");
  };



  useEffect(() => {
    const calculatedTotalAmount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.amount) || 0),
      0
    );
    // console.log(formData);
    setTotalAmount(calculatedTotalAmount.toFixed(2))
  }, [formData]);


  useEffect(() => {
    const calculatedDebit = parseFloat(debitAmount || 0)
    setTotalDebit(calculatedDebit.toFixed(2))
  }, [debitAmount]);



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };





  const fetchGlMethod = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setGlMethod(resultss);

      console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/customer/no-pagination`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const custome = response.data?.data;
      setCustomerList(custome);
      // setAddress(custome);

      // console.log(custome, "itss");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchGlMethod();
      fetchCustomers();
    }
  }, [bearer]);




  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);




  const goBack = () => {
    navigate(-1);
  }






 

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const itemss = response.data?.data;

      const options1 = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const options12 = accountName.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));



  const handleFormChange = (value, fieldName, rowIndex) => {
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      updatedFormData[rowIndex] = {
        ...updatedFormData[rowIndex],
        [fieldName]: value
      };
      updatedFormData[rowIndex].amount = parseFloat(updatedFormData[rowIndex].unitPrice) * parseFloat(updatedFormData[rowIndex].qty) || 0;
      return updatedFormData;
    });
  };

 


  const fetchAcctName = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setAccountName(resultss);

        // console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };



  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-pending-sales-invoice`, { headers });
      const roleList = response.data?.data;
      setNewData(roleList);
      // console.log(roleList);
      const roled = roleList.map((item) => ({
        label: `${item.invoice_number} - ${item.customer?.name}`,
        value: item.id,
      }));

      setRoless(roled);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData4 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/income/get-payment-method`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData5(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData5([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData3 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData2(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData2([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchAcctName();
      fetchItems();
      fetchData1();
      fetchData4();
      fetchData3();
    }
  }, [bearer]);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const createPayment = async () => {
    setPaymentLoading(true);

    try {
      let requestData = {
        id: selectedInvoice,
        bank: selectedDebitAccount,
        // credit: selectedCreditAccount,
        amount: amountToPay || '',
      };



      console.log(selectedInvoice, requestData);
      const response = await axios.post(
        `${BASE_URL}/pay-sales-invoice`,
        requestData,
        { headers }
      );

      toast.success(response.data.message);

      fetchData1();

      setSelectedInvoice('');
      setAmountToPay('');
      setSelectedDebitAccount('');
      setDescription('');
      setAmount('');
      setTotalAmount('');
      setTellerNumber('');
      setOutstanding('');
      setSelectedPaymentMode('');

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    } finally {
      setPaymentLoading(false);
    }
  };


  return (
    <div>
      <ToastContainer />
      <LatestEventDash />

      <div className={classes.finishedbodyCont}>


        {/* <h3 className={classes.productText}>Customers' Receipt</h3> */}
        <h3 style={{ fontSize: 30, fontWeight: 700,}}>Advanced Payment</h3>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            {/* <div className={classes.formLabel}> */}
            {/* </div> */}


            <div className="d-flex justify-content-center">
              <Form style={{ width: '100%' }}>
                <div className={`row ${classes.mirow}`}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Booking No:</Form.Label>
                        <Select
                          className={classes.formControl}
                          options={roless}
                          placeholder="Select Booking"
                          onChange={handleSalesChange}
                          menuPortalTarget={document.body}
                          styles={{
                            width: "100%",
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',

                            }),
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6" style={{ marginTop: -10 }}>
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className={`row ${classes.mirow}`} style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom} style={{ textAlign: "right" }}>Total Amount</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Total Amount"
                          disabled
                          value={formattedAmount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6" style={{ marginTop: -10 }}>
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Outstanding</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Outstanding"
                          disabled
                          value={formattedOutstanding}
                          onChange={(e) => setOutstanding(e.target.value)}
                        />

                      </div>
                    </Form.Group>
                  </div>
                </div>
        <h3 style={{ fontSize: 25, fontWeight: 700, marginTop: 30 }}>Payment Details</h3>

        <div className="d-flex justify-content-center">
              <Form style={{ marginTop: 10, width: '100%' }}>

                <div className={`row ${classes.mirow}`} style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom} style={{ textAlign: "right" }}>Amount</Form.Label>
                        <CurrencyInput
                                                                                  //   
                                                                                  name="amount-to-pay"
                                                                                  decimalsLimit={2}
                                                                                  className="form-control"
                                                                                  value={amountToPay} // Set the value to the balance state
                                                                                  onValueChange={handleValueChange}
                                                                                  style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                                                              />
                      </div>
                    </Form.Group>
                  </div>

                </div>
                <div className={`row ${classes.mirow}`} style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Payment Mode</Form.Label>
                        <Form.Select
                          value={selectedPaymentMode}
                          onChange={(e) => setSelectedPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>Select Payment Mode</option>
                          {tableData5.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6" style={{ marginTop: -10 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Bank</Form.Label>
                        <Select
                          className={classes.formControl}
                          options={options12}
                          placeholder="Select Bank"
                          onChange={handleAccountChange}
                          menuPortalTarget={document.body}
                          styles={{
                            width: "100%",
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',

                            }),
                          }}
                        />
                      </div>
                    </Form.Group>

                    
                  </div>
                </div>
                <div className={`row ${classes.mirow}`} style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                  <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Cheque/Teller</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Cheque/Teller Number"
                          value={tellerNumber}
                          onChange={(e) => setTellerNumber(e.target.value)}
                        />
                      </div>
                    </Form.Group>

                    <div className={classes.buttonsGroup}>


              <div className={classes.bttn}>
                {/* <Button variant="danger" onClick={goBack}>
          Go back
        </Button> */}
        <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
                <Button variant="success" onClick={createPayment}>
                  {paymentLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Make Payment"
                  )}
                </Button>
              </div>
            </div>
                  </div>
                 

                </div>

              </Form>
            </div>

              </Form>
            </div>
            


          </div>
        </div>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            {/* <div className={classes.formLabel}> */}
            {/* </div> */}


            
            









          </div>
        </div>
        {/* <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            {/* <div className={classes.formLabel}> */}
        {/* </div> */}


        {/* <div className="d-flex justify-content-center">
              <Form style={{ marginTop: 20, width: '70%' }}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Receipt ID:</Form.Label>
                        <Select
                          className={classes.formControl}
                          options={roless}
                          placeholder="Select Invoice"
                          onChange={handleSalesChange}
                          menuPortalTarget={document.body}
                          styles={{
                            width: "100%",
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',

                            }),
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom} style={{ textAlign: "right" }}>Total Amount</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Total Amount"
                          disabled
                          value={formattedAmount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Outstanding</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Outstanding"
                          disabled
                          value={formattedOutstanding}
                          onChange={(e) => setOutstanding(e.target.value)}
                        />

                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Payment Mode</Form.Label>
                        <Form.Select
                          value={selectedPaymentMode}
                          onChange={(e) => setSelectedPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>Select Payment Mode</option>
                          {tableData5.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Teller Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Teller Number"
                          value={tellerNumber}
                          onChange={(e) => setTellerNumber(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: -10 }}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Amount</Form.Label>
                        <CurrencyInput
                          name="amount-to-pay"
                          decimalsLimit={2}
                          className="form-control"
                          value={amountToPay}
                          onValueChange={handleValueChange}
                          style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Debit GL</Form.Label>
                        <Form.Select
                          value={selectedDebitAccount}
                          onChange={handleDebitChange}
                        >
                          <option value="" disabled>Select Debit Account</option>
                          {tableData2.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.gl_name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>

                </div>

              </Form>
            </div>
            <div className={classes.buttonsGroup}>


              <div className={classes.bttn}>
                {/* <Button variant="danger" onClick={goBack}>
          Go back
        </Button> */}
        {/* <Button variant="success" onClick={createPayment}>
                  {paymentLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Make Payment"
                  )}
                </Button>
              </div>
            </div>









          </div>
        </div> */}

      </div>






    </div >
  )
}