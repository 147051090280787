import React, { useState, useEffect } from 'react';
import classes from './PrintActivity.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useLocation } from 'react-router-dom';

export default function PrintBankReportConsolidated() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
        const formattedDateTime = `${day}/${month}/${year} `;
    
        setCurrentDateTime(formattedDateTime);
      }, []);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('companyName');
          const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
           const admins = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setCompany(value1);
         
          }
          if (continentName !== null) {
            setContinent(continentName);
        }
        if (regionName !== null) {
            setRegion(regionName);
        }
        if (provinceName !== null) {
            setProvince(provinceName);
        }
        if (admins !== null) {
          setUserType(admins);
      }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };

      useEffect(() => {
        readData();

    }, []);

    const { accounts, selectedDate, selectedEndDate, inputs } = location.state || {};
    console.log(accounts);
    // const startDate = new Date(accounts.start_date);
    // const endDate = new Date(accounts.end_date);

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
          ? 'th'
          : suffixes[v % 10] || 'th';
      };

      const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
    
        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
      };


      useEffect(() => {
        if (accounts) {
            const creditTotal = accounts
                .filter((item) => !isNaN(item.nCredit) && item.nCredit !== "")
                .reduce((total, item) => total + parseFloat(item.nCredit), 0);
    
            const debitTotal = accounts
                .filter((item) => !isNaN(item.nDebit) && item.nDebit !== "")
                .reduce((total, item) => total + parseFloat(item.nDebit), 0);
    
            // Format the numbers with commas and two decimal places
            const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
    
            const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
    
            setTotalDebit(formattedDebitTotal);
            setTotalCredit(formattedCreditTotal);
        }
    }, [accounts]);


    const groupedData = accounts.reduce((acc, item) => {
        if (!acc[item.gl_code]) {
          acc[item.gl_code] = [];
        }
        acc[item.gl_code].push(item);
        return acc;
      }, {});
    
      // Calculate totals for each group
      const calculateTotals = (group) => {
        return group.reduce(
          (totals, item) => {
            totals.debit += parseFloat(item.debit) || 0;
            totals.credit += parseFloat(item.credit) || 0;
            return totals;
          },
          { debit: 0, credit: 0 }
        );
      };
    
      
    
    console.log(selectedDate)  ;

  return (
    <div className={classes.generalbd}>
      <div className={classes.a4}>
        <div className={classes.header}>
        <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
                <p style={{ width: "100%", fontSize: 18, textTransform: "uppercase" }}>
                {/* {inputs?.name ? inputs?.name : province} */}
                  </p>
        {/* <p style={{ width: "100%", fontSize: 18 }}>{userType === "Continental Accountant" ? continent :
 userType === "Regional Accountant" ? region :
 userType === "Super Admin" ? "Super Admin" :
 userType === "Provincial Accountant" ? province : null}
</p> */}
          <h6 style={{fontWeight: 900}}>CONSOLIDATED BANK POSITION SUMMARY BETWEEN {selectedDate === "" ? `AS AT ${currentDateTime}` : `BETWEEN ${selectedDate}  AND ${selectedEndDate}`}</h6>
        </div>
        <p><span style={{fontWeight: 700, color: "#000"}}>DATE PRINTED:</span> {currentDateTime} </p>
        <table className={classes.table}>
  <thead>
    <tr>
      <th style={{ textAlign: 'left', width: '30px' }}>Bank Name</th>
      <th style={{ textAlign: 'right', width: '100px' }}>Amount</th>
    </tr>
  </thead>
  <tbody>
  {Object.keys(groupedData).map((gl_code) => {
    const group = groupedData[gl_code];
    const totals = calculateTotals(group);
    return (
      <React.Fragment key={gl_code}>
        {group.map((item, index) => (
          <tr key={index}>
            <td>{item.name || ''}</td>
            {/* <td>{item.account?.amount || ''}</td> */}
            <td style={{ textAlign: "right" }}>
              {(parseFloat(item.amount) || 0).toLocaleString('en-US', {
                minimumIntegerDigits: 1,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </td>
          </tr>
        ))}
        {/* <tr>
          <td colSpan="5" style={{ textAlign: "right", fontWeight: "bold" }}>
            Total
          </td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {(totals.debit || 0).toLocaleString('en-US', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {(totals.credit || 0).toLocaleString('en-US', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </td>
        </tr>
        <tr>
          <td colSpan="6" style={{textAlign: "right",  fontWeight: "bold"}}>
            Closing Balance
          </td>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>
            {(
              (totals.debit || 0) -
              (totals.credit || 0) -
              (group[0]?.opening_balance || 0)
            ).toLocaleString('en-US', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </td>
        </tr> */}
        {/* <div  style={{marginTop: 30}}/> */}
      </React.Fragment>
    );
  })}
</tbody>
</table>


      </div>
    </div>
  )
}