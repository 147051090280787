import React, { useState, useEffect } from 'react';
import classes from "../Reportts/RevenewReceipt2.module.css"
import { useLocation } from 'react-router-dom';
import RCCGLogo from '../../assets/promix/rccg.png'
import { ToWords } from 'to-words';

function RevenueReceipt2() {
  const location = useLocation();
  const { selectedLedger} = location.state || {};
  
  const toWords = new ToWords({
    localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      name: "Naira",
      plural: "Naira",
      symbol: "₦",
      fractionalUnit: {
        name: 'Kobo',
        plural: 'Kobo',
        symbol: 'K',
      },
    }
  }
  });

   

    console.log(selectedLedger, "Datas")

    const convertToWords = (number) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = [
          '',
          'Eleven',
          'Twelve',
          'Thirteen',
          'Fourteen',
          'Fifteen',
          'Sixteen',
          'Seventeen',
          'Eighteen',
          'Nineteen',
        ];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
      
        const numToWords = (num) => {
          if (num === 0) return '';
          if (num < 10) return units[num];
          if (num < 20) return teens[num - 10];
          if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
          if (num < 1000)
            return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + numToWords(num % 100) : '');
          if (num < 1000000)
            return numToWords(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + numToWords(num % 1000) : '');
          return 'Number too large to convert';
        };
      
        const [integerPart, decimalPart] = number.toFixed(2).split('.');
        const integerWords = parseInt(integerPart, 10) !== 0 ? numToWords(parseInt(integerPart, 10)) + ' Naira' : '';
        const decimalWords = parseInt(decimalPart, 10) !== 0 ? ' ' + numToWords(parseInt(decimalPart, 10)) + ' Kobo' : '';
      
        const result = (integerWords + (integerWords && decimalWords ? ',' : '') + decimalWords).trim();
      
        return result;
      };

      function formatName(name) {
        const parts = name.split(' ');
        if (parts.length > 2) {
          return `${parts[0]} ${parts[1]} ${parts[2].charAt(0)}.`;
        }
        return name;
      }
      const formattedName = selectedLedger?.particular ? formatName(selectedLedger.particular) : '';
      

    return (
       <div className={classes.generalBody}>
        <div className={classes.mainContainer}>

        <div className={classes.bckground}>
            <img src={RCCGLogo} ></img>
        </div>

        <div className={classes.mainHeader}>
          <h1> The Redeemed Christian Church of God</h1>
          <h3>National Secretariat</h3>
          <h4>OFFICIAL RECEIPT</h4>
        </div>

        <div className={`${classes.section} ${classes.section1}`}>
          <span className={classes.flexData}>
            <h5>Date:</h5>
            <p className={classes.border} style={{width:'150px'}}>{selectedLedger?.transaction_date}</p>
          </span>
          <span className={classes.flexData}>
            <h4>No.:</h4>
            <p className={classes.border} style={{width:'150px'}}>{selectedLedger?.id}</p>
          </span>
        </div>

        <div className={`${classes.section} ${classes.section2}`}>
          <span className={classes.flexData}>
            <h4>Received From:</h4>
            <p className={classes.border} style={{ width: '300px' }}>{formattedName}</p>

          </span>
          <span className={classes.flexData}>
            <h4>Amount:</h4>
            <div className={classes.amountBorder}>
              <p >
                {selectedLedger?.currency?.symbol} 
              {parseFloat(selectedLedger.amount).toLocaleString('en-US', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </p>
            </div>
          </span>
        </div>
        <div className={`${classes.section} ${classes.section3}`}>
          <span className={classes.amountInWord}>
            <h4>Amount in Words:</h4>
            <div className={classes.fullWith}>
            <p className={classes.border}>
            {selectedLedger?.amount ? toWords.convert(parseFloat(selectedLedger?.amount)) : ''}
        </p>
            </div>
          </span>
        </div>

        <div className={`${classes.section} ${classes.section4}`}>
          <span>
            <h4>For Payment of:</h4>
            <div className={classes.fullWith}>
              <p className={classes.border} style={{textAlign:'left'}}>{selectedLedger?.description}</p>
            </div>
          </span>
        </div>

        <div className={classes.section5}>
          <span className={classes.flexData}>
            <h4>Teller/ Cheque No</h4>
            <p style={{borderBottom:'2px solid black', width:'200px', textAlign:'center'}}>{selectedLedger?.teller_number}</p>
          </span>
        </div>

        <div className={classes.section6}>
          <span style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
            <h4>Received By</h4>
            {/* <div></div> */}
            <p style={{borderBottom:'2px solid black', width:'200px', textAlign:'center'}}>{selectedLedger?.user?.name}</p>
          </span>
        </div>

        </div>
       </div>

    )
}
export default RevenueReceipt2;