import React, { useState, useEffect } from 'react';
import classes from './Zone.module.css';
// import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import LatestAdminDash from '../LatestDashboard/LatestAdminDash';


export default function Zone() {
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(new Date());
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [zoneLoad, setZoneLoad] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [rccgRegions, setRccgRegions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState('');

  const [description, setDescription] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  
  const [description1, setDescription1] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [user, setUser] = useState("");
  
  // const [selectedProvince, setSelectedProvince] = useState("");



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      // if (value3 !== null) {
      //   setAdmin(value3);
      // }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchZone = async () => {
    setZoneLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/zones`, { headers });

      // console.log(response);
      const results = response.data?.data?.zones;
      setTableData(results);
      console.log("Zones:", results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      }else{ 
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
      setTableData([]);
      }
    } finally {
      setZoneLoad(false);
    }
  };

  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });

      // console.log(response);
      const resultsss = response.data?.data;
      setTableData1(resultsss);
      // console.log(results);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubContinents = async (selectedContinent) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subCont = response.data?.data;
      setTableData4(subCont);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData4([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSubContinents1 = async (selectedContinent1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subRegions = response.data?.data;
    setRegions(subRegions);
      console.log(subRegions, "subRegions");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    setRegions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubRegions = async (selectedRegion) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData5(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData5([]);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(selectedRegion1, "here");
  const fetchSubRegions1 = async (selectedRegion1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subRegionss = response.data?.data;
      setRccgRegions(subRegionss);
      console.log(subRegionss, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setRccgRegions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(selectedContinent1, selectedRegion1, "here");


  useEffect(() => {
    if (bearer) {
      fetchZone();
      fetchContinents();
    }
  }, [bearer]);

  //create beneficiary
  const createZone = async () => {
    setLoading(true);
    try {
      // console.log(description, selectedContinent, selectedProvince, selectedRegion, "hereeeee")
      const response = await axios.post(`${BASE_URL}/zones/create_zone`,
        {
          description: description,
          continent_id: selectedContinent,
          province_id: selectedProvince,
          region_id: selectedRegion,
        },
        { headers }
      );
      // console.log(response)
      fetchZone();
      handleClose();
      setDescription('');
      setSelectedContinent('');
      setSelectedProvince('');
      setSelectedRegion('');
      // return
      toast.success(response.data.message);
      console.log(response.data.message);

    } catch (error) {
      console.log(error);
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                    console.log(errorMessage)
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                    console.log(errorMessage)
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                    console.log(errorMessage)
                }
            }
            toast.error(errorMessage);
           
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  useEffect(() => {
    if (bearer && selectedContinent1) {
      fetchSubContinents1(selectedContinent1);
    }
  }, [bearer, selectedContinent1]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegions(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer && selectedRegion1) {
      fetchSubRegions1(selectedRegion1);
    }
  }, [bearer, selectedRegion1]);


  const handleContinentChange = async (event) => {
    setSelectedContinent(event.target.value);
   setSelectedRegion("");
   setSelectedProvince("");
  }


  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value)
  }
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value)
  }

  const handleContinentChange1 = (event) =>{
    setSelectedContinent1(event.target.value)
    setSelectedRegion1("");
    setSelectedProvince1("");
  }
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value)
  };

  
  const handleProvinceChange1 = (event) =>{
    setSelectedProvince1(event.target.value)
  }

  //format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  //view records
  const handleEyeClick = (id) => {
    // Find the selected grade from tableData using the id
    const foundZone= tableData.find(item => item.id === id);
    setSelectedZone(foundZone);
    setDescription1(foundZone?.description || '');
    setSelectedContinent1(foundZone?.continent?.id || '');
    setSelectedRegion1(foundZone?.region?.id || '');
    setSelectedProvince1(foundZone?.province?.id || '');

    setShow1(true);
    setEyeClicked(true);
  };



  //delete function
  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this zone. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`${BASE_URL}/zones/delete_zone?id=${id}`, { headers });
      fetchZone();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The deletion was cancelled.',
    });
  }
  };

  //update function
  const editZone = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}/zones/update_zone`,
        {
          description: description1,
          continent_id: selectedContinent1,
          province_id: selectedProvince1,
          region_id: selectedRegion1,
          id : selectedZone.id
          
        },
        { headers }
      );
handleClose1();
      fetchZone();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: errorMessage,
            });
    } finally {
      setLoading(false);
    }
  };


  //filter function
  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  return (
    <div>
      <LatestAdminDash />

      <div className={classes.finishedbodyCont}>
      <h3>Manage Zone</h3>
      <p>Add, View, Edit, and Delete Zone</p>


        <div className={classes.mainform}>

        <div className={classes.loandgrantcards}>
           
          <div className={classes.loandethead}>
            <div className={classes.formLabel}>
            </div>
            <div className={classes.formIntBtn}>
              <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Zone</Button>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Add Zone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form style={{ marginTop: 20 }}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                  <Form.Label>Continent</Form.Label>

                  <Form.Select
                    className="form-control"
                    value={selectedContinent}
                    onChange={handleContinentChange}
                  >
                    <option value="" disabled>Select Continent</option>
                    {tableData1.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    ))}
                  </Form.Select>

                  <div style={{ marginTop: 10 }} />
                  <Form.Label>Region</Form.Label>
                  
                  <Form.Select
                  className="form-control"
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  >
                    <option value="" disabled>Select Region</option>
                    {tableData4.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    ))}
                  </Form.Select>
                  
                  <div style={{ marginTop: 10 }} />
                  <Form.Label>Province</Form.Label>
                  <Form.Select
                  
                    className="form-control"
                  value={selectedProvince}
                  onChange={handleProvinceChange}
                  >
                  <option value="" disabled>Select Province</option>
                    {tableData5.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    ))}
                  </Form.Select>
                  
                  <div style={{ marginTop: 10 }} />
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Discription"
                    
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />


                
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Go back
              </Button>
              <Button variant="success" onClick={createZone}>
                {loading ? (
                  <>
                  <Spinner  size='sm' /> 
                  <span style={{ marginLeft: '5px' }}>Creating zone, Please wait...</span>
                    </>
                  ) : (
                  "Create Zone"
                  )}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1} animation={false}>

            <Modal.Header closeButton>
              <Modal.Title> Edit Zone</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form style={{ marginTop: 20 }}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

          <Form.Label>Continent</Form.Label>

          <Form.Select
            className="form-control"
            value={selectedContinent1}
            onChange={handleContinentChange1}
          >
            <option value="" disabled>Select Continent</option>
            {tableData1.map((item) => (
              <option key={item.id} value={item.id}>
                {item.description}
              </option>
            ))}
          </Form.Select>

          <div style={{ marginTop: 10 }} />
          <Form.Label>Region</Form.Label>
          
          <Form.Select
          className="form-control"
          value={selectedRegion1}
          onChange={handleRegionChange1}
          >
            <option value="" disabled>Select Region</option>
            {regions.map((item) => (
              <option key={item.id} value={item.id}>
                {item.description}
              </option>
            ))}
          </Form.Select>
          
          <div style={{ marginTop: 10 }} />
          <Form.Label>Province</Form.Label>
          <Form.Select
          
            className="form-control"
          value={selectedProvince1}
          onChange={handleProvinceChange1}
          >
          <option value="" disabled>Select Province</option>
            {rccgRegions.map((item) => (
              <option key={item.id} value={item.id}>
                {item.description}
              </option>
            ))}
          </Form.Select>
          
          <div style={{ marginTop: 10 }} />
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Discription"
            
            value={description1}
            onChange={(e) => setDescription1(e.target.value)}
          />
          
                  
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={handleClose1}>
                Go back
              </Button>

              <Button variant="success" onClick={editZone} >
                {loading ? ( <> <Spinner  size='sm' />
                  <span style={{ marginLeft: '5px' }}>
                    Editing zone, Please wait...
                  </span>
                  </>) : ("Save Changes")}
              </Button>
            </Modal.Footer>
          </Modal>


           <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
             <div className={classes.greenbtn} style={{ display: 'flex', gap: '20px' }}>
               <div className={classes.actionsBtns}>
                 <Button variant='success'>Copy</Button>
                 <Button variant='success'>Excel</Button>
                 <Button variant='success'>PDF</Button>
                 <Button variant='success'>Column visibility</Button>
               </div>
               <div>
                 <label className="d-flex justify-content-start align-items-center">
                   Show
                   <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                   //  value={entriesPerPage}
                   //     onChange={(e) => {
                   //     setEntriesPerPage(parseInt(e.target.value));
                   //     setCurrentPage(1);
                   //     }}
                   >
                     <option value={10}>10</option>
                     <option value={25}>25</option>
                     <option value={50}>50</option>
                     <option value={100}>100</option>
                   </select>
                   entries
                 </label>
               </div>
             </div>
             <div className="text-right modal-effect ">
               <div id="DataTables_Table_0_filter" className="dataTables_filter">
                 <div className="d-flex justify-content-start align-items-center">
                   <div className="mr-2">Search:</div>
                   <input
   type="search"
   value={searchTerm}
   className="form-control form-control-sm"
   placeholder=""
   aria-controls="DataTables_Table_0"
   onChange={(e) => setSearchTerm(e.target.value)}
/>
                   {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                 </div>
                 

               </div>
             </div>
           </div>
           <div className={classes.mainTable}>
           {zoneLoad ? (
                              <p>Fetching zones...</p>
                            ) : (
                             <div className="table-responsive">
                               <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                               <thead style={{ whiteSpace: 'nowrap' }}>
                                <tr>
                                  <th>ID</th>
                                    <th>Continent</th>
                                    <th>Region</th>
                                    <th>Province</th>
                                    <th>Zones</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                  </tr>
                         </thead>
                         <tbody style={{ whiteSpace: 'nowrap', }}>
                          
                         {displayedData.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.continent?.description}</td>
                                        <td>{item.region?.description}</td>
                                        <td>{item.province?.description}</td>
                                          <td>{item.description}</td>
                                        <td>{formatDate(item.created_at)}</td>
                                        <td>{formatDate(item.updated_at)}</td>
                                        <td>
                                          <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                          <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                          <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                          <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                         </tbody>
                               </table>
                             </div>
                           )}
           </div>

           <div className={classes.endded}>
             <p>
             Showing {startIndexx} to {endIndexx} of {totalEntries} entries
             </p>
             <div style={{ display: 'flex' }}>
               <button
                 style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                 onClick={handlePrevPage}
                  disabled={currentPage === 1}
               >
                 Previous
               </button>
               {[...Array(totalPages)].map((_, page) => {
                 // Show only 5 pages or less if available
                 if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                   return (
                    <button
                    key={page + 1}
                    style={{
                      marginLeft: '0.4rem',
                      marginRight: '0.4rem',
                      fontSize: '14px',
                      fontFamily: 'nunito',
                      fontWeight: 400,
                      color: page + 1 === currentPage ? '#ffffff' : '#000',
                      backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                      height: '2.5rem',
                      borderRadius: '89px',
                      padding: '0.5rem',
                      border: 'none',
                      width: '40px',
                      cursor: "pointer"
                    }}
                    onClick={() => setCurrentPage(page + 1)}
                  >
                    {page + 1}
                  </button>
                   );
                 }
                 return null;
               })}
               <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
             </div>
           </div>

         </div>
        </div>

      </div>

   


    

    </div >
)
}