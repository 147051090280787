import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../../Manage Cooperatives/Manage Members/Consolidated.module.css';
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import MainDashboard from '../../../Main Dashboard/MainDashoard';
import LatestDash from '../../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestAdminDash from '../../../LatestDashboard/LatestAdminDash';







function ContinentalAccount() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [userType, setUserType] = useState('');
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [selectedSwitch, setSelectedSwitch] = useState('');

  const handleSwitchChange = (switchId, value) => {

    if (selectedSwitch === value) {
      setSelectedSwitch('');
    } else {
      setSelectedSwitch(value);
    }

  };


  const switchOptions = [
    { id: 'switch1', label: 'Cashbook', value: 'Cashbook' },
    { id: 'switch2', label: 'Trial Balance', value: 'Trial Balance' },
    { id: 'switch3', label: 'General Ledger', value: 'General Ledger' },
    { id: 'switch4', label: 'Income Statement', value: 'Income Statement' },
    { id: 'switch5', label: 'Account Activity Report', value: 'Account Activity Report' },
    { id: 'switch6', label: 'Age - Analysis Payables', value: 'Age - Analysis Payables' },
    { id: 'switch7', label: 'Age - Analysis Receivables', value: 'Age - Analysis Receivables' },
    { id: 'switch8', label: 'Statement of Financial Position', value: 'Statement of Financial Position' },
  ];

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');


      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }



    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };


  // console.log(selectedAccount);

  const fetchAccounts = async () => {
    setSavingsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/general-ledger-filter`, {
        params: {
          gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const resultsss = response.data?.data?.journal;
      setAccounts(resultsss);

      const resultssx = response.data?.data?.input;
      setInputss(resultssx);

      console.log(resultsss);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setSavingsLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData(opt1);
      console.log(response);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);



  const filteredData = accounts.filter(item => item.details.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);



  const fetchConsolidated = async () => {
    setConsolidatedLoading(true);

    try {

      const response = await axios.get(
        `${BASE_URL}/reports/get-consolidated-reports`, {
        params: {
          gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
          continent_id: selectedContinent || "",
          region_id: selectedRegion || "",
          province_id: selectedProvince || ""

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const filtData1 = response.data?.data?.input || [];
      const filtData2 = response.data?.data?.journal || [];

      if (filtData2.length === 0) {
        toast.error('No data found for the selected account.');
      } else {
        // setInputss(filtData1);
        setAccounts(filtData1);
        navigate('/accounting/print_cashbook_consolidated', { state: { document: filtData2, inputs: filtData1 } });

      }
      console.log(response, "consolidated");
      // const genLed = response.data?.data;
      // setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      // console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setConsolidatedLoading(false);
    }
  };
















  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchContinents();
    }
  }, [bearer]);



  const fetchSubContinents = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions`, { headers });

      // console.log(response);
      const subCont = response.data?.data?.regions;
      const options1 = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData3(options1);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchSubRegion = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion?.value}`, { headers });

      // console.log(response);
      const subCont1 = response.data?.data;
      const options2 = subCont1.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData4(options2);
      console.log(options2);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };
  const fetchProvince = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/provinces`, { headers });

      // console.log(response);
      const subCont15 = response.data?.data.provinces;
      const options25 = subCont15.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData45(options25);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchSubContinents();
    }
  }, [bearer]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer) {
      fetchProvince();
    }
  }, [bearer]);

  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion('');
    setSelectedProvince('');
  };


  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption.value)
  }

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption.value)
    if (admin === 'Continental Accountant') {
      setProvinceOptions(tableData4);
    }
  }


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (accounts) {
      const debitTotal = accounts.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = accounts.reduce((total, item) => total + parseFloat(item.credit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [accounts]);


  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestAdminDash />
                </div>

                <div className={classes.finishedbodyCont}>
                  <h3 style={{ fontSize: '30px', fontWeight: 700, marginLeft: '20px', marginBottom: '0' }}>Continental Report</h3>
                  <p style={{ marginLeft: '20px', marginBottom: '10px' }}>Process report by selecting the below required field</p>

                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>Cash Book</h3> */}
                  <div className={classes.topSpace}>
                    <div className={`${classes.topPadding}`} >
                      <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'left', paddingLeft: '225px', }}>
                        <div style={{ marginTop: '30px', }}>
                          <div className="card-body">
                            <div style={{ padding: '0', margin: '0' }}>
                              <table style={{ width: 'auto', tableLayout: 'auto', padding: '0', margin: '0' }}>
                                <tbody>
                                  {switchOptions.map((option) => (
                                    <tr key={option.id}>
                                      <td>
                                        <Form>
                                          <Form.Check
                                            type="switch"
                                            id={option.id}
                                            label={option.label}
                                            value={selectedSwitch}
                                            checked={selectedSwitch === option.value}
                                            onChange={() => handleSwitchChange(option.id, option.value)}
                                          />
                                        </Form>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${classes.formSecCont} ${classes.awrow}`}>
                        <div className={`card-body ${classes.awrow}`} style={{ border: 'none' }}>
                          <div className={`row ${classes.awrow}`}>
                            {/* Provincial Accountant sees only accounts and date fields */}
                            {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant' || admin === 'Provincial Accountant') && ( */}

                            <div className={`row ${classes.awrow}`} style={{ margin: '0 0 0 180px', display: "flex", alignItems: "center" }}>
                              {/* <div className="col-md-4">
                                <div className="form-group row">
                                  <div className={`col-sm-8  ${classes.lblroDSw}`}>
                                    <Select
                                      onChange={(selectedOption) => setSelectedContinent(selectedOption)}
                                      options={selectOptions}
                                      placeholder="Select Continents"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                              <div className="col-md-4" style={{}}>
                                <div className="form-group row">
                                  
                                  <div className={`col-sm-8  ${classes.lblroDSw}`}>
                                    <Select
                                      onChange={(selectedOption) => setSelectedRegion(selectedOption)}
                                      options={tableData3}
                                      placeholder="Select Region"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4" style={{ marginLeft: '-125px'  }}>
                                <div className="form-group row">
                                  
                                  <div className={`col-sm-8  ${classes.lblroDSw}`} >
                                    <Select
                                      onChange={(selectedOption) => setSelectedProvince(selectedOption)}
                                      options={tableData4}
                                      placeholder="Select Province"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                    {/* <OverlayTrigger
                                      placement="top"
                                      className={classes.btndf1}
                                      overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Check to select all provinces</Tooltip>}
                                    >
                                        <Form className="mr-3" style={{ marginTop: '10px' }}>
                                          <Form.Check type="checkbox" id="check-api-checkbox" className="m-0">
                                            <Form.Check.Input type="checkbox" isValid />
                                          </Form.Check>
                                        </Form>
                                    </OverlayTrigger>                                     */}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4" style={{ marginLeft: '-100px' }}>
  <div className="form-group row">
    <div className="form-check">
      <input className="form-check-input" type="checkbox" id="viewConsolidatedReport" />
      <label className="form-check-label" htmlFor="viewConsolidatedReport">
        View Consolidated
      </label>
    </div>
  </div>


                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>

                      {["Cashbook", "General Ledger", "Account Activity Report"].includes(selectedSwitch) && (
  <div className={`${classes.formSecCont} ${classes.awrow}`}>
    <div className={`card-body ${classes.awrow}`} style={{ border: 'none' }}>
      <div className={`row ${classes.awrow}`}>
        {/* Provincial Accountant sees only accounts and date fields */}
        {/* Uncomment the following line to conditionally render for specific roles */}
        {/* {(["Super Admin", "Continental Accountant", "Regional Accountant", "Provincial Accountant"].includes(admin)) && ( */}
        <div className={`row ${classes.awrow}`} style={{ margin: '0 0 0 180px' }}>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="account-select" className="col-sm-5 col-form-label font-weight-400">
                Account:
              </label>
              <div className={`col-sm-8 ${classes.lblrow}`}>
                <Select
                  isMulti
                  onChange={handleAccountChange}
                  options={tableData}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '395px',
                      maxWidth: '395px',
                      overflowY: 'auto',
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: '395px',
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxWidth: '395px',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  </div>
)}

                      <div className={`${classes.formSecCont} ${classes.awrow}`}>
                        <div className={`card-body ${classes.awrow}`} style={{ border: 'none' }}>
                          <div className={`row ${classes.awrow}`}>
                            {/* Provincial Accountant sees only accounts and date fields */}
                            {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant' || admin === 'Provincial Accountant') && ( */}
                            <div className={`row ${classes.awrow}`} style={{ margin: '0 0 0 180px', }}>
                              <div className="col-md-4">
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Start Date:</label>
                                  <div className="col-sm-8">
                                    <input className={`form-control  ${classes.lblroww}`} required="" type="date" onChange={handleDateChange} name="end" value={selectedEndDate} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4" style={{ marginLeft: '-125px' }}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>End Date:</label>
                                  <div className={`col-sm-8  ${classes.lblrow}`}>
                                    <input className={`form-control  ${classes.lblroww}`} required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer" style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, marginLeft: '-50px', marginTop: '35px', }}>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to process the report between the selected field</Tooltip>}
                                >
                                  <Button
                                    disabled={!selectedEndDate ||  !selectedDate}
                                    className={classes.overlayBtns11} variant='success' onClick={fetchConsolidated}>
                                    {consolidatedLoading ? (
                                      <>
                                        <Spinner size='sm' />
                                        {/* <span style={{ marginLeft: '5px' }}>Loading...</span> */}
                                      </>
                                    ) : (
                                      "Process"
                                    )}
                                  </Button>
                                </OverlayTrigger>

                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>















                    <div style={{ backgroundColor: 'white', padding: '0 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div>
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                      }}
                      className={classes.actionBtns}
                    >
                      <Button variant="success" onClick={handleCreate}>
                        Create New Accounts
                      </Button>
                    </div>

                  </nav> */}
                        {/* )} */}

                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">
                          <div>
                            {accounts.length > 0 && (
                              <button onClick={() => navigate('/accounting/print_cashbook', { state: { accounts, inputss } })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#198754", color: "#fff", marginBottom: 10, marginTop: 10 }}>Print Report</button>
                            )}
                          </div>

                          {/* <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginTop: '0', }}>
                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className="table-responsive">
                                    <table className="table display table-hover bg-white m-0 card-table" style={{ width: 'auto', tableLayout: 'auto' }}>
                                       <thead  >
                                        <td>
                                          <Form>
                                            <Form.Check
                                              type="switch"
                                              id="switch2"
                                              label="Income Statement"
                                            />
                                          </Form>
                                        </td>
                                        <td>
                                          <Form>
                                            <Form.Check
                                              type="switch"
                                              id="switch1"
                                              label="Account Activity Report"
                                            />
                                          </Form>
                                        </td>
                                        <td>
                                          <Form>
                                            <Form.Check
                                              type="switch"
                                              id="switch6"
                                              label="Age - Analysis Payables"
                                            />
                                          </Form>
                                        </td>
                                      </thead>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginTop: '0', }}>
                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className="table-responsive">
                                    <table className="table display table-hover bg-white m-0 card-table" style={{ width: 'auto', tableLayout: 'auto' }}>
                                       <thead  >
                                        <td>
                                          <Form>
                                            <Form.Check
                                              type="switch"
                                              id="switch6"
                                              label="Age - Analysis Receivables"
                                            />
                                          </Form>
                                        </td>
                                        <td>
                                          <Form>
                                            <Form.Check
                                              type="switch"
                                              id="switch8"
                                              label="Statement of Financial Position"
                                            />
                                          </Form>
                                        </td>
                                      </thead>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default ContinentalAccount;