import React, { useState, useEffect } from 'react';
import classes from './Expenses.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
// import favicon from '../../Images/faviconn.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import CashDash from '../Cash Dash/CashDash';
import Select from 'react-select';
import PaymentDash from '../Payment Dashboard/PaymentDash';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MobileMenu from '../../Component/Mobile/MobileNav';



export default function AccountPaymentVoucher() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const [sICode, setSICode] = useState('');
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [selectedBank, setSelectedBank] = useState('');
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [chequeLoading, setChequeLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [voucherData, setVoucherData] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [banks, setBanks] = useState([]);
  const [bankkk, setBankkk] = useState([]);
  // const [totalEntries, setTotalEntries] = useState("");
  // const [totalPages, setTotalPages] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [admin, setAdmin] = useState("");
  // const [userTypes, setUserTypes] = useState('');
  const [continent, setContinent] = useState('');
  const [openNav,setOpenNav] = useState(false);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      const admins = await AsyncStorage.getItem('admin');

      // const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');


      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (admins !== null) {
        setUserTypes(admins);
    }
      if (continentName !== null) {
        setContinent(continentName);
    }
    if (regionName !== null) {
        setRegion(regionName);
    }
    if (provinceName !== null) {
        setProvince(provinceName);
    }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };    

useEffect(() => {
readData();
}, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
  }

  const fetchPayment = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payment_voucher/pending_payment_list`, { headers });
      const results = response.data?.data?.payments;
      setTableData(results);
      console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchPayment();
      // fetchBankss();

    }
  }, [bearer, currentPage]);

  const isSelected = (id) => {
    return selectedRows.includes(id);
  };


  const fetchBanks = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payment-banks`, { headers });
      const results = response.data?.data;
setBankkk(results);
      const options1 = results.map((item) => ({
        label: item.bank_name,
        value: item.id,
      }));
      setBanks(options1);
      console.log(results);
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setBanks([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (bearer) {
      fetchBanks();
    }
  }, [bearer]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const handleEyeClick = (id) => {

    const foundUser = tableData.find(item => item.id === id);


    const { name, email, phone_no, roles } = foundUser;


    setFullName1(name || '');
    setEmail1(email || '');
    setPhone1(phone_no || '');

    const selectedRole = roles.length > 0 ? roles[0].id : '';
    setSelectedRole(selectedRole);

    setShow1(true);
    setEyeClicked(true);
  };


  const handleTrashClick = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/payment_voucher/delete-payment-voucher?id=${id}`, { headers });
  
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      console.log(errorStatus);
    }
  };

  const handleMakePayment = async () => {
    setPaymentLoading(true);
    const selectedRowIds = selectedRows.map(row => row.id);
console.log(selectedRowIds, selectedBank);
    try {
      const response = await axios.post(`${BASE_URL}/pay-payables`,
        {
          payable_id: selectedRowIds,
          payment_bank: selectedBank,
         
        
        },
        { headers }
      );
handleClose();


     toast.success(response.data.message);
     
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage)
          console.log(errorMessage);
      }
    } finally {
      setPaymentLoading(false);
    }
  };
  const handleCheque = async (pv_number) => {
    setChequeLoading(true);
    console.log(pv_number);
  
    try {
      const response = await axios.get(
        `${BASE_URL}/get-payments-by-voucher-number?pv_number=${pv_number}`,
        { headers }
      );
  
      const responseData = response.data?.data;
      console.log(responseData);
  
      // if (!Array.isArray(responseData)) {
      //   responseData = [responseData];
      // }
      
      // setVoucherData(responseData);
  
      // // Navigate to the new page with state
      navigate('/payment/pending_payment_voucher', { state: {voucher: responseData } });
  
      toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      toast.error(errorMessage);
      console.log(errorMessage);
    } finally {
      setChequeLoading(false);
    }
  };


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleCreate = () => {
    if (selectedRows.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select at least a row to generate a payment voucher!',
      });
      return;
    }
    setShow(true);
    setTotalAmount(calculateTotalAmount(selectedRows));
  };
  
  const calculateTotalAmount = (rows) => {
    const total = rows.reduce((total, row) => {
      const amount = parseFloat(row.amount);
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    
    return total.toFixed(2); // Returns the total amount as a string with 2 decimal places
  };
  



  const handlePrintInvoice = (id) => {
    const selectedVoucher = tableData.find(item => item.id === id);
  
  
    navigate('/print_payment', { state: { selectedVoucher } });
  };

  const handleViewVoucher = (id) => {
    const selectedVoucher = tableData.find(item => item.id === id);
  
  
    navigate('/payment/cheque', { state: { selectedVoucher } });
  };

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.findIndex(item => item.id === row.id);
    let updatedSelectedRows = [];
  
    if (selectedIndex === -1) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter(item => item.id !== row.id);
    }
  
    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === tableData.length);
    // console.log(updatedSelectedRows);
  };
  
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedRows(isChecked ? [...tableData] : []);
    // console.log(isChecked ? [...tableData] : []);
  };

  const fetchInvoiceCode = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
    //   console.log(resultss);
      setSICode(resultss);
        // console.log(invoiceData)
    //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
        fetchInvoiceCode();
    }
  }, [bearer]);

  const handleValueChange = (value, name, values) => {
    setTotalAmount(value);
  };

  const handleCreates = () => {
    navigate('/payment/manual_entries');
  }



    return (
<div>
{
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
    <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>

      <div className={classes.finishedbodyCont}>
      

        
<div style={{display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <h3 className={classes.yoH3}>Payment Voucher</h3>
          <p style={{margin:" 0 10px 20px 0" }}>Create and View Payment Voucher</p>
</div>
<div className={`${classes.formIntBtn} ${classes.formIntBtnUnitss}`} style={{zIndex: 1,}}> 
              <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to create new voucher</Tooltip>}
                  >
                        <Button
                        className={classes.btn1}
                            style={{marginTop: 50 }} variant='success' onClick={handleCreates}>
                            Create New Voucher
                        </Button>
                  </OverlayTrigger>

               
              </div>
              </div>
        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>
            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
              </div>
             
            </div>
           
            

          <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
   type="search"
   value={searchTerm}
   className="form-control form-control-sm"
   placeholder=""
   aria-controls="DataTables_Table_0"
   onChange={(e) => setSearchTerm(e.target.value)}
/>
                  </div>

                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
            {isLoading ? (
                              <p>Fetching Vouchers...</p>
                            ) : (
                              <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead style={{ whiteSpace: 'nowrap' }}>
                                  <tr>
                                      
                                      <th>S/N</th>
                                      <th>Date</th>
                                      <th>Description</th>
                                      <th>PV Number</th>
                                      {/* <th>PV Number</th> */}
                                      <th>Total Amount</th>
                                      <th>Prepared By</th>
                                      {/* <th>Contract Amount</th> */}
                                      {/* <th>Total Tax Amount</th> */}
                                      {/* <th>Action</th> */}
                                    </tr>
                                  </thead>
                                  <tbody style={{ whiteSpace: 'nowrap' }}>
                                  {displayedData.map((item, index) => (
                                    <tr key={index}>
                                      
                                      <td>{index + 1}</td>
                                        <td>{item.date}</td>
                                      <td>{item.description}</td>
                                      <td>{item.pvnumber}</td>
                                       <td style={{ textAlign: "right",}}>{parseFloat(item.total_amount).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}</td>
                                        <td>{item.preparer_detail?.name}</td>
                                        {/* <td style={{textAlign: "left"}}>
                                          <div onClick={() => handleCheque(item.pv_number, item)}  className="btn btn-success-soft btn-sm mr-1">
                                          <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                          
                                         
                                        </td> */}
                                    </tr>
                                  ))}
                                </tbody>
                                </table>
                              </div>
                            )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>

   


    

    </div >
    )
}
