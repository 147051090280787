import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import OnbImg from '../../Images/image bg.png';
import classes from "./LatestDashboards.module.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { BASE_URL } from "../api/api";
import LatestDash from "./LatestDash";
import Chart from "react-apexcharts";
import { IoMdClose } from "react-icons/io";
import LatestMenu from "./LatestMenu";
import Dropdown from "react-bootstrap/Dropdown";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import DropdownButton from 'react-bootstrap/DropdownButton';
import { MdArrowDropDown } from "react-icons/md";
import MobileMenu from "../../Component/Mobile/MobileNav";


const LatestAccountingDashboard = () => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const [company, setCompany] = useState("");
  const [userType, setUserType] = useState("");
  const [continent, setContinent] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");
  const [firstName, setFirstName] = useState("Sade Okoya");
  const [stat, setStat] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [recievables, setReceivables] = useState([]);
  const [newLoading, setNewLoading] = useState(false);
  const [validity, setValidity] = useState("");
  const [permits, setPermits] = useState([]);
  const [admin, setAdmin] = useState("");
  const [loading, setLoading] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [openNav,setOpenNav] = useState(false);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      // const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem("admin");
      const value7 = await AsyncStorage.getItem("companyName");
      const value8 = await AsyncStorage.getItem("validity");
      const permitsss = await AsyncStorage.getItem("modules");

      if (value !== null) {
        setBearer(value);
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map((permit) => permit.name);
        setPermits(permitNames);
        // console.log(permitNames);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value8 !== null) {
        setValidity(value8);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (value7 !== null) {
        setCompany(value7);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  const data = [
    {
      label: "Money In",
      data: [
        { primary: "March 2024", secondary: 3_200_000 },
        { primary: "April 2024", secondary: 2_800_000 },
        { primary: "May 2024", secondary: 3_600_000 },
        { primary: "June 2024", secondary: 4_000_000 },
      ],
    },
    {
      label: "Money Out",
      data: [
        { primary: "March 2024", secondary: 1_500_000 },
        { primary: "April 2024", secondary: 2_000_000 },
        { primary: "May 2024", secondary: 2_500_000 },
        { primary: "June 2024", secondary: 3_000_000 },
      ],
    },
  ];

  const chartOptions = {
    primaryAxis: {
      getValue: (datum) => datum.primary,
      type: "ordinal",
    },
    secondaryAxes: [
      {
        getValue: (datum) => datum.secondary,
        elementType: "bar",
        min: 1_000_000,
        max: 4_000_000,
        tickFormat: (d) => `${(d / 1_000_000).toFixed(1)}M`,
      },
    ],
    getSeriesStyle: (series) => ({
      color: series.label === "Money In" ? "#2D995F" : "#FFBA08",
    }),
  };

  const Legend = ({ data }) => (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      {data.map((series) => (
        <div
          key={series.label}
          style={{ display: "flex", alignItems: "center", margin: "0 10px" }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor:
                series.label === "Money In" ? "#2D995F" : "#FFBA08",
              marginRight: "10px",
            }}
          ></div>
          <span>{series.label}</span>
        </div>
      ))}
    </div>
  );

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  const handleLogout = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      navigate("/login");
      //   toast.success(response.data.message);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === "string") {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join("; ");
          } else if (typeof error.response.data.message === "object") {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      }
      //   toast.error(errorMessage);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const changeName = () => {
    setFirstName("Ibrahim Awonuga");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const chart = ApexCharts();

  const areaChartOptions = {
    chart: { type: "area", height: 350 },
    xaxis: { categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"] }, // You can change based on daily, weekly, etc.
    series: [
      { name: "Income", data: [400, 430, 448, 470, 540, 580, 690] },
      { name: "Expenses", data: [120, 150, 170, 180, 190, 200, 210] },
    ],
  };

  const barChartOptions = {
    chart: { type: "bar", height: 350 },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    }, // You can change based on daily, weekly, etc.
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  // Pie Chart Data (Income from last 4 months)
  const pieChartOptions = {
    chart: { type: "donut", height: 250 },
    labels: ["Month 1", "Month 2", "Month 3", "Month 4", "Current Month"],
    series: [44, 55, 41, 67, 50],
    colors: [
      "rgba(144, 238, 144, 0.8)",
      "rgba(50, 205, 50, 0.8)",
      "rgba(0, 100, 0, 0.8)",
      "rgba(34, 139, 34, 0.8)",
      "rgba(124, 252, 0, 0.8)",
    ],
    legend: {
      show: true,
      position: "right",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      labels: {
        colors: undefined,
      },
      markers: {
        width: 12,
        height: 12,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
    },
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const tableRef = useRef(null);
  const tableRef1 = useRef(null);

  // Calculate the total number of pages
  const totalPages = Math.ceil(customer.length / itemsPerPage);
  const totalPages1 = Math.ceil(recievables.length / itemsPerPage);

  // Determine the data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = customer.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems1 = recievables.slice(indexOfFirstItem, indexOfLastItem);

  const scrollToTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: "smooth", // Smooth scrolling
        block: "start",
      });
    }
  };
  const scrollToTable1 = () => {
    if (tableRef1.current) {
      tableRef1.current.scrollIntoView({
        behavior: "smooth", // Smooth scrolling
        block: "start",
      });
    }
  };

  // Handle next and previous page actions
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      scrollToTable(); // Scroll to the top of the table
    }
  };
  const handleNextPage1 = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      scrollToTable1(); // Scroll to the top of the table
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      scrollToTable(); // Scroll to the top of the table
    }
  };
  const handlePreviousPage1 = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      scrollToTable1(); // Scroll to the top of the table
    }
  };

  const fetchData88 = async () => {
    setNewLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/display-infos`, {
        headers,
      });
      const starsss = response.data?.data;
      const sta1 = response.data?.data?.customer_owing_us;
      const sta2 = response.data?.data?.customer_we_owe;
      console.log(starsss);
      setStat(starsss);
      setCustomer(sta1);
      setReceivables(sta2);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setStat([]);
      }
    } finally {
      setNewLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      // fetchData8();
      fetchData88();
    }
  }, [bearer]);

  const navToDashboard = () => {
    navigate("/accounting");
  };

  return (
    <div className={classes.latestBody}>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
      <div className={classes.latestBodyContainer}>
        <div className={classes.formSection}>
          <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>
          <div className={classes.body_style}>
            <div className={classes.dashboardasdf112}>
              <div>
                <div className={classes.dashboardasdf112left}>
                  <div className={classes.innerasdfas121}>
                    <div
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: "15px",
                      }}
                    >
                      Total Income
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontWeight: 800,
                        fontSize: "20px",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                    >
                      ₦
                      {newLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        parseFloat(stat?.total_incomes).toLocaleString(
                          "en-US",
                          {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      )}
                    </div>
                  </div>
                  <div className={classes.innerasdfas121}>
                    <div
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: "15px",
                      }}
                    >
                      Total Expenses
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontWeight: 800,
                        fontSize: "20px",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                    >
                      ₦
                      {newLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        parseFloat(stat?.total_expenses).toLocaleString(
                          "en-US",
                          {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className={classes.dashboardasdf112leftasa121}>
                <div className={classes.innerasdfas121}>
                  <div
                    style={{
                      color: "black",
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    Account Receivables
                  </div>
                  <div
                    style={{
                      color: "black",
                      fontWeight: 800,
                      fontSize: "20px",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    ₦
                    {newLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      parseFloat(stat?.total_receivables).toLocaleString(
                        "en-US",
                        {
                          minimumIntegerDigits: 1,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    )}
                  </div>
                </div>
                <div className={classes.innerasdfas121}>
                  <div
                    style={{
                      color: "black",
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    Account Payables
                  </div>
                  <div
                    style={{
                      color: "black",
                      fontWeight: 800,
                      fontSize: "20px",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    ₦
                    {newLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      parseFloat(stat?.total_payables).toLocaleString("en-US", {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.dashboardasdf112asdfs12717as}>
              <div>
                <div className={classes.firstGraphadf1234}>
                  <div className={classes.secondGraph}>
                    <Chart
                      options={barChartOptions}
                      series={barChartOptions.series}
                      type="bar"
                    className={classes.chartsSize}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className={classes.dashboardasdf112right12asdf}>
                    <div>
                      <Chart
                        options={pieChartOptions}
                        series={
                          pieChartOptions?.series?.length
                            ? pieChartOptions.series
                            : []
                        }
                        type="pie"
                        className={classes.chartsSizes}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.dashboardasdf112asdfs}>
              <div>
                <div className={classes.dashboardasdf112right12}>
                  <h4
                    style={{
                      backgroundColor: "",
                      color: "black",
                      margin: 0,
                      padding: "5px",
                      fontWeight: "700",
                    }}
                  >
                    Receivables
                  </h4>
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "700px",
                      overflowY: "auto",
                      overflowX: "auto",
                    }}
                  >
                    {newLoading ? (
                      <p>Fetching accounts...</p>
                    ) : Array.isArray(customer) && customer.length === 0 ? (
                      <p>No data to display</p>
                    ) : (
                      <div
                        className="table-responsive"
                        style={{ minWidth: "100%" }}
                      >
                        <table
                          className="table display table-bordered table-striped table-hover bg-white m-0 card-table"
                          ref={tableRef}
                        >
                          <thead style={{ whiteSpace: "nowrap" }}>
                            <tr>
                              <th>S/N</th>
                              <th>Customer</th>
                              <th>Details</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody style={{ whiteSpace: "nowrap" }}>
                            {Array.isArray(currentItems) &&
                              currentItems.map((item, index) => (
                                <tr key={index}>
                                  <td>{indexOfFirstItem + index + 1}</td>
                                  <td style={{ whiteSpace: "normal" }}>
                                    {item.customer?.name}
                                  </td>
                                  <td style={{ whiteSpace: "normal" }}>
                                    {item.description}
                                  </td>
                                  <td
                                    style={{
                                      whiteSpace: "wrap",
                                      textAlign: "right",
                                    }}
                                  >
                                    {parseFloat(item.balance).toLocaleString(
                                      "en-US",
                                      {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {/* Pagination Controls */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="btn btn-primary"
                      >
                        Previous
                      </button>
                      <p>
                        Page {currentPage} of {totalPages}
                      </p>
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="btn btn-primary"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div className={classes.dashboardasdf112right12}>
                    <h4
                      style={{
                        backgroundColor: "",
                        color: "black",
                        margin: 0,
                        padding: "5px",
                        fontWeight: "700",
                      }}
                    >
                      Payables
                    </h4>
                    <div
                      style={{
                        width: "100%",
                        maxHeight: "700px",
                        overflowY: "auto",
                        overflowX: "auto",
                      }}
                    >
                      {newLoading ? (
                        <p>Fetching accounts...</p>
                      ) : Array.isArray(recievables) &&
                        recievables.length === 0 ? (
                        <p>No data to display</p>
                      ) : (
                        <div
                          className="table-responsive"
                          style={{ minWidth: "100%" }}
                        >
                          <table
                            className="table display table-bordered table-striped table-hover bg-white m-0 card-table"
                            ref={tableRef1}
                          >
                            <thead style={{ whiteSpace: "nowrap" }}>
                              <tr>
                                <th>S/N</th>
                                <th>Customer</th>
                                <th>Details</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody style={{ whiteSpace: "nowrap" }}>
                              {Array.isArray(currentItems1) &&
                                currentItems1.map((item, index) => (
                                  <tr key={index}>
                                    <td>{indexOfFirstItem + index + 1}</td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {item.customer?.name}
                                    </td>
                                    <td style={{ whiteSpace: "normal" }}>
                                      {item.description}
                                    </td>
                                    <td
                                      style={{
                                        whiteSpace: "wrap",
                                        textAlign: "right",
                                      }}
                                    >
                                      {parseFloat(item.balance).toLocaleString(
                                        "en-US",
                                        {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                      {/* Pagination Controls */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          onClick={handlePreviousPage1}
                          disabled={currentPage === 1}
                          className="btn btn-primary"
                        >
                          Previous
                        </button>
                        <p>
                          Page {currentPage} of {totalPages1}
                        </p>
                        <button
                          onClick={handleNextPage1}
                          disabled={currentPage === totalPages}
                          className="btn btn-primary"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestAccountingDashboard;
