import React, { useState, useEffect } from 'react';
import classes from './ManageStocks.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
import { Spinner, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MainDashoard from '../Main Dashboard/MainDashoard';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import LatestDash from '../LatestDashboard/LatestDash';
import Select from "react-select";



export default function CreateProducts() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [admin, setAdmin] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [unit, setUnit] = useState(null);
  const [roless, setRoless] = useState([]);
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [tableDataP, setTableDataP] = useState([]);
  const [tableDataPayable, setTableDataPayable] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableReceivables, setTableReceivables] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [price, setPrice] = useState("");
  const [glCode, setglCode] = useState("");
  const [stockBalance, setStockBalance] = useState("");
  const [reOderLevel, setReOrderLevel] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [selectedAdvance, setSelectedAdvance] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };


 

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/units/fetch-all`, { headers });
      const roleList = response.data?.data;
      // console.log(results);
      setRoless(roleList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData2 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/product-categories`, { headers });
      const ts = response.data?.data;
      // console.log(results);
      setTableData3(ts);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData3([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/incomes`, { headers });
      const results = response.data?.data;

      setTableData2(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPurchaseGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/expenses`, { headers });
      const results = response.data?.data;

      setTableDataP(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableDataP([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchPayableGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/payables`, { headers });
      const results = response.data?.data;

      setTableDataPayable(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableDataPayable([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAcReceivables = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/receivables`, { headers });
      const results = response.data?.data;

      setTableReceivables(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableReceivables([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAcPayables = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/expenses`, { headers });
      const results = response.data?.data;

      setSelectedPayable(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setSelectedPayable([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchData1();
      fetchData2();
      fetchGl();
      fetchPurchaseGl();
      fetchAcReceivables();
      fetchAcPayables();
      fetchPayableGl();
    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);
    try {
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

     
    
      const response = await axios.post(
        `${BASE_URL}/items/add-new-item`,
        {
          name: name,
          description: description,
          unit: unit?.value  || '',
          gl_code: glCode,
          type: selectedType?.value || '',
          re_order_level: reOderLevel,
          price: removeFormatting(price),
          quantity: stockBalance,
          sales_gl: selectedSales?.value  || '',
          payable_gl: selectedPayable?.value  || '',
          purchase_gl: selectedPurchase?.value  || '',
          account_receivable: selectedAccount?.value  || '',
          category_id: selectedCategory?.value  || '',
          advance_payment_gl: selectedAdvance?.value  || '',


        },
        { headers }
      );
      console.log(response)
      // fetchData();
      // handleClose();

      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      setName('');
      setDescription('');
      setUnit('');
      setPrice('');
      setSelectedCategory('');
      setSelectedSales('');
      setSelectedPurchase('');
      setSelectedAccount('');
      setSelectedPayable('');
      setStockBalance('');
      setReOrderLevel('');
      setglCode('');
      setSelectedAdvance('');
      setSelectedType('');


      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.data),
        });
        // toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setLoading(false);
    }
  };




 
 

  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);



 

  const handleUnitChange = (selectedOptions) => {
    setUnit(selectedOptions[0].value)
  }
  const handleSalesChange = (selectedOptions) => {
    setSelectedSales(selectedOptions[0].value)
  }
  const handleAccountChange = (selectedOptions) => {
    setSelectedAccount(selectedOptions[0].value)
  }
  const handlePurchaseChange = (selectedOptions) => {
    setSelectedPurchase(selectedOptions[0].value)
  }
  const handlePayablesChange = (selectedOptions) => {
    setSelectedPayable(selectedOptions[0].value)
  }
  const handleCategory = (selectedOptions) => {
    setSelectedCategory(selectedOptions[0].value)
  }
 

  const formatPrice = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice(finalValue);
  };

  const options = roless.map(item => ({
    label: item.name,
    value: item.id
  }));

  const options1 = tableData3.map(item => ({
    label: item.description,
    value: item.id
  }));

  const acReceivables = tableReceivables.map(item => ({
    label: item.gl_name,
    value: item.id
  }));


  const salesOptions = tableData2.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const purchaseOptions = tableDataP.map(item => ({
    label: item.gl_name,
    value: item.id
  }));
  const payableOptions = tableDataPayable.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const advanceOptions = tableDataPayable.map(item => ({
    label: item.gl_name,
    value: item.id
  }));
  





  return (
    <div>
      <LatestDash />
      <ToastContainer />
      <div className={classes.finishedbodyCont}>



              <h3 className={` ${classes.createPrdLg}`}>Add Products/Services</h3>
              <p className={` ${classes.subPrdLg}`}>Kindly fill in the below with the correct details</p>

        <div className={`${classes.mainform} ${classes.mainformMin}`}>

          <div className={classes.loandgrantcards}>

              {/* <h3 className={classes.productText}>Add Products</h3> */}
            <div className={classes.formLabel}>
            </div>
            <div className={classes.loandethead}>
              <div className={classes.formIntBtn}>
              </div>
            </div>

            <div className="d-flex justify-content-left">

              <Form className={`${classes.formLg} ${classes.formMd}`}>
                {/* <div className="row"> */}
                  {/* <div className="col-md-6"> */}

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-left form-group-custom" style={{marginLeft: -5}}>
                        <Form.Label className={classes.formLabelCustom} >Type</Form.Label>
                        <div className={classes.formControl}>
                        <Select loading={isLoading}         options={[
                        { value: '', label: 'Select Type' },
                        { value: '1', label: 'Services' },
                        { value: '2', label: 'Products' }
                      ]}
                       placeholder="Select Type" onChange={setSelectedType} value={selectedType}
                       />
                      </div>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Name</Form.Label>
                        <Form.Control
                        className={classes.formControl}
                          type="text"
                          // placeholder="Enter Product's Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  {/* </div> */}
                  {/* <div className="col-md-6"> */}
                    <Form.Group className="mb-3" style={{marginTop: -10}}>
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                        <Form.Control
                        className={classes.formControl}
                          type="text"
                          // placeholder="Enter Description of the Product"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  {/* </div>
                </div> */}


               
                    <Form.Group className="mb-3" style={{marginTop: -10}}>
                      <div className="d-flex align-items-left form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Unit Price</Form.Label>
                        <Form.Control
                        className={classes.formControl + ' ' + classes.alignRight}
                          type="text"
                          placeholder="0.00"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          onBlur={(e) => formatPrice(e.target.value)}
                        />
                      </div>
                    </Form.Group>

                    {selectedType?.value !== '1' && (
        <>
                <div className="row" style={{marginLeft: 12, marginTop: -10}}>
                  <div className="col-md-12 col-lg-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-left form-group-custom" style={{marginLeft: -5}}>
                        
                        <Form.Label className={classes.formLabelCustom} >UOM</Form.Label>
                        <div className={classes.formControl}>
                        <Select loading={isLoading} options={options} dropdownHeight='100px' style={{ }}
                       placeholder="Select " onChange={setUnit} value={unit}
                       />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12 col-lg-6" >
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-left form-group-custom" >
                        <Form.Label className={classes.formLabelCustom} style={{ borderRadius: 5, textAlign: 'right' }}>Category</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={options1} style={{ borderRadius: 5, width: '99%' }}
                       placeholder="Select Category" onChange={setSelectedCategory} value={selectedCategory}
                       />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                
                <div className="row" style={{marginLeft: 12, marginTop: -10}}>
                  <div className="col-md-12 col-lg-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-left form-group-custom">
                        <Form.Label className={classes.formLabelCustomsdf}>Quantity in Stock</Form.Label>
                        <Form.Control
                          className={classes.formControl}
                          type="text"
                          // placeholder="0.00"
                          value={stockBalance}
                          onChange={(e) => setStockBalance(e.target.value)}
                          
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12 col-lg-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className="d-flex align-items-center form-group-custom">
                          <Form.Label className={classes.formLabelCustom}>Re-Order Level</Form.Label>
                          <Form.Control
                          className={classes.formControl}
                            type="text"
                            // placeholder="Enter Product's Name"
                            value={reOderLevel}
                            onChange={(e) => setReOrderLevel(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                  </div>
                </div>
                </>
      )}
                <div className="row" style={{marginLeft: 12, marginTop: -10}}>
                  <div className="col-md-12 col-lg-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom" style={{marginLeft: -5}}>
                        <Form.Label className={classes.formLabelCustom} >Sales GL</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={salesOptions} style={{borderRadius: 5, marginLeft: -4}}
                          onChange={setSelectedSales} value={selectedSales} placeholder="Select Sales Account"
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom" style={{ width: '100%' }}>
                        <Form.Label className={classes.formLabelCustom}>Purchase GL</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} value={selectedPurchase}  dropdownHeight='100px' options={purchaseOptions} style={{ borderRadius: 5, width: '98%' }}
                        className={classes.formSelect}
                          onChange={setSelectedPurchase}
                        placeholder="Select Purchases "
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row" style={{marginLeft: -10, marginTop: -10}}>
                  <div className="col-md-12 col-lg-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom" >
                        <Form.Label className={classes.formLabelCustom} >A/c Receivables</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={acReceivables} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                          onChange={setSelectedAccount} value={selectedAccount}  placeholder="Select Receivables"
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom" style={{ width: '100%' }}>
                        <Form.Label className={classes.formLabelCustom} style={{ marginLeft: 10 }}>A/c Payables</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading}  dropdownHeight='100px' options={payableOptions} style={{ borderRadius: 5, width: '100%'}}
                        className={classes.formSelect}
                          onChange={setSelectedPayable}
                          value={selectedPayable}
                        placeholder="Select Payables"
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
               
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{marginTop: -10}}>
                      <div className="d-flex align-items-center form-group-custom" style={{marginLeft: -14}}>
                        <Form.Label className={classes.formLabelCustom} >Advance Payment</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={advanceOptions} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                          onChange={setSelectedAdvance} value={selectedAdvance}  placeholder="Select Advance"
                        />
                      </div>
                      </div>
                    </Form.Group>
                   
              </Form>
          </div>
        <div className={`${classes.buttonsGroup} ${classes.buttonsGroupMin}`}>
            <Button  variant="success" onClick={createUser}>
                      {loading ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Adding Product, Please wait...</span>
                        </>
                      ) : (
                        "Add Product/Services"
                      )}
              </Button>
          </div>

        






          </div>
        </div>

      </div>






    </div >
  )
}