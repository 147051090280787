import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestDash from '../LatestDashboard/LatestDash';
import MobileMenu from '../../Component/Mobile/MobileNav';






function IncomeRevenue() {
    const [entriesPerPage, setEntriesPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [fetchingLoading, setFetchingLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [tableData, setTableData] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [inputss, setInputss] = useState([]);
    const [allAccounts, setAllAccounts] = useState([]);
    const [ledgTableData, setLedgTableData] = useState([]);
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [totalReceipts, setTotalReceipts] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [totalEntries, setTotalEntries] = useState("");
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    const [userTypes, setUserTypes] = useState('');
    const [admin, setAdmin] = useState('');
    const [openNav,setOpenNav] = useState(false);
    //   const [province, setProvince] = useState('');
    //   const [continent, setContinent] = useState('');


    // const filteredData = accounts.filter(item => item.details.toLowerCase().includes(searchTerm.toLowerCase()));

    // const totalPages = Math.ceil(filteredData.length / entriesPerPage);


    // const fetchBankss = async () => {
    //     setIsLoading(true);
    //     try {
    //         const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
    //         const results = response.data?.data;

    //         setTableData(results);
    //         // console.log(results);
    //     } catch (error) {
    //         if (error.response && error.response.status === 401) {

    //             navigate('/login');
    //         } else {
    //             const errorStatus = error.response?.data?.message;
    //             console.log(errorStatus);
    //             setTableData([]);
    //         }
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };


    const fetchReceipt = async () => {
        setLoad(true);
        try {
            const response = await axios.get(`${BASE_URL}/fetch-receipts?page=${currentPage}`, { headers });
            const results = response.data?.data?.data;
            const resultx = response.data?.data?.total;
            setTotalReceipts(resultx);
            setLedgTableData(results);
            const total = response.data?.data?.last_page || 1;
            setTotalPages(total);
            console.log("fetched Receipts:", results);
            // toast.success(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            } else {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response && error.response.data && error.response.data.message) {
                    if (typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data.message)) {
                        errorMessage = error.response.data.message.join('; ');
                    } else if (typeof error.response.data.message === 'object') {
                        toast.error(errorMessage)
                        console.log(errorMessage);
                    }
                }
                setTableData([]);
            }
        } finally {
            setLoad(false);
        }
    };



    useEffect(() => {
        if (bearer) {
            fetchReceipt();

        }
    }, [bearer, currentPage]);




    const handlePrint = (id) => {
        const selectedLedger = ledgTableData.find(item => item.id === id);


        navigate('/cash_management/reports/revenue_receipt2', { state: { selectedLedger } });
    };


    const fetchAccountss = async () => {
        setLoadings(true);

        try {
            const response = await axios.get(`${BASE_URL}/account`, { headers });

            const allAccts = response.data?.data;
            const opt1 = allAccts.map((item) => ({
                label: item.gl_name,
                value: item.id,
            }));
            setAllAccounts(opt1);

            //   console.log(allAccts, "All Accounts");
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoadings(false);
        }
    };

    useEffect(() => {
        fetchAccountss();
    }, [bearer]);

    // const handleDateChange1 = (event) => {
    //     setSelectedEndDate(event.target.value);
    // };

    // const handleDateChange = (event) => {
    //     setSelectedDate(event.target.value);
    // };


    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };



    // const filteredData = ledgTableData.filter(item => item.particular.includes(searchTerm));

    const startIndexx = (currentPage - 1) * entriesPerPage + 1;
    const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
    // const displayedData = filteredData.slice(currentPage - 1, totalEntries);


    const handleDateChange1 = (event) => {
        setSelectedEndDate(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    useEffect(() => {
        if (accounts) {
            const debitTotal = accounts.reduce((total, item) => total + parseFloat(item.debit), 0);
            const creditTotal = accounts.reduce((total, item) => total + parseFloat(item.credit), 0);

            // Format the numbers with commas and two decimal places
            const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            setTotalDebit(formattedDebitTotal);
            setTotalCredit(formattedCreditTotal);
        }
    }, [accounts]);


    const fetchAccounts = async () => {
        setFetchingLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/reports/searchReceiptByCode`, {
                params: {
                    credit_gl_code: selectedBank,
                    start_date: selectedDate,
                    end_date: selectedEndDate
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearer}`
                }
            });

            const resultsss = response.data?.data?.receipt;
            setAccounts(resultsss);

            const resultssx = response.data?.data?.input;
            setInputss(resultssx);

            console.log(resultsss);

            if (resultsss?.length > 0) {
                navigate('/cash_management/print_income', {
                    state: {
                        document: resultsss,
                        inputs: resultssx
                    }
                });
            } else {
                toast.error('No data for the selected account');
            }
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
            console.log(errorMessage);
        } finally {
            setFetchingLoading(false);
        }
    };

    // useEffect(() => {
    //     if (bearer) {
    //         fetchBankss();

    //     }
    // }, [bearer]);


    const handleBank = (selectedOption) => {
        setSelectedBank(selectedOption.value);
        // const currentDate = new Date();
        // const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        // setSelectedDate(firstDayOfMonth.toISOString().split('T')[0]);

        // setSelectedEndDate(currentDate.toISOString().split('T')[0]);

        // fetchAccounts();
    };

    console.log(selectedBank);


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            const value2 = await AsyncStorage.getItem('admin');
            const admins = await AsyncStorage.getItem('admin');

            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (value2 !== null) {
                setAdmin(value2);
            }

            if (admins !== null) {
                setUserTypes(admins);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();

    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };



    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
        return formattedDate;
    }

    function padZero(num) {
        return num < 10 ? `0${num}` : num;
    }



    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
        return formattedDate;
    }

    function padZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    //   const filteredData = ledgTableData.filter(item => {
    //     const searchFields = [item.narration, item.transaction_date, formatDate(item.created_at)];
    //     return searchFields.some(field => field.toLowerCase().includes(searchTerm.toLowerCase()));
    //   });

    const formattedTotalEntries = totalEntries.toLocaleString();

    const handleCreate = () => {
        navigate('/cash_management/create_income_&_revenue');
    }


  return (

    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>
                  {/* <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                        <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''}
</p>
                            <h3>Cashbook</h3>
                            <small>Create and view your loan accounts...</small>
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                            <p style={{marginTop: -10}}>{admin}</p>
                            <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p>

                        </div>
                    </div> */}
                </div>

                <div className={classes.finishedbodyCont}>
                <h3 style={{fontSize: '30px', fontWeight: 700, marginLeft:'20px' }}>Cash Management</h3>
                <p style={{ margin: " 0 10px 20px 10px" }}>Process, Add, Print, and Delete Cash Report</p>

                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>Cash Book</h3> */}
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont} ${classes.awrow}`}>
                        <div  className={`card-body ${classes.awrow}`} style={{ border: 'none' }}>


                          <div className={`row ${classes.awrow}`}>
                            {/* Super Admin sees everything */}
                            {/* {admin === 'Super Admin' && (
        <>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Continent:</label>
              <div className="col-sm-9">
                <Select
                  onChange={(selectedOption) => handleContinentChange(selectedOption)}
                  options={selectOptions}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      maxWidth: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
              <div className="col-sm-9">
                <Select
                  onChange={handleRegionChange}
                  options={tableData3}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      maxWidth: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
              <div className="col-sm-9">
                <Select
                  onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                  options={tableData45}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )} */}

                            {/* Continental Accountant sees region and province */}
                            {/* {admin === 'Continental Accountant' && (
        <>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
              <div className="col-sm-9">
                <Select
                  onChange={handleRegionChange}
                  options={tableData3}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      maxWidth: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
              <div className="col-sm-9">
                <Select
                  onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                  options={tableData4}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )} */}

                            {/* Regional Accountant sees only province */}
                            {/* {admin === 'Regional Accountant' && (
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                options={tableData45}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      )} */}

                            {/* Provincial Accountant sees only accounts and date fields */}
                            {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant' || admin === 'Provincial Accountant') && ( */}
                            <div className={`row ${classes.awrow}`} style={{ marginTop: 10 }}>
                              <div className="col-md-4">
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className="col-sm-5 col-form-label font-weight-400">Accounts:</label>
                                  <div className={`col-sm-8  ${classes.lblrow}`} >
                                    <Select
                                      onChange={(selectedOption) => handleBank(selectedOption)}
                                      options={allAccounts}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 ${classes.lblrowinpt}`}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Start Date:</label>
                                  <div className="col-sm-8">
                                    <input className={`form-control  ${classes.lblroww}`} required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 ${classes.lblrowinpt}`}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>End Date:</label>
                                  <div className={`col-sm-8  ${classes.lblrow}`}>
                                    <input className={`form-control  ${classes.lblroww}`} required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                  </div>
                                </div>
                              </div>
                              {/* <div class={`modal-footer ${classes.yeatStyles}`} > */}

                              <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to process cash book between the selected dates</Tooltip>}
                              >
                               <Button
                               variant='success'
                               className={classes.overlayBtnTin}
style={{  height: 40, marginLeft: -100, width: 100, marginTop: 30,  }}  onClick={fetchAccounts}>
{fetchingLoading ? (
    <>
        <Spinner size='sm' />
        <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
    </>
) : (
    "Process"
)}
</Button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                    placement="top"
                                    className={classes.btndf1}
                                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add income</Tooltip>}
                                >
                                    <Button
                                    className={classes.btn1}
                                        style={{  height: 40,  width: 200, marginLeft: 10, marginTop: 30  }} variant='success' onClick={handleCreate}>
                                        Add Income
                                    </Button>
                                </OverlayTrigger>

                              {/* </div> */}
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      {/* <div style={{ marginTop: 10 }} /> */}



                      {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant') && ( */}
                      {/* <>
          <Button style={{ borderRadius: 0 }} variant='primary' onClick={fetchConsolidated}
          disabled={!selectedEndDate || !selectedAccount || !selectedDate}
          >

              {consolidatedLoading ? (
                  <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                  </>
              ) : (
                  "Print Consolidated Report"
              )}
          </Button>
      </> */}
                      {/* )} */}


                    </div>















                    <div style={{ backgroundColor: 'white', padding: '0 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="content-header row align-items-center m-0">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                      }}
                      className={classes.actionBtns}
                    >
                      <Button variant="success" onClick={handleCreate}>
                        Create New Accounts
                      </Button>
                    </div>

                  </nav> */}
                        {/* )} */}

                        <div className="col-sm-8 header-title p-0">
                          <div className="media">
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                      <div className="card">


                      </div>
                    </div> */}




                          

                          <div className="col-lg-12">
                            <div className="card">
                            {ledgTableData.length > 0 && (
                                        <button onClick={() => navigate('/cash_management/print_income', { state: { document: ledgTableData, } })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#2d995f", color: "#fff", marginBottom: 10, marginTop: 10, marginLeft: 10}}>Print Report</button>
                                      )}
                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '0px 0 0 0', marginBottom: 0 }}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                    <div>
                          
                                      <button  style={{ height: 30,  borderRadius: 0, backgroundColor: "#2d995f", color: "#fff", marginBottom: 10, marginTop: 10,}}>Copy</button>
                                      <button  style={{ height: 30,  borderRadius: 0, backgroundColor: "#2d995f", color: "#fff", marginBottom: 10, marginTop: 10,}}>Excel</button>
                                      <button  style={{ height: 30,  borderRadius: 0, backgroundColor: "#2d995f", color: "#fff", marginBottom: 10, marginTop: 10,}}>PDF</button>
                                      <button  style={{ height: 30, width: 150,  borderRadius: 0, backgroundColor: "#2d995f", color: "#fff", marginBottom: 10, marginTop: 10,}}>Column Visibility</button>
                          </div>
                                      {/* <div className={classes.actionsBtns}>
                                        <button>Copy</button>
                                        <button>Excel</button>
                                        <button>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div> */}
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                  <div className={classes.mainTable}>
                                  {load ? (
                                    <p>Fetching accounts...</p>
                                  ) : (
                                    <div className="table-responsive">
                                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                      <thead style={{ whiteSpace: 'nowrap' }}>
                                                        <tr>
                                                            <th>S/N</th>
                                                            <th>Transaction Date</th>
                                                            <th>Particulars</th>
                                                            <th>Description</th>
                                                            <th>Lodged Status</th>
                                                            <th>Lodged Date</th>
                                                            <th>Lodged Bank</th>
                                                            <th>Mode</th>
                                                            <th>Teller Number</th>
                                                            <th>Received By</th>
                                                            <th>Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ whiteSpace: 'nowrap' }}>
                                                        {ledgTableData.length > 0 ? (
                                                            ledgTableData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.transaction_date}</td>
                                                                    <td>{item.particular}</td>
                                                                    <td style={{whiteSpace: "wrap"}}>{item?.description}</td>
                                                                    <td style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                          {item.bank_lodged === null ? (
                                            <div style={{height: 15, width: 100,  border: "1px solid #ff5400", borderRadius: 3, color: '#ff5400', fontSize: 10, textAlign: "center", fontWeight: 700}}>NOT LODGED</div>
                                            
                                          ) : (
                                            <div style={{height: 15, width: 50,  border: "1px solid #50b848", borderRadius: 3, color: "#50b848", fontSize: 10, textAlign: "center", fontWeight: 700}}>LODGED</div>
                                              )}
                                          </td>
                                                                    <td>{item?.date_logded}</td>
                                                                    <td >{item?.bank_lodged?.gl_name}</td>
                                                                    <td>{item?.mode?.name}</td>
                                                                    <td>{item?.teller_number}</td>
                                                                    <td>{item?.user?.name}</td>
                                                                    <td style={{ textAlign: "right" }}>{parseFloat(item.amount).toLocaleString('en-US', {
                                                                        minimumIntegerDigits: 1,
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })}
                                                                    </td>
                                                                    <td>

                                                                        {/* <OverlayTrigger
                                                                            placement="top"
                                                                            className={classes.btndf1}
                                                                            overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click to here view cash</Tooltip>}
                                                                        >
                                                                            <div className="mr-1 btn btn-success-soft btn-sm">
                                                                                <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                                                            </div>
                                                                        </OverlayTrigger>

                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            className={classes.btndf1}
                                                                            overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click to here delete cash row</Tooltip>}
                                                                        >
                                                                            <div className="btn btn-danger-soft btn-sm">
                                                                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                                                            </div>
                                                                        </OverlayTrigger> */}

                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            className={classes.btndf1}
                                                                            overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click to here print row</Tooltip>}
                                                                        >
                                                                            <div className="btn btn-success-soft btn-sm mr-1" onClick={() => handlePrint(item.id)}>
                                                                                <i className="fa fa-print" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="10" style={{ textAlign: 'center' }}>No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                      </table>
                                    </div>
                                  )}
                                  </div>
                                  <div className={classes.endded}>
                                    <p>
                                      Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                      <button
                                        style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                      >
                                        Previous
                                      </button>
                                      {[...Array(totalPages)].map((_, page) => {
                                        // Show only 5 pages or less if available
                                        if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                          return (
                                            <button
                                              key={page + 1}
                                              style={{
                                                marginLeft: '0.4rem',
                                                marginRight: '0.4rem',
                                                fontSize: '14px',
                                                fontFamily: 'nunito',
                                                fontWeight: 400,
                                                color: page + 1 === currentPage ? '#ffffff' : '#000',
                                                backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                                height: '2.5rem',
                                                borderRadius: '89px',
                                                padding: '0.5rem',
                                                border: 'none',
                                                width: '40px',
                                                cursor: "pointer"
                                              }}
                                              onClick={() => setCurrentPage(page + 1)}
                                            >
                                              {page + 1}
                                            </button>
                                          );
                                        }
                                        return null;
                                      })}
                                      <button
                                        style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default IncomeRevenue;