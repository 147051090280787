import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import CurrencyInput from 'react-currency-input-field';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestAdminDash from '../LatestDashboard/LatestAdminDash';



export default function AdminCharts3() {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [glname, setGlname] = useState("");
  const [balance, setBalance] = useState("");
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSubCategory1, setSelectedSubCategory1] = useState('');
  const [glname1, setGlname1] = useState("");
  const [balance1, setBalance1] = useState("");
  const [selectedDirection1, setSelectedDirection1] = useState("");
  const [selectedDate1, setSelectedDate1] = useState('');
  const [selectedChart, setSelectedChart] = useState('')
  const [tableData2, setTableData2] = useState([]);
  const [glcode, setGlcode] = useState("");
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const { slctdChart } = location.state || {};
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-subcategories-by-categories-id?category_id=${slctdChart}`, { headers });
      const results = response.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

 



  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);

  

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleView = (id) => {

    const foundCharts = tableData.find(item => item.id === id);
    const chartId = foundCharts.id;
    setSelectedChart(chartId)
    navigate('/admin/charts_of_accounts_view_subcategory', { state: {slctdChart: chartId} });
    
    setEyeClicked(true);
  };


const navGoBack = () => {
  navigate(-1);
}

const handleTrashClick = async (id) => {
  const confirmed = await Swal.fire({
    title: 'Are you sure?',
    text: 'You are about to delete this account.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel',
  });

  if (!confirmed.isConfirmed) {
    return; // User canceled, do nothing
  }

  try {
    const response = await axios.get(`${BASE_URL}/delete-subcategory?id=${id}`, { headers });
    fetchData();
    toast.success(response.data.message);
    setTrashClicked(true);
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response && error.response.data && error.response.data.message) {
      if (typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
        errorMessage = error.response.data.message.join('; ');
      } else if (typeof error.response.data.message === 'object') {
        errorMessage = JSON.stringify(error.response.data.message);
      }
      toast.error(errorMessage)
      console.log(errorMessage);
    }
  }
};

const handleEyeClick = (id) => {

  const foundCharts = tableData.find(item => item.id === id);
  const chartId = foundCharts.id;
  setSelectedChart(chartId)

  const { description } = foundCharts;
  setDescription1(description || '');
  // const selectedRole = roles.length > 0 ? roles[0].id : '';
  // setSelectedRole(selectedRole);

  setShow1(true);
  setEyeClicked(true);
};
useEffect(() => {

}, { selectedChart }

)

const createAccount = async () => {
  setLoading(true);
  try {
    const response = await axios.post(
      `${BASE_URL}/add-subcategory`,
      {
        category_id: slctdChart,
        description: description,
      },
      { headers }
    );
    console.log(response)
    fetchData();
    handleClose();
    setDescription('');
    // return
    toast.success(response.data.message);
    console.log(response.data);

  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response && error.response.data && error.response.data.message) {
      if (typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
        errorMessage = error.response.data.message.join('; ');
      } else if (typeof error.response.data.message === 'object') {
        errorMessage = JSON.stringify(error.response.data.message);
      }
      toast.error(errorMessage);
      console.log(error);
    }
  } finally {
    setLoading(false);
  }
};

const updateAccount = async () => {
  setLoading(true);
  try {
    const response = await axios.post(
      `${BASE_URL}/update-subcategory`,
      {
        id: selectedChart,
        description: description1,
      },
      { headers }
    );
    console.log(response)
    fetchData();
    handleClose1();
    // setDescription('');
    // return
    toast.success(response.data.message);
    console.log(response.data);

  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response && error.response.data && error.response.data.message) {
      if (typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
        errorMessage = error.response.data.message.join('; ');
      } else if (typeof error.response.data.message === 'object') {
        errorMessage = JSON.stringify(error.response.data.message);
      }
      toast.error(errorMessage);
      console.log(error);
    }
  } finally {
    setLoading(false);
  }
};


  return (
    <div>
      <ToastContainer />
      {/* <MainDashoard /> */}

      <div className={classes.finishedbodyCont}>
        <div >
          <LatestAdminDash/>
        </div>

          <div className={classes.topSpace}>
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
              <div>
              <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  15px" }}>Chart of Account</h3>
              <p style={{margin:" 0 10px 20px 15px" }}>View Charts of Account</p>
              </div>
              <div className={classes.formIntBtn} >
                    <Button variant="success" onClick={handleShow} className={classes.btn1} style={{marginRight:'10px'}}>Add New</Button>
                    <Button variant="success" onClick={navGoBack} className={classes.btn2}>Back</Button>
                  </div>
            </div>
         
            <div className={classes.mainform}>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Create Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label style={{ marginTop: 20 }}>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />

                    </Form.Group>
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Go back
                  </Button>
                  <Button variant="success" onClick={createAccount}>
                    {loading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>Creating account, Please wait...</span>
                      </>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={show1} onHide={handleClose1} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label style={{ marginTop: 20 }}>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        value={description1}
                        onChange={(e) => setDescription1(e.target.value)}
                      />

                    

                      

                     
                      
                     

                    </Form.Group>
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose1}>
                    Go back
                  </Button>
                  {/* <Button variant="success" onClick={editUser}> */}
                  <Button variant="success" onClick={updateAccount}>
                  {loading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>Updating account, Please wait...</span>
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className={classes.loandgrantcards}>

                <div className={classes.loandethead}>
                  <div className={classes.formLabel}>
                  </div>
                  {/* <div className={classes.formIntBtn}>
                  <Button variant="success" onClick={handleShow} className={classes.btn1}>Add New</Button>
                    <Button variant="success" onClick={navGoBack} className={classes.btn2}>Back</Button>
                  </div> */}
                </div>
                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                  <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                    <div className={classes.actionsBtns}>
                      <Button variant='success'>Copy</Button>
                      <Button variant='success'>Excel</Button>
                      <Button variant='success'>PDF</Button>
                      <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                    </div>
                  </div>
                    <div className={classes.show}>
                      <label className="d-flex justify-content-start align-items-center">
                        Show
                        <select style={{width: 50}} name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                        //  value={entriesPerPage}
                        //     onChange={(e) => {
                        //     setEntriesPerPage(parseInt(e.target.value));
                        //     setCurrentPage(1);
                        //     }}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                            // setCurrentPage(1);
                          }}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div style={{marginTop: 5}}/>
                <div className={classes.mainTable}>
                  {roleLoading ? (
                    <p>Fetching charts...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>S/N</th>
                            <th>Description</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap', }}>
                          {displayedData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.description}</td>
                              <td style={{ textAlign: "left" }}>{formatDate(item.created_at)}</td>
                              <td>{formatDate(item.updated_at)}</td>
                              <td style={{display: "flex", flexDirection: "row"}}>
                              <div onClick={() => handleView(item.id)} style={{height: 15, width: 50,  border: "1px solid #50b848", borderRadius: 3, color: "#50b848", fontSize: 10, textAlign: "center", fontWeight: 700, cursor: "pointer"}}>View</div>
                              <div
                                    onClick={() => handleEyeClick(item.id)}
                                    className="btn btn-success-soft btn-sm mr-1"
                                    style={{ padding: '0 10px' }}
                                  >
                                    <i
                                      className="fas fa-pencil-alt"
                                      style={{
                                        color: "#008a4b",
                                        backgroundColor: "#28a7451a",
                                        padding: 2,
                                        borderColor: "#28a7454d",
                                        borderRadius: 5,
                                        fontSize: 12
                                      }}
                                    ></i>
                                  </div>
                                  <div
                                    onClick={() => handleTrashClick(item.id)}
                                    className="btn btn-danger-soft btn-sm"
                                    style={{ padding: '0' }}
                                  >
                                    <i
                                      className="far fa-trash-alt"
                                      style={{
                                        color: "#dc3545",
                                        backgroundColor: "#dc35451a",
                                        padding: 2,
                                        borderColor: "#dc35454d",
                                        borderRadius: 5,
                                        fontSize: 12
                                      }}
                                    ></i>
                                  </div>
                              </td>
                            </tr>
                                ))}
                              </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className={classes.endded}>
                  <p>
                    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                  </p>
                  <div style={{ display: 'flex' }}>
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      // Show only 5 pages or less if available
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

      </div>






    </div >
  )
}