import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './Suppliers.module.css'
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import MainDashoard from '../Main Dashboard/MainDashoard';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MobileMenu from '../../Component/Mobile/MobileNav';



function SupplierPayablesAccounting() {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permittedHeaders, setPermittedHeaders] = useState([]);
  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [beneficiaries, setBeneficiaries] = useState('');
  const [user, setUser] = useState('');

  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [continent, setContinent] = useState('');
  const [admin, setAdmin] = useState('');
  const [bankName, setBankName] = useState('');
  const [accName, setAccName] = useState('');
  const [accNo, setAccNo] = useState('');
  const [openNav,setOpenNav] = useState(false);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  // specify header
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  //fetch records
  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });


      const results = response.data?.data;
      setTableData(results);
      console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();

    }
  }, [bearer]);

  //create beneficiary
  const createBeneficiary = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/beneficiary/add`,
        {
          name: fullName,
          email: email,
          phone_number: phone,
          address: address,
          bank_name: bankName,
          account_name: accName,
          account_number: accNo
        },
        { headers }
      );
      console.log(response.data.message);
      fetchBeneficiaries();
      handleClose();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data.message);

    } catch (error) {
      let errorMessage;

      // Attempt to extract the error message
      if (error.response && error.response.data) {
        // If message is an object, attempt to stringify it
        if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        } else {
          errorMessage = error.response.data.message;
        }
      } else if (error.message) {
        errorMessage = error.message;
      } else {
        errorMessage = "An unexpected error occurred";
      }

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  //view records
  //   const handleEyeClick = (id) => {
  //     const foundUser = tableData.find(item => item.id === id);

  //     if (foundUser) {
  //         const { name, email, phone_number,  } = foundUser;
  //         setSelectedUser(id);
  //         setFullName1(name || '');
  //         setEmail1(email || '');
  //         setPhone1(phone_number || '');
  //         setAddress(address || '');



  //         setShow1(true);
  //         setEyeClicked(true);
  //     } else {
  //         console.error(`User with id ${id} not found`);
  //     }
  // };
  const handleEyeClick = (id) => {
    const foundCustomer = tableData.find(item => item.id === id);
    navigate('/payables/update_supplier_details', { state: { selectedCustomer: foundCustomer } });
    setEyeClicked(true);
  };


  //delete function
  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this member.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary/delete?id=${id}`, { headers });
      fetchBeneficiaries();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      toast.success(response.data.message);

      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };
  // };

  //update function
  const editUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}/admin/users/update`,
        {
          name: fullName1,
          // id: deptId, 
          email: email1,
          phone_number: phone1,
          // role: selectedRole
        },
        { headers }
      );

      fetchBeneficiaries();

      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };


  //filter function
  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  useEffect(() => {
    const retrieveAdminStatus = async () => {
      try {
        const permitted = await AsyncStorage.getItem('permissions');
        if (permitted) {
          setPermittedHeaders(permitted);

        }
      } catch (error) {
        console.error('Error retrieving admin status:', error);
      }
    };

    retrieveAdminStatus();
  }, []);


  return (

    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            {/* <LatestDash /> */}
            {/* <MainDashoard/> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div className={classes.topPadding}>
                  <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>
                  {/* <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                            <h3>Suppliers/Contractors</h3>
                            
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                            
                        </div>
                    </div> */}
                </div>

                <div className={classes.topSpace}>
                  {/* <h3 className={classes.productText1}>Manage Supplier</h3> */}
                  {/* <h3 style={{fontSize: 30, fontWeight: 700, }}>Manage Supplier</h3> */}
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Manage Suppliers</h3>
                      <p style={{ margin: " 0 10px 20px 15px" }}>Add, View, Edit and Delete Supplier</p>
                    </div>
                    <div className={`${classes.formIntBtn} ${classes.formIntBtnUnitss}`} style={{ zIndex: 1, }}>
                      <Button className={classes.btn1} variant="success" onClick={handleShow}>
                        Add New Supplier/Contractor
                      </Button>
                    </div>
                  </div>
                  <div style={{ backgroundColor: 'white', }}>
                    {/* <!--Content Header (Page header)--> */}
                    <div style={{ padding: 20 }}>
                      <div className="content-header row align-items-center m-0">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                      }}
                      className={classes.actionBtns}
                    >
                      <Button variant="success" onClick={handleCreate}>
                        Create New Accounts
                      </Button>
                    </div>

                  </nav> */}

                        {/* )} */}

                        <div className="col-sm-8 header-title p-0">
                          <div className="media">
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">

                        <Modal show={show} onHide={handleClose} animation={false}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add New Supplier/Contractor</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form style={{ marginTop: 20 }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Full Name"
                                  // autoFocus
                                  value={fullName}
                                  onChange={(e) => setFullName(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Email Address"
                                  // autoFocus
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter Phone Number"
                                  // autoFocus
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Address"
                                  // autoFocus
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />

                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Supplier's Bank Name"
                                  // autoFocus
                                  value={bankName}
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Account Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Supplier's Account Name"
                                  // autoFocus
                                  value={accName}
                                  onChange={(e) => setAccName(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Account Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Supplier's Account Number"
                                  // autoFocus
                                  value={accNo}
                                  onChange={(e) => setAccNo(e.target.value)}
                                />


                              </Form.Group>
                            </Form>
                          </Modal.Body>

                          <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                              Go back
                            </Button>
                            <Button variant="success" onClick={createBeneficiary}>
                              {loading ? (
                                <>
                                  <Spinner size='sm' />
                                  <span style={{ marginLeft: '5px' }}>Creating Beneficiary, Please wait...</span>
                                </>
                              ) : (
                                "Create Beneficiary"
                              )}
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        <div className="row">

                          <div className="col-lg-12" style={{ padding: '20px' }}>
                            <div>
                              <div className="card-body" >
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                      <div className={classes.actionsBtns1}>
                                        <button>Copy</button>
                                        <button>Excel</button>
                                        <button>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div>
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ marginTop: 20 }} />
                                  <div className={classes.mainTable}>
                                    {isLoading ? (
                                      <p>Fetching Suppliers...</p>
                                    ) : (
                                      <div className="table-responsive">
                                        <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                          <thead style={{ whiteSpace: 'nowrap' }}>
                                            <tr>
                                              <th>S/N</th>
                                              <th>Name</th>
                                              <th>Email</th>
                                              <th>Phone Number</th>
                                              {/* <th>Created at</th>
                                      <th>Updated by</th> */}
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody style={{ whiteSpace: 'nowrap' }}>
                                            {displayedData.map((item, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.phone_number}</td>
                                                {/* <td>{formatDate(item.created_at)}</td>
                                          <td>{formatDate(item.updated_at)}</td> */}
                                                <td style={{ textAlign: "left" }}>
                                                  <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1" style={{ padding: '2px 20px' }}>
                                                    <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                                  </div>
                                                  <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm" style={{ padding: '0' }}>
                                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </div>
                                  <div className={classes.endded}>
                                    <p>
                                      Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                      <button
                                        style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                      >
                                        Previous
                                      </button>
                                      {[...Array(totalPages)].map((_, page) => {
                                        // Show only 5 pages or less if available
                                        if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                          return (
                                            <button
                                              key={page + 1}
                                              style={{
                                                marginLeft: '0.4rem',
                                                marginRight: '0.4rem',
                                                fontSize: '14px',
                                                fontFamily: 'nunito',
                                                fontWeight: 400,
                                                color: page + 1 === currentPage ? '#ffffff' : '#000',
                                                backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                                height: '2.5rem',
                                                borderRadius: '89px',
                                                padding: '0.5rem',
                                                border: 'none',
                                                width: '40px',
                                                cursor: "pointer"
                                              }}
                                              onClick={() => setCurrentPage(page + 1)}
                                            >
                                              {page + 1}
                                            </button>
                                          );
                                        }
                                        return null;
                                      })}
                                      <button
                                        style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>

                                  <Modal show={show1} onHide={handleClose1} animation={false}>
                                    <Modal.Header closeButton>
                                      <Modal.Title>Edit Supplier/Contractor</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <Form style={{ marginTop: 20 }}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                          <Form.Label>Full Name</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Full Name"
                                            // autoFocus
                                            value={fullName1}
                                            onChange={(e) => setFullName1(e.target.value)}
                                          />
                                          <div style={{ marginTop: 10 }} />
                                          <Form.Label>Email Address</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Email Address"
                                            // autoFocus
                                            value={email1}
                                            onChange={(e) => setEmail1(e.target.value)}
                                          />
                                          <div style={{ marginTop: 10 }} />
                                          <Form.Label>Phone Number</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Phone Number"
                                            // autoFocus
                                            value={phone1}
                                            onChange={(e) => setPhone1(e.target.value)}
                                          />

                                        </Form.Group>
                                      </Form>
                                    </Modal.Body>






                                    <Modal.Footer>
                                      <Button variant="danger" onClick={handleClose1}>
                                        Go back
                                      </Button>
                                      <Button variant="success" onClick={editUser}>
                                        {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default SupplierPayablesAccounting;