import React, { useState, useEffect } from 'react';
import classes from './PrintIncomeStatement.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintBalance() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `Date Printed: ${day}/${month}/${year} `;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('companyName');
          const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
           const admins = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setCompany(value1);
         
          }
          if (continentName !== null) {
            setContinent(continentName);
        }
        if (regionName !== null) {
            setRegion(regionName);
        }
        if (provinceName !== null) {
            setProvince(provinceName);
        }
        if (admins !== null) {
          setUserType(admins);
      }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };

      useEffect(() => {
        readData();

    }, []);

    const { accounts, selectedEndDate, selectedDate } = location.state || {};
    const startDate = new Date(accounts.start);
    const endDate = new Date(accounts.end);

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
            ? 'th'
            : suffixes[v % 10] || 'th';
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };






    return (
        <div className={classes.generalbd}>
            <div className={classes.a4}>
                <div className={classes.header}>
                <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
        <p style={{ width: "100%", fontSize: 18 }}>{userType === "Continental Accountant" ? continent :
 userType === "Regional Accountant" ? region :
 userType === "Super Admin" ? "Super Admin" :
 userType === "Provincial Accountant" ? province : null}
</p>
                    <h6 style={{ fontWeight: 900 }}>STATEMENT OF FINANCIAL POSITION BETWEEN THE PERIOD OF {selectedDate} TO {selectedEndDate}</h6>
                </div>
                <p>{currentDateTime} </p>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', width: '200px' }} className={classes.th}>Description</th>

                            {/* <th></th> Empty cell for gap */}
                            {/* <th style={{ textAlign: 'right', width: '100px' }}>Debit</th> */}
                            {/* <th></th> Empty cell for gap */}
                            {/* <th style={{ textAlign: 'right', width: '100px' }}>Credit</th> */}
                            {/* <th style={{ textAlign: 'right', width: '100px' }}>Debit</th> */}
                            {/* <th></th> Empty cell for gap */}
                            {/* <th style={{ textAlign: 'right', width: '100px' }}>Credit</th> */}
                            {/* <th style={{ textAlign: 'right', width: '100px' }}>Debit</th> */}
                            {/* <th></th> Empty cell for gap */}
                            <th style={{ textAlign: 'right', width: '250px' }} className={classes.th}>Amount</th>
                        </tr>
                    </thead>


                    <tbody>


                    {accounts && accounts.map((item, index) => {
    // Calculate the sum of the total values of all categories for this item
    const totalItem = item.categories.reduce((sum, category) => sum + parseFloat(category.total), 0);

    return (
        <React.Fragment key={index}>
            <tr>
                <th colSpan={4}>{item.description.toUpperCase()}</th>
            </tr>
            {item.categories?.map((category, catIndex) => (
                <React.Fragment key={catIndex}>
                    <tr>
                        <th colSpan={4}>{category.description}</th>
                    </tr>
                    {category.postings.map((posting, postIndex) => (
                        <tr key={postIndex}>
                            <td style={{ paddingLeft: 20 }}>{posting.name}</td>
                            <td style={{ textAlign: "right" }}>
                                {parseFloat(posting.amount).toLocaleString('en-US', {
                                    minimumIntegerDigits: 1,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={1} style={{ fontWeight: 'bold' }} className={classes.totalTh}>
                            Total {category.description}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: 'bold' }} className={classes.totalTh}>
                            {parseFloat(category.total).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                    </tr>
                    <tr><td colSpan={4}>&nbsp;</td></tr>
                </React.Fragment>
            ))}
            <tr>
                <td colSpan={1} style={{ fontWeight: 'bold' }} className={classes.totalTh}>
                    TOTAL {item.description.toUpperCase()}
                </td>
                <td style={{ textAlign: "right", fontWeight: 'bold' }} className={classes.totalTh}>
                    {totalItem.toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </td>
            </tr>
            <tr><td colSpan={4}>&nbsp;</td></tr>
        </React.Fragment>
    );
})}


                    </tbody>
                </table>


            </div>
        </div>
    )
}
