import React, { useState, useEffect } from 'react';
import classes from './BankRecon.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
// import favicon from '../../Images/faviconn.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import CashDash from '../Cash Dash/CashDash';
import Select from 'react-select';
import PaymentDash from '../Payment Dashboard/PaymentDash';
import LatestDash from '../LatestDashboard/LatestDash';
import portal from "../../assets/nw/portal.svg";
import notification from "../../assets/nw/notification.svg";
import user from "../../assets/nw/manageUser.svg";
import modules from "../../assets/nw/modules.svg";
import currency from "../../assets/nw/currency.svg";
import down from "../../assets/nw/dropdown.svg";
import plus from "../../assets/nw/plus.svg";
import chart from "../../assets/nw/chart.svg"
import vector from "../../assets/nw/vector.svg";
import linear from "../../assets/nw/linear.svg";
import s from "../../assets/nw/delete.svg";
import downs from "../../assets/nw/down1.svg"
import { Tabs, Tab } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoChatbubblesSharp } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { ImCancelCircle } from "react-icons/im";
import { FaRegSave } from "react-icons/fa";
import { GrPowerCycle } from "react-icons/gr";
import MobileMenu from '../../Component/Mobile/MobileNav';




export default function BankReconciliation() {
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [selectedBankAc, setSelectedBankAc] = useState('');
  const [selectedBankName, setSelectedBankName] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [ledgTableData, setLedgTableData] = useState([]);
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [totalReceipts, setTotalReceipts] = useState("");
  // const [totalPages, setTotalPages] = useState(1);
  // const [totalEntries, setTotalEntries] = useState("");
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

  const [userTypes, setUserTypes] = useState('');
  const [admin, setAdmin] = useState('');
  const [openNav,setOpenNav] = useState(false);
  //   const [province, setProvince] = useState('');
  //   const [continent, setContinent] = useState('');

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
    setSelectedBankName(selectedOption.label);
    setSelectedBankAc(selectedOption.acNo);
  };

  // const filteredData = accounts.filter(item => item.details.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);


  // const fetchBankss = async () => {
  //     setIsLoading(true);
  //     try {
  //         const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
  //         const results = response.data?.data;

  //         setTableData(results);
  //         // console.log(results);
  //     } catch (error) {
  //         if (error.response && error.response.status === 401) {

  //             navigate('/login');
  //         } else {
  //             const errorStatus = error.response?.data?.message;
  //             console.log(errorStatus);
  //             setTableData([]);
  //         }
  //     } finally {
  //         setIsLoading(false);
  //     }
  // };

  const fetchExpenses = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-all-expenses`, { headers });

      // console.log(response);
      const results = response.data?.data;
      setTableData(results);
      console.log(results, "heree");
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchExpenses();
      //   fetchTotalExpenses();
    }
  }, [bearer]);


  //   const fetchTotalExpenses = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get('https://api-sme.promixaccounting.com/api/v1/total-expenses', { headers });
  //       const total = response.data?.data;

  //       setExpenses(total);
  //       // console.log(results);
  //     } catch (error) {
  //       const errorStatus = error.response?.data?.message;
  //       console.log(errorStatus);
  //       setExpenses([]);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };



  // const fetchReceipt = async () => {
  //     setLoad(true);
  //     try {
  //         const response = await axios.get(`${BASE_URL}/fetch-all-expenses?page=${currentPage}`, { headers });
  //         const results = response.data?.data?.data;
  //         const resultx = response.data?.data?.total;
  //         setTotalReceipts(resultx);
  //         setLedgTableData(results);
  //         const total = response.data?.data?.last_page || 1;
  //         setTotalPages(total);
  //         console.log("fetched Expenses:", results);
  //         // toast.success(response.data.message);
  //     } catch (error) {
  //         if (error.response && error.response.status === 401) {
  //             navigate('/login');
  //         } else {
  //             let errorMessage = 'An error occurred. Please try again.';
  //             if (error.response && error.response.data && error.response.data.message) {
  //                 if (typeof error.response.data.message === 'string') {
  //                     errorMessage = error.response.data.message;
  //                 } else if (Array.isArray(error.response.data.message)) {
  //                     errorMessage = error.response.data.message.join('; ');
  //                 } else if (typeof error.response.data.message === 'object') {
  //                     toast.error(errorMessage)
  //                     console.log(errorMessage);
  //                 }
  //             }
  //             setTableData([]);
  //         }
  //     } finally {
  //         setLoad(false);
  //     }
  // };



  // useEffect(() => {
  //     if (bearer) {
  //         fetchReceipt();

  //     }
  // }, [bearer, currentPage]);




  const handlePrint = (id) => {
    const selectedLedger = ledgTableData.find(item => item.id === id);


    navigate('/cash_management/reports/revenue_receipt2', { state: { selectedLedger } });
  };


  // const fetchAccountss = async () => {
  //     setLoadings(true);

  //     try {
  //         const response = await axios.get(`${BASE_URL}/account/no-pagination`, { headers });

  //         const allAccts = response.data?.data;
  //         const opt1 = allAccts.map((item) => ({
  //             label: item.gl_name,
  //             value: item.id,
  //         }));
  //         setAllAccounts(opt1);

  //         //   console.log(allAccts, "All Accounts");
  //     } catch (error) {
  //         const errorStatus = error.response.data.message;
  //         console.error(errorStatus);
  //     } finally {
  //         setLoadings(false);
  //     }
  // };

  // useEffect(() => {
  //     fetchAccountss();
  // }, [bearer]);

  // const handleDateChange1 = (event) => {
  //     setSelectedEndDate(event.target.value);
  // };

  // const handleDateChange = (event) => {
  //     setSelectedDate(event.target.value);
  // };


  // const handlePrevPage = () => {
  //     setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  // };

  // const handleNextPage = () => {
  //     setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  // };



  // const filteredData = ledgTableData.filter(item => item.particular.includes(searchTerm));

  // const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  // const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(currentPage - 1, totalEntries);


  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (accounts) {
      const debitTotal = accounts.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = accounts.reduce((total, item) => total + parseFloat(item.credit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [accounts]);


  // const fetchAccounts = async () => {
  //     setFetchingLoading(true);
  //     try {
  //         const response = await axios.get(`${BASE_URL}/reports/searchReceiptByCode`, {
  //             params: {
  //                 credit_gl_code: selectedBank,
  //                 start_date: selectedDate,
  //                 end_date: selectedEndDate
  //             },
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': `Bearer ${bearer}`
  //             }
  //         });

  //         const resultsss = response.data?.data?.receipt;
  //         setAccounts(resultsss);

  //         const resultssx = response.data?.data?.input;
  //         setInputss(resultssx);

  //         console.log(resultsss);

  //         if (resultsss?.length > 0) {
  //             navigate('/cash_management/print_income', {
  //                 state: {
  //                     document: resultsss,
  //                     inputs: resultssx
  //                 }
  //             });
  //         } else {
  //             toast.error('No data for the selected account');
  //         }
  //     } catch (error) {
  //         let errorMessage = 'An error occurred. Please try again.';
  //         if (error.response && error.response.data && error.response.data.message) {
  //             if (typeof error.response.data.message === 'string') {
  //                 errorMessage = error.response.data.message;
  //             } else if (Array.isArray(error.response.data.message)) {
  //                 errorMessage = error.response.data.message.join('; ');
  //             } else if (typeof error.response.data.message === 'object') {
  //                 errorMessage = JSON.stringify(error.response.data.message);
  //             }
  //         }
  //         toast.error(errorMessage);
  //         console.log(errorMessage);
  //     } finally {
  //         setFetchingLoading(false);
  //     }
  // };

  // useEffect(() => {
  //     if (bearer) {
  //         fetchBankss();

  //     }
  // }, [bearer]);


  const handleBank = (selectedOption) => {
    setSelectedBank(selectedOption.value);
    // const currentDate = new Date();
    // const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // setSelectedDate(firstDayOfMonth.toISOString().split('T')[0]);

    // setSelectedEndDate(currentDate.toISOString().split('T')[0]);

    // fetchAccounts();
  };

  console.log(selectedBank);


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();

  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  //   const filteredData = ledgTableData.filter(item => {
  //     const searchFields = [item.narration, item.transaction_date, formatDate(item.created_at)];
  //     return searchFields.some(field => field.toLowerCase().includes(searchTerm.toLowerCase()));
  //   });



  const handleCreate = () => {
    navigate('/payment/create_expenses');
  }



  const filteredData = tableData.filter(item => 
    item.particulars && item.particulars.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const formattedTotalEntries = totalEntries.toLocaleString();

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
      const debitAcct = response.data?.data;

      const options1 = debitAcct.map((item) => ({
        label: item.gl_name,
        value: item.id,
        acNo: item.gl_code,
      }));

      setTableData1(debitAcct);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchDebit();
    }
  }, [bearer]);

  return (
    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
      <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>

      <div className={classes.finishedbodyCont}>



        <div className={classes.mainasdflkj}>
          <div className={classes.leftasdflkj}>
            <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 0 10px 0  15px" }}>Bank Reconciliation</h3>
            <p style={{ margin: " 0 10px 20px 15px" }}>Reconcile Bank Transactions</p>
          </div>
          <div className={classes.leftasdflkj1}>
          <div className="form-group row" style={{ margin: " 10px 0 0  0" }}>
                <label for="example-text-input" className={`col-sm-4 col-form-label font-weight-400 ${classes.mbllbl}`}>Select Bank:</label>
                <div className="col-sm-1" >
                  <div style={{ width: 300 }}>
                    <Select

                      options={selectOptions1}
                      placeholder='Select Bank'
                      onChange={handleBankChange}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (provided) => ({
                          ...provided,
                          maxHeight: '200px',
                          // maxWidth: '400px',
                          overflowY: 'auto',
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
          </div>
        </div>
          <div className={classes.iconsStyle}>
              <IoMdCheckmark style={{ color: 'green', cursor: "pointer" }} size={25} />
              <ImCancelCircle style={{ color: 'red', cursor: "pointer" }} size={25} />
              <FaRegSave size={25} style={{ cursor: "pointer" }} />
              <GrPowerCycle style={{ color: 'orange', cursor: "pointer" }} size={25} />
            </div>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>
            {/* <div className={`col-md-4 ${classes.mblrow}`}>
              <div className="form-group row">
                <label for="example-text-input" className={`col-sm-4 col-form-label font-weight-400 ${classes.mbllbl}`} style={{ textAlign: "right" }}>Select Bank:</label>
                <div className="col-sm-1" >
                  <div style={{ width: 300 }}>
                    <Select

                      options={selectOptions1}
                      placeholder='Select Bank'
                      onChange={handleBankChange}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (provided) => ({
                          ...provided,
                          maxHeight: '200px',
                          // maxWidth: '400px',
                          overflowY: 'auto',
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div> */}

            <div className={classes.tab1}>

              <div>
                <div className={classes.ledgerTop}>
                  <h4 className={classes.ledgerText}>Ledger (Balance : 0.00)</h4>
                  <p className={classes.ledgerMatched}>111 0f 111 matched</p>
                </div>
                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} >
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          // value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                        // onChange={(e) => {
                        // setSearchTerm(e.target.value);


                        // }}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>

                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>Post Date</th>
                          <th>Validate</th>
                          <th >Details</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap', }}>
                        <tr >
                          <td ><input type='checkbox' className={classes.inPutTextBox} /></td>
                          <td ><IoChatbubblesSharp /> <FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                          <td style={{ fontSize: 12 }}>4 Jun. 2018</td>
                          <td style={{ fontSize: 12 }}>4 Jun. 2018</td>
                          <td style={{ width: 300, fontSize: 12 }}>stan-8352</td>
                          <td style={{ fontSize: 12, textAlign: "right" }}>8,000.00</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <div>
                <div className={classes.ledgerTop}>
                  <h4 className={classes.ledgerText}>Statement (Balance : 0.00)</h4>
                  <p className={classes.ledgerMatched}>111 0f 111 matched</p>
                </div>
                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          // value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                        // onChange={(e) => {
                        // setSearchTerm(e.target.value);


                        // }}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>

                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>Post Date</th>
                          <th>Validate</th>
                          <th >Details</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap', }}>
                        <tr >
                          <td ><input type='checkbox' className={classes.inPutTextBox} /></td>
                          <td ><IoChatbubblesSharp /> <FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                          <td style={{ fontSize: 12 }}>4 Jun. 2018</td>
                          <td style={{ fontSize: 12 }}>4 Jun. 2018</td>
                          <td style={{ width: 300, fontSize: 12 }}>stan-8352</td>
                          <td style={{ fontSize: 12, textAlign: "right" }}>8,000.00</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

            </div>

            {/* <div className={classes.tableTables}>

              <div className={classes.iconsStyle}>
                <IoMdCheckmark style={{ color: 'green' }} size={25} />
                <ImCancelCircle style={{ color: 'red' }} size={25} />
                <FaRegSave size={25} />
                <GrPowerCycle style={{ color: 'orange' }} size={25} />
              </div>
              <div className={classes.tableTop}>
                <div className={classes.ledgerTable}>
                  <div className={classes.ledgerTop}>
                    <h4 className={classes.ledgerText}>Ledger (Balance : 150,000,000.00)</h4>
                    <p className={classes.ledgerMatched}>111 0f 111 matched</p>
                  </div>
                  <div className={classes.ledgerInput}>
                    <input type='text' placeholder='search details' className={classes.searchDetails} />
                    <input type='text' placeholder='Search' className={classes.search} />
                  </div>
                  <table className={classes.tablestble}>
                    <tr className={classes.headRow}>
                      <th className={classes.headVertical}></th>
                      <th className={classes.headVertical}></th>
                      <th className={classes.headVertical}></th>
                      <th className={classes.headVertical}>PostDate</th>
                      <th className={classes.headVertical}>Validate</th>
                      <th className={`${classes.headVertical} ${classes.column6}`}>Details</th>
                      <th className={classes.headVerticalA}>Amount</th>
                    </tr>
                    <tr className={classes.ledgerRow}>
                      <td className={classes.ledgerVer}><input type='checkbox' className={classes.inPutTextBox} /></td>
                      <td className={classes.ledgerVer}><IoChatbubblesSharp /></td>
                      <td className={classes.ledgerVerU} style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}><FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>stan-8352</td>
                      <td className={classes.ledgerVerA}>8,000.00</td>
                    </tr>
                    <tr className={classes.ledgerRow}>
                      <td className={classes.ledgerVer}><input type='checkbox' /></td>
                      <td className={classes.ledgerVer}><IoChatbubblesSharp /></td>
                      <td className={classes.ledgerVerU} style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}><FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px', alignItems: 'center', justifyContent: 'center', display: 'flex' }} /></td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>stan-8352</td>
                      <td className={classes.ledgerVerA}>8,000.00</td>
                    </tr>

                  </table>
                </div>
                <div className={classes.statementTable}>
                  <div className={classes.ledgerTop}>
                    <h4 className={classes.ledgerText}>Statement(Balance : 14,000,000.00)</h4>
                    <p className={classes.ledgerMatched}>111 0f 111 matched</p>
                  </div>
                  <div className={classes.ledgerInput}>
                    <input type='text' placeholder='search details' className={classes.searchDetails} />
                    <input type='text' placeholder='Search' className={classes.search} />
                  </div>
                  <table className={classes.tablestble}>
                    <tr className={classes.headRow}>
                      <th className={classes.headVertical}></th>
                      <th className={classes.headVertical}></th>
                      <th className={classes.headVertical}></th>
                      <th className={classes.headVertical}>PostDate</th>
                      <th className={classes.headVertical}>Validate</th>
                      <th className={`${classes.headVertical} ${classes.column6}`}>Details</th>
                      <th className={classes.headVerticalA}>Amount</th>
                    </tr>
                    <tr className={classes.ledgerRow}>
                      <td className={classes.ledgerVer}><input type='checkbox' className={classes.inPutTextBox} /></td>
                      <td className={classes.ledgerVer}><IoChatbubblesSharp /></td>
                      <td className={classes.ledgerVerU} style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}><FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>stan-8352</td>
                      <td className={classes.ledgerVerA}>-0.50</td>
                    </tr>
                    <tr className={classes.ledgerRow}>
                      <td className={classes.ledgerVer}><input type='checkbox' /></td>
                      <td className={classes.ledgerVer}><IoChatbubblesSharp /></td>
                      <td className={classes.ledgerVerU} style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}><FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px', alignItems: 'center', justifyContent: 'center', display: 'flex' }} /></td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>4 Jun. 2018</td>
                      <td className={classes.ledgerVer}>stan-8352</td>
                      <td className={classes.ledgerVerA}>-0.50</td>
                    </tr>

                  </table>
                </div>
              </div>
            </div> */}
          </div>
        </div>

      </div>






    </div >
  )
}