import React, { useState, useEffect } from 'react';
import classes from '../Manage Continent/Continent.module.css';
// import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import LatestDash from '../../Pages/LatestDashboard/LatestBudgetDash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MobileBudget from '../../Component/MobileBudget/MobileBudget';


export default function ContinentBudget() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [provinceLoading, setProvinceLoading] = useState(false);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [eyeClicked1, setEyeClicked1] = useState(false);
  const [trashClicked1, setTrashClicked1] = useState(false);
  const [eyeClicked2, setEyeClicked2] = useState(false);
  const [trashClicked2, setTrashClicked2] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage1, setEntriesPerPage1] = useState(100);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [entriesPerPage2, setEntriesPerPage2] = useState(100);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [description, setDescription] = useState('')
  const [description1, setDescription1] = useState('')
  const [accountantName, setAccountantName] = useState("");
  const [accountantName1, setAccountantName1] = useState("");
  const [accountantPhone, setAccountantPhone] = useState("");
  const [accountantPhone1, setAccountantPhone1] = useState("");
  const [accountantEmail, setAccountantEmail] = useState("");
  const [accountantEmail1, setAccountantEmail1] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [displayedData1212, setDisplayedData1212] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [openNav,setOpenNav] = useState(false);



  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });

      // console.log(response);
      const results = response.data?.data;
      setTableData(results);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };






  useEffect(() => {
    if (bearer) {
      fetchContinents();

    }
  }, [bearer]);

    // Function to export data as Excel
    const handleExportExcel = () => {
      // Define the column headers for the Excel export
      const tableHeaders = ["S/N", "CONTINENT", "CREATED AT", "UPDATED AT"];
      
      // Create the rows based on displayedData2
      const tableRows = tableData.map((item, index) => [
        index + 1,
        item.description,
        formatDate(item.created_at),
        formatDate(item.updated_at)
    ]);
    
      // Create an array with headers and rows
      const dataToExport = [tableHeaders, ...tableRows];
    
      // Convert the data to a worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "TableData");
      
      // Write the file
      XLSX.writeFile(workbook, "Manage Continent.xlsx");
    };

    // Function to export data as PDF
    const handleExportPDF = () => {
      const doc = new jsPDF();
      const tableColumn = ["S/N", "CONTINENT", "CREATED AT", "UPDATED AT"];
      const tableRows = [];

      tableData.forEach((item, index) => {
        const rowData = [
          index + 1,
          item.description,
          formatDate(item.created_at),
          formatDate(item.updated_at)
        ];
        tableRows.push(rowData);
      });

      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
      });

      doc.save("Manage Continent.pdf");
    };

    // Function to copy data to clipboard
    const handleCopy = () => {
      const copytableData = tableData.map((item, index) =>
        `${index + 1}\t${item.description}\t${formatDate(item.created_at)}\t${formatDate(item.updated_at)}`
      ).join("\n");

      return copytableData;
    };
  // };

    const createContinent = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${BASE_URL}/continents/create_continent`,
          {
            description: description,

          },
          { headers }
        );

        fetchContinents();
        handleClose();

        toast.success(response.data.message);
        console.log(response);

      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
          console.log(errorMessage);
        }
      } finally {
        setLoading(false);
      }
    };




    function formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
      return formattedDate;
    }

    function padZero(num) {
      return num < 10 ? `0${num}` : num;
    }




    const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

    const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    const handlePrevPage = () => {
      setCurrentPage(Math.max(currentPage - 1, 1));
    };

    const handleNextPage = () => {
      setCurrentPage(Math.min(currentPage + 1, totalPages));
    };

    const totalEntries = filteredData.length;
    const startIndexx = (currentPage - 1) * entriesPerPage + 1;
    const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
    const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

    const filteredData1 = tableData1.filter(item => item.description.toLowerCase().includes(searchTerm1.toLowerCase()));

    const totalPages1 = Math.ceil(filteredData1.length / entriesPerPage1);



    const totalEntries1 = filteredData1.length;
    const startIndexx1 = (currentPage1 - 1) * entriesPerPage1 + 1;
    const endIndexx1 = Math.min(startIndexx1 + entriesPerPage1 - 1, totalEntries1);
    const displayedData1 = filteredData1.slice(startIndexx1 - 1, endIndexx1);

    const filteredData2 = tableData2.filter(item => item.description.toLowerCase().includes(searchTerm2.toLowerCase()));

    const totalPages2 = Math.ceil(filteredData2.length / entriesPerPage2);



    const totalEntries2 = filteredData2.length;
    const startIndexx2 = (currentPage2 - 1) * entriesPerPage2 + 1;
    const endIndexx2 = Math.min(startIndexx2 + entriesPerPage2 - 1, totalEntries2);
    const displayedData2 = filteredData2.slice(startIndexx2 - 1, endIndexx2);



    const handleEyeClick = (id) => {
      const foundContinent = tableData.find(item => item.id === id);
      setSelectedContinent(foundContinent);
      setDescription1(foundContinent?.description || '');
      setShow1(true);
      setEyeClicked(true);
    };




    const handleTrashClick = async (id) => {
      const confirmResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this continent. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      });

      if (confirmResult.isConfirmed) {
        try {
          const response = await axios.get(`${BASE_URL}/continents/delete_continent?id=${id}`, { headers });
          fetchContinents();
          toast.success(response.data.message);
          setTrashClicked(true);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
            }
            toast.error(errorMessage);
          }
        }
      } else {

        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'The deletion was cancelled.',
        });
      }
    };

    const handleTrashClick1 = async (id) => {
      const confirmResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this region. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      });

      if (confirmResult.isConfirmed) {
        try {
          const response = await axios.get(`${BASE_URL}/regions/delete_region?id=${id}`, { headers });

          toast.success(response.data.message);
          setTrashClicked(true);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
            }
            toast.error(errorMessage);
          }
        }
      } else {

        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'The deletion was cancelled.',
        });
      }
    };

    const handleTrashClick2 = async (id) => {
      const confirmResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this province. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      });

      if (confirmResult.isConfirmed) {
        try {
          const response = await axios.get(`${BASE_URL}/provinces/delete_province?id=${id}`, { headers });

          toast.success(response.data.message);
          setTrashClicked(true);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
            }
            toast.error(errorMessage);
          }
        }
      } else {

        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'The deletion was cancelled.',
        });
      }
    };


    const editContinent = async () => {
      setLoading(true);

      try {
        const response = await axios.post(
          `${BASE_URL}/continents/update_continent`,
          {
            description: description1,
            id: selectedContinent.id

          },
          { headers }
        );

        fetchContinents();
        handleClose1();
        toast.success(response.data.message);

        // console.log(response.data);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      } finally {
        setLoading(false);
      }
    };


    return (
      
      <div>
          {
            openNav && ( 
              <MobileBudget setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
        <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>

        <div className={classes.finishedbodyCont}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div >
              <h3>Manage Continent</h3>
              <p>Add, View, Edit, and Delete Continent</p>
            </div>
            <div style={{ zIndex: 1 }}>
              {/* <div className={classes.formIntBtn}>
               <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Continent</Button>
             </div> */}
            </div>
          </div>



          <div className={classes.mainform}>

            <div className={classes.loandgrantcards}>

              <div className={classes.loandethead}>
                <div className={classes.formLabel}>
                </div>
                <div className={classes.formIntBtn}>
                  <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Continent</Button>
                </div>
              </div>
              <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Continent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ marginTop: 20 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        // autoFocus
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />

                    </Form.Group>
                  </Form>
                </Modal.Body>


                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Go back
                  </Button>
                  <Button variant="success" onClick={createContinent}>
                    {loading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>Creating continent, Please wait...</span>
                      </>
                    ) : (
                      "Create Continent"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={show1} onHide={handleClose1} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Continent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ marginTop: 20 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        // autoFocus
                        value={description1}
                        onChange={(e) => setDescription1(e.target.value)}
                      />

                    </Form.Group>
                  </Form>
                </Modal.Body>






                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose1}>
                    Go back
                  </Button>
                  <Button variant="success" onClick={editContinent} >
                    {loading ? (<> <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>
                        Editing continent, Please wait...
                      </span>
                    </>) : ("Save Changes")}
                  </Button>



                </Modal.Footer>
              </Modal>



              <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                <div className={classes.greenbtn} style={{ display: 'flex', gap: '20px' }}>
                  <div className={classes.actionsBtns}>
                    <CopyToClipboard text={handleCopy()}>
                      <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }}>Copy</Button>
                    </CopyToClipboard>
                    <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }} onClick={handleExportExcel}>Excel</Button>
                    <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }} onClick={handleExportPDF}>PDF</Button>
                    <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }} >Column visibility</Button>
                  </div>
                  <div className={classes.entr}>
                    <label className="d-flex justify-content-start align-items-center">
                      Show
                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                        value={entriesPerPage}
                        onChange={(e) => {
                          setEntriesPerPage(parseInt(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div className="text-right modal-effect ">
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="mr-2">Search:</div>
                      <input
                        type="search"
                        value={searchTerm}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                    </div>


                  </div>
                </div>
              </div>
              <div className={classes.mainTable}>
                {isLoading ? (
                  <p>Fetching continents...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Continent</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap', }}>

                        {displayedData.map((item, index) => (
                          <tr key={index}>
                            <td style={{ paddingLeft: 10 }}>{index + 1}</td>
                            <td style={{ paddingLeft: 10 }}>{item.description}</td>
                            <td style={{ paddingLeft: 10 }}>{formatDate(item.created_at)}</td>
                            <td style={{ paddingLeft: 10 }}>{formatDate(item.updated_at)}</td>
                            <td>
                              <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                              <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className={classes.endded}>
                <p>
                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                </p>
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, page) => {
                    // Show only 5 pages or less if available
                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                      return (
                        <button
                          key={page + 1}
                          style={{
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                            fontSize: '14px',
                            fontFamily: 'nunito',
                            fontWeight: 400,
                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                            backgroundColor: page + 1 === currentPage ? '#2d995f' : 'gainsboro',
                            height: '2.5rem',
                            borderRadius: '89px',
                            padding: '0.5rem',
                            border: 'none',
                            width: '40px',
                            cursor: "pointer"
                          }}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    }
                    return null;
                  })}
                  <button
                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>

        </div>






      </div>
    )
  }