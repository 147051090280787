import React, { useState, useEffect } from 'react';
import classes from './CreateSalesAcct.module.css';
import "../../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL, BASE_URL1, TOKEN } from '../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import Pic from '../../../assets/nw/img.png'
import Pdf from '../../../assets/nw/pdf.png'

import LatestDash from '../../LatestDashboard/LatestDash';
import CurrencyInput from 'react-currency-input-field';
import LatestFixedDash from '../../LatestDashboard/LatestFixedDash';

const initialState = () => {
  return [{ items: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', amount: '' }];
};


export default function ViewAssetReport() {
  const location = useLocation();
  const { selectedAsset } = location.state || {};
  const [user, setUser] = useState("");
  const [show, setShow] = useState(false);
const [fullName, setFullName] = useState('');
const [address, setAddress] = useState('');
const [officeAddress, setOfficeAddress] = useState('');
const [phone, setPhone] = useState('');
const [email, setEmail] = useState('');
const [loading, setLoading] = useState(false);
  const [debitGl, setDebitGl] = useState('');
  const [glMethod, setGlMethod] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [selectedRep, setSelectedRep] = useState('');
  const [sICode, setSICode] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [debitCode, setDebitCode] = useState('');
  const [debitAmount, setDebitAmount] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState(initialState);
  // const [loading, setLoading] = useState(false);
  const [totalCharge, setTotalCharge] = useState("");
  const [itemList, setItemList] = useState([]);
  const [generalInvoices, setGeneralInvoices] = useState([]);
  const [mod, setMod] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState('');
  const [totalTax, setTotalTax] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedTax, setSelectedTax] = useState('');
  const [selectedMode, setSelectedMode] = useState('');
  const [customerBalance, setCustomerBalance] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [repName, setRepName] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [disApprovalLoading, setDisapprovalLoading] = useState(false);


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);




  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  




  const goBack = () => {
    navigate(-1);
  }








  const formatNumber = (value) => {
    if (!value) return '';
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const parseNumber = (value) => {
    return value.replace(/,/g, '');
  };



  
  const handleApprove = async () => {
    setApprovalLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL1}/fixedassets/approve-assets`,
        {
          id: selectedAsset.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
  
      // Display success message
    
      console.log(response.data.message);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      navigate(-1);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setApprovalLoading(false);
    }
  };
  
  const handleDisApprove = async () => {
    setDisapprovalLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL1}/fixedassets/disapprove-assets`,
        {
          id: selectedAsset.id,
          description: description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);
      navigate(-1);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setDisapprovalLoading(false);
    }
  };






  return (
    <div>
      <ToastContainer />
      <LatestFixedDash />

      <div className={classes.finishedbodyCont}>


<div style={{marginTop: '12rem'}}/>
        <h3 className={classes.productText}>View Asset</h3>
        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <div className={classes.formLabel}>
            </div>
          


            <div className="d-flex justify-content-center">
              <Form style={{ marginTop: 20, width: '90%' }}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Identification Number</Form.Label>
                        <div style={{ width: 500 }}>
                        <Form.Control
          className="formControl" 
          type="text"
          value={selectedAsset?.identification_number}
          disabled
        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                      <Form.Label className={classes.formLabelCustom122}>Asset Name</Form.Label>
                      <div style={{ width: 500 }}>
                      <Form.Control
          className="formControl" 
          type="text"
          value={selectedAsset?.description}
          disabled
        />
                      </div>
                      
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Category</Form.Label>
                        <div style={{ width: 500, display: "flex", flexDirection: "column" }}>
                        <Form.Control
          className="formControl" 
          type="text"
          value={selectedAsset?.asset_category?.description}
          disabled
        />
                        </div>
                      </div>
                    </Form.Group>
                       
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Amount Purchased</Form.Label>
                        <CurrencyInput
                  name={`amount purchased`}
                  decimalsLimit={2}
                  value={selectedAsset?.amount_purchased}
                  className="form-control"
                  disabled
                  style={{ textAlign: "right", border: "none", width: '100%' }}
                />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ marginTop: 5 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Proceed on Sale</Form.Label>
                        <CurrencyInput
                  name={`amount sold`}
                  decimalsLimit={2}
                  value={selectedAsset?.proceed_on_sale}
                  className="form-control"
                  disabled
                  style={{ textAlign: "right", border: "none", width: '100%' }}
                />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Date Purchased</Form.Label>
                        <Form.Control
                              type="date"
                              value={selectedAsset?.date_purchased}
                          disabled
                            />

                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row" style={{ marginTop: 5 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Date Disposed</Form.Label>
                        <Form.Control
                              type="date"
                              value={selectedAsset?.date_disposed}
                          disabled
                            />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Lifetime in years</Form.Label>
                        <Form.Control
                              type="text"
                              value={selectedAsset?.lifetime_in_years}
                          disabled
                            />

                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row" style={{ marginTop: 5 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Model Number</Form.Label>
                        <Form.Control
                              type="text"
                              value={selectedAsset?.model_number}
                          disabled
                            />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Serial Number</Form.Label>
                        <Form.Control
                              type="text"
                              value={selectedAsset?.serial_number}
                          disabled
                            />

                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row" style={{ marginTop: 5 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Quantity</Form.Label>
                        <Form.Control
                              type="text"
                              value={selectedAsset?.quantity}
                          disabled
                            />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Unit Price</Form.Label>
                        <CurrencyInput
                  name={`amount sold`}
                  decimalsLimit={2}
                  value={selectedAsset?.unit_price}
                  className="form-control"
                  disabled
                  style={{ textAlign: "right", border: "none", width: '100%' }}
                />

                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row" style={{ marginTop: 5 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Location</Form.Label>
                        <Form.Control
                              type="text"
                              value={selectedAsset?.location}
                          disabled
                            />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Residual Value</Form.Label>
                        <CurrencyInput
                  name={`amount sold`}
                  decimalsLimit={2}
                  value={selectedAsset?.residual_value}
                  className="form-control"
                  disabled
                  style={{ textAlign: "right", border: "none", width: '100%' }}
                />

                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row" style={{ marginTop: 5 }}>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Supporting Document</Form.Label>
                        <Form.Control
                              type="file"
                              // value={selectedAsset?.location}
                          disabled
                            />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom122}>Remarks</Form.Label>
                        <Form.Control
                              type="text"
                              value={selectedAsset?.remarks}
                          disabled
                            />

                      </div>
                    </Form.Group>
                  </div>
                </div>
                
               
       
      </Form>
    </div>
    <div className={classes.buttonsGroup}>
   <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
   <div className="row" style={{maxWidth: "100%", marginTop: 30}}>
      <h5 style={{ textAlign: "left" }}>Document(s) Uploaded</h5>
      {selectedAsset?.documents && selectedAsset?.documents?.length > 0 ? (
                                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                                              {selectedAsset.documents.map((document, index) => (
                                                                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', textAlign: 'center' }}>
                                                                  {document.pdf_file.toLowerCase().endsWith('.pdf') ? (
                                                                    <span title={document.pdf_file}>
                                                                      <img src={Pdf} alt="PDF Icon" style={{ width: '100px', height: '100px', cursor: 'pointer' }} onClick={() => window.open(document.pdf_file, '_blank')} />
                                                                      <div style={{ marginTop: '5px' }}>
                                                                        {document.pdf_file.length > 20 ? `${document.pdf_file.substring(0, 20)}...` : document.pdf_file}
                                                                      </div>
                                                                    </span>
                                                                  ) : (
                                                                    <span title={document.pdf_file}>
                                                                      <img src={Pic} alt="Document" style={{ width: '100px', height: '100px', cursor: 'pointer' }} onClick={() => window.open(document.pdf_file, '_blank')} />
                                                                      <div style={{ marginTop: '5px' }}>
                                                                        {document.pdf_file.length > 20 ? `${document.pdf_file.substring(0, 20)}...` : document.pdf_file}
                                                                      </div>
                                                                    </span>
                                                                  )}
                                                                </div>
                                                              ))}
                                                            </div>
                                                          ) : (
                                                            <p>No document uploaded</p>
                                                          )}

                  
                </div>
            </div>
          

            <div style={{marginTop: 50}} className={classes.bttn}>
              <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
              {/* {selectedAsset?.approval_status === "1" && (
    <>
              <Button  style={{ borderRadius: 5 }} variant='success' onClick={handleApprove}>
                {approvalLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Approve"
                          )}
             
              </Button>
              <Button  style={{ borderRadius: 5 }} variant='danger' onClick={handleDisApprove}>
                {disApprovalLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Disapprove"
                          )}
                
              </Button>
              </>
)} */}
            </div>
          </div>









        </div>
      </div>

    </div>






    </div >
  )
}