import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import CurrencyInput from 'react-currency-input-field';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import MobileMenu from '../../Component/Mobile/MobileNav';

export default function LoanAccount() {
  const navigate = useNavigate();
  const [selectedLoan, setSelectedLoan] = useState('');
  const [loanCode, setLoanCode] = useState('');
  const [loanDescription, setLoanDescription] = useState('');
  const [show, setShow] = useState(false);
  const [accPay, setAccPay] = useState([]);
  const [accRec, setAccRec] = useState([]);
  const [selectedPayables, setSelectedPayables] = useState(null);
  const [recievables, setReceivables] = useState([]);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [accountCode1, setAccountCode1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [roless, setRoless] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [glname, setGlname] = useState("");
  const [glcode1, setGlcode1] = useState("");
  const [glcode, setGlcode] = useState("");
  const [balance, setBalance] = useState("");
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSubCategory1, setSelectedSubCategory1] = useState('');
  const [glname1, setGlname1] = useState("");
  const [balance1, setBalance1] = useState("");
  const [selectedDirection1, setSelectedDirection1] = useState("");
  const [selectedDate1, setSelectedDate1] = useState('');
  const [selectedChart, setSelectedChart] = useState('')
  const [tableData2, setTableData2] = useState([]);
  // const [glcode, setGlcode] = useState("");
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [loanLoading, setLoanLoading] = useState(false);
  const [selectedReportTo, setSelectedReportTo] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [openNav,setOpenNav] = useState(false);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/fetch-loans`, { headers });
      const results = response.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };




  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);



  const createAccount = async () => {

    setLoanLoading(true);
    try {

      const response = await axios.post(`${BASE_URL}/account/create-loan-account`,
        {
          code: loanCode,
          description: loanDescription,
          // opening_balance: balance,
          report_to: selectedReportTo?.value,
          // payable: selectedPayables?.value,
          type: 2

        },
        { headers }
      );
      console.log(response.data.message)
      fetchData();
      setLoanCode('');
      setLoanDescription('');
      setBalance('');
      setSelectedReportTo(null);
      setSelectedPayables(null);
      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setLoanLoading(false);
    }
  }

  const editLoan = async () => {

    setLoanLoading(true);
    try {

      const response = await axios.post(`${BASE_URL}/account/update-account`,
        {
          code: loanCode,
          description: loanDescription,
          opening_balance: balance,
          report_to: selectedReportTo?.value || '',
          payable: selectedPayables?.value || '',
          type: 2,
          id: selectedLoan

        },
        { headers }
      );
      console.log(response.data.message)

      fetchData();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      // return
      console.log(response.data);

    } catch (error) {

      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: error.response.data.message,
        });
        console.log(errorMessage);
      }
    } finally {
      setLoanLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  const handleValueChange = (value, name, values) => {
    setBalance(value);

  };

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }




  // const filteredData = tableData?.filter(item => item?.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(tableData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = tableData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = tableData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {

    const foundCharts = tableData.find(item => item.id === id);
    const chartId = foundCharts.id;
    setSelectedChart(chartId)

    const { code, description, interest, opening_balance } = foundCharts;
    setSelectedLoan(id);
    setLoanCode(code || '');
    setLoanDescription(description || '');
    setBalance(opening_balance || '');
    // const selectedRole = roles.length > 0 ? roles[0].id : '';
    // setSelectedRole(selectedRole);

    setShow1(true);
    setEyeClicked(true);
  };
  useEffect(() => {

  }, { selectedChart }

  )

  const fetchAcReceivables = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-loans-receivables`, { headers });
      const accs = response.data?.data;
      console.log("lists:", response);
      const accOptions = accs.map(item => ({
        label: item.gl_name,
        value: item.id
      }));

      setAccRec(accOptions);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setAccRec([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAcPayables = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/payables`, { headers });
      const pay = response.data?.data;
      console.log("lists:", response);
      const payOptions = pay.map(item => ({
        label: item.gl_name,
        value: item.id
      }));

      setAccPay(payOptions);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setAccPay([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchAcReceivables();
      fetchAcPayables();
    }
  }, [bearer]);



  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/users/update-user`,
        {
          name: fullName1,
          id: selectedId,
          email: email1,
          phone_no: phone1,
          role: selectedRole1,
          user_id: selectedUser
        },
        { headers }
      );

      fetchData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this role.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/account/delete?id=${id}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleDirectionChange = (e) => {
    setSelectedDirection(e.target.value);
  };
  const handleDirectionChange1 = (e) => {
    setSelectedDirection1(e.target.value);
  };

  const handleValueChange1 = (value1, name1, values1) => {
    setBalance1(value1); // Update the balance state
    console.log(value1, name1, values1);
  };

  const handleExcelExport = async () => {
    // Create a workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Sheet');

    // Define columns (headers)
    worksheet.columns = [
      { header: 'S/N', key: 'index', width: 10 },
      { header: 'Account Code', key: 'gl_code', width: 20 },
      { header: 'Account Name', key: 'gl_name', width: 30 },
      { header: 'Account Type', key: 'class_description', width: 20 },
      { header: 'Category', key: 'category_description', width: 20 },
      { header: 'Sub Category', key: 'subcategory_description', width: 20 }
    ];

    // Add data to worksheet
    tableData.forEach((item, index) => {
      worksheet.addRow({
        index: index + 1,
        gl_code: item.gl_code,
        gl_name: item.gl_name,
        class_description: item.class?.description,
        category_description: item.category?.description,
        subcategory_description: item.subcategory?.description
      });
    });

    // Set up file saving
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'Charts of Account.xlsx');
  };


  return (
    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
      <ToastContainer />
      {/* <MainDashoard /> */}

      <div className={classes.finishedbodyCont}>
        <div >
          <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>
          {/* <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Manage Charts of Account</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
              <p style={{marginTop: -10}}>{admin}</p>
                            <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p>

            </div>
          </div> */}
        </div>

        <div className={classes.topSpace}>
          <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Loan Account</h3>
          <p style={{ margin: " 0 10px 20px 15px" }}>Add, Upload, View, Edit, and Delete Loan Account</p>
          <div className={classes.mainform}>

            <div className={classes.loandgrantcards}>

              <div className={classes.loandethead}>
                <div className={classes.formLabel}>
                </div>
                <div className={classes.formIntBtn}>

                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to create loan account</Tooltip>}
                  >
                    <Button variant="success" onClick={handleShow} className={classes.btn1}>  Create Loan Account</Button>
                  </OverlayTrigger>
                </div>
              </div>
              <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Create loan Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label style={{ marginTop: 20 }}>Loan Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Loan Code"
                        value={loanCode} onChange={(e) => setLoanCode(e.target.value)}
                      />
                      <Form.Label style={{ marginTop: 20 }}>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        value={loanDescription}
                        onChange={(e) => setLoanDescription(e.target.value)}
                      />




                      {/* <Form.Label style={{ marginTop: 20 }}>Balance</Form.Label>
                      <CurrencyInput

                        name="balance"
                        decimalsLimit={2}
                        className="form-control"
                        value={balance} // Set the value to the balance state
                        onValueChange={handleValueChange}
                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                      /> */}

                      <Form.Label style={{ marginTop: 20 }}>Account Receivables</Form.Label>
                      <Select loading={isLoading} dropdownHeight='100px' options={accRec} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                        onChange={setSelectedReportTo} value={selectedReportTo} placeholder="Select Account Receivables"
                      />
                      {/* <Form.Label style={{ marginTop: 20 }}>Account Payables</Form.Label>
                      <Select loading={isLoading} dropdownHeight='100px' options={accPay} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                        onChange={setSelectedPayables} value={selectedPayables} placeholder="Select Account Payables"
                      /> */}



                    </Form.Group>
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Go back
                  </Button>
                  <Button variant="success" onClick={createAccount}>
                    {loanLoading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>Creating account, Please wait...</span>
                      </>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={show1} onHide={handleClose1} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Update Loan Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label style={{ marginTop: 20 }}>Loan Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Loan Code"
                        value={loanCode} onChange={(e) => setLoanCode(e.target.value)}
                      />
                      <Form.Label style={{ marginTop: 20 }}>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        value={loanDescription}
                        onChange={(e) => setLoanDescription(e.target.value)}
                      />




                      <Form.Label style={{ marginTop: 20 }}>Balance</Form.Label>
                      <CurrencyInput

                        name="balance"
                        decimalsLimit={2}
                        className="form-control"
                        value={balance} // Set the value to the balance state
                        onValueChange={handleValueChange}
                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                      />

                      <Form.Label style={{ marginTop: 20 }}>Account Receivables</Form.Label>
                      <Select loading={isLoading} dropdownHeight='100px' options={accRec} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                        onChange={setSelectedReportTo} value={selectedReportTo} placeholder="Select Account Receivables"
                      />
                      <Form.Label style={{ marginTop: 20 }}>Account Payables</Form.Label>
                      <Select loading={isLoading} dropdownHeight='100px' options={accPay} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                        onChange={setSelectedPayables} value={selectedPayables} placeholder="Select Account Payables"
                      />



                    </Form.Group>
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose1}>
                    Go back
                  </Button>
                  {/* <Button variant="success" onClick={editLoan}> */}
                  <Button variant="success" onClick={editLoan}>
                    {loanLoading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                  </Button>
                </Modal.Footer>
              </Modal>


              <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                  <div className={classes.actionsBtns}>
                    <Button variant='success'>Copy</Button>
                    <Button variant='success' onClick={handleExcelExport}>Excel</Button>
                    <Button variant='success'>PDF</Button>
                    <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                  </div>
                  <div className={classes.show}>
                    <label className="d-flex justify-content-start align-items-center">
                      Show
                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                      //  value={entriesPerPage}
                      //     onChange={(e) => {
                      //     setEntriesPerPage(parseInt(e.target.value));
                      //     setCurrentPage(1);
                      //     }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div className="text-right modal-effect ">
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="mr-2">Search:</div>
                      <input
                        type="search"
                        value={searchTerm}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          // setCurrentPage(1);
                        }}
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={classes.mainTable}>
                {roleLoading ? (
                  <p>Fetching accounts...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Code</th>
                          <th>Description</th>
                          {/* <th>Interest Rate</th> */}
                          {/* <th>Opening Balance</th> */}
                          <th>Report To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap' }}>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: "left" }}>{item.code}</td>
                            <td>{item.description}</td>
                            {/* <td style={{textAlign: "left"}}>{item.interest}</td> */}
                            {/* <td style={{ textAlign: "right" }}>{parseFloat(item.opening_balance).toLocaleString('en-US', {
                              minimumIntegerDigits: 1,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</td> */}
                            <td>{item.report?.gl_name}</td>
                            <td>
                              {/* {(isAdmin || permittedHeaders.includes('update-loan-account')) && ( */}
                              <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                              {/* )} */}
                              {/* {(isAdmin || permittedHeaders.includes('delete-loan-account')) && ( */}
                              <div className="btn btn-danger-soft btn-sm">
                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                              {/* )} */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className={classes.endded}>
                <p>
                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                </p>
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, page) => {
                    // Show only 5 pages or less if available
                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                      return (
                        <button
                          key={page + 1}
                          style={{
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                            fontSize: '14px',
                            fontFamily: 'nunito',
                            fontWeight: 400,
                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                            backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                            height: '2.5rem',
                            borderRadius: '89px',
                            padding: '0.5rem',
                            border: 'none',
                            width: '40px',
                            cursor: "pointer"
                          }}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    }
                    return null;
                  })}
                  <button
                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>






    </div >
  )
}