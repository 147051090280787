import React, { useState, useEffect } from 'react';
import classes from './PrintCash.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useLocation } from 'react-router-dom';


export default function PrintCash() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
        const formattedDateTime = `${day}/${month}/${year} `;
    
        setCurrentDateTime(formattedDateTime);
      }, []);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('companyName');
          const continentName = await AsyncStorage.getItem('continent');
          const regionName = await AsyncStorage.getItem('region');
          const provinceName = await AsyncStorage.getItem('province');
         const admins = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setCompany(value1);
         
          }

          if (continentName !== null) {
            setContinent(continentName);
        }
        if (regionName !== null) {
            setRegion(regionName);
        }
        if (provinceName !== null) {
            setProvince(provinceName);
        }
        if (admins !== null) {
          setUserType(admins);
      }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };

      useEffect(() => {
        readData();

    }, []);

    const { accounts, inputss } = location.state || {};
    console.log(accounts, inputss);
    const startDate = new Date(inputss.start_date);
    const endDate = new Date(inputss.end_date);
    const accountNames = accounts.map(item => item.account?.gl_name.toUpperCase());

    // console.log(accountNames, "here");

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
          ? 'th'
          : suffixes[v % 10] || 'th';
      };

      const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
    
        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
      };


      useEffect(() => {
        if (accounts) {
          const debitTotal = accounts.reduce((total, item) => total + parseFloat(item.debit), 0);
          const creditTotal = accounts.reduce((total, item) => total + parseFloat(item.credit), 0);
      
          // Format the numbers with commas and two decimal places
          const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
      
          setTotalDebit(formattedDebitTotal);
          setTotalCredit(formattedCreditTotal);
        }
      }, [accounts]);
      

      function formattedDates(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
        return formattedDate;
      }
    
      function padZero(num) {
        return num < 10 ? `0${num}` : num;
      }

  return (
    <div style={{ marginTop: '1rem', }}>
        {/* <AdminHeaderNav /> */}
        <div className='newBody'>
            <div className='newWidth'>
                <div className="wrapper">        
                    <div className={classes.a4}>
                <div className={classes.header}>
                <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
                <p style={{ width: "100%", fontSize: 18, textTransform: "uppercase" }}>
                  {inputss?.name}
                  </p>
                {/* <p style={{ width: "100%", fontSize: 18 }}>{userType === "Continental Accountant" ? continent :
 userType === "Regional Accountant" ? region :
 userType === "Super Admin" ? "Super Admin" :
 userType === "Provincial Accountant" ? province : null}
</p> */}
                    {/* <h4>Account Activity Period, 01 - 01 (September - September) 2017/2018</h4> */}
                    <h6>CASHBOOK REPORT BETWEEN {formatDate(startDate)}  AND {formatDate(endDate)} </h6>
                    <h6 style={{fontWeight: 700}}>{accountNames[0]} </h6>
                </div>
                <p><span style={{fontWeight: 700, color: "#000"}}>DATE PRINTED:</span> {currentDateTime} </p>      
                    <table className={classes.table}>
                    <thead>
                    <tr>
    <th style={{ width: '200px' }}>Post Date</th>
    <th style={{ width: '200px' }}>Value Date</th>
    {/* <th style={{ width: '200px' }}>Particulars</th> */}
    <th style={{ width: '500px' }}>Details</th>
    
    <th style={{ textAlign: "right", width: '200px' }}>Debit</th>
    <th style={{ textAlign: "right", width: '200px' }}>Credit</th>
  </tr>
</thead>
  <tbody>
    {accounts && accounts.map((item, index) => (
      <tr key={index}>
        <td>{formattedDates(item.created_at)}</td>
        <td>{item.transaction_date}</td>
        {/* <td>{item.particular}</td> */}
        <td>{item.details}</td>
        {/* <td>{item.account?.gl_name}</td> */}
        {/* <td></td> */}
        {/* <td></td> */}
        {/* <td></td> */}
        <td style={{ textAlign: "right",  }}>{parseFloat(item.debit).toLocaleString('en-US', {
                    minimumIntegerDigits: 1,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</td>
        <td style={{ textAlign: "right" }}>{parseFloat(item.credit).toLocaleString('en-US', {
                    minimumIntegerDigits: 1,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</td>
        
                  
      </tr>
    ))}
    <tr>
    <td colSpan="9" style={{ borderBottom: "2px solid #000" }}></td>
  </tr>
    <tr>
      <td colSpan="3">Total</td>
      <td style={{ textAlign: "right", fontWeight: "bold" }}>{totalDebit}</td>
      <td  style={{ textAlign: "right", fontWeight: "bold" }}>{totalCredit}</td>
                    
    </tr>
    <tr>
    <td colSpan="9" style={{ borderBottom: "2px solid #000" }}></td>
  </tr>
  </tbody>
</table>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}