import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
// import CurrencyInput from 'react-currency-input-field';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import CashDash from '../Cash Dash/CashDash';
import PaymentDash from '../Payment Dashboard/PaymentDash';
import LatestDash from '../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'

function CreateExpenses() {
    const [payment, setPayment] = useState('');
    const [subCat, setSubcat] = useState([]);
    const [subCat1, setSubcat1] = useState([]);
    const [paymentMeth, setPaymentMeth] = useState([]);
    const [debitAccount, setDebitAccount] = useState([]);
    const [teller, setTeller] = useState('');
    const [description, setDescription] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [currency, setCurrency] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState('');
    const [selectedDebit, setSelectedDebit] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currLoading, setCurrLoading] = useState(false);
    const [incomeLoading, setIncomeLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState([{ sn: 1, accountName: '', accountCode: '', amount: '' }]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [banks, setBanks] = useState([]);
    const [debit1, setDebit1] = useState([]);
    const [debit2, setDebit2] = useState([]);
    const [incomeAmount, setIncomeAmount] = useState();
    const [totalAmount, setTotalAmount] = useState('');
    const [selectedBanks, setSelectedBanks] = useState('');
    // const [debitAccount, setDebitAccount] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
    const [user, setUser] = useState('');
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [selectOptions, setSelectOptions] = useState([]);
    const [userType, setUserType] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [currentMonth, setCurrentMonth] = useState('');
    const [minDate, setMinDate] = useState("");
    const [maxDate, setMaxDate] = useState("");



    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };


    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    // const handleCurrencyChange = (event) => {
    //     setSelectedCurrency(event.target.value);
    // };

    const handleAssetChange = (selectedOption) => {
        setSelectedDebitAccount(selectedOption.value);
    };
    const handleBanksChange = (selectedOption) => {
        setSelectedBanks(selectedOption.value);
    };
    const handleDebitChange = (event) => {
        setSelectedDebit(event.target.value);
    };






    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            const admins = await AsyncStorage.getItem('admin');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const current = await AsyncStorage.getItem('currentMonth');


            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (admins !== null) {
                setUserType(admins);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (current !== null) {
                console.log(current)
                const date = new Date(current);  // Assume current is in a parsable date format

                setCurrentMonth(date);
                const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                // Set the min and max date for the date input

                const formatDate = (date) => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');  // Add leading zero
                    const day = String(date.getDate()).padStart(2, '0');         // Add leading zero
                    return `${year}-${month}-${day}`;
                };

                setMinDate(formatDate(startOfMonth));
                setMaxDate(formatDate(endOfMonth));

                console.log(formatDate(startOfMonth), formatDate(endOfMonth));

            }

        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => {
        navigate(-1);
    }

    const addRow = () => {
        const newRow = {
            sn: formData.length + 1,
            accountName: '',
            accountCode: '',
            amount: ''
        };
        setFormData([...formData, newRow]);
        // setSelectedPayment('');
    };

    const deleteRow = (index) => {
        const updatedData = formData.filter((_, i) => i !== index);
        setFormData(updatedData);
    };

    const handleFormChange = (index, field, value) => {
        const updatedFormData = [...formData];
        const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
        const formattedValue = numericValue.toFixed(2);

        updatedFormData[index][field] = formattedValue;
        setFormData(updatedFormData);
    };


    const fetchDebitAcct = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/account`, { headers });
            //     {

            //       headers: { 
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer ${bearer}`
            //       }
            //     }
            //   );
            const debitAcc = response.data?.data;

            setDebitAccount(debitAcc);

            //   console.log(debitAcc, "Debit Accounts");
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDebitAcct();
    }, [bearer]);

    const fetchSubCat = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/account/expenses`, { headers });
            //     {

            //       headers: { 
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer ${bearer}`
            //       }
            //     }
            //   );
            const results = response.data?.data;
            const oopt = results.map((item) => ({
                label: item.gl_name,
                value: item.id,
                code: item.gl_code,


            }));
            setSubcat(oopt);
            setSubcat1(results);

            //   console.log(results, "Accounts");
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };


    const fetchDebit1 = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/account/bank-accounts`, { headers });

            const debt1 = response.data?.data;
            const oopt1 = debt1.map((item) => ({
                label: item.gl_name,
                value: item.id,


            }));
            setDebit1(oopt1);



        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };
    const fetchDebit2 = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/account/cash`, { headers });

            const debt2 = response.data?.data;
            const oopt2 = debt2.map((item) => ({
                label: item.gl_name,
                value: item.id,

            }));
            setDebit2(oopt2);



        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (bearer) {
            fetchSubCat();
            fetchPaymentMethod();
            fetchDebit1();
            fetchDebit2();
        }
    }, [bearer]);

    const fetchSubBanks = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/get-banks`, { headers });
            //     {

            //       headers: { 
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer ${bearer}`
            //       }
            //     }
            //   );
            const banked = response.data?.data;
            const ban1 = banked.map((item) => ({
                label: item.bank_name,
                value: item.id,


            }));
            setBanks(ban1);
            //   console.log(response);
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };


    // const fetchAccountName = async (selectedPayment) => {
    //     setLoading(true);

    //     try {
    //         const response = await axios.get(
    //             `https://api-sme.promixaccounting.com/api/v1/get-account-by-payment-mode?type=${selectedPayment}`,
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `Bearer ${bearer}`,
    //                 },
    //             }
    //         );

    //         const paid = response.data?.data;
    //         // console.log(paid, 'paid');
    //         setPaymentMeth(paid);
    //     } catch (error) {
    //         const errorStatus = error.response.data.message;
    //         console.error(errorStatus);
    //     } finally {
    //         setLoading(false);
    //     }
    // };



    //   useEffect(() => {
    //     if (bearer && selectedPayment) {
    //       fetchAccountName(selectedPayment);
    //     }
    //   }, [bearer, selectedPayment]);

    // console.log(formData);conso

    console.log(selectedCurrency);

    const createIncome = async () => {
        setIncomeLoading(true);

        try {
            const accountNames = formData.map((row) => row.accountName).filter((name) => name !== undefined);
            const amounts = formData.map((row) => row.amount).filter((amount) => amount !== undefined);
            const payload = {
                account_id: accountNames,
                gl_code: selectedDebitAccount,
                description: description,
                payment_mode: selectedPayment,
                breakdown_amount: amounts,
                total_amount: totalCredit,
                teller_no: teller,
                particulars: payment,
                currency: selectedCurrency,
                transaction_date: selectedDate,
                // bank_id: selectedBanks
            };

            console.log("Payload:", payload);

            const response = await axios.post(`${BASE_URL}/post-expenses`, payload, { headers });

            console.log(response.data, "Response Data");
            navigate(-1);

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
            });
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                    toast.error(errorMessage);
                }
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: errorMessage,
                });
            }
        } finally {
            setIncomeLoading(false);
        }
    };


    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');

    const handleSupplierChange = (selectedOption) => {
        setSelectedCustomer(selectedOption.value);
    }

    const fetchSupplierss = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
            const results = response.data?.data;

            const options = results.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setCustomers(options);
            //   setSelectOptions(options);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setCustomers([]);
        } finally {
            setIsLoading(false);
        }
    };



    const fetchPaymentMethod = async () => {
        setLoading(true);


        try {
            const response = await axios.get(`${BASE_URL}/income/get-payment-method`,
                {

                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );
            const resultss = response.data?.data;
            setPaymentMethod(resultss);

            console.log(resultss, "PaymentModes");
        } catch (error) {
            const errorStatus = error.response.data.message;
            console.error(errorStatus);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (bearer) {
            fetchSubCat();
            fetchPaymentMethod();
        }
    }, [bearer]);

    const fetchCurrency = async () => {
        setCurrLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/fetch-all-currencies`, { headers });
            const results = response.data?.data;
            // console.log(results, "currencis")
            const options = results.map((item) => ({
                label: item.code,
                value: item.id,
                symbol: item.symbol
            }));
            setCurrency(results);
            setSelectOptions(options);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setCurrency([]);
        } finally {
            setCurrLoading(false);
        }
    };

    // const fetchCurrency = async () => {
    //     setLoading(true);


    //     try {
    //         const response = await axios.get(`${BASE_URL}/fetch-all-currencies`,
    //             {

    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${bearer}`
    //                 }
    //             }
    //         );
    //         const curr = response.data?.data;
    //         setCurrency(curr);

    //         //   console.log(curr, "CURRENCY");
    //     } catch (error) {
    //         const errorStatus = error.response.data.message;
    //         console.error(errorStatus);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        if (bearer) {
            fetchCurrency();
            fetchSubBanks();
        }
    }, [bearer]);


    const handleCurrencyChange = (selectedOption) => {
        setSelectedCurrency(selectedOption.value);
        // setSelectedCustomerName(selectedOption);
        // setSelectedSavingsType(null);
        // fetchCustomerSavings(selectedOption.value); 
        // setPaidBooking([]);
    };



    // const fetchDebitAccount = async () => {
    //     setLoading(true);


    //     try {
    //         const response = await axios.get(
    //             `https://api-sme.promixaccounting.com/api/v1/income/get-payment-method`,
    //             {

    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${bearer}`
    //                 }
    //             }
    //         );
    //         const debitted = response.data?.data;
    //         setDebitAccount(debitted);

    //         // console.log(debitted, "NI");
    //     } catch (error) {
    //         const errorStatus = error.response.data.message;
    //         console.error(errorStatus);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     if (bearer) {
    //         fetchDebitAccount();
    //     }
    // }, [bearer]);

    console.log(formData)


    const handleAccountChange = (selectedOption, index) => {
        const selectedAccount = selectedOption.value;
        const selectedItem = subCat1.find(item => item.id === selectedAccount);
        // const intselectedId = parseInt(selectedAccount);
        const selectedCode = selectedItem?.gl_code
        // const selectedGlCode = subCat.find((item) => item.id === intselectedId) || '';

        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            accountName: selectedAccount,
            accountCode: selectedCode,
        };

        setFormData(updatedFormData);
    };

    const handleValueChange = (value) => {
        setIncomeAmount(value);
    };

    const handleValueChange1 = (value, index) => {
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            amount: value,
        };
        setFormData(updatedFormData);
        calculateTotalCredit(); // Recalculate total credit whenever table amounts change
    };


    const calculateTotalCredit = () => {
        const total = formData.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
        const formattedTotal = total.toFixed(2);
        setTotalCredit(formattedTotal);
    };


    useEffect(() => {

        calculateTotalCredit();
    }, [formData]);




    useEffect(() => {
        if (bearer) {
            fetchSupplierss();
        }
    }, [bearer]);




    return (

        <div>

            <div className="wrapper">
                {/* <!-- Sidebar  --> */}


                {/* <!-- Page Content  --> */}
                <div className="content-wrapper">
                    <div className="main-content">

                        <LatestDash />
                        <div className='newBody'>


                            <div className={classes.finishedbodyCont}>
                                <div className={classes.newWidth}>

                                    <h3 style={{ fontSize: 30, fontWeight: 700 }}>Create Expenses</h3>


                                    <div className={classes.topPadding}>
                                        <div className={`${classes.formSecCont}`}>
                                            <div className="card-body" style={{ border: 'none' }}>


                                                <div className="row">
                                                    <div className={`${classes.formSection} ${classes.formSectionGap}`}>

                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Payment To</label>
                                                                <div className="col-sm-8">
                                                                    <input className="form-control" required="" type="text" value={payment} onChange={(e) => setPayment(e.target.value)} name="name" />
                                                                    {/* <Select
                               
                                onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                options={customers}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                /> */}

                                                                    {/* <Form.Select name="customer" className="form-control" required="" value={selectedCurrency} onChange={handleCurrencyChange}>
                                    <option value="">Choose Currency</option>
                                    {currency.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.code}
                                    </option>
                                    ))}
                                </Form.Select> */}

                                                                    {/* <Select
                                onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                options={selectOptions}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                /> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Transaction Date</label>
                                                                <div className="col-sm-8">
                                                                    <input className="form-control" required="" type="date" onChange={handleDateChange}
                                                                        min={minDate}
                                                                        max={maxDate}
                                                                        name="date" value={selectedDate} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 5 }} />

                                                    <div className="col-md-3" style={{ width: "100%" }}>
                                                        <div className="form-group row" style={{}}>
                                                            <label for="example-text-input" className="col-sm-2 col-form-label font-weight-400" style={{ textAlign: "right" }}>Description</label>
                                                            <div className="col-sm-10">
                                                                <textarea
                                                                    style={{ maxWidth: "100%", }}
                                                                    className="form-control"
                                                                    rows="2"
                                                                    cols="10"
                                                                    required=""
                                                                    value={description}
                                                                    onChange={(e) => setDescription(e.target.value)}
                                                                    name="description"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: "5px" }} />
                                                    <div className={`${classes.formSection} ${classes.formSectionGap}`}>
                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Payment Mode</label>
                                                                <div className="col-sm-8">
                                                                    {/* <input className="form-control" required="" type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="name" /> */}
                                                                    {/* <Select
                                value={selectedMode}
                                onChange={(selectedOption) => handleModeChange(selectedOption)}
                                options={paymentMethod}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                /> */}

                                                                    <Form.Select name="customer" className="form-control" required="" value={selectedPayment} onChange={handlePaymentChange}>
                                                                        <option value="">Select Mode</option>
                                                                        {paymentMethod.map((item) => (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6" style={{ width: "100%" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Currency</label>
                                                                <div className="col-sm-8">
                                                                    {/* <input className="form-control" required="" type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="name" /> */}
                                                                    <Select
                                                                        // value={selectedCurrency}
                                                                        onChange={(selectedOption) => handleCurrencyChange(selectedOption)}
                                                                        options={selectOptions}
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                // maxHeight: '200px',
                                                                                // overflowY: 'auto',
                                                                            }),
                                                                        }}
                                                                    />

                                                                    {/* <Form.Select name="customer" className="form-control" required="" value={selectedCurrency} onChange={handleCurrencyChange}>
                                    <option value="">Choose Currency</option>
                                    {currency.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.code}
                                    </option>
                                    ))}
                                </Form.Select> */}

                                                                    {/* <Select
                                onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                options={selectOptions}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                /> */}

                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6" style={{ width: "100%", marginTop: "-10px" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Teller/<br />Cheque No.</label>
                                                                <div className="col-sm-8">
                                                                    <input className="form-control" required="" type="text" value={teller} onChange={(e) => setTeller(e.target.value)} name="receive" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" style={{ width: "100%", marginTop: "-10px" }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Credit Account</label>
                                                                <div className="col-sm-8">
                                                                    {/* <input className="form-control" required="" type="text" value={address} onChange={(e) => setAddress(e.target.value)} name="name" /> */}
                                                                    <Select

                                                                        onChange={(selectedOption) => handleAssetChange(selectedOption)}
                                                                        options={selectedPayment === '1' || selectedPayment === '3' ? debit1 : (selectedPayment === '2' || selectedPayment === '4' ? debit2 : debit1)}
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                maxHeight: '400px',
                                                                                overflowY: 'auto',
                                                                            }),
                                                                        }}
                                                                    />
                                                                    {/* <Form.Select name="customer" className="form-control" required="" value={selectedDebitAccount} onChange={handleAssetChange}>
                                        <option value="">Choose Account</option>
                                        {debitAccount.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.gl_name}
                                        </option>
                                        ))}
                                    </Form.Select> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6" style={{ width: "100%", marginTop: -25, }}>
                                                            <div className="form-group row">
                                                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Amount</label>
                                                                <div className="col-sm-8">
                                                                    {/* <input className="form-control" required="" type="text" value={address} onChange={(e) => setAddress(e.target.value)} name="name" /> */}
                                                                    <CurrencyInput
                                                                        name="amount"
                                                                        decimalsLimit={2}
                                                                        className="form-control"
                                                                        value={incomeAmount} // Set the value to the balance state
                                                                        onValueChange={handleValueChange}
                                                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="col-md-6" style={{width:"100%"}}>
                            <div className="form-group row">
                                <label for="example-text-input" className="col-sm-2 col-form-label font-weight-400">Amount</label>
                                <div className="col-sm-9">
                                    
                                    <CurrencyInput
                                                                        //   
                                        name="amount"
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={incomeAmount} // Set the value to the balance state
                                            onValueChange={handleValueChange}
                                            style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none"}}
                                        />
                                </div>
                                
                            </div>
                        </div> */}



                                                    </div>
                                                    <div style={{ marginTop: 40 }} />
                                                    {/* <h5 style={{textAlign: "center"}}>Add Bank Accounts</h5> */}

                                                    <div className="row" style={{ width: "100%", marginLeft: 20 }}>
                                                        {/* <div className="col-md-6"> */}
                                                        <div className="table-responsive">
                                                            <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                                                <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th style={{ width: '500px', }}>Account Name</th>
                                                                        <th style={{ width: '200px', }}>Account Code</th>
                                                                        <th style={{ width: '200px', }}>Amount</th>
                                                                        <th>
                                                                            <div style={{}}>
                                                                                <div className="btn btn-sm printbtninv" onClick={() => addRow()}>
                                                                                    <i className="fas fa-plus" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                                                    {formData.map((row, index) => (
                                                                        <tr key={index}>
                                                                            <td>{row.sn}</td>
                                                                            <td>
                                                                                <Select
                                                                                    //   value={row.accountName}
                                                                                    onChange={(selectedOption) => handleAccountChange(selectedOption, index)}
                                                                                    options={subCat}
                                                                                    menuPortalTarget={document.body}
                                                                                    styles={{
                                                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                                        menu: (provided) => ({
                                                                                            ...provided,
                                                                                            maxHeight: '300px',
                                                                                            // maxWidth: '200px',
                                                                                            overflowY: 'auto',
                                                                                        }),
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={row.accountCode}
                                                                                    disabled
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <CurrencyInput
                                                                                    name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                                                                    // decimalsLimit={2}
                                                                                    value={row.amount}
                                                                                    className="form-control"
                                                                                    onValueChange={(value) => handleValueChange1(value, index)}
                                                                                    style={{ textAlign: "right", border: "none" }}
                                                                                />
                                                                                {/* <input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.amount}
                                                        onChange={(e) => handleFormChange(index, 'amount', e.target.value)}
                                                        style={{ textAlign: "right" }}
                                                    /> */}
                                                                            </td>
                                                                            <td style={{ textAlign: "center", width: "2rem" }}>
                                                                                <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow(index)}>
                                                                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <div style={{ marginTop: 30 }} />
                                                            <div style={{ display: "flex", justifyContent: "flex-end", gap: '20px' }}>
                                                                {/* <div style={{display:'flex', justifyContent:'flex-end', alignItems:"center", gap:'20px'}}> */}

                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Total Debit:</Form.Label>
                                                                    <CurrencyInput
                                                                        //   
                                                                        name="amount"
                                                                        decimalsLimit={2}
                                                                        className="form-control"
                                                                        value={incomeAmount} // Set the value to the balance state
                                                                        disabled
                                                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                                                    />
                                                                </Form.Group>
                                                                {/* </div> */}

                                                                {/* <div style={{display:'flex', justifyContent:'flex-end', alignItems:"center", gap:'20px'}}> */}

                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Total Credit</Form.Label>
                                                                    <CurrencyInput
                                                                        //   
                                                                        name="amount"
                                                                        decimalsLimit={2}
                                                                        className="form-control"
                                                                        value={totalCredit} // Set the value to the balance state
                                                                        disabled
                                                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                                                    />
                                                                </Form.Group>
                                                                {/* </div> */}
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>

                                    </div>


                                    <div class="modal-footer" style={{ marginTop: '20px' }}>
                                        <Button style={{ borderRadius: 0 }} variant='success'
                                            disabled={parseFloat(incomeAmount) !== parseFloat(totalCredit) ? true : false}
                                            onClick={createIncome}>
                                            {incomeLoading ? (
                                                <>
                                                    <Spinner size='sm' />
                                                    <span style={{ marginLeft: '5px' }}>Creating expenses, Please wait...</span>
                                                </>
                                            ) : (
                                                "Create Expenses"
                                            )}
                                        </Button>
                                        {/* <Button>Save Changes</Button> */}
                                        {/* <button type="submit" class="btn btn-success"><span id="loaderg" className="spinner-border spinner-border-sm me-2" role="status" style={{display:"none",}}></span>Save changes</button> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>













                    {/* </div> */}
                    {/* <!--/.main content--> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* <InfoFooter /> */}
                    {/* <!--/.footer content--> */}
        //   <div className="overlay"></div>
        // </div>
                {/* <!--/.wrapper--> */}


            </div>
        </div>

    );
}

export default CreateExpenses;