import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import CurrencyInput from 'react-currency-input-field';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import LatestAdminDash from '../LatestDashboard/LatestAdminDash';
import LatestBudgetDash from '../LatestDashboard/LatestBudgetDash';
import { FaCalendarCheck } from "react-icons/fa";
import { FaCalendarXmark } from "react-icons/fa6";



export default function AccountApproval() {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = (id) => {
    setSelectedItemId(id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setDescription(''); // Clear the description on close
  };
  const [selectedItemId, setSelectedItemId] = useState(null);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [disapproveLoading, setDisapproveLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [accountCode1, setAccountCode1] = useState("");
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedRole1, setSelectedRole1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState('');
  const [phone, setPhone] = useState("");
  const [roless, setRoless] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [glname, setGlname] = useState("");
  const [glcode1, setGlcode1] = useState("");
  const [glcode, setGlcode] = useState("");
  const [balance, setBalance] = useState("");
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSubCategory1, setSelectedSubCategory1] = useState('');
  const [glname1, setGlname1] = useState("");
  const [balance1, setBalance1] = useState("");
  const [selectedDirection1, setSelectedDirection1] = useState("");
  const [selectedDate1, setSelectedDate1] = useState('');
  const [selectedChart, setSelectedChart] = useState('')
  const [tableData2, setTableData2] = useState([]);
  // const [glcode, setGlcode] = useState("");
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [load, setLoad] = useState(false);
  const [province, setProvince] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [tableData3, setTableData3] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(itemId => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  // Handle "Select All" checkbox toggle
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]); // Deselect all
    } else {
      const allIds = [...displayedData, ...tableData].map(item => item.id);
      setSelectedItems(allIds); // Select all
    }
    setSelectAll(!selectAll);
  };

  // Determine if the item is selected
  const isSelected = (id) => selectedItems.includes(id);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleApprove = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to approve this`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve it!',
      cancelButtonText: 'No, Cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: 'Approving...',
      text: 'Please wait while your request is being processed.',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
    setApproveLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/approve`,
        {
          id: id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      fetchData();
      console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setApproveLoading(false);
    }
  };

  const handleApprove1 = async () => {
    if (selectedItems.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'No Items Selected',
        text: 'Please select at least one account to approve.',
      });
      return;
    }
  
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to approve the selected accounts`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve them!',
      cancelButtonText: 'No, Cancel',
    });
  
    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }
  
    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: 'Approving...',
      text: 'Please wait while your request is being processed.',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
  
    setApproveLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/approve-array`,
        {
          id: selectedItems, // Pass the selected IDs array here
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );
  
      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      fetchData();
      setSelectedItems([]);
      console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setApproveLoading(false);
    }
  };
  

  const handleDisApprove = async () => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to disapprove this`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Yes, Disapprove it!',
      cancelButtonText: 'No, Cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: 'Disapproving...',
      text: 'Please wait while your request is being processed.',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
    setDisapproveLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/disapprove`,
        {
          id: selectedItemId,
          description: description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setDisapproveLoading(false);
    }
  };

  const handleDisApprove1 = async () => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to disapprove this accounts`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Disapprove it!',
      cancelButtonText: 'No, Cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: 'Disapproving...',
      text: 'Please wait while your request is being processed.',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
    setDisapproveLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/disapprove-array`,
        {
          id: selectedItems,
          description: description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );
      fetchData();
      setSelectedItems([]);
      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setDisapproveLoading(false);
    }
  };



  const fetchData1 = async () => {
    setRoleLoading(true);
    // setTableData2([]);
    try {
      const response = await axios.get(`${BASE_URL}/provinces/get-province-pending-account?province_id=${selectedProvince}`, { headers });
      const results = response.data?.data;
      console.log(results);
      setTableData2(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/all-pending`, { headers });
      const subCat = response.data?.data;
      setTableData2(subCat);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      // fetchData();
      fetchData();
    }
  }, [bearer]);

  const handleSubCatChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };
  const handleSubCatChange1 = (event) => {
    setSelectedSubCategory1(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleDateChange1 = (event) => {
    setSelectedDate1(event.target.value);
  };

  const createAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          sub_category_id: selectedSubCategory,
          gl_name: glname,
          gl_code: accountCode,
          // opening_balance: balance,
          direction: selectedDirection,
          // transaction_date: selectedDate

        },
        { headers }
      );
      console.log(response)
      fetchData();
      handleClose();
      setGlcode('');
      setAccountCode('');
      setGlname('');
      setBalance('');
      setSelectedSubCategory('');
      setSelectedDate('');
      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubContinents = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });

      // console.log(response);
      const subCont = response.data?.data;
      const options1 = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData3(options1);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchSubRegion = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

      // console.log(response);
      const subCont1 = response.data?.data;
      const options2 = subCont1.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData4(options2);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData4([]);
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    if (bearer) {
      fetchContinents();
    }
  }, [bearer]);


  const filteredData = tableData2.filter(item => item.gl_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {

    const foundCharts = tableData.find(item => item.id === id);
    const chartId = foundCharts.id;
    setSelectedChart(chartId)

    const { gl_code, gl_name, balance, direction, transaction_date } = foundCharts;
    setSelectedSubCategory1(gl_code || '');
    setGlname1(gl_name || '');
    setAccountCode1(gl_code || '');
    setBalance1(balance || '');
    setSelectedDirection1(direction || '');
    setSelectedDate1(transaction_date || '');
    // const selectedRole = roles.length > 0 ? roles[0].id : '';
    // setSelectedRole(selectedRole);

    setShow1(true);
    setEyeClicked(true);
  };
  useEffect(() => {

  }, { selectedChart }

  )



  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/users/update-user`,
        {
          name: fullName1,
          id: selectedId,
          email: email1,
          phone_no: phone1,
          role: selectedRole1,
          user_id: selectedUser
        },
        { headers }
      );

      fetchData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this account.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/provinces/delete-province-pending-account?id=${id}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleDirectionChange = (e) => {
    setSelectedDirection(e.target.value);
  };
  const handleDirectionChange1 = (e) => {
    setSelectedDirection1(e.target.value);
  };

  const handleValueChange = (value, name, values) => {
    setBalance(value); // Update the balance state
    console.log(value, name, values);
  };
  const handleValueChange1 = (value1, name1, values1) => {
    setBalance1(value1); // Update the balance state
    console.log(value1, name1, values1);
  };

  const handleExcelExport = async () => {
    // Create a workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Sheet');

    // Define columns (headers)
    worksheet.columns = [
      { header: 'S/N', key: 'index', width: 10 },
      { header: 'Account Code', key: 'gl_code', width: 20 },
      { header: 'Account Name', key: 'gl_name', width: 30 },
      { header: 'Account Type', key: 'class_description', width: 20 },
      { header: 'Category', key: 'category_description', width: 20 },
      { header: 'Sub Category', key: 'subcategory_description', width: 20 }
    ];

    // Add data to worksheet
    tableData.forEach((item, index) => {
      worksheet.addRow({
        index: index + 1,
        gl_code: item.gl_code,
        gl_name: item.gl_name,
        class_description: item.class?.description,
        category_description: item.category?.description,
        subcategory_description: item.subcategory?.description
      });
    });

    // Set up file saving
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'Charts of Account.xlsx');
  };

  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion('');
    setSelectedProvince('');
    setTableData([]);
  };


  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption.value)
    setSelectedProvince('');
    setTableData([]);
  }

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption.value);
    setTableData([]);
  }








  return (
    <div>
      <ToastContainer />
      {/* <MainDashoard /> */}

      <LatestBudgetDash />
      <div className={classes.finishedbodyCont}>
        <div >

          {/* <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Manage Charts of Account</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
              <p style={{marginTop: -10}}>{admin}</p>
                            <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p>

            </div>
          </div> */}
        </div>

        <div className={classes.topSpace}>
          <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Account Approval</h3>
          <p style={{ margin: " 0 10px 20px 15px" }}>Approve and Disapprove Account</p>
          <div className={classes.mainform}>

            <div className={classes.loandgrantcards}>
              <div className={`row ${classes.awrow}`} style={{ marginBottom: 20 }}>
                <div className={`row ${classes.awrow}`} style={{ marginTop: 10 }}>

                  {/* {admin === "Continental Accountant" && (
                                    <> */}

                  <div className="col-md-4" style={{}}>
                    <div className="form-group row">
                      <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Continent:</label>
                      <div className="col-sm-8">
                        <Select
                          onChange={(selectedOption) => handleContinentChange(selectedOption)}
                          options={selectOptions}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              maxWidth: '300px',
                              overflowY: 'auto',
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>


                  <div className={`col-md-4 ${classes.newmobile}`}>
                    <div className="form-group row">
                      <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
                      <div className={`col-sm-8  ${classes.lblrow}`}>
                        <Select
                          onChange={(selectedOption) => handleRegionChange(selectedOption)}
                          options={tableData3}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              maxWidth: '300px',
                              overflowY: 'auto',
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* </>
                               )} */}
                  {/* {admin === "Regional Accountant" && (
                                    <> */}

                  {/* <div className="col-md-4" style={{ }}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
                                  <div className="col-sm-8">
                                  <Select
                                      onChange={(selectedOption) => handleRegionChange(selectedOption)}
                                      options={tableData3}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div> */}


                  <div className={`col-md-4 ${classes.newmobile}`}>
                    <div className="form-group row">
                      <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Province:</label>
                      <div className={`col-sm-8  ${classes.lblrow}`}>
                        <Select
                          onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                          options={tableData4}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              maxWidth: '300px',
                              overflowY: 'auto',
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Button className={classes.newmobile12} onClick={() => fetchData1()}>
                    {roleLoading ? (
                      <>
                        <Spinner size='sm' />
                      </>
                    ) : (
                      "Process"
                    )}
                  </Button>
               </div>
              </div>


              <div className={classes.loandethead}>
                <div className={classes.formLabel}>
                </div>
                {/* <div className={classes.formIntBtn}>
                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to upload new account</Tooltip>}
                  >
                    <Button variant="success"  >  Upload</Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new account</Tooltip>}
                  >
                    <Button variant="success" onClick={handleShow} className={classes.btn1}>  Add New Account</Button>
                  </OverlayTrigger>
                </div> */}
              </div>
              <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Reason for Disapproving</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <textarea
                      className="form-control"
                      rows="3"
                      cols="50"
                      placeholder="Enter reason here..."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleDisApprove}>
                  {disapproveLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Disapprove"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

              <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Reason for Disapproving</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <textarea
                      className="form-control"
                      rows="3"
                      cols="50"
                      placeholder="Enter reason here..."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleDisApprove1}>
                  {disapproveLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Disapprove"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

           

              {selectedItems.length > 0 && (
        <div style={{ marginBottom: '10px' }}>
          <button className="btn btn-success" onClick={() => handleApprove1(selectedItems)}>Approve</button>
          <button className="btn btn-danger" style={{ marginLeft: '10px' }} onClick={() => handleShow1(selectedItems)}>Disapprove</button>
        </div>
      )}
              <div className={classes.mainTable} style={{ marginTop: 10 }}>
                {isLoading ? (
                  <p>Fetching charts...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                        <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                          <th>S/N</th>
                          <th style={{ width: "70px", whiteSpace: "wrap" }}>Account Code</th>
                          <th>Account Name</th>
                          <th style={{ width: "70px", whiteSpace: "wrap" }}>Account Type</th>
                          <th style={{ width: "70px", whiteSpace: "wrap" }}>Category</th>
                          <th style={{ width: "70px", whiteSpace: "wrap" }}>Sub Category</th>
                          <th style={{ width: "70px", whiteSpace: "wrap" }}>Created By</th>
                          <th style={{ width: "70px", whiteSpace: "wrap" }}>Created Date</th>
                          <th>Directions</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap' }}>
                        {(displayedData.length === 0 && tableData.length === 0) ? (
                          <tr>
                            <td colSpan="11" style={{ textAlign: "center" }}>No data available</td>
                          </tr>
                        ) : (
                          <>
                            {displayedData.map((item, index) => (
                              <tr key={index}>
                                 <td>
                        <input
                          type="checkbox"
                          checked={isSelected(item.id)}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                      </td>
                                <td style={{ width: 20 }}>{index + 1}</td>
                                <td>{item.gl_code}</td>
                                <td style={{ textAlign: "left", whiteSpace: "wrap", width: 300 }}>{item.gl_name}</td>
                                <td style={{ width: 60, whiteSpace: "wrap" }}>{item.class?.description}</td>
                                <td style={{ width: 120, whiteSpace: "wrap" }}>{item.category?.description}</td>
                                <td style={{ textAlign: "left", width: 200, whiteSpace: "wrap" }}>{item.subcategory?.description}</td>
                                <td style={{ textAlign: "left", width: 200, whiteSpace: "wrap" }}>{item.created_by?.name}</td>
                                <td style={{ textAlign: "left", width: 200, whiteSpace: "wrap" }}>{formatDate(item.created_at)}</td>
                                <td style={{ textAlign: "left", width: 60, whiteSpace: "wrap" }}>{item.direction === "1" ? "DR" : "CR"}</td>
                                <td style={{ padding: 5 }}>
                                  {item.status !== '1' && item.status !== '2' && (
                                    <>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Click to approve this account</Tooltip>}
                                      >
                                        <FaCalendarCheck onClick={() => handleApprove(item.id)} style={{ height: 22, color: "#2d995f", cursor: 'pointer' }} />
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Click to disapprove this account</Tooltip>}
                                      >
                                        <FaCalendarXmark style={{ height: 22, color: "#dc3545", cursor: 'pointer', marginLeft: 10 }} onClick={() => handleShow(item.id)} />
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Click to delete this account</Tooltip>}
                                      >
                                      <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                          <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                      </OverlayTrigger>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {tableData.map((item, index) => (
                              <tr key={index}>
                                <td style={{ width: 20 }}>{index + 1}</td>
                                <td>{item.gl_code}</td>
                                <td style={{ textAlign: "left", whiteSpace: "wrap", width: 300 }}>{item.gl_name}</td>
                                <td style={{ width: 60, whiteSpace: "wrap" }}>{item.class?.description}</td>
                                <td style={{ width: 120, whiteSpace: "wrap" }}>{item.category?.description}</td>
                                <td style={{ textAlign: "left", width: 200, whiteSpace: "wrap" }}>{item.subcategory?.description}</td>
                                <td style={{ textAlign: "left", width: 200, whiteSpace: "wrap" }}>{item.created_by?.name}</td>
                                <td style={{ textAlign: "left", width: 200, whiteSpace: "wrap" }}>{formatDate(item.created_at)}</td>
                                <td style={{ textAlign: "left", width: 60, whiteSpace: "wrap" }}>{item.direction === "1" ? "DR" : "CR"}</td>
                                <td style={{ padding: 5 }}>
                                  {item.status !== '1' && item.status !== '2' && (
                                    <>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Click to delete this account</Tooltip>}
                                      >
                                        <FaCalendarCheck onClick={() => handleApprove(item.id)} style={{ height: 22, color: "#2d995f", cursor: 'pointer' }} />
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Click to disapprove this account</Tooltip>}
                                      >
                                        <FaCalendarXmark style={{ height: 22, color: "#dc3545", cursor: 'pointer', marginLeft: 10 }} onClick={() => handleShow(item.id)} />
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Click to delete this account</Tooltip>}
                                      >
                                      <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                          <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                      </OverlayTrigger>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className={classes.endded}>
                <p>
                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                </p>
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, page) => {
                    // Show only 5 pages or less if available
                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                      return (
                        <button
                          key={page + 1}
                          style={{
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                            fontSize: '14px',
                            fontFamily: 'nunito',
                            fontWeight: 400,
                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                            backgroundColor: page + 1 === currentPage ? '#2d995f' : 'gainsboro',
                            height: '2.5rem',
                            borderRadius: '89px',
                            padding: '0.5rem',
                            border: 'none',
                            width: '40px',
                            cursor: "pointer"
                          }}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    }
                    return null;
                  })}
                  <button
                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>






    </div >
  )
}