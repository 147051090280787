import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MobileBudget.module.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { NavLink, Link } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { BASE_URL } from "../../Pages/api/api";

const MobileBudget = ({ setOpenNav }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [bearer, setBearer] = useState("");
  const [permits, setPermits] = useState([]);
  const [user, setUser] = useState("");
  const [validity, setValidity] = useState("");
  const [admin, setAdmin] = useState("");
  const [company, setCompany] = useState("");
  const [currMonth, setCurrMonth] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpenNav(false);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value3 = await AsyncStorage.getItem("admin");
      const value7 = await AsyncStorage.getItem("companyName");
      const value8 = await AsyncStorage.getItem("validity");
      const curr = await AsyncStorage.getItem("currentMonth");
      const permitsss = await AsyncStorage.getItem("modules");

      if (value !== null && value.trim() !== "") {
        setBearer(value);
      } else {
        navigate("/login");
        return;
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map((permit) => permit.name);
        setPermits(permitNames);
      }
      if (value1 !== null) setUser(value1);
      if (value8 !== null) setValidity(value8);
      if (value3 !== null) setAdmin(value3);
      if (value7 !== null) setCompany(value7);
      if (curr !== null) setCurrMonth(curr);
    } catch (e) {
      alert("Failed to fetch data from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      navigate("/login");
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.mobileMenu}>
      <div className={classes.mobileBgr}>
        <div className={classes.closeMenu}>
          <button onClick={() => setOpenNav(false)}>
            <IoMdClose size={24} />
          </button>
        </div>
        <ul className={classes.menuMobile}>
          <li className={classes.menuItem}>
            <Link to="/accounting" onClick={handleNavigation}>
              Dashboard
            </Link>
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("setup")}
            >
              <span>Setup</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "setup" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/budget/continent"
                  onClick={() => handleNavigation("/budget/continent")}
                  className={classes.dropdownItem}
                >
                  Manage Continents
                </Link>
                <Link
                  to="/budget/region"
                  onClick={() => handleNavigation("/budget/region")}
                  className={classes.dropdownItem}
                >
                  Manage Region
                </Link>
                <Link
                  to="/budget/province"
                  onClick={() => handleNavigation("/budget/province")}
                  className={classes.dropdownItem}
                >
                  Manage Province
                </Link>
                <Link
                  to="/budget/user_management"
                  onClick={() => handleNavigation("/budget/user_management")}
                  className={classes.dropdownItem}
                >
                  Manage Users
                </Link>
                <Link
                  to="/budgets/users"
                  onClick={() => handleNavigation("/budgets/users")}
                  className={classes.dropdownItem}
                >
                  Assigned Users to Province
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("approvals")}
            >
              <span>Approvals</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "approvals" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/budgets/change_working_month_approvals"
                  onClick={() =>
                    handleNavigation("/budgets/change_working_month_approvals")
                  }
                  className={classes.dropdownItem}
                >
                  Change Working Month Approvals
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("records")}
            >
              <span>Province Records</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "records" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/budget/customer"
                  onClick={() => handleNavigation("/budget/customer")}
                  className={classes.dropdownItem}
                >
                  Customers
                </Link>
                <Link
                  to="/budget/supplier"
                  onClick={() => handleNavigation("/budget/supplier")}
                  className={classes.dropdownItem}
                >
                  Suppliers
                </Link>
                <Link
                  to="/budget/charts_of_account"
                  onClick={() => handleNavigation("/budget/charts_of_account")}
                  className={classes.dropdownItem}
                >
                  Charts of Account
                </Link>
                <Link
                  to="/budget/loan"
                  onClick={() => handleNavigation("/budget/loan")}
                  className={classes.dropdownItem}
                >
                  Loan Beneficiaries
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("financial")}
            >
              <span>Financial Statements</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "financial" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/budget/cashbook"
                  onClick={() => handleNavigation("/budget/cashbook")}
                  className={classes.dropdownItem}
                >
                  Cashbook
                </Link>
                <Link
                  to="/budget/trial_balance"
                  onClick={() => handleNavigation("/budget/trial_balance")}
                  className={classes.dropdownItem}
                >
                  Trial Balance
                </Link>
                <Link
                  to="/budget/general_ledger/postings"
                  onClick={() =>
                    handleNavigation("/budget/general_ledger/postings")
                  }
                  className={classes.dropdownItem}
                >
                  General Ledger
                </Link>
                <Link
                  to="/budget/income_statement"
                  onClick={() => handleNavigation("/budget/income_statement")}
                  className={classes.dropdownItem}
                >
                  Income Statement - Summary
                </Link>
                <Link
                  to="/budget/income_statement_detailed"
                  onClick={() =>
                    handleNavigation("/budget/income_statement_detailed")
                  }
                  className={classes.dropdownItem}
                >
                  Income Statement - Detailed
                </Link>
                <Link
                  to="/budget/activity_report"
                  onClick={() => handleNavigation("/budget/activity_report")}
                  className={classes.dropdownItem}
                >
                  Account Activity Report
                </Link>
                <Link
                  to="/budget/aged_analysis_payables"
                  onClick={() =>
                    handleNavigation("/budget/aged_analysis_payables")
                  }
                  className={classes.dropdownItem}
                >
                  Age Analysis - Payables
                </Link>
                <Link
                  to="/budget/aged_analysis_receivables"
                  onClick={() =>
                    handleNavigation("/budget/aged_analysis_receivables")
                  }
                  className={classes.dropdownItem}
                >
                  Age Analysis - Receivables
                </Link>
                <Link
                  to="/budget_statement_of_financial_position"
                  onClick={() =>
                    handleNavigation("/budget_statement_of_financial_position")
                  }
                  className={classes.dropdownItem}
                >
                  Statement of Financial Position - Summary
                </Link>
                <Link
                  to="/financial_position_detail"
                  onClick={() => handleNavigation("/financial_position_detail")}
                  className={classes.dropdownItem}
                >
                  Statement of Financial Position - Detailed
                </Link>
                <Link
                  to="/budget/schedule_of_receivable"
                  onClick={() =>
                    handleNavigation("/budget/schedule_of_receivable")
                  }
                  className={classes.dropdownItem}
                >
                  Schedule of Receivables
                </Link>
                <Link
                  to="/budget/schedule_of_payable"
                  onClick={() =>
                    handleNavigation("/budget/schedule_of_payable")
                  }
                  className={classes.dropdownItem}
                >
                  Schedule of Payables
                </Link>
                <Link
                  to="/budget/bank_position_summary"
                  onClick={() =>
                    handleNavigation("/budget/bank_position_summary")
                  }
                  className={classes.dropdownItem}
                >
                  Bank Position Summary
                </Link>
                <Link
                  to="/accounting/statement_of_cashflow_summary_budget"
                  onClick={() =>
                    handleNavigation(
                      "/accounting/statement_of_cashflow_summary_budget"
                    )
                  }
                  className={classes.dropdownItem}
                >
                  Statement of Cash Flow
                </Link>
              </div>
            )}
          </li>

          <li className={classes.menuItem}>
            <Link to="/welcome" onClick={() => handleNavigation("/welcome")}>
              Switch Account
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link to="/accounting" onClick={handleNavigation}>
              Change Password
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link
              to="/accounting"
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              {loading ? "Logging out..." : "Logout"}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileBudget;