import React, { useState, useEffect, useRef } from 'react';
// import "../../../assetss/assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../../../assetss/assets/plugins/metisMenu/metisMenu.min.css";
// import "../../../assetss/assets/plugins/fontawesome/css/all.min.css";
// import "../../../assetss/assets/plugins/typicons/src/typicons.min.css";
// import "../../../assetss/assets/plugins/themify-icons/themify-icons.min.css";
// import "../../../assetss/assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import Footer from '../../Pages/Footer/Footer';
// import { InfoFooter } from '../../InfoFooter';
import Select from 'react-select';
import { toast } from 'react-toastify';
import classes from '../Manage Members/ManageMember.module.css'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Button, Spinner, Accordion, Badge, Form } from 'react-bootstrap';
// import favicon from '../../Im/ages/faviconn.png'
// import TableToPrint from './TableToPrint';
import { useReactToPrint } from 'react-to-print';
import { BASE_URL } from '../api/api';
import CurrencyInput from 'react-currency-input-field';
import PayablesDash from '../Payables Dashboard/PayablesDash';
import PaymentDash from '../Payment Dashboard/PaymentDash';
import LatestDash from '../LatestDashboard/LatestDash';

function ManualEntriesPayment() {

  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [duration, setDuration] = useState('');
  const [selectedLoan, setSelectedLoan] = useState('');
  const [principalAmount, setPrincipalAmount] = useState('');
  const [loanInterest, setLoanInterest] = useState(0.00);
  const [interest, setInterest] = useState(0.00);
  const [totalRepayment, setTotalRepayment] = useState(0.00);
  const [monthlyDeduction, setMonthlyDeduction] = useState('');
  const [cheque, setCheque] = useState('');
  const [selectedCredit, setSelectedCredit] = useState('');
  const [customers, setCustomers] = useState([]);
  const [loans, setLoans] = useState([]);
  const [banks, setBanks] = useState([]);
  const [bankkk, setBankkk] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payableLoading, setPayableLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [expenses, setExpenses] = useState([]);

  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [sICode, setSICode] = useState('');
  const [description, setDescription] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [admin, setAdmin] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState([{ sn: 1, accountName: '', accountCode: '', amount: '' }]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  // const [admin, setAdmin] = useState("");
  // const [userTypes, setUserTypes] = useState('');
  const [continent, setContinent] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };



  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  // const handleReportChange = (e) => {
  //   setSelectedReport(e.target.value);
  // };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const current = await AsyncStorage.getItem('currentMonth');



      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (current !== null) {
        console.log(current);
        const date = new Date(current);
      
        setCurrentMonth(date);
      
        const startOfDateRange = new Date(1900, 0, 1);
     
        const endOfDateRange = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      
        // Format date function as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const day = String(date.getDate()).padStart(2, '0');     
          return `${year}-${month}-${day}`;
        };
      
        setMinDate(formatDate(startOfDateRange));
        setMaxDate(formatDate(endOfDateRange));
      
        console.log("Date Range:", formatDate(startOfDateRange), "to", formatDate(endOfDateRange));
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      accountName: '',
      accountCode: '',
      amount: ''
    };
    setFormData([...formData, newRow]);
    // setSelectedPayment('');
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const handleFormChange = (index, field, value) => {
    const updatedFormData = [...formData];
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
    const formattedValue = numericValue.toFixed(2);

    updatedFormData[index][field] = formattedValue;
    setFormData(updatedFormData);
  };

  const handleAccountChange = (selectedOption, index) => {
    const selectedAccount = selectedOption.value;
    const selectedItem = expenses.find(item => item.id === selectedAccount);
    // const intselectedId = parseInt(selectedAccount);
    const selectedCode = selectedItem?.gl_code
    // const selectedGlCode = subCat.find((item) => item.id === intselectedId) || '';

    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      accountName: selectedAccount,
      accountCode: selectedCode,
    };

    setFormData(updatedFormData);
  };

  // const handleValueChange = (value) => {
  //   setIncomeAmount(value);
  // };

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
    calculateTotalCredit(); // Recalculate total credit whenever table amounts change
  };


  const calculateTotalCredit = () => {
    const total = formData.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
    const formattedTotal = total.toFixed(2);
    setTotalCredit(formattedTotal);
  };


  useEffect(() => {

    calculateTotalCredit();
  }, [formData]);



  const fetchSupplierss = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLoans = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payable-types`, { headers });
      const results = response.data?.data;

      const options1 = results.map((item) => ({
        label: item.description,
        value: item.id,
        // interest: item.interest
      }));
      setLoans(options1);
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBanks = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;
      setBankkk(results);
      const banked = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setBanks(banked);
      console.log(results);
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setBanks([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchExpenses = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      // const banked = results.map((item) => ({
      //   label: item.gl_name,
      //   value: item.id,
      // }));
      setExpenses(results);
      console.log(results);
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setExpenses([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (bearer) {
      fetchSupplierss();
      fetchLoans();
      fetchBanks();
      fetchExpenses();
    }
  }, [bearer]);


  const handleSupplierChange = (selectedOption) => {
    setSelectedCustomer(selectedOption.value);
  }

  const handleLoanTypeChange = (selectedOption) => {
    setSelectedLoan(selectedOption?.value);
  }

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
  }

  const handleCreditChange = (selectedOption) => {
    setSelectedCredit(selectedOption);
  }

  const handleValueChange = (value, name, values) => {
    setPrincipalAmount(value);
  };

  const handleValueChange4 = (value, name, values) => {
    setLoanInterest(value);
  };

  const handleValueChange2 = (value, name, values) => {
    setInterest(value);
  };

  const handleValueChange3 = (value, name, values) => {
    setTotalRepayment(value);
  };

  const handleValueChange9 = (value, name, values) => {
    setMonthlyDeduction(value);
  };

  useEffect(() => {
    const calculateInterestRate = () => {
      const calculatedInterestRate = (parseFloat(loanInterest) / 100) * parseFloat(principalAmount);
      const formattedInterestRate = isNaN(calculatedInterestRate) ? "0.00" : calculatedInterestRate.toFixed(2);

      setInterest(formattedInterestRate);
    };

    calculateInterestRate();
  }, [loanInterest, principalAmount]);


  useEffect(() => {
    const calculateTotalRepayment = () => {
      const calculatedTotalRepayment = parseFloat(principalAmount) + parseFloat(interest);
      const formattedTotalRepayment = isNaN(calculatedTotalRepayment) ? "0.00" : calculatedTotalRepayment.toFixed(2);

      setTotalRepayment(formattedTotalRepayment);
    };

    calculateTotalRepayment();
  }, [interest, principalAmount]);

  useEffect(() => {
    const calculateReturn = () => {
      const calculatedReturn = parseFloat(totalRepayment) / parseFloat(duration);
      const formattedReturn = isNaN(calculatedReturn) ? "0.00" : calculatedReturn.toFixed(2);

      setMonthlyDeduction(formattedReturn);
    };

    calculateReturn();
  }, [totalRepayment, duration]);


  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);

  const createPayable = async () => {
    setPayableLoading(true);



    try {
      const formDatas = new FormData();
      const accountNames = formData.map((row) => row.accountName).filter((name) => name !== undefined);
      const amounts = formData.map((row) => row.amount).filter((name) => name !== undefined);

      accountNames.forEach((name, index) => {
        formDatas.append(`account_id[${index}]`, name);
      });

      amounts.forEach((amount, index) => {
        formDatas.append(`account_amount[${index}]`, amount);
      });
      formDatas.append('credit_gl_code', selectedBank);
      formDatas.append('description', description);
      formDatas.append('amount', principalAmount);
      formDatas.append('transaction_date', selectedDate);
      formDatas.append('invoice_number', sICode);
      formDatas.append('supplier_id', selectedCustomer);
      formDatas.append('payable_type', selectedLoan);
      if (selectedImage) {
        formDatas.append('supporting_document', selectedImage);
      }

      const response = await axios.post(`${BASE_URL}/post-account-payable`,
        formDatas,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log(response.data.message)

      navigate(-1);

      toast.success(response.data.message);
      console.log(response.data);
      console.log(response.data.message)
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      toast.error(errorMessage);
      console.log(errorMessage);
    } finally {
      setPayableLoading(false);
    }
  };


  const acc = expenses.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));



  return (
    <div>
      <LatestDash />
      <div className='newBody'>
        <div className={classes.newWidth}>
          <h3 style={{ fontSize: 20, fontWeight: 700, marginTop: '7rem' }}>Create Payment Voucher</h3>
          <div className={classes.topPadding}>
            <div className={`${classes.formSecCont}`}>
              <div className="card-body" style={{ border: 'none' }}>


                <div className="row" style={{ paddingTop: '2rem' }}>
                  <div className={`${classes.formSection} ${classes.formSectionGap}`}>

                    <div className="col-md-6" style={{ width: "100%" }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Supplier:</label>
                        <div className="col-sm-8">
                          <Select
                            // value={selectedCustomer}
                            className={classes.select}
                            onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                            options={selectOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="col-md-6" style={{ width: "100%" }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Transaction Date</label>
                        <div className="col-sm-8">
                          <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate}
                            min={minDate}
                            max={maxDate}

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 2 }} />

                  <div className="col-md-3" style={{ width: "100%" }}>
                    <div className="form-group row" style={{}}>
                      <label for="example-text-input" className="col-sm-2 col-form-label font-weight-400" style={{ textAlign: "right" }}>Description</label>
                      <div className="col-sm-10">
                        <textarea
                          style={{ maxWidth: "100%", }}
                          className="form-control"
                          rows="2"
                          cols="10"
                          required=""
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          name="description"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 2 }} />
                  <div className={`${classes.formSection} ${classes.formSectionGap}`}>

                    <div className="col-md-6" style={{ width: "100%" }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Payable Types:</label>
                        <div className="col-sm-8">
                          <Select
                            className={classes.select}
                            onChange={(selectedOption) => handleLoanTypeChange(selectedOption)}
                            options={loans}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '200px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6" style={{ width: "100%" }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Amount</label>
                        <div className="col-sm-8">
                          <CurrencyInput

                            name="principal amount"
                            decimalsLimit={2}
                            className="form-control"
                            value={principalAmount}
                            onValueChange={handleValueChange}
                            style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6" style={{ width: "100%", marginTop: '-10px' }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Invoice Code</label>
                        <div className="col-sm-8">
                          <input className="form-control" required="" type="text" disabled value={sICode} name="invoice" />


                        </div>
                      </div>
                    </div>
                    <div className="col-md-6" style={{ width: "100%", marginTop: '-10px' }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Supporting Document</label>
                        <div className="col-sm-8">

                          <Form.Control
                            type="file"
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={handleImageChange}
                          />

                        </div>
                      </div>
                    </div>

                    <div className="col-md-6" style={{ width: "100%", marginTop: '-10px' }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Credit Account</label>
                        <div className="col-sm-8">
                          <Select
                            // value={selectedBank}
                            className={classes.select}
                            onChange={(selectedOption) => handleBankChange(selectedOption)}
                            options={banks}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                overflowY: 'auto',
                              }),
                            }}
                          />


                        </div>
                      </div>
                    </div>
                    <div className="col-md-6" style={{ width: "100%", marginTop: '-10px' }}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{ textAlign: "right" }}>Cheque No.</label>
                        <div className="col-sm-8">

                          <input className="form-control" required="" type="text" onChange={(e) => setCheque(e.target.value)} name="cheque no" value={cheque} />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div style={{ marginTop: 10 }} />
            {/* <h5 style={{textAlign: "center"}}>Add Bank Accounts</h5> */}

            <div className="row">
              {/* <div className="col-md-6"> */}
              <div className="table-responsive">
                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                  <thead style={{ textAlign: "center", alignItems: "center" }}>
                    <tr>
                      {/* <th>#</th> */}
                      <th style={{ width: '500px', }}>Account Name</th>
                      <th style={{ width: '200px', }}>Account Code</th>
                      <th style={{ width: '200px', }}>Amount</th>
                      <th>
                        <div style={{}}>
                          <div className="btn btn-sm printbtninv" onClick={() => addRow()}>
                            <i className="fas fa-plus" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                    {formData.map((row, index) => (
                      <tr key={index}>
                        {/* <td>{row.sn}</td> */}
                        <td>
                          <Select
                            //   value={row.accountName}
                            onChange={(selectedOption) => handleAccountChange(selectedOption, index)}
                            options={acc}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                // maxWidth: '200px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={row.accountCode}

                            disabled
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                            // decimalsLimit={2}
                            value={row.amount}
                            className="form-control"
                            onValueChange={(value) => handleValueChange1(value, index)}
                            style={{ textAlign: "right", border: "none" }}
                          />
                          {/* <input
                                        type="text"
                                        className="form-control"
                                        value={row.amount}
                                        onChange={(e) => handleFormChange(index, 'amount', e.target.value)}
                                        style={{ textAlign: "right" }}
                                    /> */}
                        </td>
                        <td style={{ textAlign: "center", width: "2rem" }}>
                          <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow(index)}>
                            <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ marginTop: 30 }} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center", gap: '20px' }}>
                  {/* <Form.Group className="mb-3">
                        <Form.Label>Total Debit:</Form.Label>
                        <CurrencyInput
                            //   
                            name="amount"
                            decimalsLimit={2}
                            className="form-control"
                            value={incomeAmount} // Set the value to the balance state
                            disabled
                            style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none"}}
                        />
                    </Form.Group> */}
                  <Form.Group className="mb-3">
                    <Form.Label>Total Amount:</Form.Label>
                    <CurrencyInput
                      //   
                      name="amount"
                      decimalsLimit={2}
                      className="form-control"
                      value={totalCredit} // Set the value to the balance state
                      disabled
                      style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                    />
                  </Form.Group>
                </div>

              </div>
            </div>

            <div style={{ marginTop: 20 }} />
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
              <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
              <Button disabled={parseFloat(principalAmount) !== parseFloat(totalCredit) ? true : false} style={{ borderRadius: 5, marginLeft: 10, }} variant='success' onClick={() => createPayable()}>
                {payableLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                  </>
                ) : (
                  "Create Payment Voucher"
                )}
              </Button>

            </div>
          </div>



        </div>
      </div>
      {/* <InfoFooter /> */}
    </div>
  )
}

export default ManualEntriesPayment;