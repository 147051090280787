import React, { useState, useEffect } from 'react';
import classes from './Province.module.css';
// import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import LatestAdminDash from '../LatestDashboard/LatestAdminDash';
import LatestBudgetDash from '../LatestDashboard/LatestBudgetDash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MobileBudget from '../../Component/MobileBudget/MobileBudget';


export default function BudgetProvince() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [provinceLoading, setProvinceLoading] = useState(false);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData44, setTableData44] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loads, setLoads] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [eyeClicked, setEyeClicked] = useState(false);
  const [createProvLoading, setCreateProvLoading] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [eyeClicked1, setEyeClicked1] = useState(false);
  const [trashClicked1, setTrashClicked1] = useState(false);
  const [eyeClicked2, setEyeClicked2] = useState(false);
  const [trashClicked2, setTrashClicked2] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage1, setEntriesPerPage1] = useState(100);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [entriesPerPage2, setEntriesPerPage2] = useState(100);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [description, setDescription] = useState('')
  const [description1, setDescription1] = useState('')
  const [accountantName, setAccountantName] = useState("");
  const [accountantName1, setAccountantName1] = useState("");
  const [accountantPhone, setAccountantPhone] = useState("");
  const [accountantPhone1, setAccountantPhone1] = useState("");
  const [accountantEmail, setAccountantEmail] = useState("");
  const [accountantEmail1, setAccountantEmail1] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [tableData12, setTableData12] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData33, setTableData33] = useState([]);
  const [admin, setAdmin] = useState('');
  const [roleLoading, setRoleLoading] = useState(false);

  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [openNav,setOpenNav] = useState(false);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });

      // console.log(response);
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData(results);
      setTableData12(options);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const fetchRegions = async () => {
    setRegionLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions`, { headers });

      // console.log(response);
      const reg = response.data?.data?.regions;
      setTableData1(reg);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData1([]);
      }
    } finally {
      setRegionLoading(false);
    }
  };
  const fetchProvinces = async () => {
    setProvinceLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/provinces`, { headers });

      // console.log(response);
      const prov = response.data?.data.provinces;
      setTableData2(prov);
      // console.log(prov);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData2([]);
      }
    } finally {
      setProvinceLoading(false);
    }
  };

  const fetchSubContinents = async (selectedContinent) => {
    setLoad(true);

    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });
      const subCont = response.data?.data;
      const options1 = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData3(subCont);
      setTableData33(options1);
      console.log("Regions:", subCont);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchSubContinents1 = async (selectedContinent1) => {
    setLoad(true);

    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent1}`, { headers });
      const subCont = response.data?.data;
      setTableData3(subCont);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };
  // console.log("tableData3:", tableData3)

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  useEffect(() => {
    if (bearer && selectedContinent1) {
      fetchSubContinents1(selectedContinent1);
    }
  }, [bearer, selectedContinent1]);



  useEffect(() => {
    if (bearer) {
      fetchContinents();
      fetchRegions();
      fetchProvinces();

    }
  }, [bearer]);

  const handleContinent = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion('');

  };


  const handleRegion = (selectedOption) => {
    setSelectedRegion(selectedOption.value)


  }

  const fetchSubRegion = async () => {
    setTableData2([]);
    setLoads(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

      // console.log(response);
      const subCont1 = response.data?.data;
      // const options2 = subCont1.map((item) => ({
      //   label: item.description,
      //   value: item.id,
      // }));
      setTableData44(subCont1);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData44([]);
      }
    } finally {
      setLoads(false);
    }
  };

  const createContinent = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/continents/create_continent`,
        {
          description: description,

        },
        { headers }
      );
      console.log(response)
      fetchContinents();
      handleClose();

      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };
  const createRegion = async () => {
    setCreateRegionLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/regions/create_region`,
        {
          description: description,
          continent_id: selectedContinent,

        },
        { headers }
      );
      console.log(response)
      fetchRegions();
      handleClose();

      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setCreateRegionLoading(false);
    }
  };
  const createProvince = async () => {
    setCreateProvLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/create_province`,
        {
          description: description,
          continent_id: selectedContinent,
          region_id: selectedRegion,
          as_at: selectedDate,
        },
        { headers }
      );
      console.log(response)
      fetchProvinces();
      handleClose();

      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setCreateProvLoading(false);
    }
  };



  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const handleContinentChange = async (event) => {
    setSelectedContinent(event.target.value);
    setSelectedRegion("");
  }


  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value)
  }

  const handleContinentChange1 = (event) => {
    setSelectedContinent1(event.target.value)
  }
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value)
  }


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const filteredData1 = tableData1.filter(item => item.description.toLowerCase().includes(searchTerm1.toLowerCase()));

  const totalPages1 = Math.ceil(filteredData1.length / entriesPerPage1);

  const handlePrevPage1 = () => {
    setCurrentPage1(Math.max(currentPage1 - 1, 1));
  };

  const handleNextPage1 = () => {
    setCurrentPage1(Math.min(currentPage1 + 1, totalPages1));
  };

  const totalEntries1 = filteredData1.length;
  const startIndexx1 = (currentPage1 - 1) * entriesPerPage1 + 1;
  const endIndexx1 = Math.min(startIndexx1 + entriesPerPage1 - 1, totalEntries1);
  const displayedData1 = filteredData1.slice(startIndexx1 - 1, endIndexx1);

  const filteredData2 = tableData2.filter(item => item.description.toLowerCase().includes(searchTerm2.toLowerCase()));

  const totalPages2 = Math.ceil(filteredData2.length / entriesPerPage2);

  const handlePrevPage2 = () => {
    setCurrentPage2(Math.max(currentPage2 - 1, 1));
  };

  const handleNextPage2 = () => {
    setCurrentPage2(Math.min(currentPage2 + 1, totalPages2));
  };

  const totalEntries2 = filteredData2.length;
  const startIndexx2 = (currentPage2 - 1) * entriesPerPage2 + 1;
  const endIndexx2 = Math.min(startIndexx2 + entriesPerPage2 - 1, totalEntries2);
  const displayedData2 = filteredData2.slice(startIndexx2 - 1, endIndexx2);



  const handleEyeClick = (id) => {
    const foundContinent = tableData.find(item => item.id === id);
    setSelectedContinent(foundContinent);
    setDescription1(foundContinent?.description || '');
    setShow1(true);
    setEyeClicked(true);
  };

  const handleEyeClick1 = (id) => {
    // Find the selected grade from tableData using the id
    const foundRegion = tableData.find(item => item.id === id);
    setSelectedRegion(foundRegion);
    setDescription1(foundRegion?.description || '');
    setSelectedContinent1(foundRegion?.continent_id || '');
    setShow1(true);
    setEyeClicked(true);
  };

  const handleEyeClick2 = (id) => {
    const foundProvince = tableData2.find(item => item.id === id);
    setSelectedProvince(foundProvince);
    console.log(foundProvince);
    setDescription1(foundProvince?.description || '');
    setSelectedContinent1(foundProvince?.continent?.id || '');
    setSelectedRegion1(foundProvince?.region?.id || '');
    setShow1(true);
    setEyeClicked(true);
  };

  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this continent. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
      try {
        const response = await axios.get(`${BASE_URL}/continents/delete_continent?id=${id}`, { headers });
        fetchContinents();
        toast.success(response.data.message);
        setTrashClicked(true);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The deletion was cancelled.',
      });
    }
  };

  const handleTrashClick1 = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this region. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
      try {
        const response = await axios.get(`${BASE_URL}/regions/delete_region?id=${id}`, { headers });
        fetchRegions();
        toast.success(response.data.message);
        setTrashClicked(true);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The deletion was cancelled.',
      });
    }
  };

  const handleTrashClick2 = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this province. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
      try {
        const response = await axios.get(`${BASE_URL}/provinces/delete_province?id=${id}`, { headers });
        fetchProvinces();
        toast.success(response.data.message);
        setTrashClicked(true);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The deletion was cancelled.',
      });
    }
  };


  const editContinent = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/continents/update_continent`,
        {
          description: description1,
          id: selectedContinent.id

        },
        { headers }
      );

      fetchContinents();

      toast.success(response.data.message);

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

      // Function to export data as Excel
      // const handleExportExcel = () => {
      //   const worksheet = XLSX.utils.json_to_sheet(displayedData2);
      //   const workbook = XLSX.utils.book_new();
      //   XLSX.utils.book_append_sheet(workbook, worksheet, "TableData");
      //   XLSX.writeFile(workbook, "Manage Province.xlsx");
      // };

      const handleExportExcel = () => {
        // Define the column headers for the Excel export
        const tableHeaders = ["S/N", "PROVINCE", "REGION", "CONTINENT", "CREATED AT", "UPDATED AT"];
        
        // Create the rows based on displayedData2
        const tableRows = tableData2.map((item, index) => [
          index + 1,
          item.description,
          item.region?.description,
          item.continent?.description,
          formatDate(item.last_date),
          formatDate(item.current_month)
        ]);
      
        // Create an array with headers and rows
        const dataToExport = [tableHeaders, ...tableRows];
      
        // Convert the data to a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "TableData");
        
        // Write the file
        XLSX.writeFile(workbook, "Manage Province.xlsx");
      };
  
      // Function to export data as PDF
      const handleExportPDF = () => {
        const doc = new jsPDF();
        const tableColumn = ["S/N", "PROVINCE", "REGION", "CONTINENT", "CREATED AT", "UPDATED AT"];
        const tableRows = [];
  
        tableData2.forEach((item, index) => {
          const rowData = [
            index + 1,
            item.description,
            item.region?.description,
            item.continent?.description,
            formatDate(item.last_date),
            formatDate(item.current_month)
          ];
          tableRows.push(rowData);
        });
  
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
        });
  
        doc.save("Manage Province.pdf");
      };
  
      // Function to copy data to clipboard
      const handleCopy = () => {
        const tableData = tableData2.map((item, index) =>
          `${index + 1}\t${item.description}\t${item.region?.description}\t${item.continent?.description}\t${formatDate(item.last_date)}\t${formatDate(item.current_date)}`
        ).join("\n");
  
        return tableData;
      };

      
  const editRegion = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/regions/update_region`,
        {
          description: description1,
          continent_id: selectedContinent1,
          id: selectedRegion.id,
          accountant_name: accountantName1,
          accountant_email: accountantEmail1,
          accountant_phone_number: accountantPhone1

        },
        { headers }
      );

      fetchRegions();

      toast.success(response.data.message);

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const editProvince = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/update_province`,
        {
          continent_id: selectedContinent1,
          region_id: selectedRegion1,
          description: description1,
          id: selectedProvince.id,

        },
        { headers }
      );

      fetchProvinces();
      handleClose1();
      toast.success(response.data.message);

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13 ? 'th' : suffixes[v % 10] || 'th';
  };

  const formatDate = (dateString) => {
    // Check if dateString is valid
    if (!dateString) return "Invalid date";

    // Assuming dateString is in "YYYY-MM-DD" format
    const [year, month, day] = dateString.split('-').map(Number); // Split by dash and convert to numbers

    // Create a Date object
    const date = new Date(year, month - 1, day); // month is zero-indexed for Date

    if (isNaN(date.getTime())) {
      return "Invalid date"; // Handle invalid dates
    }

    // Format date to "23rd October, 2024"
    const dayOfMonth = date.getDate();
    const monthName = date.toLocaleString('default', { month: 'long' });
    const yearNumber = date.getFullYear();

    return `${dayOfMonth}${getOrdinalSuffix(dayOfMonth)} ${monthName}, ${yearNumber}`;
  };



  return (
    <div>
        {
            openNav && ( 
              <MobileBudget setOpenNav={setOpenNav} openNav={openNav}/>)
        }
      <LatestBudgetDash setOpenNav={setOpenNav} openNav={openNav}/>

      <div className={classes.finishedbodyCont}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <h3>Manage Province</h3>
            <p>Add, View, Edit, and Delete Province</p>
          </div>
          <div style={{ zIndex: 1 }}>
            <div className={classes.formIntBtn}>
              <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Province</Button>
            </div>
          </div>
        </div>



        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <div className={`row ${classes.awrow}`} style={{ marginBottom: 20 }}>
              <div className={`row ${classes.awrow}`} style={{ marginTop: 10 }}>

                {/* {admin === "Continental Accountant" && (
                                    <> */}

                <div className="col-md-4" style={{}}>
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Continent:</label>
                    <div className="col-sm-8">
                      <Select
                        onChange={(selectedOption) => handleContinent(selectedOption)}
                        options={tableData12}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (provided) => ({
                            ...provided,
                            maxHeight: '300px',
                            maxWidth: '300px',
                            overflowY: 'auto',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>


                <div className="col-md-4" style={{ marginLeft: '-125px' }}>
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
                    <div className={`col-sm-8  ${classes.lblrow}`}>
                      <Select
                        onChange={(selectedOption) => handleRegion(selectedOption)}
                        options={tableData33}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (provided) => ({
                            ...provided,
                            maxHeight: '300px',
                            maxWidth: '300px',
                            overflowY: 'auto',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>





                <Button onClick={() => fetchSubRegion()} style={{ backgroundColor: '#2d995f', color: "#ffffff", borderColor: '#e7e7e7', maxWidth: 'fit-content', maxHeight: 'fit-content', marginTop: 35, marginLeft: '-80px' }}>
                  {loads ? (
                    <>
                      <Spinner size='sm' />
                    </>
                  ) : (
                    "Process"
                  )}
                </Button>
                {/* </>
                               )} */}



              </div>

            </div>


            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
              </div>
              {/* <div className={classes.formIntBtn}>
               <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Province</Button>
             </div> */}
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Province</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                    <Form.Label>Continents</Form.Label>

                    <Form.Select
                      className="form-control"
                      value={selectedContinent}
                      onChange={handleContinentChange}
                    >
                      <option value="" disabled>Select Continent</option>
                      {tableData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Region</Form.Label>

                    <Form.Select
                      className="form-control"
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      placeholder='Select Region'
                    >
                      <option value="" disabled>Select Region</option>
                      {tableData3.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"

                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                    />

                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createProvince}>
                  {createProvLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating province, Please wait...</span>
                    </>
                  ) : (
                    "Create Province"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Province</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                    <Form.Label>Continents</Form.Label>

                    <Form.Select
                      className="form-control"
                      value={selectedContinent1}
                      onChange={handleContinentChange1}
                    >
                      <option value="" disabled>Select Continent</option>
                      {tableData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Region</Form.Label>

                    <Form.Select
                      className="form-control"
                      value={selectedRegion1}
                      onChange={handleRegionChange1}
                      placeholder='Select Region'
                    >
                      <option value="" disabled>Select Region</option>
                      {tableData3.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"

                      value={description1}
                      onChange={(e) => setDescription1(e.target.value)}
                    />

                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Admin Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Admin Name"
                              
                              value={adminName1}
                              onChange={(e) => setAdminName1(e.target.value)}
                            /> */}
                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Admin Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Admin Phone Number"
                              
                              value={adminPhone1}
                              onChange={(e) => setAdminPhone1(e.target.value)}
                            /> */}
                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Admin Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Admin Email Address"
                              
                              value={adminEmail1}
                              onChange={(e) => setAdminEmail1(e.target.value)}
                            />                                */}

                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>
                <Button variant="success" onClick={editProvince} >
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Updating province, Please wait...</span>
                    </>
                  ) : (
                    "Update Province"
                  )}
                </Button>


              </Modal.Footer>
            </Modal>




            <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
              <div className={classes.greenbtn} style={{ display: 'flex', gap: '20px' }}>
                <div className={classes.actionsBtns}>
                  <CopyToClipboard text={handleCopy()}>
                    <Button variant='success'>Copy</Button>
                  </CopyToClipboard>
                  <Button variant='success' onClick={handleExportExcel}>Excel</Button>
                  <Button variant='success' onClick={handleExportPDF}>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm2}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm2(e.target.value)}
                    />
                    {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                  </div>


                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {(provinceLoading || loads) ? (
                <p>Fetching provinces...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Province</th>
                        <th>Region</th>
                        <th>Continent</th>
                        <th>Last Date</th>
                        <th>Current Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap', }}>

                      {displayedData2.map((item, index) => (
                        <tr key={index}>
                          <td style={{ paddingLeft: 10 }}>{index + 1}</td>
                          <td style={{ paddingLeft: 10, whiteSpace: "wrap", width: "300px" }}>{item.description}</td>
                          <td style={{ paddingLeft: 10, whiteSpace: "wrap", width: "200px" }}>{item.region?.description}</td>
                          <td style={{ paddingLeft: 10, whiteSpace: "wrap", width: "200px" }}>{item.continent?.description}</td>
                          <td>{formatDate(item.last_date)}</td>
                          <td>{formatDate(item.current_month)}</td>
                          <td style={{ display: "flex", width: 100, justifyContent: "center", alignItems: "center" }}>

                            <div
                              style={{
                                height: 15,
                                width: 70,
                                border: `1px solid ${item.activeness === '1' ? '#50b848' : '#ff5400'}`,
                                borderRadius: 3,
                                color: `${item.activeness === '1' ? '#50b848' : '#ff5400'}`,
                                fontSize: 10,
                                textAlign: "center",
                                fontWeight: 700
                              }}
                            >
                              {item.activeness === '0' ? 'NOT UPDATED' : 'UPDATED'}
                            </div>

                          </td>


                          {/* <td>{item.current_date}</td> */}
                          <td>
                            <div onClick={() => handleEyeClick2(item.id)} className="btn btn-success-soft btn-sm mr-1">
                              <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            <div onClick={() => handleTrashClick2(item.id)} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {tableData44.map((item, index) => (
                        <tr key={index}>
                          <td style={{ paddingLeft: 10 }}>{index + 1}</td>
                          <td style={{ paddingLeft: 10, whiteSpace: "wrap", width: "300px" }}>{item.description}</td>
                          <td style={{ paddingLeft: 10, whiteSpace: "wrap", width: "200px" }}>{item.region?.description}</td>
                          <td style={{ paddingLeft: 10, whiteSpace: "wrap", width: "200px" }}>{item.continent?.description}</td>
                          {/* <td style={{paddingLeft: 10}}>{item.accountant_name}</td>
                                        <td style={{paddingLeft: 10}}>{item.accountant_email}</td>
                                        <td style={{paddingLeft: 10}}>{item.accountant_phone_number}</td> */}
                          <td>{item.last_date}</td>
                          <td>{item.current_month}</td>
                          {/* <td style={{ display: "flex", width: 100, justifyContent: "center", alignItems: "center" }}>
                              {item.salesinvoice?.balance === "0.00" ? (
                                <div style={{ height: 15, width: 50, border: "1px solid #50b848", borderRadius: 3, color: "#50b848", fontSize: 10, textAlign: "center", fontWeight: 700 }}>PAID</div>
                              ) : (
                                <div style={{ height: 15, width: 50, border: "1px solid #ff5400", borderRadius: 3, color: '#ff5400', fontSize: 10, textAlign: "center", fontWeight: 700 }}>PENDING</div>
                              )}
                            </td> */}
                          <td>{item.current_date}</td>
                          <td>
                            <div onClick={() => handleEyeClick2(item.id)} className="btn btn-success-soft btn-sm mr-1">
                              <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            <div onClick={() => handleTrashClick2(item.id)} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx2} to {endIndexx2} of {totalEntries2} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage2}
                  disabled={currentPage2 === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages2)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage2 - 1 || page === totalPages2 - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage2 ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage2 ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage2(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage2}
                  disabled={currentPage2 === totalPages2}
                >
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>






    </div >
  )
}