import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Dashboard from './Pages/Dashboard.js';

import GeneralPaymentVoucher from './Pages/PaymentVouchers/GeneralPaymentVoucher/GeneralPaymentVoucher.js';
import PaymentVoucherForm from './Pages/PaymentVouchers/PaymentVoucherForm/PaymentVoucherForm.js';
// import GeneralLedger from './Pages/General Ledger/GeneralLedger.js';
import SignUp from './Promix/Signup/Signup.js';
import GeneralLedgerTable from './Pages/General Ledger Table/GeneralLedgerTable.js';
import CreatePaymentVoucher from './Pages/PaymentVouchers/Create Payment Voucher/CreatePaymentVoucher.js';
import SalesReceipt from './Pages/Sales Receipt/SalesReceipt.js';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Promix/Login/Login.js';
import MainDashboard from './Pages/Main Dashboard/MainDashoard.js';
import Dashboard from './Pages/Dashboard/Dashboard.js';
import ManageRole from './Pages/Manae Role/ManageRole.js';
import ApprovalLevel from './Pages/Manae Approval Level/ApprovalLevel.js';
import Members from './Pages/Manage Cooperatives/Manage Members/Members.js';
import ManageRoles from './Pages/Manae Role/ManageRole.js';
import CreateRole from './Pages/Manae Role/CreateRole.js';
import { ToastContainer } from 'react-toastify';
import EditMember from './Pages/Manage Cooperatives/Manage Members/EditMember.js';
import SavingsAccounts from './Pages/Manage Cooperatives/Manage Savings Account/SavingAccount.js';
import EditSavings from './Pages/Manage Cooperatives/Manage Savings Account/EditSavings.js';
import Cashbook from './Pages/Cashbook/Cashbook.js';
import EditRole from './Pages/Manae Role/EditRole.js';
import CreateApproval from './Pages/Manae Approval Level/CreateApproval.js';
import ManageUser from './Pages/Manage User/ManageUser.js';
// import AllReceivables from './Pages/Manage User/AllReceiables.js';
import AllReceivables from './Pages/Manage Customers/AllAvailablesRec.js';
import ManageSalesRep from './Pages/Manage SalesRep/ManageSales.js';
import ManageCategory from './Pages/Manage Category/ManageCategory.js';
import Charts from './Pages/Charts of Account/Charts.js';
import CreateSavings from './Pages/Manage Cooperatives/Manage Savings Account/CreateSavings.js';
import LoanAccounts from './Pages/Manage Cooperatives/Manage Loan/LoanAccount.js';
import CreateLoan from './Pages/Manage Cooperatives/Manage Loan/CreateLoan.js';
import EditLoan from './Pages/Manage Cooperatives/Manage Loan/EditLoan.js';
import Deduction from './Pages/Manage Cooperatives/Manage Deductions/Deduction.js';
import TrialBalance from './Pages/Trial balance/Trial_balance.js';
import IncomeExpenditure from './Pages/Income and Expenditure/Income_Expenditure.js';
import MonthlyIncome from './Pages/Monthly Income/MonthlyIncome.js';
import BalanceSheet from './Pages/Balance Sheet/BalanceSheet.js';
import EmployeeMembers from './Pages/Receiveables/Manage Members/Members.js';
import EditEmployeeMember from './Pages/Receiveables/Manage Members/EditMember.js';
import BalanceSheetPrint from './Pages/Balance Sheet Print/BalanceSheetPrint.js';
import LoanRepayment from './Pages/Manage Cooperatives/Loan Repayment/LoanRepayment.js';
import ProcessGeneral from './Pages/Process-General-ledger/ProcessGeneral.js';
import ProcessCashBook from './Pages/Process-CashBook/ProcessCashBook.js';
import ProcessActivityReport from './Pages/Process-Activity-Report/ProcessActivityReport.js';
import IncomeProcess from './Pages/Income & expend/IncomeProcess.js';
import MonthlyIncomeProcess from './Pages/Monthly Income & Expend/MonthlyIncomeProcess.js';
import SalesInvoice from './Pages/Receiveables/Sales Invoice/SalesInvoice.js';
import CreateSales from './Pages/Receiveables/Sales Invoice/CreateSales.js';
import EditInvoice from './Pages/Receiveables/Sales Invoice/EditInvoice.js';
import Invoice from './Pages/Receiveables/Sales Invoice/Invoice.js';
import ManageBooking from './Pages/Receiveables/Advance Booking/ManagingBooking.js';
import CreateBooking from './Pages/Receiveables/Advance Booking/CreateBooking.js';
import EditBooking from './Pages/Receiveables/Advance Booking/EditBooking.js';
import BookingReceipt from './Pages/Receiveables/Advance Booking/BookingReceipt.js';
import LoanRepaymentExcel from './Pages/Manage Cooperatives/Loan Repayment Excel/LoanRepaymentExcel.js';
import ManageSavings from './Pages/Manage Cooperatives/Manage Savings/ManageSavings.js';
import CreateSavingsApp from './Pages/Manage Cooperatives/Manage Savings/CreateSavingsApp.js';
import SavingExcel from './Pages/Manage Cooperatives/Manage Savings/SavingExcel.js';
import Supplier from './Pages/Manage Payables/Manage Suppliers/Supplier.js';
import SavingsRepayment from './Pages/Receiveables/Advance Booking Pyaments/AdvanceBooking.js';
import AdvanceBooking from './Pages/Receiveables/Advance Booking Pyaments/AdvanceBooking.js';
import PaymentVoucher from './Pages/Manage Payables/Manage Payment Voucher/PaymentVoucher.js';
import ManageLoans from './Pages/Manage Cooperatives/Manage Loan/ManageLoans.js';
import DisburseLoan from './Pages/Manage Cooperatives/Manage Loan/DisburseLoan.js';
import EditDisburseLoan from './Pages/Manage Cooperatives/Manage Loan/EditDisburse.js';
import SavingsWithdrawals from './Pages/Manage Cooperatives/Manage Savings/SavingsWithdrawals/SavingsWithdrawals.js';
import SavingsDeduction from './Pages/Manage Cooperatives/Manage Savings/SavingsDeduction.js';
import LoanDeduction from './Pages/Manage Cooperatives/Manage Savings/LoanDeduction.js';
import SavingsLedger from './Pages/Manage Cooperatives/Manage Savings Account/SavingsLedger.js';
import LoanLedger from './Pages/Manage Cooperatives/Manage Loan/LoanLedger.js';
import ReportLedger from './Pages/Manage Cooperatives/Manage Loan/Report/Report.js';
import Welcome from './Pages/Welcome.js';
import Forbidden from './Pages/Forbidden.js';
import ViewPaymentVoucher from './Pages/Manage Payables/Manage Payment Voucher/ViewPendingPaymentVoucer.js';
import PrintVoucher from './Pages/Manage Payables/Manage Payment Voucher/PrintVoucher.js';
import AccountingDashboard from './Pages/Dashboard/AccountingDashboard.js';
import CompletedPaymentVoucher from './Pages/Manage Payables/Manage Payment Voucher/CompletedPaymentVoucher.js';
import ViewCompletedPaymentVoucher from './Pages/Manage Payables/Manage Payment Voucher/ViewCompletedPaymentVoucer.js';
import BulkPaymentExcel from './Pages/Manage Payables/Manage Payment Excel/BulkPaymentExcel.js';
import BulkEntries from './Pages/Manage Payables/Manage Payment Excel/BulkEntries.js';
import Schedule from './Pages/Manage Payables/Schedule of payment/Schedule.js';
import CreateSavingsWithdrawals from './Pages/Manage Cooperatives/Manage Savings/SavingsWithdrawals/CreateSavingsWithdrawals.js';
import CreateLoanRepayment from './Pages/Loan Payment/CreateLoanRepayment.js';
import LoanRepay from './Pages/Loan Payment/LoanRepay.js';
import SavingsPayment from './Pages/Savings Payment/SavingsPayment.js';
import CreateSavingsPayment from './Pages/Savings Payment/CreateSavingsPayment.js';
import Savings from './Pages/Manage Payables/Manage Savings/Savings.js';
import AddNewSavings from './Pages/Manage Payables/Manage Savings/AddNewSavings.js';
import ManageGeneralLedger from './Pages/Manage Payables/Manage General Ledger/ManageGeneralLedger.js';
import BudgetManageGeneralLedger from './Pages/Manage Payables/Manage General Ledger/BudgetManageGeneralLedger.js';
import CreateJournal from './Pages/Manage Jurnal Entries/CreateJournal.js';
import AccountReceivables from './Pages/Manage Receivables/AccountReceivables.js';

import EditAccountReceivables from './Pages/Manage Receivables/EditAccountReceivables.js';
import LoanAdvances from './Pages/Manage Receivables/LoandAdvancese.js';
import StaffLoanAdvances from './Pages/Manage Receivables/StaffLoanssss.js';
import CreateNewLoan from './Pages/Manage Receivables/CreateNewLoan.js';
import EditLaonAdvance from './Pages/Manage Receivables/EditLoanAdvance.js';
import MaterialCost from './Pages/Receiveables/Advance Booking/MaterialCost.js';
import StockDashboard from './Pages/Stock Dashboard/StockDashboard.js';
import StockDash from './Pages/Dashboard/StockDash.js';
import ManageUnit from './Pages/ManageUnit/ManageUnit.js';
import ManageStocks from './Pages/Manage Stocks/ManageStocks.js';
import Services from './Pages/Services/Services.js';
import CreateServices from './Pages/Services/CreateServices.js';
import ViewServices from './Pages/Services/ViewServices.js';
import ViewMaterial from './Pages/Services/ViewMaterial.js';
import ViewLabor from './Pages/Services/ViewLabor.js';
import MainServices from './Pages/Main Services/MainServices.js';
import AdvancePayment from './Pages/Receiveables/Advance Booking Pyaments/AdvancePayment.js';
import ViewAdvance from './Pages/Receiveables/Advance Booking Pyaments/ViewAdvance.js';
import Requisition from './Pages/Requisition/Requisition.js';
import CreateRequistion from './Pages/Requisition/CreateRequistion.js';
import ViewRequisition from './Pages/Requisition/ViewRequisition.js';
import Inflow from './Pages/Inflow/Inflow.js';
import InventoryDashboard from './Pages/Dashboard/InventoryDashboard.js';
import Customers from './Pages/Manage Customers/Customers.js';
import EditCustomer from './Pages/Manage Customers/EditCustomer.js';
import Suppliers from './Pages/Manage Suppliers/Suppliers.js';
import EditSupplier from './Pages/Manage Suppliers/EditSupplier.js';
import PurchaseInvoice from './Pages/Purchase Invoice/PurchaseInvoice.js';
import EditPurchase from './Pages/Purchase Invoice/EditPurchaseInventory.js';
import Purchase from './Pages/Purchase Invoice/Purchase.js';
import CreatePurchase from './Pages/Purchase Invoice/CreatePurchase.js';
import StockDelivery from './Pages/Stock Delivery/StockDelivery.js';
import CreateStockDelivery from './Pages/Stock Delivery/CreateStockDelivery.js';
import Income from './Pages/Main Income and Expenditur/Income.js';
import CreateNewIncome from './Pages/Main Income and Expenditur/CreateNewIncome.js';
import Expenditures from './Pages/Main Income and Expenditur/Expenditures.js';
import CreateNewExpenditure from './Pages/Main Income and Expenditur/CreateNewExpenditure.js';
import ManageSettings from './Pages/Manage Settings/ManageSettings.js';
import ManageReport from './Pages/Manage Report/ManageReport.js';
import NewActDashboard from './Pages/Dashboard/NewActDashboard.js';
import ContinentLedger from './Pages/Redeem General Ledger/ContinentLedger.js';
import RegionLedger from './Pages/Redeem General Ledger/RegionLedger.js';
import ProvinceLedger from './Pages/Redeem General Ledger/ProvinceLedger.js';
import ContinentCashbook from './Pages/Redeem Cashbook/ContinentCashbook.js';
import RegionCashbook from './Pages/Redeem Cashbook/RegionCashbook.js';
import ProvinceCashbook from './Pages/Redeem Cashbook/ProvinceCashbook.js';
import ContinentTrialBalance from './Pages/Redeem TrialBalance/ContinentTrialBalance.js';
import RegionTrialBalance from './Pages/Redeem TrialBalance/RegionTrialBalance.js';
import ProvinceTrialBalance from './Pages/Redeem TrialBalance/ProvinceTrialBalance.js';
import RegionIncome from './Pages/Redeem Income/RegionIncome.js';
import ProvinceIncome from './Pages/Redeem Income/ProvinceIncome.js';
import ContinentIncome from './Pages/Redeem Income/ContinentIncome.js';
import ContinentMonthly from './Pages/Redeem Monthly/ContinentMonthly.js';
import RegionMonthly from './Pages/Redeem Monthly/RegionMonthly.js';
import ProvinceMonthly from './Pages/Redeem Monthly/ProvinceMonthly.js';
import ContinentBalance from './Pages/Redeem Balance/ContinentBalance.js';
import RegionBalance from './Pages/Redeem Balance/RegionBalance.js';
import ProvinceBalance from './Pages/Redeem Balance/ProvinceBalance.js';
import ContinentInflow from './Pages/Redeem Inflow/ContinentInflow.js';
import RegionInflow from './Pages/Redeem Inflow/RegionInflow.js';
import ProvinceInflow from './Pages/Redeem Inflow/ProvinceInflow.js';
import Continent from './Pages/Manage Continent/Continent.js';
import Region from './Pages/Manage Region/Region.js';
import Province from './Pages/Manage Province/Province.js';
import ContinentBudget from './Pages/Manage Continent/ContinentBudget.js';
import RegionBudget from './Pages/Manage Region/RegionBudget.js';
import ProvinceBudget from './Pages/Manage Province/ProvinceBudget.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CashDashboard from './Pages/Dashboard/CashDashboard.js';
import IncomeRevenue from './Pages/Income & Revenue/IncomeRevenue.js';
import CreateIncome from './Pages/Income & Revenue/CreateIncome.js';
import Pending from './Pages/Bank Lodgements/Pending.js';
import CreatePending from './Pages/Bank Lodgements/CreatePending.js';
import Completed from './Pages/Bank Lodgements/Completed.js';
import LodgementReports from './Pages/Reportts/LodgementReports.js';
import Receipt from './Pages/Reportts/Receipt.js';
import MonthlyIncomeSummary from './Pages/Reportts/MonthlyIncome.js';
import CurrencyInflow from './Pages/Reportts/CurrenyInflow.js';
import PaymentDashboard from './Pages/Dashboard/PaymentDashboard.js';
import Zone from './Pages/Manage Zone/Zone.js';
import Area from './Pages/Manage Area/Area.js';
import CreateUser from './Pages/Create User/CreateUser.js';
import EditUser from './Pages/Create User/EditUser.js';
import ContinentActivity from './Pages/Redeem Activity/ContinentActivity.js';
import ReceivablesDashboard from './Pages/Dashboard/ReceivablesDashboard.js';
import ManualEntriess from './Pages/Manage Receivables/ManualEntries.js';
import ReceivableTypes from './Pages/Manage Receivable Types/ReceivableTypes.js';
import AgedReport from './Pages/Loan Payment/AgedReport.js';
import PayablesDashboard from './Pages/Dashboard/PayablesDashboard.js';
import SupplierPayables from './Pages/Manage Suppliers payables/SupplierPayables.js';
import AccountPayables from './Pages/Manage Payables/Manage Payment Excel/AccountPayables.js';
import ManualEntriessPayables from './Pages/Manage Receivables/ManualEntriesPayables.js';
import ManagePayables from './Pages/Manage Cooperatives/Manage Loan/ManagePayables.js';
import DisbursePayables from './Pages/Manage Cooperatives/Manage Loan/DisbursePayables.js';
import EditDisbursePayables from './Pages/Manage Cooperatives/Manage Loan/EditDisbursePayables.js';
import PayableTypes from './Pages/Manage Receivable Types/PayableTypes.js';
import SavingsLedgerPayables from './Pages/Manage Cooperatives/Manage Savings Account/SavingsLedgerPayables.js';
import LoanRepayPayables from './Pages/Loan Payment/LoanRepayPayables.js';
import CreateLoanRepaymentPayables from './Pages/Loan Payment/CreateLoanRepaymentPayables.js';
import AgedReportPayables from './Pages/Loan Payment/AgedReportPayables.js';
import AgedReportPayablesAdmin from './Pages/Loan Payment/AgedReportPayablesAdmin.js';
import BudgetAgedReportPayables from './Pages/Loan Payment/BudgetAgedReportPayables.js';
import EditSuppliersPayables from './Pages/Manage Suppliers payables/EditSuppliersPayables.js';
import EditEmployeeMemberPayable from './Pages/Receiveables/Manage Members/UpdateMember.js';
import UpdateMemberEmployee from './Pages/Receiveables/Manage Members/UpdateMember.js';
import ManagePaymentBank from './Pages/Manage Payment Bank/ManagePaymentBank.js';
import CreateBank from './Pages/Create User/CreateBank.js';
import EditBank from './Pages/Create User/EditBank.js';
import PendingPayment from './Pages/Manage Payables/Manage Payment Voucher/PendingPayment.js';
import BulkPaymentExcelPayment from './Pages/Manage Payables/Manage Payment Excel/BulkPaymentExcelPayment.js';
import BulkEntriesPayment from './Pages/Manage Payables/Manage Payment Excel/BulkEntriesPayment.js';
import EditSupplierss from './Pages/Manage Payables/Manage Suppliers/EditSuppliers.js';
import EditSupplierrrd from './Pages/Manage Payables/Manage Suppliers/EditSupplierrrd.js';
import Payables from './Pages/Manage Payables/Manage Payment Excel/Payables.js';
import ActivityReport from './Pages/Manage Payables/Manage General Ledger/Reports Others/ActivityReport.js';
import ActivityReportAcct from './Pages/Manage Payables/Manage General Ledger/Reports Others/ActivityReportAcct.js';
import BankReport from './Pages/Manage Payables/Manage General Ledger/Reports Others/BankReport.js';
import BudgetActivityReport from './Pages/Manage Payables/Manage General Ledger/Reports Others/BudgetActivityReport.js';
import Cashbooks from './Pages/Manage Payables/Manage General Ledger/Reports Others/Cashbooks.js';
import BudgetCashbooks from './Pages/Manage Payables/Manage General Ledger/Reports Others/BudgetCashbook.js';
import ConsolidatedReport1212 from './Pages/Manage Payables/Manage General Ledger/Reports Others/ConsolidatedReportssss.js';
import TrialBalances from './Pages/Manage Payables/Manage General Ledger/Reports Others/TrialBalances.js';
import IncomeStatements from './Pages/Manage Payables/Manage General Ledger/Reports Others/IncomeStatements.js';
import IncomeStatementsDetail from './Pages/Manage Payables/Manage General Ledger/Reports Others/IncomeStatementsDetail.js';
// import IncomeStatementsSummary from './Pages/Manage Payables/Manage General Ledger/Reports Others/IncomeStatementsSummary.js';
import BudgetIncomeStatements from './Pages/Manage Payables/Manage General Ledger/Reports Others/BudgetIncomeStatements.js';
import StatementFinancial from './Pages/Manage Payables/Manage General Ledger/Reports Others/StatementFinancial.js';
import OTP from './Promix/Login/OTP.js';
import Invoicesss24242 from './Promix/Login/Invoice.js';
import Receiptsss24242 from './Promix/Login/Receiptss.js';
import ModuleSelector from './Pages/Welcome 2/ModuleSelector.js';
import PrintCheque from './Pages/Manage Payables/Manage Payment Voucher/PrintCheque.js';
import PendingPaymentFinal from './Pages/Manage Payables/Manage Payment Voucher/PendingPaymentFinal.js';
import RevenueReport from './Pages/Reportts/RevenueByCurrency.js';
import RevenueByCurrency from './Pages/Reportts/RevenueByCurrency.js';
import RevenueByAccount from './Pages/Reportts/RevenueByAccount.js';
import RevenueReceipt from './Pages/Reportts/RevenueReceipt.js';
import RevenueReceipt2 from './Pages/Reportts/RevenueReceipt2.js';
import ExchangeRate from './Pages/ExchangeRate/ExchangeRate.js';
import PrintActivity from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintActiviy.js';
import PrintBudgetScheduleReceiv from './Pages/Manage Payables/Schedule  of Receivables/PrintBudgetSchedReceiv.js';
import PrintBankReport from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintBankReport.js';
import PrintCash from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintCash.js';
import PrintTrial from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintTrial.js';
import PaymentInstruction from './Pages/Manage Payables/Manage Payment Voucher/PaymentInstruction.js';
import UploadReceivables from './Pages/Manage Cooperatives/Manage Loan/UploadReceivables.js';
import PrintIncome from './Pages/Income & Revenue/PrintIncome.js';
import PrintLodgement from './Pages/Reportts/PrintLodgement.js';
import PrintCurrency from './Pages/Reportts/PrintCurrency.js';
import PrintIncomeStatement from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintIncomeStatement.js';
import PaymentPayable from './Pages/Manage Receivables/PaymentPayable.js';
import ManualEntriesPayment from './Pages/Manage Receivables/ManualEntriesPayment.js';

import ManageOtherReceivables from './Pages/Manage Receivable Types/ManageOtherReceivables.js';
import ScheduleOfReceivables from './Pages/Manage Payables/Schedule  of Receivables/ScheduleOfReceivables.js';
import PettyPayment from './Pages/Manage Payables/Manage Payment Excel/PettyPayment.js';
import PettyPaymentExcel from './Pages/Manage Payables/Manage Payment Excel/PettyPaymentExcel.js';
import ScheduleOfPayPrintActivity from './Pages/Manage Payables/Schedule of payment/ScheduleOfPayPrintActiviy.js';
import ScheduleOfReceivablePrintActivity from './Pages/Manage Payables/Schedule  of Receivables/ScheduleOfReceivablePrintActiviy.js';
import PersonalLedgerReport from './Pages/PersonalLedgerReport/PersonalLedgerReport.js';
import PrintAgedPayable from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintAgedPayable.js';
import PrintAgedReceivables from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintAgedReceivables.js';
import AgedReportReceivables from './Pages/Loan Payment/AgedReportReceivables.js';
import AgedReportReceivablesAdmin from './Pages/Loan Payment/AgedReportReceivablesAdmin.js';
import BudgetAgedReportReceivables from './Pages/Loan Payment/BudgetAgedReportReceivables.js';
import ReceivableScheduleOfReceivables from './Pages/Manage Payables/Receivable Schedule Of Receivables/ReceivableScheduleOfReceivables.js';
import PayableSchedule from './Pages/Manage Payables/Schedule of Payablesnew/SchedulePayables.js';
import PayableSchedulePrintActivity from './Pages/Manage Payables/Schedule of Payablesnew/ScheduleOfPayablesPrintActiviy.js';
import ReceivableScheduleOfReceivablePrintActivity from './Pages/Manage Payables/Receivable Schedule Of Receivables/ReceivableScheduleOfReceivablePrintActivity.js';
import ConsolidatedReport from './Pages/Process-General-ledger/ConsolidateReport.js';
import ConsolidateReportActivity from './Pages/Process-General-ledger/ConsolidateReportActivity.js';
import ConsolidateReportCashbook from './Pages/Process-General-ledger/ConsolidateReportCashbook.js';
import PrintIncomeStatementConsolidated from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintIncomeStatementConsolidated.js';
import PayablePersonalLedgerReport from './Pages/PersonalLedgerReport/PayablePersonalLedgerReport.js';
import UploadRepayment from './Pages/Manage Cooperatives/Manage Loan/UploadRepayment.js';
import JournalDashboard from './Pages/Dashboard/JournalDashboard.js';
import PrintTrialConsolidated from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintTrialConsolidated.js';
import NewDashboard from './Pages/Dashboard/NewDashboard.js';
import Landing from './Pages/Landing.js';
import NewSignup from './Promix/Signup/NewSignup.js';
import NewLogin from './Promix/Signup/NewLogin2.js';

import ViewDeductions from './Pages/Manage Savings/ViewDeductions.js';
import DeductionTransactions from './Pages/Manage Savings/DeductionTransactions.js';
import ModuleSelector1 from './Pages/Welcome 2/ModuleSelector1.js';
import SalesInvoiceReceivable from './Pages/Receiveables/Sales Invoice/SalesInvoiceReceivable.js';
import CreateSalesReceivable from './Pages/Receiveables/Sales Invoice/CreateSalesReceivable.js';
import Expenses from './Pages/Expenses/Expenses.js';
import CreateExpenses from './Pages/Expenses/CreateExpenses.js';
import ManageProducts from './Pages/Manage Stocks/ManageProducts.js';
import CustomersAccount from './Pages/Manage Customers/CustomersAccount.js';
import SalesInvoiceAccount from './Pages/Receiveables/Sales Invoice/SalesInvoiceAccount.js';
import AgedReportAccounting from './Pages/Loan Payment/AgedReportAccounting.js';
import ReceivableScheduleOfReceivablesAccounting from './Pages/Manage Payables/Receivable Schedule Of Receivables/ReceivableScheduleOfReceivablesAccounting.js';
import EditInvoiceAccounting from './Pages/Receiveables/Sales Invoice/EditInvoiceAccounting.js';

import SupplierPayablesAccounting from './Pages/Manage Suppliers payables/SupplierPayables.js';
import PurchaseInvoiceAccounting from './Pages/Purchase Invoice/PurchaseInvoiceAccounting.js';
import CreatePurchaseAccount from './Pages/Purchase Invoice/CreatePurchase.js';
import EditPurchaseInventory from './Pages/Purchase Invoice/EditPurchaseInventory.js';
import EditPurchaseAccounting from './Pages/Purchase Invoice/EditPurchaseAccounting.js';
import CreateSalesAccounting from './Pages/Receiveables/Sales Invoice/CreateSalesAccounting.js';
import LatestDashboard from './Pages/LatestDashboard/LatestDashboard.js';
import LatestAccountingDashboard from './Pages/LatestDashboard/LatestAccountingDashboard.js';
import LatestBudgetDashboard from './Pages/LatestDashboard/LatestBudget.js';
import CreateProducts from './Pages/Manage Stocks/CreateProducts.js';
import EditProducts from './Pages/Manage Stocks/EditProducts.js';
import CreateSalesAcct from './Pages/Receiveables/Sales Invoice/CreateSalesAcct.js';
import CustomersReceipt from './Pages/Receiveables/Sales Invoice/CustomersReceipt.js';
import ScheduleReceivables from './Pages/Manage Payables/Schedule  of Receivables/ScheduleReceivables.js';
import AccountNew from './Pages/AccountingNew/AccountNew.js';
import AddTeamForm from './Pages/AccountingNew/AddTeamForm.js';
import TeamMember from './Pages/AccountingNew/AddTeam.js';
import Chartofaounts from './Pages/AccountingNew/setupchart.js';

import SuppliersReceipt from './Pages/Receiveables/Sales Invoice/SuppliersReceipt.js';
import TeamMemberList from './Pages/AccountingNew/TeamMemberList.js';
import UploadBal from './Pages/AccountingNew/UploadBal.js';
import SelectCompany from './Pages/AccountingNew/SelectCompany.js';
import AccountPaymentVoucher from './Pages/Expenses/AccountPaymentVoucher.js';
import BankTransactions from './Pages/Nw2/BankTransactions.js';
import BankTransactionss from './Pages/Expenses/BankTransactions.js';
import StatementofFinSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/SOFP.js';
import SOFP from './Pages/Manage Payables/Manage General Ledger/Reports Others/SOFP.js';
import SOFPAccount from './Pages/Manage Payables/Manage General Ledger/Reports Others/SOFPAccount.js';
import SOFPAdmin from './Pages/Manage Payables/Manage General Ledger/Reports Others/SOFPAdmin.js';
import StatementofCash from './Pages/Manage Payables/Manage General Ledger/Reports Others/StatementofCashFlow.js';
import PrintCashFlow from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintStatementofCash.js';
import FinancialPosition from './Pages/Manage Payables/Manage General Ledger/Reports Others/FinancialPosition.js';
import FinancialPositionSummary from './Pages/Manage Payables/Manage General Ledger/Reports Others/FinancialPositionSummary.js';
import FinancialPositionDetail from './Pages/Manage Payables/Manage General Ledger/Reports Others/FinancialPositionDetail.js';
import BudgetSOFP from './Pages/Manage Payables/Manage General Ledger/Reports Others/BudgetSOFP.js';
import SalesOrder from './Pages/Receiveables/Sales Invoice/SalesOrder.js';
import ManageQuote from './Pages/Receiveables/Sales Invoice/ManageQuote.js';
import CreateOrder from './Pages/Receiveables/Sales Invoice/CreateOrder.js';
import UpdateOrder from './Pages/Receiveables/Sales Invoice/UpdateOrder.js';
import CreateQuote from './Pages/Receiveables/Sales Invoice/CreateQuote.js';
import PurchaseOrder from './Pages/Purchase Invoice/PurchaseOrder.js';
import CreatePurchaseOrder from './Pages/Purchase Invoice/CreatePurchaseOrder.js';
import PrintBalance from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintBalance.js';
import PrintFinPosBalance from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintFinPosBalance.js';
import CustomersReceipts from './Pages/Receiveables/Sales Invoice/CustomersReceipts.js';
import CustomersPersonalLedger from './Pages/Receiveables/Sales Invoice/CustomersPersonalLedger.js';
import SuppliersReceipts from './Pages/Receiveables/Sales Invoice/SuppliersReceipts.js';
import UnitMeasurement from './Pages/ManageUnit/UnitMeasurement.js';
import ProductCategory from './Pages/ManageUnit/ProductCategories.js';
import TrialBalancesNew from './Pages/Manage Payables/Manage General Ledger/Reports Others/TrialBalancesNew.js';
import BudgetTrialBalancesNew from './Pages/Manage Payables/Manage General Ledger/Reports Others/BudgetTrialBalance.js';
import ManageCompany from './Pages/Manage User/ManageCompany.js';
import CreateCompany from './Pages/Create User/CreateCompany.js';
import CreateSalesRep from './Pages/Create User/CreateSalesRep.js';
import ChangePassword from './Pages/Create User/ChangePassword.js';
import PayableAgedReport from './Pages/Loan Payment/PayableAgedReport.js';
import SalesReport from './Pages/Manage Payables/Manage General Ledger/Reports Others/SalesReport.js';
import PurchaseReport from './Pages/Manage Payables/Manage General Ledger/Reports Others/PurchaseReport.js';
import PrintSales from './Pages/Process-General-ledger/PrintSales.js';
import Tax from './Pages/ExchangeRate/Tax.js';
import BankReconciliation from './Pages/Nw2/BankReconciliation.js';
import SupplierInvoice from './Pages/Invoice/SupplierInvoice.js';
import SalesInvoicePrint from './Pages/Invoice/SalesInvoicePrint.js';
import Receiptsneawws from './Pages/Receiptsnew/Receipt.js';
import Instruction from './Pages/Instruction/Instruction.js';
import ReceiptSupp from './Pages/Receiptsnew/ReceiptSupplier.js';
import Reconciliation from './Pages/Manage Payables/Manage General Ledger/Reports Others/Reconciliation.js';
import Redeem from './Pages/Manage Payables/Manage General Ledger/Reports Others/RedeemTable/Redeem.js';
import BookingDashboard from './Pages/LatestDashboard/BookingDashboard.js';
import AdvancedPayment from './Pages/Receiveables/Sales Invoice/AdvancedPayment.js';
import CreateAdvancedPayment from './Pages/Receiveables/Sales Invoice/CreateAdvancedPayment.js';
import ReceiptSupplier from './Pages/Receiptsnew/ReceiptSupplier.js';
import UpdateQuote from './Pages/Receiveables/Sales Invoice/UpdateQuote.js';
import UpdateSalesAcct from './Pages/Receiveables/Sales Invoice/UpdateSalesAcct.js';
import UpdateUser from './Pages/Create User/UpdateUser.js';
import EditsSalesRep from './Pages/Create User/EditsSalesRep.js';
import SupplierPersonalLedger from './Pages/Receiveables/Sales Invoice/SupplierPersonalLedger.js';
import UpdatePurchaseOrder from './Pages/Purchase Invoice/UpdatePurchaseOrder.js';
import UpdatePurchase from './Pages/Purchase Invoice/UpdatePurchase.js';
import SalesOrderPrint from './Pages/Invoice/SalesOrderPrint.js';
import QuotePrint from './Pages/Invoice/QuotePrint.js';
import EditCompany from './Pages/Create User/EditCompany.js';
import PrintCustomerLedger from './Pages/Process-General-ledger/PrintCustomerLedger.js';
import PrintSupplierLedger from './Pages/Process-General-ledger/PrintSupplierLedger.js';
import PurchasesOrderPrint from './Pages/Invoice/PurchasesOrderPrint.js';
import AdminNew from './Pages/Dashboard/AdminNew.js';
import FixedAssetHome from './Pages/Fixed Asset/Home.js';
// import StatementOfAccount from './Pages/Expenses/StatementOfAccount.js';
import StatementOfAccount from './Pages/Expenses/UploadStatementAccount.js';
import StatementOfAccountList from './Pages/Expenses/StatementofAccountlist.js';
import ConsolidatedContinental from './Pages/Manage Payables/Manage General Ledger/Reports Others/ConsolidatedContinental.js';
import ContinentalAccount from './Pages/Manage Payables/Manage General Ledger/Reports Others/ContinentalAccount.js';
import RegionalAccount from './Pages/Manage Payables/Manage General Ledger/Reports Others/RegionalAccount.js';
import ProvincialAccount from './Pages/Manage Payables/Manage General Ledger/Reports Others/ProvincialAccount.js';
import ConsolidatedReportAll from './Pages/Process-General-ledger/ConsolidatedReport.js';
import StaffLoanss from './Pages/Manage Receivables/StaffLoanssss.js';
import Remitances from './Pages/Income & Revenue/Remitances.js';
import CreateRemitance from './Pages/Income & Revenue/CreateRemitance.js';
import CashbooksSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/CashbooksSuper.js';
import TrialBalanceSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/TrialBalanceSuper.js';
import GeneralLedgerSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/GeneralLedgerSuper.js';
// import StatementofFinSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/StatementofFinSuper.js';
import IncomeStatementSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/IncomeStatementSuper.js';
import IncomeStatementSuperDetail from './Pages/Manage Payables/Manage General Ledger/Reports Others/IncomeStatementSuperDetail.js';
import BankStatement from './Pages/Manage Payables/Manage General Ledger/Reports Others/BankStatement.js';
import BankPositionSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/BankPositionSuper.js';
import StatementOfCashFlowSuper from './Pages/Manage Payables/Manage General Ledger/Reports Others/StatementOfCashFlowSuper.js';
import UploadOpeningBalance from './Pages/Manage Jurnal Entries/UploadOpeningBalance.js';
import UploadJournalEntry from './Pages/Manage Jurnal Entries/UploadJournalEntry.js';
import PrintBalances from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintBalances.js';
import TrialBalanc from './Pages/Manage Payables/Manage General Ledger/Reports Others/TrialBalances.js';
import PrintBalancesDetail from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintBalancesDetail.js';
import IncomeStatementsSummary from './Pages/Manage Payables/Manage General Ledger/Reports Others/IncomeStatementsSummary.js';
import TrialBalReport from './Pages/Manage Payables/Manage General Ledger/Reports Others/RedeemTable/TrialBalReport.js';
import PrintNewIncome from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintNewIncome.js';
import IncomeStatementsDetailed from './Pages/Manage Payables/Manage General Ledger/Reports Others/IncomeStatementsDetailed.js';
import PrintNewIncomeDetailed from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintNewIncomeDetailed.js';
import AdminCharts from './Pages/Charts of Account/AdminCharts.js';
import AdminCharts2 from './Pages/Charts of Account/AdminCharts2.js';
import AdminCharts3 from './Pages/Charts of Account/AdminCharts3.js';
import AdminCharts4 from './Pages/Charts of Account/AdminCharts4.js';
import AdminCharts5 from './Pages/Charts of Account/AdminCharts5.js';
import PrintNewIncomeConsolidated from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintNewIncomeConsolidated.js';
import CreateBalanceBulk from './Pages/Manage Jurnal Entries/CreateBalanceBulk.js';
import PrintCashbook from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintCashbook.js';
import PrintCashbookConsolidated from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintCashbookConsolidated.js';
import PrintGeneralLedgerConsolidated from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintGeneralLedgerConsolidated.js';
import PrintGeneralLedgerNew from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintGeneralLedgerNew.js';
import PrintNewIncomeConsoloditedDetailed from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintNewIncomeConsoloditedDetailed.js';
import PrintConsolidatedBalances from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintConsolidatedBalances.js';
import PrintBalancesConsolidatedDetail from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintBalancesConsolidatedDetail.js';
import PrintBankReportConsolidated from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintBankReportConsolidated.js';
import PrintBalancesDrilledDetail from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintBalancesDrilledDetail.js';
import AdminChartsAcc from './Pages/Charts of Account/AdminChartsAcc.js';
import AdminCharts6 from './Pages/Charts of Account/AdminCharts6.js';
import BudgetIncomeStatementsDetailed from './Pages/Manage Payables/Manage General Ledger/Reports Others/BudgetIncomeStatementsDetailed.js';
import BankPositionBudget from './Pages/Manage Payables/Manage General Ledger/Reports Others/BankPositionBudget.js';
import StatementOfCashFlowBudget from './Pages/Manage Payables/Manage General Ledger/Reports Others/StatementOfCashFlowBudget.js';
import FinancialPositionAdminDetail from './Pages/Manage Payables/Manage General Ledger/Reports Others/FinancialPositionAdminDetail.js';
// import ReverseOperations from './Pages/Charts of Account/ReverseOperations.js';
import ViewJournalEntries from './Pages/Charts of Account/ViewJournalEntries.js';
import Zones from './Pages/Manage Continent/Zones.js';
import Areas from './Pages/Manage Continent/Areas.js';
import Parish from './Pages/Manage Continent/Parish.js';
import ViewFixedAssets from './Pages/Fixed Asset/ViewFixedAssets.js';
import ViewAsset from './Pages/Receiveables/Sales Invoice/ViewAsset.js';
import Payabless from './Pages/Expenses/Payabless.js';
import CreatePayables from './Pages/Expenses/CreatePayables.js';
import UploadRemitance from './Pages/Manage Jurnal Entries/UploadRemitance.js';
import LoanBeneficiariesAccount from './Pages/Manage Customers/LoanBeneficiariesAccount.js';
import ManageUserProv from './Pages/Manage User/ManageUserProv.js';
import CreateUserProv from './Pages/Create User/CreateUserProv.js';
import UpdateUserProv from './Pages/Create User/UpdateUserProv.js';
import LoanAccount from './Pages/Charts of Account/LoanAccount.js';
import ManageUserBudget from './Pages/Manage User/ManageUserBudget.js';
import ChangeWorkBudget from './Pages/Manage User/ChangeWorkBudget.js';
import AccountAprovalBudget from './Pages/Manage User/AccountApprovalsBudget.js';
import ChartsAdmin from './Pages/Charts of Account/ChartsAdmin.js';
import BudgetUserProv from './Pages/Manage User/BudgetUserProv.js';
import BudgetAccount from './Pages/Manage Customers/BudgetAccount.js';
import SupplierBudget from './Pages/Manage Suppliers payables/SupplierBudget.js';
import BudgetCharts from './Pages/Charts of Account/BudgetCharts.js';
import BudgetCharts2 from './Pages/Charts of Account/BudgetCharts2.js';
import BudgetCharts3 from './Pages/Charts of Account/BudgetCharts3.js';
import BudgetCharts4 from './Pages/Charts of Account/BudgetCharts4.js';
import BudgetCharts5 from './Pages/Charts of Account/BudgetCharts5.js';
import BudgetCharts6 from './Pages/Charts of Account/BudgetCharts6.js';
import BudgetChart from './Pages/Charts of Account/BudgetChart.js';
import LodgedTransactions from './Pages/Charts of Account/LodgedTransactions.js';
import LoanBeneficiariesAccountBudget from './Pages/Manage Customers/LoanBeneficiariesAccountBudget.js';
import ManageUserAdmin from './Pages/Manage User/ManageUserAdmin.js';
import ChangeWorkAdmin from './Pages/Manage User/ChangeWorkAdmin.js';
import AdminAccount from './Pages/Manage Customers/AdminAccount.js';
import SupplierAdmin from './Pages/Manage Suppliers payables/SupplierAdmin.js';
import LoanBeneficiariesAccountAdmin from './Pages/Manage Customers/LoanBeneficiariesAccountAdmin.js';
import ManageUserBudgett from './Pages/Manage User/ManageUserBudgett.js';
import UpdateUserBudget from './Pages/Create User/UpdateUserBudget.js';
import CreateUserBudget from './Pages/Create User/CreateUserBudget.js';
import ScheduleReceivablesBudget from './Pages/Manage Payables/Schedule  of Receivables/ScheduleReceivablesBudget.js';
import PayableScheduleBudget from './Pages/Manage Payables/Payable Schedule Of Payable/PayableScheduleBudget.js';
import FixedAssetReport from './Pages/Fixed Asset/FixedAssetReport.js';
import ViewAssetReport from './Pages/Receiveables/Sales Invoice/ViewAssetReport.js';
import PrintFixedAsset from './Pages/Manage Payables/Manage General Ledger/Reports Others/PrintFixedAsset.js';
import AdminFixed from './Pages/Fixed Asset/AdminFixed.js';
import ZonesAdmin from './Pages/Manage Continent/ZonesAdmin.js';
import AreasAdmin from './Pages/Manage Continent/AreasAdmin.js';
import ParishAdmin from './Pages/Manage Continent/ParishAdmin.js';
import BankStatementOfAccount from './Pages/Manage Payables/Manage General Ledger/Reports Others/BankStatementOfAccount.js';
import BankStatementAdmin from './Pages/Manage Payables/Manage General Ledger/Reports Others/BankStatementAdmin.js';
import UploadStatementOpening from './Pages/Expenses/UploadStatementOpening.js';
import StatementOpeningBalance from './Pages/Manage Payables/Manage General Ledger/Reports Others/StatementOpeningBalance.js';







function App() {
  const [userIsInactive, setUserIsInactive] = useState(false);
  const inactivityThreshold = 600000; 
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location]);

  let inactivityTimer;
  
 const resetInactivityTimer = () => {
   if (inactivityTimer) {
     clearTimeout(inactivityTimer);
   }
 
   inactivityTimer = setTimeout(async () => {
   
     setUserIsInactive(true);
     await AsyncStorage.clear();
     navigate('/login');
     
   }, inactivityThreshold);
 };
 
 const handleUserActivity = () => {
   resetInactivityTimer();
 };
 
 useEffect(() => {
   resetInactivityTimer();
 
   const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
   activityEvents.forEach((event) => {
     document.addEventListener(event, handleUserActivity);
   });
 
   return () => {
     activityEvents.forEach((event) => {
       document.removeEventListener(event, handleUserActivity);
     });
 
     if (inactivityTimer) {
       clearTimeout(inactivityTimer);
     }
   };
 }, []);


  return (
    <>

      <Routes>
        {/* <Route path='/' element={<Landing />} /> */}
        <Route path='/' element={<NewLogin />} />
        {/* <Route path='/' element={<NewLogin />} /> */}
        <Route path='/invoicesssss' element={<Invoicesss24242 />} />
        <Route path='/print_purchase_invoice' element={<SupplierInvoice />} />
        <Route path='/print_sales_invoice' element={<SalesInvoicePrint />} />
        <Route path='/print_sales_order' element={<SalesOrderPrint />} />
        <Route path='/print_quote' element={<QuotePrint />} />
        <Route path='/print_instructn' element={<Instruction />} />
        <Route path='/receiptssss' element={<Receiptsss24242 />} />
        {/* <Route path='/login' element={<Login />} /> */}
        <Route path='/otp_secure' element={<OTP />} />
        {/* <Route path='/welcome' element={<Welcome />} /> */}
        {/* <Route path='/welcome' element={<ModuleSelector />} /> */}
        <Route path='/welcome' element={<ModuleSelector1 />} />
        {/* <Route path='/signup' element={<SignUp />} /> */}
        <Route path='/signup' element={<NewSignup />} />
        <Route path='/login' element={<NewLogin />} />
        {/* <Route path='/new_login' element={<Login/>} /> */}
      
        <Route path='/forbidden' element={<Forbidden />} />
        <Route path='/new_dashboard' element={<NewDashboard />} />
        <Route path='/fixed_asset_home' element={<FixedAssetHome />} />
        <Route path='/fixed_asset_report' element={<FixedAssetReport />} />
        <Route path='/admin/users' element={<ManageUser />} />
        <Route path='/budget/user_management' element={<ManageUserBudgett />} />
        <Route path='/accounting/users' element={<ManageUserProv />} />
        <Route path='/budget/customer' element={<BudgetAccount />} />
        <Route path='/admin/customers' element={<AdminAccount />} />
        <Route path='/budgets/users' element={<ManageUserBudget />} />
        <Route path='/admin/assigned_users' element={<ManageUserAdmin />} />
        <Route path='/budgets/change_working_month_approvals' element={<ChangeWorkBudget />} />
        <Route path='/budgets/account_approvals' element={<AccountAprovalBudget />} />
        <Route path='/admin/change_working_month_approvals' element={<ChangeWorkAdmin />} />
        <Route path='/accounting/create_users' element={<CreateUserProv />} />
        <Route path='/admin/allreceivables' element={<AllReceivables />} />
        <Route path='/admin/salesrep' element={<ManageSalesRep />} />
        <Route path='/admin/editsrep' element={<EditsSalesRep />} />
        <Route path='/admin/company' element={<ManageCompany />} />
        <Route path='/admin/change_password' element={<ChangePassword />} />
        <Route path='/admin/view_user' element={<EditUser />} />
        <Route path='/admin/create_user' element={<CreateUser />} />
      <Route path='/budget/create_user' element={<CreateUserBudget />} />
        <Route path='/admin/update_user' element={<UpdateUser />} />
        <Route path='/budget/update_user' element={<UpdateUserBudget />} />
        <Route path='/accounting/update_user' element={<UpdateUserProv />} />
        <Route path='/admin/create_company' element={<CreateCompany />} />
        <Route path='/admin/edit_company' element={<EditCompany />} />
        <Route path='/admin/create_salesrep' element={<CreateSalesRep />} />
        <Route path='/admin/continent' element={<Continent />} />
        <Route path='/admin/region' element={<Region />} />
        <Route path='/admin/province' element={<Province />} />
        <Route path='/budget/continent' element={<ContinentBudget />} />
        <Route path='/budget/region' element={<RegionBudget />} />
        <Route path='/budget/province' element={<ProvinceBudget />} />

        <Route path='/admin/zone' element={<Zone />} />
        <Route path='/admin/area' element={<Area />} />
        <Route path='/admin/create_role' element={<CreateRole/>} />
        <Route path='/admin/edit_role' element={<EditRole/>} />
        <Route path='/admin/roles_and_permissions' element={<ManageRole />} />
        <Route path='/admin/settings' element={<ManageSettings />} />
        <Route path='/admin/report1' element={<ManageReport />} />
        <Route path='/admin' element={<AdminNew />} />
        {/* <Route path='/admin' element={<NewActDashboard />} /> */}
        <Route path='/admin/general_ledger' element={<ContinentLedger />} />
        <Route path='/admin/cashbook' element={<ContinentCashbook />} />
        <Route path='/admin/activity_report' element={<ContinentActivity />} />
        <Route path='/admin/exchange_rate' element={<ExchangeRate/>} />
        <Route path='/admin/tax' element={<Tax/>} />
        
        <Route path='/admin/trial_&_balance' element={<ContinentTrialBalance />} />
      
        <Route path='/admin/income_&_expenditure' element={<ContinentIncome />} />
        
        <Route path='/admin/monthly_income' element={<ContinentMonthly />} />
    
        <Route path='/admin/balance_sheet' element={<ContinentBalance />} />
      
        <Route path='/admin/inflow' element={<ContinentInflow />} />


        <Route path='/journals' element={<JournalDashboard />} />
        <Route path='/point_of_sales' element={<SalesReceipt />} />
        <Route path='/AccountNew' element={<AccountNew />} />
        <Route path='/TeamMember' element={<TeamMember />} />
        <Route path='/AddTeamForm' element={<AddTeamForm />} />
        <Route path='/chartofAccount' element={<Chartofaounts />} />
        <Route path='/TeamList' element={<TeamMemberList />} />
        <Route path='/UploadBal' element={<UploadBal />} />
        <Route path='/trial_balance_report' element={<TrialBalReport  />} /> 
        <Route path='/SelectCompany' element={<SelectCompany />} />



        {/* <Route path='/accountNew' element={<Acco/>} /> */}


       

        <Route path='/cash_management' element={<CashDashboard />} />
        <Route path='/accounting/cash_management' element={<IncomeRevenue />} />
        <Route path='/accounting/remitances' element={<Remitances />} />
        <Route path='/accounting/create_remitances' element={<CreateRemitance />} />
        <Route path='/cash_management/create_income_&_revenue' element={<CreateIncome />} />
        <Route path='/cash_management/bank_lodgement/pending' element={<Pending />} />
        <Route path='/cash_management/bank_lodgement/create_pending' element={<CreatePending />} />
        <Route path='/cash_management/bank_lodgement/completed' element={<Completed />} />
        <Route path='/cash_management/reports/lodgement_reports' element={<LodgementReports />} />
        <Route path='/cash_management/reports/receipt' element={<Receipt />} />
        <Route path='/cash_management/reports/monthly_income_summary' element={<MonthlyIncomeSummary />} />
        <Route path='/cash_management/reports/currency_inflow' element={<CurrencyInflow />} />
        <Route path='/cash_management/reports/revenue_by_currency' element={<RevenueByCurrency/>} />
        <Route path='/cash_management/reports/revenue_by_account' element={<RevenueByAccount/>} />
        <Route path='/cash_management/reports/revenue_receipt' element={<RevenueReceipt/>} />
        <Route path='/cash_management/reports/revenue_receipt2' element={<RevenueReceipt2/>} />
        <Route path='/cash_management/print_income' element={<PrintIncome/>} />
        <Route path='/cash_management/print_lodgement' element={<PrintLodgement/>} />
        <Route path='/cash_management/print_currency' element={<PrintCurrency/>} />
        <Route path='/fixed_asset/zone' element={<Zones/>} />
        <Route path='/admin/fixed_asset/zone' element={<ZonesAdmin/>} />
        <Route path='/fixed_asset/area' element={<Areas/>} />
        <Route path='/admin/fixed_asset/area' element={<AreasAdmin/>} />
        <Route path='/fixed_asset/parish' element={<Parish/>} />
        <Route path='/admin/fixed_asset/parish' element={<ParishAdmin/>} />
        <Route path='/fixed_assets_register' element={<ViewFixedAssets/>} />
        <Route path='/view_asset' element={<ViewAsset/>} />
        <Route path='/view_asset_report' element={<ViewAssetReport/>} />
        <Route path='/fixed_asset/print_report' element={<PrintFixedAsset/>} />
        <Route path='/admin/fixed_asset_report' element={<AdminFixed/>} />
        <Route path='/accounting' element={<LatestAccountingDashboard />} />
        <Route path='/budget_dash' element={<LatestBudgetDashboard />} />
        <Route path='/booking/dashboard' element={<BookingDashboard />} />
        {/* <Route path='/bank_reconciliation' element={<BankingTran />} /> */}
        {/* <Route path='/accounting' element={<AccountingDashboard />} /> */}
        <Route path='/accounting/print_general_consolidated' element={<ConsolidatedReport />} />
        <Route path='/accounting/print_activity_consolidated' element={<ConsolidateReportActivity />} />
        <Route path='/accounting/print_cashbook_consolidated' element={<ConsolidateReportCashbook />} />
        <Route path='/accounting/print_consolidated_report' element={<ConsolidatedReportAll />} />
        <Route path='/accounting/print_income_statement_consolidated' element={<PrintIncomeStatementConsolidated />} />
        <Route path='/accounting/print_trial_balance_consolidated' element={<PrintTrialConsolidated />} />
        <Route path='/payment' element={<PaymentDashboard />} />
        <Route path='/accounting/chart_of_accounting/manage_category' element={<ManageCategory />} />
        <Route path='/accounting/charts_of_account/account' element={<Charts />} />
        <Route path='/admin/charts_of_account' element={<ChartsAdmin />} />
        <Route path='/budget/charts_of_account' element={<BudgetChart />} />

        <Route path='/accounting/loan_account' element={<LoanAccount />} />
        <Route path='/admin/account_categories' element={<AdminCharts />} />
        <Route path='/budget/account_categories' element={<BudgetCharts />} />
        <Route path='/admin/charts_of_accounts/acct' element={<AdminChartsAcc />} />
        <Route path='/admin/charts_of_accounts_view_class' element={<AdminCharts2 />} />
        <Route path='/budget/charts_of_accounts_view_class' element={<BudgetCharts2 />} />
        <Route path='/admin/charts_of_accounts_view_category' element={<AdminCharts3 />} />
        <Route path='/budget/charts_of_accounts_view_category' element={<BudgetCharts3 />} />
        <Route path='/admin/charts_of_accounts_view_subcategory' element={<AdminCharts4 />} />
        <Route path='/budget/charts_of_accounts_view_subcategory' element={<BudgetCharts4 />} />
        <Route path='/admin/charts_of_accounts_view_sub_subcategory' element={<AdminCharts5 />} />
        <Route path='/budget/charts_of_accounts_view_sub_subcategory' element={<BudgetCharts5 />} />
        <Route path='/admin/charts_of_accounts_view_sub_sub_subcategory' element={<AdminCharts6 />} />
        <Route path='/budget/charts_of_accounts_view_sub_sub_subcategory' element={<BudgetCharts6 />} />
        {/* <Route path='/accounting/bank_transactions' element={<BankTransactionss />} /> */}
        <Route path='/accounting/bank_transactions' element={<BankTransactions />} />
        <Route path='/accounting/bank_reconciliation' element={<BankReconciliation />} />
        <Route path='payment/suppliers' element={<Supplier/>} />
        <Route path='/payment/payment_voucher' element={<PaymentVoucher/>} />
        <Route path='/payment/completed_payment_voucher' element={<CompletedPaymentVoucher/>} />
        <Route path='/payment/payment_voucher/create_payment_voucher' element={<CreatePaymentVoucher/>} />
        <Route path='/payment/payment_voucher/view_pending_payment_voucher' element={<ViewPaymentVoucher/>} />
        <Route path='/payment/payment_voucher/view_completed_pending_payment_voucher' element={<ViewCompletedPaymentVoucher/>} />
        <Route path='/payment/suppliers/edit_supplier' element={<EditSupplierss/>} />
        <Route path='/payment/bulk_payment_excel' element={<BulkPaymentExcelPayment/>} />
        <Route path='/payment/bulk_payment' element={<BulkEntriesPayment/>} />
        <Route path='/account_payables_bulk' element={<AccountPayables/>} />
        <Route path='/payment/payment_bank' element={<ManagePaymentBank/>} />
        <Route path='/payment/petty_payment' element={<PettyPayment/>} />
        <Route path='/payment/petty_payment_excel' element={<PettyPaymentExcel/>} />
        <Route path='/payment/view_bank' element={<EditBank/>} />
        <Route path='/payment/create_bank' element={<CreateBank/>} />
        <Route path='/payment/payment_manual' element={<PaymentPayable/>} />
        <Route path='/payment/manual_entries' element={<ManualEntriesPayment/>} />
        <Route path='/payment/cheque' element={<PrintCheque/>} />
        <Route path='/payment/instruction' element={<PaymentInstruction/>} />
        <Route path='/payment/pending_payment' element={<PendingPayment/>} />|
        <Route path='/accounting/general_ledger' element={<ProcessGeneral/>} />|
        <Route path='/accounting/print_personal_ledger' element={<PrintCustomerLedger/>} />|
        <Route path='/accounting/print_supplier_personal_ledger' element={<PrintSupplierLedger/>} />|
        <Route path='/accounting/print_purchases_order' element={<PurchasesOrderPrint/>} />|
        <Route path='/payment/pending_payment_voucher' element={<PendingPaymentFinal/>} />|
        <Route path='/payment/completed_payment' element={<CompletedPaymentVoucher/>} />
        <Route path='/payment/pending_payables' element={<Payables/>} />
        <Route path='/payment/expenses' element={<Expenses/>} />
        <Route path='/payment/payables' element={<Payabless/>} />
        <Route path='/payment/create_expenses' element={<CreateExpenses/>} />
        <Route path='/payment/create_payables' element={<CreatePayables/>} />
        <Route path='/account_payment_voucher' element={<AccountPaymentVoucher/>} />
        <Route path='/accounting/upload_opening_balance_bulk' element={<CreateBalanceBulk/>} />
        
        <Route path='/payment/savings' element={<Savings/>} />
        <Route path='/payment/add_new_savings' element={<AddNewSavings/>} />
        <Route path='/payment/print_schedule_of_payment' element={<ScheduleOfPayPrintActivity/>} />

        <Route path='/accounting/customers_receiptssss' element={<Receiptsneawws/>} />
        <Route path='/accounting/statement_of_account' element={<StatementOfAccount/>} />
        <Route path='/accounting/statement_of_balance' element={<UploadStatementOpening/>} />
        <Route path='/accounting/statement_of_accountlist' element={<StatementOfAccountList/>} />
        <Route path='/accounting/supp_receiptssss' element={<ReceiptSupplier/>} />
        <Route path='/accounting/general_ledger/postings' element={<ManageGeneralLedger/>} />
        <Route path='/accounting/print_general_ledger_new_consolidated' element={<PrintGeneralLedgerConsolidated/>} />
        <Route path='/accounting/print_general_ledger_new' element={<PrintGeneralLedgerNew/>} />
        <Route path='/budget/general_ledger/postings' element={<BudgetManageGeneralLedger/>} />
        <Route path='/accounting/activity_report' element={<ActivityReport/>} />
        <Route path='/accounting/activity_report_acct' element={<ActivityReportAcct/>} />
        <Route path='/accounting/bank_report' element={<BankReport/>} />
        <Route path='/budget/activity_report' element={<BudgetActivityReport/>} />
        <Route path='/accounting/print_activity' element={<PrintActivity/>} />
        <Route path='/budget/print_scheduleofreceivables' element={<PrintBudgetScheduleReceiv/>} />
        <Route path='/accounting/print_bank' element={<PrintBankReport/>} />
        <Route path='/accounting/print_bank_consolidated' element={<PrintBankReportConsolidated/>} />
        <Route path='/accounting/print_cashbook' element={<PrintCash/>} />
        <Route path='/accounting/print_trial_balance' element={<PrintTrial/>} />
        <Route path='/accounting/cashbook' element={<Cashbooks/>} />
        <Route path='/accounting/cashbook_admin' element={<CashbooksSuper/>} />
        <Route path='/accounting/cashbook_admin' element={<CashbooksSuper/>} />
        <Route path='/accounting/general_ledger_admin' element={<GeneralLedgerSuper/>} />
        <Route path='/accounting/statement_of_financial_position_super' element={<StatementofFinSuper/>} />
        <Route path='/accounting/income_statement_admin' element={<IncomeStatementSuper/>} />
        <Route path='/accounting/income_statement_admin_detail' element={<IncomeStatementSuperDetail/>} />
        <Route path='/budget/income_statement_detailed' element={<BudgetIncomeStatementsDetailed/>} />
        <Route path='/accounting/trialbalance_admin' element={<TrialBalanceSuper/>} />
        <Route path='/accounting/bank_position_summary_admin' element={<BankPositionSuper/>} />
        {/* <Route path='/accounting/reverse_operations' element={<ReverseOperations/>} /> */}
        <Route path='/accounting/view_journal_entries' element={<ViewJournalEntries/>} />
        <Route path='/budget/bank_position_summary' element={<BankPositionBudget/>} />
        <Route path='/accounting/statement_of_cashflow_summary_admin' element={<StatementOfCashFlowSuper/>} />
        <Route path='/accounting/statement_of_cashflow_summary_budget' element={<StatementOfCashFlowBudget/>} />
        <Route path='/accounting/upload_opening_balance' element={<UploadOpeningBalance/>} />
        <Route path='/accounting/statement_opening_balance' element={<StatementOpeningBalance/>} />
        <Route path='/accounting/upload_remitance_excel' element={<UploadRemitance/>} />
        <Route path='/accounting/upload_journal_entry' element={<UploadJournalEntry/>} />
        <Route path='/budget/cashbook' element={<BudgetCashbooks/>} />
        <Route path='/accounting/consolidatedreport' element={<ConsolidatedReport1212/>} />
        <Route path='/accounting/consolidated_report_continental' element={<ConsolidatedContinental/>} />
        <Route path='/accounting/continental_account' element={<ContinentalAccount/>} />
        <Route path='/accounting/regional_account' element={<RegionalAccount/>} />
        <Route path='/accounting/provincial_account' element={<ProvincialAccount/>} />
        {/* <Route path='/accounting/trial_balance' element={<TrialBalances/>} /> */}
        <Route path='/accounting/trial_balance' element={<TrialBalancesNew/>} />
        <Route path='/budget/trial_balance' element={<BudgetTrialBalancesNew/>} />
        <Route path='/accounting/income_statement' element={<IncomeStatements/>} />
        <Route path='/accounting/income_statement_summary' element={<IncomeStatementsSummary/>} />
        <Route path='/accounting/income_statement_detailed' element={<IncomeStatementsDetailed/>} />
        <Route path='/accounting/print_income_statement_detailed' element={<PrintNewIncomeDetailed/>} />
        <Route path='/accounting/print_income_statement_detailed_consolidated' element={<PrintNewIncomeConsoloditedDetailed/>} />
        <Route path='/budget/income_statement' element={<BudgetIncomeStatements/>} />
        <Route path='/budget/bank_statement' element={<BankStatement/>} />
        <Route path='/admin/bank_statement' element={<BankStatementAdmin/>} />
        <Route path='/accounting/statement_of_bank_account' element={<BankStatementOfAccount/>} />
        <Route path='/accounting/print_cashbook_new' element={<PrintCashbook/>} />
        <Route path='/accounting/print_cashbook_new_consolidated' element={<PrintCashbookConsolidated/>} />
        <Route path='/accounting/reconciliation_summary_report' element={<Reconciliation/>} />
        <Route path='/accounting/reconciliation_report' element={<Redeem/>} />
        {/* <Route path='/accounting/statement_of_financial_position' element={<StatementFinancial/>} /> */}
        <Route path='/journals/create_journal_entries' element={<CreateJournal/>} />
        {/* <Route path='/accounting/receivables/account_receivables' element={<AccountReceivables/>} /> */}
        {/* <Route path='/accounting/receivables/manual_entries' element={<ManualEntries/>} /> */}
        <Route path='/accounting/receivables/edit_account_receivables' element={<EditAccountReceivables/>} />
        <Route path='/accounting/receivables/customers_advance_payment' element={<AdvanceBooking/>} />
        {/* <Route path='/accounting/receivables/create_new_payment' element={<CreateNew/>} /> */}
        <Route path='/accounting/receivables/staff_loan' element={<StaffLoanss/>} />
        <Route path='/accounting/receivables/loan_and_advances' element={<LoanAdvances/>} />
        <Route path='/accounting/receivables/loan_and_advances/create_loan' element={<CreateNewLoan/>} />
        <Route path='/accounting/receivables/loan_and_advances/edit_loan_advance' element={<EditLaonAdvance/>} />
        <Route path='/accounting/reports/cashbook' element={<Cashbook/>} />
        <Route path='/accounting/reports/cashbook/process_cash_book' element={<ProcessCashBook/>} />
        <Route path='/accounting/reports/trial_balance' element={<TrialBalance/>} />
        <Route path='/accounting/reports/trial_balance/process_activity_report' element={<ProcessActivityReport/>} />
        <Route path='/accounting/reports/income_expenditure' element={<IncomeExpenditure/>} />
        <Route path='/accounting/reports/income_expenditure/income_print' element={<IncomeProcess/>} />
        <Route path='/accounting/reports/monthly_income' element={<MonthlyIncome/>} />
        <Route path='/accounting/reports/monthly_income/monthly_income_process' element={<MonthlyIncomeProcess/>} />
        <Route path='/accounting/reports/balance_sheet' element={<BalanceSheet/>} />
        <Route path='/accounting/reports/balance_sheet/balance_sheet_print' element={<BalanceSheetPrint/>} />
        <Route path='/accounting/reports/inflow' element={<Inflow/>} />
        <Route path='/accounting/income_and_expenditure/income' element={<Income/>} />
        <Route path='/accounting/income_and_expenditure/income/add_new_income' element={<CreateNewIncome/>} />
        <Route path='/accounting/income_and_expenditure/expenditures' element={<Expenditures/>} />
        <Route path='/accounting/income_and_expenditure/expenditures/add_new_expenditure' element={<CreateNewExpenditure/>} />
        <Route path='/accounting/print_income_statement' element={<PrintIncomeStatement/>} />
        <Route path='/accounting/schedule_of_payment' element={<Schedule/>} />
        <Route path='/accounting/schedule_of_receivables' element={<ScheduleOfReceivables/>} />
        <Route path='/accounting/sales_report' element={<SalesReport/>} />
        <Route path='/accounting/purchase_report' element={<PurchaseReport/>}/>
        <Route path='/print_payment' element={<PrintVoucher/>} />
        <Route path='/accounting/statement_of_financial_position_admin' element={<SOFPAccount/>} />
        <Route path='/statement_of_financial_position' element={<SOFPAdmin/>} />
        <Route path='/statement_of_financial_position_admin' element={<SOFP/>} />
        <Route path='/financial_position' element={<FinancialPosition/>} />
        <Route path='/financial_position_summary' element={<FinancialPositionSummary/>} />
        <Route path='/financial_position_detail' element={<FinancialPositionDetail/>} />
        <Route path='/admin_financial_position_detail' element={<FinancialPositionAdminDetail/>} />
        <Route path='/statement_of_cash_flow' element={<StatementofCash/>} />
        <Route path='/budget_statement_of_financial_position' element={<BudgetSOFP/>} />
        <Route path='/print_statement_of_financial_position' element={<PrintBalance/>} />
        <Route path='/print_statement_of_financial_positions' element={<PrintBalances/>} />
        <Route path='/print_statement_of_financial_positions_consolidated' element={<PrintConsolidatedBalances/>} />
        <Route path='/print_income_statement_summary' element={<PrintNewIncome/>} />
        <Route path='/print_consolidated_income_statement_summary' element={<PrintNewIncomeConsolidated/>} />
        <Route path='/print_statement_of_financial_position_detail' element={<PrintBalancesDetail/>} />
        <Route path='/print_statement_of_financial_position_detail_consolidated' element={<PrintBalancesConsolidatedDetail/>} />
        <Route path='/print_statement_of_financial_position_detail_drilled_down' element={<PrintBalancesDrilledDetail/>} />
        <Route path='/print_financial_position' element={<PrintFinPosBalance/>} />
        <Route path='/print_statement_of_cash_flow' element={<PrintCashFlow/>} />
        
        <Route path='/receivables' element={<ReceivablesDashboard />} />
        <Route path='/receivables/members' element={<Members />} />
        <Route path='/receivables/account_receivables' element={<AccountReceivables />} />
        <Route path='/receivables/manual_entries' element={<ManualEntriess/>} />
        <Route path='/receivables/loan_and_advances' element={<ManageLoans />} />
        <Route path='/receivables/create_loan_and_advances' element={<DisburseLoan />} />
        <Route path='/receivables/edit_loan_and_advances' element={<EditDisburseLoan/>} />
        <Route path='/receivables/personal_ledger' element={<SavingsLedger/>} />
        <Route path='/receivables/repayment' element={<LoanRepay/>} />
        <Route path='/receivables/add_repayment' element={<CreateLoanRepayment/>} />
        <Route path='/receivables/receivable_types' element={<ReceivableTypes/>} />
        <Route path='/receivables/other_receivables' element={<ManageOtherReceivables/>} />
        <Route path='/receivables/aged_report' element={<AgedReport/>} />
        <Route path='/accounting/aged_report' element={<AgedReportAccounting/>} />
        <Route path='/receivables/edit_member' element={<EditEmployeeMember/>} />
        <Route path='/receivables/upload_receivables' element={<UploadReceivables/>} />
        <Route path='/receivables/upload_repayment' element={<UploadRepayment/>} />
        <Route path='/receivable_schedule_of_receivable' element={<ReceivableScheduleOfReceivables/>} />
        {/* <Route path='/accounting/schedule_of_receivable' element={<ReceivableScheduleOfReceivablesAccounting/>} /> */}
        <Route path='/accounting/schedule_of_receivable' element={<ScheduleReceivables/>} />
        <Route path='/budget/schedule_of_receivable' element={<ScheduleReceivablesBudget/>} />
        <Route path='/receivables/print_schedule_of_receivables' element={<ScheduleOfReceivablePrintActivity/>}/>
        <Route path='/receivables/schedule_of_receivable_print_activity' element={<ReceivableScheduleOfReceivablePrintActivity/>}/>
        <Route path='/receivables/ledger_report' element={<PersonalLedgerReport/>} />
        <Route path='/accounting/aged_report_payables' element={<AgedReportPayables/>} />
        <Route path='/accounting/aged_analysis_payables' element={<AgedReportPayables/>} />
        <Route path='/accounting/aged_analysis_payables_admin' element={<AgedReportPayablesAdmin/>} />
        <Route path='/budget/aged_analysis_payables' element={<BudgetAgedReportPayables/>} />
        <Route path='/accounting/aged_analysis_receivables' element={<AgedReportReceivables/>} />
        <Route path='/accounting/aged_analysis_receivables_admin' element={<AgedReportReceivablesAdmin/>} />
        <Route path='/budget/aged_analysis_receivables' element={<BudgetAgedReportReceivables/>} />
        <Route path='/accounting/payable_aged_repot' element={<PayableAgedReport/>} />
        <Route path='/accounting/print_aged_report_payables' element={<PrintAgedPayable/>} />
        <Route path='/accounting/print_aged_report_receivables' element={<PrintAgedReceivables/>} />
        

        {/* <Route path='/receivables/acc' element={<EditEmployeeMember/>} /> */}

        <Route path='/payables' element={<PayablesDashboard />} />
        <Route path='/payables/supplier' element={<SupplierPayables />} />
        <Route path='/accounting/supplier' element={<SupplierPayablesAccounting />} />
        <Route path='/budget/supplier' element={<SupplierBudget />} />
        <Route path='/admin/supplier' element={<SupplierAdmin />} />
        <Route path='/payables/account_payables' element={<AccountPayables />} />
        <Route path='/payables/manual_entries' element={<ManualEntriessPayables/>} />
        <Route path='/payables/loan_and_advances' element={<ManagePayables />} />
        <Route path='/payables/create_loan_and_advances' element={<DisbursePayables />} />
        <Route path='/payables/edit_loan_and_advances' element={<EditDisbursePayables/>} />
        <Route path='/payables/personal_ledger' element={<SavingsLedgerPayables/>} />
        <Route path='/payables/repayment' element={<LoanRepayPayables/>} />
        <Route path='/payables/add_repayment' element={<CreateLoanRepaymentPayables/>} />
        <Route path='/payables/payable_types' element={<PayableTypes/>} />
        <Route path='/payable/schedule_of_payable' element={<PayableSchedule/>}/>
        <Route path='/payable/schedule_of_payable_printactivityp' element={<PayableSchedulePrintActivity/>}/>
        <Route path='/accounting/schedule_of_payable' element={<PayableSchedule/>}/>
        <Route path='/budget/schedule_of_payable' element={<PayableScheduleBudget/>}/>
        <Route path='/Payable/payable_personal_ledger_report' element={<PayablePersonalLedgerReport/>} />
        
        <Route path='/payables/update_supplier_details' element={<EditSupplierrrd/>} />
        <Route path='/payables/bulk_entries' element={<BulkEntries/>} />
        <Route path='/payables/bulk_entries_excel' element={<BulkPaymentExcel/>} />


        <Route path='/cooperative' element={<Dashboard />} />
        <Route path='/cooperative/manage_roles' element={<ManageRoles />} />
        <Route path='/cooperative/loan_account' element={<LoanAccounts />} />
        <Route path='/cooperative/loan_advances' element={<ManageLoans />} />
        <Route path='/cooperative/disburse_loan' element={<DisburseLoan />} />
        <Route path='/cooperative/savings_withdrawals' element={<SavingsWithdrawals />} />
        <Route path='/cooperative/create_savings_withdrawals' element={<CreateSavingsWithdrawals />} />
        <Route path='/cooperative/savings_individual_ledger' element={<SavingsLedger />} />
        <Route path='/cooperative/loan_individual_ledger' element={<LoanLedger />} />
        <Route path='/cooperative/report_ledger' element={<ReportLedger />} />
        <Route path='/cooperative/savings_deduction' element={<SavingsDeduction />} />
        <Route path='/cooperative/loan_deduction' element={<LoanDeduction />} />
        <Route path='/cooperative/deduction_transactions' element={<DeductionTransactions />} />
        <Route path='/cooperative/view_deduction_transactions' element={<ViewDeductions />} />
        <Route path='/cooperative/update_disburse_loan' element={<EditDisburseLoan />} />
        <Route path='/cooperative/approval_level' element={<ApprovalLevel />} />
        <Route path='/cooperative/manage_user' element={<ManageUser />} />
        <Route path='/cooperative/manage_savings' element={<ManageSavings />} />
        <Route path='/cooperative/savings_account' element={<SavingsAccounts />} />
        <Route path='/cooperative/create_approval' element={<CreateApproval />} />
        <Route path='/cooperative/create_role' element={<CreateRole />} />
        <Route path='/cooperative/edit_role' element={<EditRole />} />
        <Route path='/cooperative/members' element={<EmployeeMembers />} />
        <Route path='/cooperative/edit_member' element={<EditEmployeeMember />} />
        <Route path='/cooperative/edit_savings' element={<EditSavings />} />
        <Route path='/cooperative/create_savings' element={<CreateSavings />} />
        <Route path='/cooperative/create_loan' element={<CreateLoan />} />
        <Route path='/cooperative/edit_loan' element={<EditLoan />} />
        <Route path='/cooperative/create_savings_application' element={<CreateSavingsApp />} />
        <Route path='/cooperative/loan_repayment' element={<LoanRepay />} />
        <Route path='/cooperative/savings_payment' element={<SavingsPayment />} />
        <Route path='/cooperative/create_loan_repayment' element={<CreateLoanRepayment />} />
        <Route path='/cooperative/create_savings_payment' element={<CreateSavingsPayment />} />
      
        {/* <Route path='/event_mgt_system' element={<StockDash/>} /> */}
      <Route path='/bookings' element={<Services/>} />
      <Route path='/event_mgt_system/services' element={<MainServices/>} />
      <Route path='/bookings/create_booking' element={<CreateServices/>} />
      <Route path='/event_mgt_system/view_booking' element={<ViewServices/>} />
      <Route path='/event_mgt_system/material_cost' element={<ViewMaterial/>} />
      <Route path='/event_mgt_system/other_cost' element={<ViewLabor/>} />
      <Route path='/event_mgt_system/customers_advance_payment' element={<AdvanceBooking/>} />
      <Route path='/event_mgt_system/create_customers_advance_payment' element={<AdvancePayment/>} />
      <Route path='/event_mgt_system/view_customers_advance_payment' element={<ViewAdvance/>} />
      <Route path='/event_mgt_system/print_advance_payment' element={<BookingReceipt/>} />
      <Route path='/print_sales_report' element={<PrintSales/>} />


      <Route path='/inventory' element={<InventoryDashboard/>} />
      <Route path='/account/create_product' element={<CreateProducts/>} />
      <Route path='/account/edit_product' element={<EditProducts/>} />
      <Route path='/inventory/unit_of_measurement' element={<ManageUnit/>} />
      <Route path='/accounting/unit_of_measurement' element={<UnitMeasurement/>} />
      <Route path='/accounting/product_categories' element={<ProductCategory/>} />
      <Route path='/inventory/products' element={<ManageStocks/>} />
      <Route path='/accounting/products' element={<ManageProducts/>} />
      <Route path='/inventory/requisition' element={<Requisition/>} />
      <Route path='/inventory/create_requisition' element={<CreateRequistion/>} />
      <Route path='/inventory/view_requisition' element={<ViewRequisition/>} />
      <Route path='/inventory/customers' element={<Customers/>} />
      <Route path='/accounting/customers' element={<CustomersAccount/>} />
      <Route path='/cash_management/lodged_transactions' element={<LodgedTransactions/>} />
      <Route path='/accounting/loan' element={<LoanBeneficiariesAccount/>} />
      <Route path='/budget/loan' element={<LoanBeneficiariesAccountBudget/>} />
      <Route path='/admin/loan' element={<LoanBeneficiariesAccountAdmin/>} />
      <Route path='/update_customer' element={<EditCustomer/>} />
      <Route path='/inventory/suppliers' element={<Suppliers/>} />
      <Route path='/inventory/update_supplier' element={<EditSupplier/>} />
      <Route path='/inventory/sales_invoice' element={<SalesInvoice/>} />
      <Route path='/accounting/sales_invoice' element={<SalesInvoiceAccount/>} />
      <Route path='/accounting/customers_receipts' element={<CustomersReceipts/>} />
      <Route path='/accounting/personal_ledger' element={<CustomersPersonalLedger/>} />
      <Route path='/accounting/suppliers_personal_ledger' element={<SupplierPersonalLedger/>} />
      <Route path='/booking/create_advanced_payment' element={<CreateAdvancedPayment/>} />
      <Route path='/booking/advanced_payment' element={<AdvancedPayment/>} />
      <Route path='/accounting/suppliers_receipts' element={<SuppliersReceipts/>} />
      <Route path='/accounting/sales_order' element={<SalesOrder/>} />
      <Route path='/accounting/manage_quote' element={<ManageQuote/>} />
      <Route path='/receivables/sales_invoice' element={<SalesInvoiceReceivable/>} />
      <Route path='/receivables/create_sales_invoice' element={<CreateSalesReceivable/>} />
      <Route path='/inventory/create_sales_invoice' element={<CreateSales/>} />
      <Route path='/accounting/create_order' element={<CreateOrder/>} />
      <Route path='/accounting/update_order' element={<UpdateOrder/>} />
      <Route path='/accounting/create_quote' element={<CreateQuote/>} />
      {/* <Route path='/accounting/create_sales_invoice' element={<CreateSalesAccounting/>} /> */}
      <Route path='/accounting/create_sales_invoice' element={<CreateSalesAcct/>} />
      <Route path='/accounting/update_sales_invoice_account' element={<UpdateSalesAcct/>} />
      <Route path='/accounting/customers_receipt' element={<CustomersReceipt/>} />
      <Route path='/accounting/suppliers_receipt' element={<SuppliersReceipt/>} />
      <Route path='/inventory/stock_delivery' element={<StockDelivery/>} />
      <Route path='/inventory/create_stock_delivery' element={<CreateStockDelivery/>} />
      <Route path='/inventory/update_sales_invoice' element={<EditInvoice />} />
      <Route path='/accounting/update_sales_invoice' element={<EditInvoiceAccounting />} />
      <Route path='/accounting/update_sales_invoice-account' element={<UpdateQuote />} />
      <Route path='/inventory/official_invoice' element={<Invoice />} />
      <Route path='/inventory/purchase_invoices' element={<PurchaseInvoice/>} />
      <Route path='/accounting/purchase_invoices' element={<PurchaseInvoiceAccounting/>} />
      <Route path='/accounting/purchase_order' element={<PurchaseOrder/>} />
      <Route path='/inventory/create_purchase_invoice' element={<CreatePurchase/>} />
      <Route path='/accounting/create_purchase_invoice' element={<CreatePurchaseAccount/>} />
      <Route path='/accounting/update_purchase_invoice_accounting' element={<UpdatePurchase/>} />
      <Route path='/accounting/create_purchase_order' element={<CreatePurchaseOrder />} />
      <Route path='/accounting/update_purchase_order_account' element={<UpdatePurchaseOrder />} />
      <Route path='/inventory/update_purchase_invoice' element={<EditPurchaseInventory />} />
      <Route path='/accounting/update_purchase_invoice' element={<EditPurchaseAccounting />} />
      <Route path='/inventory/official_purchase_invoice' element={<Purchase />} /> 

      <Route path='/latest_dashboard' element={<LatestDashboard/>} /> 
      {/* <Route path='/team_member1' element={<TeamMember1/>}/>  */}
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
