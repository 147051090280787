import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import { BASE_URL, BASE_URL1, TOKEN } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestFixedDash from '../LatestDashboard/LatestFixedDash';
import { LuView } from "react-icons/lu";
import { GrView } from "react-icons/gr";
import LatestFixedDashes from '../LatestDashboard/LatestFixedDashes';





function AdminFixed() {
  const [selectedFiles1, setSelectedFiles1] = useState(null);
  const [user, setUser] = useState('');
  const [userTypes, setUserTypes] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const navigate = useNavigate();
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [provLoading, setProvLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const [assetLoading, setAssetLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loaddd, setLoaddd] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
const [trashClicked, setTrashClicked] = useState(false);
const [show1, setShow1] = useState(false);
const [selectedContinent, setSelectedContinent] = useState('');
const [selectedProvince, setSelectedProvince] = useState('');
const [selectedRegion, setSelectedRegion] = useState('');
const [selectedZone, setSelectedZone] = useState('');
const [selectedArea, setSelectedArea] = useState('');
const [selectedParish, setSelectedParish] = useState('');
const [show2, setShow2] = useState(false);
const [areaLoad, setAreaLoad] = useState(false);
const [entriesPerPage, setEntriesPerPage] = useState(100);
const [currentPage, setCurrentPage] = useState(1);
const [searchTerm, setSearchTerm] = useState("");
const [tableData1, setTableData1] = useState([]);
const [tableData2, setTableData2] = useState([]);
const [tableData3, setTableData3] = useState([]);
const [tableData4, setTableData4] = useState([]);
const [tableData5, setTableData5] = useState([]);
const [tableData6, setTableData6] = useState([]);
const [tableData7, setTableData7] = useState([]);
const [areas, setAreas] = useState([]);
const [parishes, setParishes] = useState([]);
const [tableData8, setTableData8] = useState([]);
const [tableData9, setTableData9] = useState([]);
const [zones, setZones] = useState([]);
const [selectedRows, setSelectedRows] = useState([]);
const [selectedItemId, setSelectedItemId] = useState(null);
const [continentID, setContinentID] = useState(null);
const [regionID, setRegionID] = useState(null);
const [provinceID, setProvinceID] = useState(null);

const [totalEntries, setTotalEntries] = useState("");
const [totalPages, setTotalPages] = useState(1);

const [responseData, setResponseData] = useState([]);
const handleClose1 = () => {
  setShow1(false);
 
};
const handleClose2 = () => {
  setShow2(false);
 
};

const handleFileChange1 = (event) => {
  const files = event.target.files;
  setSelectedFiles1(Array.from(files));
};

const handleShow1 = (id) => {
  setSelectedAssetId(id);
  setShow1(true);
};

const handleShow2 = (id) => {
  setSelectedAssetId(id);
  setShow2(true);
};

const handleContinentChange = async (event) => {
  setSelectedContinent(event.target.value);
 setSelectedRegion("");
 setSelectedProvince("");
 setSelectedZone("");
 setSelectedArea("");
 setSelectedParish("");
}
const handleRegionChange = (event) => {
  setSelectedRegion(event.target.value);
 setSelectedProvince("");

}
const handleProvinceChange = (event) => {
  setSelectedProvince(event.target.value);
 setSelectedZone("");
}
// const handleZoneChange = (event) =>{
//   setSelectedZone(event.target.value);
//  setSelectedArea("");
  
// }
const handleParishChange = (event) =>{
  setSelectedParish(event.target.value);
  
}
const handleAreaChange = (event) =>{
  setSelectedArea(event.target.value);
 setSelectedParish("");
}


const readData = async () => {
  try {
    const value = await AsyncStorage.getItem('userToken');
    const value1 = await AsyncStorage.getItem('tobi');
    const admins = await AsyncStorage.getItem('admin');

    const continentName = await AsyncStorage.getItem('continent');
    const regionName = await AsyncStorage.getItem('region');
    const provinceName = await AsyncStorage.getItem('province');

    if (value !== null) {
      setBearer(value);
    }
    if (value1 !== null) {
      setUser(value1);
    }
   

    if (admins !== null) {
      setUserTypes(admins);
  }
  if (continentName !== null) {
    setContinent(continentName);
}
if (regionName !== null) {
    setRegion(regionName);
}
if (provinceName !== null) {
    setProvince(provinceName);
}
  } catch (e) {
    alert('Failed to fetch the input from storage');
  }
};    

useEffect(() => {
  readData();
}, []);

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${bearer}`
};

const readData1 = async () => {
  try {
    const value4 = await AsyncStorage.getItem('provinceID');
    const value5 = await AsyncStorage.getItem('regionID');
    const value6 = await AsyncStorage.getItem('continentID');

   
    if (value4 !== null) {
      setProvinceID(value4);
    }
    if (value5 !== null) {
      setRegionID(value5);
    }
    if (value6 !== null) {
      setContinentID(value6);
    }
  } catch (e) {
    alert('Failed to fetch the input from storage');
  }
};

useEffect(() => {
  if (bearer)
  readData1();
}, [bearer]);

// const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

// const totalPages = Math.ceil(filteredData.length / entriesPerPage);

const handlePrevPage = () => {
  setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
};

const handleNextPage = () => {
  setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
};

// const totalEntries = filteredData.length;
const startIndexx = (currentPage - 1) * entriesPerPage + 1;
const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
// const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

    const fetchProvinces = async () => {
      setAssetLoading(true);
      try {
        const response = await axios.get(`${BASE_URL1}/fixedassets/all-approved-assets?page=${currentPage}`, {
          headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json'
        }, 
        });
        const results = response.data?.data?.data;
        setTableData(results);
        const resultx = response.data?.data?.total;
      setTotalEntries(resultx);
      const total = response.data?.data?.last_page || 1;
      setTotalPages(total);
        console.log(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          
          // navigate('/login');
        } else {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
          setTableData([]);
        }
      } finally {
        setAssetLoading(false);
      }
    };
  
  
  
    useEffect(() => {
      if (TOKEN, continentID, regionID, provinceID) {
        fetchProvinces();
      
      }
    }, [TOKEN, currentPage, continentID, regionID, provinceID ]);


    const handleViewFixed = async (id) => {
      setSelectedItemId(id);
          setViewLoading(true);
          console.log(id)
          try {
            const response = await axios.get(`${BASE_URL1}/fixedassets/get-by-type?parish_id=${id}`,
              
              {headers:{
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${TOKEN}`
          }});
  
            const instructionData1 = response.data?.data?.assets?.data;
            setResponseData(instructionData1);
            const resultx = response.data?.data?.assets?.total;
            setTotalEntries(resultx);
            const total = response.data?.data?.assets?.last_page || 1;
            setTotalPages(total);
           console.log(instructionData1, "Am here")

            navigate('/fixed_assets_register', {state: {parishID: id}});
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
  
          } finally {
            setViewLoading(false);
          }
        }

        const handleEyeClicks = (id) => {
          const foundAsset = tableData.find(item => item.id === id);
          navigate('/view_asset_report', { state: { selectedAsset: foundAsset } });
          setEyeClicked(true);
        };

        const handleProcess = async () => {
          setProcessLoading(true);
        
          try {
            const response = await axios.get(`${BASE_URL1}/fixedassets/all-approved-parish-assets?page=${currentPage}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
              },
              params: {
                continent_id: selectedContinent || '',
                region_id: selectedRegion || '',
                province_id: selectedProvince || '',
                zone_id: selectedZone || '',
                area_id: selectedArea || '',
                parish_id: selectedParish || ''
              }
            });
        
            const results = response.data?.data?.data;
        setTableData(results);
        const resultx = response.data?.data?.data?.total;
      setTotalEntries(resultx);
      const total = response.data?.data?.data?.last_page || 1;
      setTotalPages(total);
        
            Swal.fire({
              icon: 'success',
              title: 'Successful',
              text: response.data.message,
            });
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data) {
              if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
              }
            }
        
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: errorMessage,
            });
          } finally {
            setProcessLoading(false);
          }
        };
        
      


const handleEyeClick = (id) => {
  const foundAsset = tableData.find(item => item.id === id);
  navigate('/edit_fixed_assets_register', { state: { selectedAsset: foundAsset } });
  setEyeClicked(true);
};

        const uploadDocument = async () => {
          setLoad(true);
        
          try {
            const formData = new FormData();
           
            formData.append('asset_id', selectedAssetId);
            selectedFiles1.forEach((file, index) => {
              formData.append(`asset_document[${index}]`, file);
            });
           
        
            const response = await axios.post(
              `${BASE_URL}/fixedassets/upload_document`,
              formData,
              {
                headers: {
                  ...headers,
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
        
            console.log(response.data.message);
            handleClose1();
           
            setSelectedFiles1([]);
          //   fetchAssets();
      
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
            });
        
            console.log(response.data);
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            console.log(error);
          } finally {
            setLoad(false);
          }
        };

        const fetchContinents = async () => {
          setIsLoading(true);
          try {
            const response = await axios.get(`${BASE_URL}/continents`, { headers });
      
            // console.log(response);
            const resultsss = response.data?.data;
            const optionas = resultsss.map((item) => ({
              label: item.description,
              value: item.id,
            }));
           
            setTableData1(optionas);
            // console.log(results);
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setTableData1([]);
          } finally {
            setIsLoading(false);
          }
        };
        useEffect(() => {
          if (bearer) {
        
            fetchContinents();
          }
        }, [bearer]);

        const fetchSubContinents = async (selectedContinent) => {
          setIsLoading(true);
          try {
            const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${bearer}`,
          }, });
      
            // console.log(response);
            const subCont = response.data?.data;
            const optionass = subCont.map((item) => ({
              label: item.description,
              value: item.id,
            }));
            setTableData4(optionass);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setTableData4([]);
          } finally {
            setIsLoading(false);
          }
        };
      
        
      
        const fetchSubRegions = async (selectedRegion) => {
          setIsLoading(true);
          try {
            const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${bearer}`,
          }, });
      
            // console.log(response);
            const subReg = response.data?.data;
            const optionaps = subReg.map((item) => ({
              label: item.description,
              value: item.id,
            }));
            setTableData5(optionaps);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setTableData5([]);
          } finally {
            setIsLoading(false);
          }
        };
      
        
        const fetchSubProvinces = async (selectedProvince) => {
          setIsLoading(true);
          try {
            const response = await axios.get(`${BASE_URL}/provinces/getzonesprovince?province_id=${selectedProvince}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${bearer}`,
          }, });
      
            // console.log(response);
            const subReg = response.data?.data;
            setTableData6(subReg);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setTableData6([]);
          } finally {
            setIsLoading(false);
          }
        };

        const fetchAllZones = async (selectedProvince) => {
          setProvLoading(true);
          try {
            const response = await axios.get(`${BASE_URL1}/provinces/getzonesprovince?province_id=${selectedProvince}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${TOKEN}`,
          }, });
      
            // console.log(response);
            const subRegg = response.data?.data;
            const optionfs = subRegg.map((item) => ({
              label: item.description,
              value: item.id,
            }));
            setZones(optionfs);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setZones([]);
          } finally {
            setProvLoading(false);
          }
        };

        const fetchAllParishes = async (selectedArea) => {
          setProvLoading(true);
          try {
            const response = await axios.get(`${BASE_URL1}/areas/getparishesarea?area_id=${selectedArea}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${TOKEN}`,
          }, });
      
            // console.log(response);
            const subReg2 = response.data?.data;
            const options2 = subReg2.map((item) => ({
              label: item.description,
              value: item.id,
            }));
            setParishes(options2);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setParishes([]);
          } finally {
            setProvLoading(false);
          }
        };
      
        
      
        const fetchSubZones = async (selectedZone) => {
          setIsLoading(true);
          try {
            const response = await axios.get(`${BASE_URL}/zones/getareaszone?zone_id=${selectedZone}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${bearer}`,
          }, });
      
            // console.log(response);
            const subReg = response.data?.data;
            setTableData7(subReg);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setTableData7([]);
          } finally {
            setIsLoading(false);
          }
        };

    

        const fetchAllAreas = async (selectedZone) => {
          setAreaLoad(true);
          try {
            const response = await axios.get(`${BASE_URL1}/zones/getareaszone?zone_id=${selectedZone}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${TOKEN}`,
          }, });
      
            // console.log(response);
            const subReg1 = response.data?.data;
            const options1 = subReg1.map((item) => ({
              label: item.description,
              value: item.id,
            }));
            setAreas(options1);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setAreas([]);
          } finally {
            setAreaLoad(false);
          }
        };

        const fetchSubAreas = async (selectedArea) => {
          setIsLoading(true);
          try {
            const response = await axios.get(`${BASE_URL}/areas/getparishesarea?area_id=${selectedArea}`, 
            { headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${bearer}`,
          }, });
      
            // console.log(response);
            const subAreaa = response.data?.data;
            setTableData9(subAreaa);
            // console.log(subCont, "heeeer");
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            setTableData9([]);
          } finally {
            setIsLoading(false);
          }
        };
      
        
      
        useEffect(() => {
          if (bearer && selectedContinent) {
            fetchSubContinents(selectedContinent);
          }
        }, [bearer, selectedContinent]);

        useEffect(() => {
          if (bearer && selectedArea) {
            fetchSubAreas(selectedArea);
          }
        }, [bearer, selectedArea]);

        useEffect(() => {
          if (TOKEN && selectedProvince) {
            fetchAllZones(selectedProvince);
          }
        }, [TOKEN, selectedProvince]);

        useEffect(() => {
          if (TOKEN && selectedZone) {
            fetchAllAreas(selectedZone);
          }
        }, [TOKEN, selectedZone]);

        useEffect(() => {
          if (TOKEN && selectedArea) {
            fetchAllParishes(selectedArea);
          }
        }, [TOKEN, selectedArea]);
      
        useEffect(() => {
          if (TOKEN && selectedRegion) {
            fetchSubRegions(selectedRegion);
          }
        }, [TOKEN, selectedRegion]);
      
        useEffect(() => {
          if (bearer && selectedProvince) {
            fetchSubProvinces(selectedProvince);
          }
        }, [bearer, selectedProvince]);
      
        useEffect(() => {
          if (bearer && selectedZone) {
            fetchSubZones(selectedZone);
          }
        }, [bearer, selectedZone]);

        const handleContinent = async (selectedOption) => {
  setSelectedContinent(selectedOption.value);

}
const handleRegion = (selectedOption) => {
  setSelectedRegion(selectedOption.value);


}
const handleProvince = (selectedOption) => {
  setSelectedProvince(selectedOption.value);

}

        const handleZone = (selectedOption) => {
          setSelectedZone(selectedOption.value);
        };

        const handleArea = (selectedOption) => {
          setSelectedArea(selectedOption.value);
        };

        const handleParish = (selectedOption) => {
          setSelectedParish(selectedOption.value);
        };
      
        const createProvince = async () => {
          setLoaddd(true);
          try {
            const response = await axios.post(
              `${BASE_URL}/fixedassets/transfer`,
              {
                continent_id: selectedContinent,
                region_id: selectedRegion,
                area_id: selectedArea,
                province_id: selectedProvince,
                asset_id: selectedAssetId,
                zone_id: selectedZone,
                parish_id: selectedParish

               
              },
              { headers }
            );
            // console.log(response)
          //   fetchAssets();
            handleClose2();
            // return
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
            });
            console.log(response.data);
      
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
            console.log(error);
          } finally {
            setLoaddd(false);
          }
        };

        const handleFixedAsset = () => {
          navigate('/home2')
        }




        function formatDate(dateString) {
          const date = new Date(dateString);
          const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
          return formattedDate;
        }
      
        function padZero(num) {
          return num < 10 ? `0${num}` : num;
        }


  

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestFixedDashes />
                 
                </div>
<div style={{marginTop: '11rem'}}/>
                <div className={classes.finishedbodyCont}>
                <h3 style={{fontSize: '30px', fontWeight: 700, }}>Fixed Asset Report</h3>
                <p style={{marginTop: -10, marginBottom: 20}}>View all reports</p>
                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>General Ledger</h3> */}
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont}`}>
                        <div className="card-body" style={{ border: 'none' }}>


                          <div className="row">
                          <div className="col-md-4">
          <div className="form-group row">
            {/* <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Zone:</label> */}
            <div className="col-sm-9">
              <Select
              placeholder='Select Continent'
                onChange={(selectedOption) => handleContinent(selectedOption)}
                options={tableData1}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4" style={{marginLeft: -100}}>
          <div className="form-group row">
            {/* <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Area:</label> */}
            <div className="col-sm-9">
              <Select
                placeholder='Select Region'
                onChange={(selectedOption) => handleRegion(selectedOption)}
                options={tableData4}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4" style={{marginLeft: -100}}>
          <div className="form-group row">
            {/* <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Area:</label> */}
            <div className="col-sm-9">
              <Select
                placeholder='Select Province'
                onChange={(selectedOption) => handleProvince(selectedOption)}
                options={tableData5}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
                          </div>
                          <div style={{marginTop: 10}}/>
                          <div className="row">
                          <div className="col-md-4">
          <div className="form-group row">
            {/* <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Zone:</label> */}
            <div className="col-sm-9">
              <Select
              placeholder='Select Zone'
                onChange={(selectedOption) => handleZone(selectedOption)}
                options={zones}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4" style={{marginLeft: -100}}>
          <div className="form-group row">
            {/* <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Area:</label> */}
            <div className="col-sm-9">
              <Select
                placeholder='Select Area'
                onChange={(selectedOption) => handleArea(selectedOption)}
                options={areas}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4" style={{marginLeft: -100}}>
          <div className="form-group row">
            {/* <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Area:</label> */}
            <div className="col-sm-9">
              <Select
                placeholder='Select Parish'
                onChange={(selectedOption) => handleParish(selectedOption)}
                options={parishes}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4" style={{marginTop: 10}}>
        <Button
                                  style={{ borderRadius: 0, }} variant='success' onClick={handleProcess}>
                                  {processLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                    </>
                                  ) : (
                                    "Process"
                                  )}
                                </Button>
        </div>
                         
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ backgroundColor: 'white', padding: '0 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="m-0 content-header row align-items-center">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="p-0 mb-3 col-sm-4 order-sm-last mb-sm-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}
                        {/* )} */}

                        <div className="p-0 col-sm-8 header-title">
                          <div className="media">
                            {/* <div className="mr-3 header-icon text-success"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="mb-4 col-md-12 col-lg-12 col-xl-3">
                    <div className="card">


                    </div>
                  </div> */}





                          <div className="col-lg-12">
                            <div className="card">
                              <div style={{ marginLeft: 10 }}>
                                {tableData.length > 0 && (
                                  <button onClick={() => navigate('/fixed_asset/print_report', { state: { tableData: tableData } })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#198754", color: "#fff", marginBottom: 10, marginTop: 10, }}>Print Report</button>
                                )}
                              </div>

                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '0px 0 0 0', marginBottom: 0 }}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`} >
                                      {/* <div className={classes.actionsBtns}>
                                        <button>Copy</button>
                                        <button>Excel</button>
                                        <button>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div> */}
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                  <div className={classes.mainTable}>
                                  {(assetLoading || processLoading) ? (
                              <p>Fetching Assets...</p>
                            ) : (
                                    <div className="table-responsive">
                                      <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                                        <thead style={{ whiteSpace: 'nowrap' }}>
                                        
                                          <tr>
                                      <th>S/N</th>
                                      <th>Identification Number</th>
                                      <th>Asset Name</th>
                                      <th>Category</th>
                                      <th>Quantity</th>
                                      <th>Parish Name</th>
                                      <th>Province Name</th>
                                      {/* <th>Province</th> */}
                                      {/* <th>Area</th>
                                      <th>Zone</th> */}
                                      <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody style={{ whiteSpace: 'nowrap' }}>
                                        {tableData.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td style={{width: 100, whiteSpace: "wrap"}}>{item.identification_number}</td>
                                        <td style={{width: 200, whiteSpace: "wrap"}}>{item.description}</td>
                                        <td style={{width: 100, whiteSpace: "wrap"}}>{item.asset_category?.description}</td>
                                        <td>{item.quantity}</td>
                                        <td style={{width: 200, whiteSpace: "wrap"}}>{item.parish?.description}</td>
                                        <td style={{width: 200, whiteSpace: "wrap"}}>{item.parish?.description}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                        <div onClick={() => handleEyeClicks(item.id)}  className="btn btn-success-soft btn-sm mr-1">
                                            <i className="far fa-edit" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                          </div>
                                        </td>
                                        </tr>
                                         ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                  </div>
                                  <div className={classes.endded}>
  <p>
    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
  </p>
  <div style={{ display: 'flex' }}>
    <button
      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
      onClick={handlePrevPage}
      disabled={currentPage === 1}
    >
      Previous
    </button>
    {[...Array(totalPages)].map((_, page) => {
      // Show only 5 pages or less if available
      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
        return (
          <button
            key={page + 1}
            style={{
              marginLeft: '0.4rem',
              marginRight: '0.4rem',
              fontSize: '14px',
              fontFamily: 'nunito',
              fontWeight: 400,
              color: page + 1 === currentPage ? '#ffffff' : '#000',
              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
              height: '2.5rem',
              borderRadius: '89px',
              padding: '0.5rem',
              border: 'none',
              width: '40px',
              cursor: "pointer"
            }}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </button>
        );
      }
      return null;
    })}
    <button
      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
      onClick={handleNextPage}
      disabled={currentPage === totalPages}
    >
      Next
    </button>
  </div>
</div>




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default AdminFixed;