import React, { useState, useEffect } from 'react';
import classes from './TrialBalReport.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintGeneralLedgerNew() {

    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [totalClosing, setTotalClosing] = useState('');
    const [totalOpening, setTotalOpening] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `${day}/${month}/${year} `;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const admins = await AsyncStorage.getItem('admin');

            if (value !== null) {
                setBearer(value);
                // setAuthenticated(true);
            }
            if (value1 !== null) {
                setCompany(value1);

            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (admins !== null) {
                setUserType(admins);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();

    }, []);

    const { document, inputs } = location.state || {};
  console.log(inputs, document, "printing");
  const startDate = new Date(inputs.start_date);
  const endDate = new Date(inputs.end_date);

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
            ? 'th'
            : suffixes[v % 10] || 'th';
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };

    function formattedDates(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
        return formattedDate;
      }
    
      function padZero(num) {
        return num < 10 ? `0${num}` : num;
      }

  useEffect(() => {
    if (document) {
      const debitTotal = document.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = document.reduce((total, item) => total + parseFloat(item.credit), 0);

      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [document]);

  const allNamesSame = document.every(doc => doc.account?.gl_name === document[0].account?.gl_name);
  const displayName = allNamesSame ? document[0].account?.gl_name : "All Accounts";
      

    return (
        <div className={classes.a4}>
        <div className={classes.report}>
            <h2 className={classes.headerTop}>THE REDEEMED CHRISTIAN CHURCH OF GOD</h2>
            <h4 className={classes.headerTwoo}>{inputs?.name}</h4>
            <h4 className={classes.headerTwo}> GENERAL LEDGER REPORT
                  {inputs?.start_date?.length > 0 ? (
                    startDate && endDate && !isNaN(new Date(startDate)) && !isNaN(new Date(endDate)) ? (
                      ` FROM ${formatDate(startDate)} TO ${formatDate(endDate)}`
                    ) : (
                      ''
                    )
                  ) : (
                    ` AS AT ${currentDateTime}`
                  )}</h4>
            <h4 className={classes.headerTwoo}>ACCOUNT: {displayName}</h4>
            <h4 className={classes.headerTwoo} style={{textAlign: "right"}}>OPENING BALANCE: {parseFloat(document[0]?.opening_balance).toLocaleString('en-US', {
                    minimumIntegerDigits: 1,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</h4>

            <table className={classes.tableDivF}>
                <thead>
                    <tr className={classes.tableDivTr}>
                    <th style={{ backgroundColor: '#181717', color: 'white', fontSize: '14px', textAlign: 'left', padding: '10px', width: '25px', whiteSpace: "nowrap" }}>POST DATE</th>
                        <th style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'left', padding: '10px', width: '25px', whiteSpace: "nowrap" }}>VALUE DATE</th>
                        <th style={{ backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'left', width: '270px', padding: '10px' }}>DETAILS</th>
                        <th style={{ backgroundColor: '#757373', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '10px' }}>DEBIT</th>
                        <th style={{ backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '10px' }}>CREDIT</th>
                        {/* <th style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'right', width: '200px', padding: '10px' }}>ENDING BALANCE</th> */}
                    </tr>
                </thead>
                <tbody className={classes.tableBodyFirst}>
                {document && document.map((item, index) => (
                    <tr key={index} style={{borderBottom: '1px solid #c4c4c4'}}>
                      <td>{formattedDates(item.created_at)}</td>
                      <td>{item.transaction_date}</td>
                      <td>{item.details}</td>
                      <td style={{ textAlign: "right",  }}>{parseFloat(item.debit).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</td>
                      <td style={{ textAlign: "right",  }}>{parseFloat(item.credit).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</td>
                    </tr>
                  ))}

                </tbody>
                <div style={{marginTop: 10}}/>
                <thead className={classes.bottomHeader} >
                    <tr>
                        <td style={{ backgroundColor: '#535252', color: "#fff", fontSize: '14px', textAlign: 'left', padding: '2px', fontWeight: 900 }}>TOTALS</td>
                        <td style={{ border: '0', backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}></td>
                        <td style={{ border: '0', backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}></td>
                        <td style={{ backgroundColor: '#757373', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}>{totalDebit}</td>
                        <td style={{ backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}>{totalCredit}</td>
                        {/* <td style={{ backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}>{totalCredit}</td>
                        <td style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'right', width: '90px', padding: '2px' }}>{totalClosing}</td> */}
                    </tr>
                </thead>
                <div style={{marginTop: 10}}/>
                <thead className={classes.bottomHeader}>
                    <tr>
                        <td style={{ backgroundColor: '#535252', color: '#fff', fontSize: '14px', textAlign: 'left', padding: '2px', fontWeight: 900, whiteSpace: "nowrap" }}>CLOSING BALANCE</td>
                        <td style={{ border: '0', backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}></td>
                        <td style={{ border: '0', backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}></td>
                        <td style={{ border: '0', backgroundColor: '#757373', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}>--</td>
                        <td style={{ backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}>{parseFloat(document[0]?.closing_balance).toLocaleString('en-US', {
                    minimumIntegerDigits: 1,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</td>
                        {/* <td style={{ backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '2px' }}>{totalCredit}</td>
                        <td style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'right', width: '90px', padding: '2px' }}>{totalClosing}</td> */}
                    </tr>
                </thead>
            </table>

        </div>
        </div>
    )
}
