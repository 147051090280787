import React, { useState, useEffect } from 'react';
// import DashboardNav from '../Components/Navigation.js/Navigation'
// import Horheader from '../Components/horheader/horheader'
import classes from './ModuleSelector1.module.css'
// import pysf from '../Assets/payurfees.png'
// import hostlfees from '../Assets/hostlfees.png'
// import fundurwlt from '../Assets/fundurwlt.png'
// import chekurrslt from '../Assets/chkurwlt.png'
// import gturcrsmtrls from '../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../Assets/rgstrurcurses.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import firstImg from '../Assets/dashboardfirst.svg'
// import T_Icon from '../Assets/T Icon.svg'
// import M_Icon from '../Assets/M_Icom.svg'
// import Q_Icon from '../Assets/QIcon.svg'
// import TheImg from '../Assets/BottomIcon.svg'
// import bell from '../Assets/notification.png'
// import avatar from '../Assets/avatar.png'
import icon7 from '../../assets/promix/vuesax/log.png';
import icon8 from '../../assets/promix/image7.png';
import icon9 from '../../assets/promix/image8.png';
import { ProgressBar, Spinner, Button, Modal, Form } from 'react-bootstrap';
import icon6 from '../../assets/promix/vuesax/icon.png';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import ToggleSlider from './ToggleSlider';
import CurrencyInput from 'react-currency-input-field';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Select from 'react-select';


const ModuleSelector1 = () => {
  const [loanCode, setLoanCode] = useState('');
  const [interest, setInterest] = useState('');
  const [loanDescription, setLoanDescription] = useState('');
  const [balance, setBalance] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const [selectedPayables, setSelectedPayables] = useState(null);
  const [stat, setStat] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [recievables, setReceivables] = useState([]);
  const [newLoading, setNewLoading] = useState(false);


  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => {
    clearForm();
    setShow(false);
  }

  const handleValueChange = (value, name, values) => {
    setBalance(value); 

  };

  const handleClose3 = () => {
    clearForm();
    setShow3(false);
  }
  const handleClose1 = () => {
    setToggleStates({});
    setShow1(false);
  }
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow3 = () => setShow3(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loanLoading, setLoanLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [contLoading, setContLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [staffLoading, setStaffLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [accPay, setAccPay] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedReportTo, setSelectedReportTo] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent, setSelectedContinent] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [accRec, setAccRec] = useState([]);
  const [continents, setContinents] = useState([]);
  const [staffLoan, setStaffLoan] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [validity, setValidity] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [permId, setPermId] = useState([]);
  const [userType, setUserType] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [trashClicked1, setTrashClicked1] = useState(false);
  const [trashClicked2, setTrashClicked2] = useState(false);
  const [permits, setPermits] = useState([]);
  const [error, setError] = useState(null);
  const [continent, setContinent] = useState('');
  const [selectedLoan, setSelectedLoan] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [monthLoading, setMonthLoading] = useState(false);

  const [toggleStates, setToggleStates] = useState({});

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      // const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const value7 = await AsyncStorage.getItem('companyName');
      const value8 = await AsyncStorage.getItem('validity');
      const permitsss = await AsyncStorage.getItem('permissions');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const current = await AsyncStorage.getItem('currentMonth');

      if (value !== null) {
        setBearer(value);
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map(permit => permit.name);
        setPermits(permitNames);
        // console.log(permitNames);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

      if (value8 !== null) {
        setValidity(value8);
      }
      if (current !== null) {
        setCurrentMonth(new Date(current));
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (value7 !== null) {
        setCompany(value7);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

 

  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/users/fetch-all?page=${currentPage}`, { headers });
      const results = response.data?.data?.data;
      const resultx = response.data?.data?.total;
      setTotalEntries(resultx);
      setTableData(results);
      console.log(results);
      const total = response.data?.data?.last_page || 1;
      setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);


  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
      let res;
      if (searchTerm.trim() === "") {
        res = tableData1;
      } else {
        const response = await axios.get(`${BASE_URL}/users/search`, {
          params: { variable: searchTerm },
          headers
        });
        res = response.data?.data;
      }
      setSearchedResult(res);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setSearchedResult([]);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/all-usertype`, { headers });
      const typeList = response.data?.data;
      console.log("lists:", typeList);
      setUserType(typeList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStaffLoan = async () => {
    setStaffLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/fetch-loans`, { headers });
      const staffs = response.data?.data;
      console.log("lists:", response);
      setStaffLoan(staffs);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setStaffLoan([]);
      }
    } finally {
      setStaffLoading(false);
    }
  };

  const fetchAcReceivables = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/receivables`, { headers });
      const accs = response.data?.data;
      console.log("lists:", response);
      const accOptions = accs.map(item => ({
        label: item.gl_name,
        value: item.id
      }));

      setAccRec(accOptions);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setAccRec([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAcPayables = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/payables`, { headers });
      const pay = response.data?.data;
      console.log("lists:", response);
      const payOptions = pay.map(item => ({
        label: item.gl_name,
        value: item.id
      }));

      setAccPay(payOptions);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setAccPay([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };


  const createLoan = async () => {

    setLoanLoading(true);
    try {
      
      const response = await axios.post(`${BASE_URL}/account/create-loan-account`,
        {
          code: loanCode,
          description: loanDescription,
          opening_balance: balance,
          report_to: selectedReportTo?.value,
          payable: selectedPayables?.value,
          interest: interest,
          type: 2

        },
        { headers }
      );
      console.log(response.data.message)
      fetchStaffLoan();
      setLoanCode('');
      setLoanDescription('');
      setBalance('');
      setSelectedReportTo(null);
      setSelectedPayables(null);
      setInterest('');
      
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
    
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
      } finally {
      setLoanLoading(false);
    }
  }

  //filter function
  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(currentPage - 1, totalEntries);

console.log(permits);

  const handleModuleClick = (module) => {
    if (permits.includes(module)) {
      setError(null);
      if (module === 'Final Accounts') {
        navigate('/accounting');
      }
      if (module === 'Cash Management') {
        navigate('/cash_management');
      }
      if (module === 'Administration') {
        navigate('/admin');
      }
      if (module === 'Payments') {
        navigate('/payment');
      }
      if (module === 'Account Receivables') {
        navigate('/receivables');
      }
      if (module === 'Account Payables') {
        navigate('/payables');
      }
      if (module === 'Journal Entries') {
        navigate('/journals');
      }
      if (module === 'cooperative_management_system') {
        navigate('/cooperative');
      }
      if (module === 'event_centers_mgt_system') {
        navigate('/event_mgt_system');
      }
      if (module === 'inventory') {
        navigate('/inventory');
      }
      if (module === 'Fixed Assets') {
        navigate('/fixed_asset_home');
      }
      // if (module === 'Budgets') {
      //   window.open('https://pentsuite.com.ng/budget', '_blank');
      // }
      // if (module === 'Fixed Assets') {
      //   navigate('/fixed_asset_home');
      // }
      if (module === 'Budgets') {
        navigate('/budget_dash');
      }
      if (module === 'Reconciliation') {
        window.open('https://pentsuite.com.ng/', '_blank');
      }

    } else {
      setError(`Access to ${module} is forbidden`);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: `Access to ${module} is forbidden`,
      });

    }
  };

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13 ? 'th' : suffixes[v % 10] || 'th';
  };
  
  const formattDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Invalid Date';
    }
  
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
  
    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  // const currentMonth = new Date();

  const handleLogout = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      navigate('/login');
      //   toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response) {
        if (error.response.status === 401) {
          navigate('/login');
        } else if (error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      }
      //   toast.error(errorMessage);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const editUser = async (id) => {
    setCreateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/users/update-user`,
        {
          name: fullName,
          email: email,
          phone_number: phone,
          user_id: selectedUser,
        },
        { headers }
      );

      fetchData();
      setFullName('');
      setPhone('');
      setEmail('');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.data),
        });
        console.log(error);
      }
    } finally {
      setCreateLoading(false);
    }
  };



  const handleEyeClick = (id) => {
    const foundUser = tableData.find(item => item.id === id);

    if (foundUser) {
      const { name, email, phone_number } = foundUser;
      setSelectedUser(id);
      setFullName(name || '');
      setEmail(email || '');
      setPhone(phone_number || '');

      setIsUpdateMode(true);

    } else {
      console.error(`User with id ${id} not found`);
    }
  };

  const handleEyeClick1 = (id) => {
    const foundLoan = staffLoan.find(item => item.id === id);

    if (foundLoan && foundLoan) {
      const slcRpt = accRec.find(item => item.value === foundLoan.report.id);
      setSelectedReportTo(slcRpt);
    }

    if (foundLoan && foundLoan) {
      const slcPay = accPay.find(item => item.value === foundLoan.report.id);
      setSelectedPayables(slcPay);
    }

    if (foundLoan) {
      const { code, description, interest, opening_balance,  } = foundLoan;
      setSelectedLoan(id);
      setLoanCode(code || '');
      setLoanDescription(description || '');
      setInterest(interest || '');
      setBalance(opening_balance || '');
    

      setIsUpdateMode(true);

    } else {
      console.error(`User with id ${id} not found`);
    }
  };



  const clearForm = () => {
    setSelectedUser(null);
    setSelectedLoan(null);
    setSelectedReportTo(null);
    setFullName('');
    setPhone('');
    setEmail('');
    setLoanDescription('');
    setLoanCode('');
    setBalance('');
    setInterest('');
    setIsUpdateMode(false);
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this user.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/users/delete?id=${id}`, { headers });
      fetchData();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        console.log(errorMessage);
      }
    }
  };


  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const createUser = async () => {
    setCreateLoading(true);
    try {
      console.log(fullName, email, phone);
      const response = await axios.post(
        `${BASE_URL}/users/create-new`,
        {
          name: fullName,
          email: email,
          phone_number: phone,

        },
        { headers }
      );
      console.log(response)
      fetchData();
      setFullName('');
      setPhone('');
      setEmail('');

      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setCreateLoading(false);
    }
  };

  const editLoan = async () => {

    setLoanLoading(true);
    try {
      
      const response = await axios.post(`${BASE_URL}/account/update-account`,
        {
          code: loanCode,
          description: loanDescription,
          opening_balance: balance,
          report_to: selectedReportTo?.value || '',
          payable: selectedPayables?.value || '',
          type: 2,
          id: selectedLoan

        },
        { headers }
      );
      console.log(response.data.message)
      
clearForm();
fetchStaffLoan();
Swal.fire({
  icon: 'success',
  title: 'Success',
  text: response.data.message,
});
      // return
      console.log(response.data);

    } catch (error) {
            
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: error.response.data.message,
          });
          console.log(errorMessage);
      }
    } finally {
      setLoanLoading(false);
    }
  };

  const handleSubmit = () => {
    if (isUpdateMode) {
      // Call update user function here
      editUser();
    } else {
      // Call create user function here
      createUser();
    }
  };

  const handleSubmit1 = () => {
    if (isUpdateMode) {
      // Call update user function here
      editLoan();
    } else {
      // Call create user function here
      createLoan();
    }
  };

  const fetchPermission = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/users/fetch-user-modules`, { headers });
      const data = response?.data?.data;
      const permissionId = data?.map(item => item.id);
      setPermId(permissionId);
      console.log(data);
      const initialToggleStates = Object.fromEntries(permissions.map(id => [id, false]));

      // const initialToggleStates = false; 


      setPermissions(data);
      setToggleStates(initialToggleStates);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(error);
      setPermissions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchPermission();
    }
  }, [bearer]);

  useEffect(() => {
    if (bearer) {
      fetchAcReceivables();
      fetchAcPayables();
      fetchStaffLoan();
    }
  }, [bearer]);



  const handleToggleChange = (itemId) => {
    setToggleStates(prevToggleStates => ({
      ...prevToggleStates,
      [itemId]: !prevToggleStates[itemId],
    }));
  };

  const handleCheckAllToggle = () => {
    const checkAllValue = !checkAll;
    setCheckAll(checkAllValue);

    // Set all toggle states to the determined value
    const updatedToggleStates = Object.fromEntries(permId.map(id => [id, checkAllValue]));
    setToggleStates(updatedToggleStates);
  };



  const createModule = async () => {
    setModuleLoading(true);

    try {
      // Map IDs to names
      const idToNameMap = permissions.reduce((acc, item) => {
        acc[item.id] = item.id;
        return acc;
      }, {});

      const selectedToggle = permissions
      .filter((item) => toggleStates[item.id])
      .map((item) => item.module?.id);

      // const selectedToggle = Object.entries(toggleStates)
      //   .filter(([_, value]) => value)
      //   .map(([key, _]) => idToNameMap[parseInt(key)]);

    console.log(selectedUser, selectedToggle);
    // return;

      const response = await axios.post(
        `${BASE_URL}/users/assign-module`,
        {
          user_id: selectedUser,
          module: selectedToggle
        },
        { headers }
      );

      handleClose1();
      setToggleStates({});
      setSelectedUser('');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      // handleLogout();
      //   toast.success(response.data.message)

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.data)
        });
        console.log(error);
      }

    } finally {
      setModuleLoading(false);
    }
  };

  const getNextMonthName = (currentMonth) => {
    // Parse the currentMonth string to create a Date object
    const date = new Date(currentMonth);
    
    // Extract the current month (0-based index, so adding 1 to get the next month)
    let nextMonth = date.getMonth() + 1; // getMonth() is 0-based (Jan = 0)
    
    // If the next month is December (11) and we need to roll over to January
    if (nextMonth > 11) {
      nextMonth = 0;
    }
  
    // Create a new date object for the next month (day set to 1 for simplicity)
    const nextMonthDate = new Date(date.getFullYear(), nextMonth, 1);
  
    // Use toLocaleString() to get the month name in words
    const nextMonthName = nextMonthDate.toLocaleString('default', { month: 'long' });
  
    return nextMonthName;
  };

  const getPrevMonthName = (currentMonth) => {
    // Parse the currentMonth string to create a Date object
    const date = new Date(currentMonth);
  
    // Extract the current month and subtract 1 to get the previous month
    let prevMonth = date.getMonth() - 1; // getMonth() is 0-based (Jan = 0)
  
    // If the previous month is before January, roll over to December of the previous year
    if (prevMonth < 0) {
      prevMonth = 11; // December
      date.setFullYear(date.getFullYear() - 1); // Move to the previous year
    }
  
    // Create a new date object for the previous month (day set to 1 for simplicity)
    const prevMonthDate = new Date(date.getFullYear(), prevMonth, 1);
  
    // Use toLocaleString() to get the month name in words
    const prevMonthName = prevMonthDate.toLocaleString('default', { month: 'long' });
  
    return prevMonthName;
  };

  const getCurrentMonthName = (currentMonth) => {
    // Parse the currentMonth string to create a Date object
    const date = new Date(currentMonth);
  
    // Use toLocaleString() to get the current month name in words
    const currentMonthName = date.toLocaleString('default', { month: 'long' });
  
    return currentMonthName;
  };
  

  const handleTrashClick1 = async () => {
    const currentMonthName = getCurrentMonthName(currentMonth);
const nextMonthName = getNextMonthName(currentMonth);
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to change your working month from ${currentMonthName} to ${nextMonthName}.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!',
      cancelButtonText: 'No, Cancel',
    });
  
    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }
  
    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: 'Changing the working month...',
      text: 'Please wait while we process your request.',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
  
    setMonthLoading(true);
  
    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/update-province-month`,
        {}, // Empty body
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );
  
      // Close the loading Swal and show the success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      navigate('/login');
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
  
      // Close the loading Swal and show the error message
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setMonthLoading(false); // Stop loading
    }
  };
  
  const handleTrashClick2 = async () => {
    const currentMonthName = getCurrentMonthName(currentMonth);
    const prevMonthName = getPrevMonthName(currentMonth);
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to reset your working month from ${currentMonthName} to ${prevMonthName}.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reset it!',
      cancelButtonText: 'No, Cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

      // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
      Swal.fire({
        title: 'Resetting the working month...',
        text: 'Please wait while we process your request.',
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false, // Hide the confirm button
        didOpen: () => {
          Swal.showLoading(); // Show loading spinner
        }
      });

    setMonthLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/reverse-province-month`,
        {}, // Empty body
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      navigate('/login');
    //   setTrashClicked1(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setMonthLoading(false); // Stop loading
    }
  };

  const fetchData8 = async () => {
    setNewLoading(true);
    try {
        const response = await axios.get(`${BASE_URL}/payable-receivable-dashboard`, { headers });
        const sta = response.data?.data;
        const sta1 = response.data?.data?.top_customers;
        const sta2 = response.data?.data?.account_receivables;
        console.log(sta);
        setStat(sta);
        setCustomer(sta1);
        setReceivables(sta2);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
        } else {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setStat([]);
        }
    } finally {
        setNewLoading(false);
    }
};

useEffect(() => {
    if (bearer) {
        fetchData8();

    }
}, [bearer]);



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  return (
    <div>
      {/* <div className={classes.navbrd}> */}
      <ToastContainer />
      <div className={classes.horinfodashbrd}>
        <div className={classes.stinfo}>
          <p className={classes.stinfosts}>Welcome</p>
          <p className={classes.stinfolvl}>{user.toLocaleUpperCase()}</p>
          <p className={classes.stinfosts}>{`(${admin})`}</p>

        </div>

        <div className={classes.usrcnr}>

          {/* <div className={classes.vrtlne}></div> */}
          <div className={classes.usrinfo}>
            <div className={classes.usrnminf}>

              {/* <p className={classes.usrpstn}>Welcome</p> */}
              <p className={classes.usrpstn}>Company Name</p>
              <p className={classes.usrnme}>{province === 'undefined' ? admin : province}</p>
              <p className={classes.usrpstn}>
                {continent !== 'undefined' && continent}
                {continent !== 'undefined' && region !== 'undefined' && ' | '}
                {region !== 'undefined' && region}
              </p>


            </div>
            <div className={classes.avtrs} onClick={() => handleLogout()} >

              <>

                {loading ? <Spinner size="sm" animation="border" variant="light" /> :
                  <img src={icon7} alt="Icon" className={classes.avtrs} style={{backgroundColor:'#3770ab'}}/>
                }
              </>
            </div>

          </div>
          {/* <img src={bell} className={classes.bel} /> */}
        </div>

      </div>
      {/* </div> */}

      <div className={classes.appcontainer}>


        <div className={classes.maincontent}>

          <div className={classes.dashBoardCont}>
            {/* <div className={classes.firstGrid1}>

              <div className={classes.receivableCont}>
                <h1>Total Receivables</h1>
                <h2>TOTAL UNPAID INVOICES</h2>
                <h3> ₦{newLoading ? <Spinner size='sm' /> : parseFloat(stat?.total_receivables).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</h3>
                <ProgressBar variant="success" now={0} />
               
              </div>
              <div className={classes.receivableCont}>
                <h1>Total Payables</h1>
                <h2>TOTAL UNPAID BILLS</h2>
                <h3>₦{newLoading ? <Spinner size='sm' /> : parseFloat(stat?.total_payables).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</h3>
                <ProgressBar variant="warning" now={0} />
                
              </div>
              <div className={classes.receivableCont1}>
                <div className={classes.adminHeader}>
                  <p className={classes.moduleText1}>{admin === "Provincial Accountant" ? "PROVINCIAL SETTINGS" :
 admin === "Continental Accountant" ? "CONTINENTAL SETTINGS" :
 admin === "Regional Accountant" ? "REGIONAL SETTINGS" :
 "ADMIN SETTINGS"}</p>
                </div>
                <div className={classes.settingsContent}>
                  <div
                    className={classes.settings}
                    onClick={admin !== "Super Admin" ? handleShow : null}
                    style={{ cursor: admin === "Super Admin" ? "not-allowed" : "pointer" }}
                  >
                    <div>
                      <img src={icon6} className={classes.avtr1} />
                    </div>
                    <div className={classes.modButton1}>
                      <h1>Manage User</h1>
                      <p>Create, Update Users' Record</p>
                    </div>
                  </div>

                  <div className={classes.settings} onClick={admin !== "Super Admin" ? handleShow1 : null}
                    style={{ cursor: admin === "Super Admin" ? "not-allowed" : "pointer" }}
                  >
                    <div>
                      <img src={icon6} className={classes.avtr1} />
                    </div>
                    <div className={classes.modButton1}>
                      <h1>User Modules</h1>
                      <p>Assign Modules to Users</p>
                    </div>
                  </div>
                  <div className={classes.settings} onClick={admin !== "Super Admin" ? handleShow3 : null}
                    style={{ cursor: admin === "Super Admin" ? "not-allowed" : "pointer" }}
                  >
                    <div>
                      <img src={icon6} className={classes.avtr1} />
                    </div>
                    <div className={classes.modButton1}>
                      <h1>Loan Types</h1>
                      <p>Create loan types</p>
                    </div>
                  </div>
                
                </div>
              </div>



            </div> */}
            <Modal show={show} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Manage User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-1">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone Number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div>
                    <Form.Group className="mb-1">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>


                </Form>
                <Modal.Footer>
                
                  {isUpdateMode && (
                    <Button variant="danger" onClick={clearForm}>
                      Clear Form
                    </Button>
                  )}
                  <Button variant="success" onClick={handleSubmit}>
                    {createLoading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>{isUpdateMode ? "Updating user, Please wait..." : "Creating user, Please wait..."}</span>
                      </>
                    ) : (
                      isUpdateMode ? 'Update User' : 'Create User')}
                  </Button>

                </Modal.Footer>


                <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>

                </div>
             
                <div className={classes.spaceb} />
                <div className="mainTable">
                  {roleLoading ? (
                    <p>Fetching users...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            {/* <th>Role</th>
                    <th>User Type</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap' }}>
                          {tableData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.phone_number}</td>
                              {/* <td>{item.roles?.map((role, index) => <span key={index}>{role.name}</span>)}</td> */}
                              <td>
                                <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                  <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                </div>
                                <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                  <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className={classes.space1b} />
                <div className="d-flex justify-content-between align-items-center">
                  <p>Showing {startIndexx} to {endIndexx} of {totalEntries} entries</p>
                  <div className="d-flex">
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Modal.Body>
             
            </Modal>

            <Modal show={show3} onHide={handleClose3} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Loan Types</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Form.Label>Loan Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Loan Code"
                          value={loanCode} onChange={(e) => setLoanCode(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Description"
                          value={loanDescription} 
                          onChange={(e) => setLoanDescription(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>Loan Interest</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Loan Interest"
                        value={interest} onChange={(e) => setInterest(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>Balance</Form.Label>
                      <CurrencyInput

name="balance"
decimalsLimit={2}
className="form-control"
value={balance} // Set the value to the balance state
onValueChange={handleValueChange}
style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
/>
                    </Form.Group>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>A/C Receivables</Form.Label>
                      <Select loading={isLoading} dropdownHeight='100px' options={accRec} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                          onChange={setSelectedReportTo} value={selectedReportTo}  placeholder="Select Account Receivables"
                        />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-1">
                      <Form.Label>A/C Payables</Form.Label>
                      <Select loading={isLoading} dropdownHeight='100px' options={accPay} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                          onChange={setSelectedPayables} value={selectedPayables}  placeholder="Select Account Payables"
                        />
                    </Form.Group>
                  </div>
                  </div>

                </Form>
                <Modal.Footer>
                  
                  {isUpdateMode && (
                    <Button variant="danger" onClick={clearForm}>
                      Clear Form
                    </Button>
                  )}
                  <Button variant="success" onClick={handleSubmit1}>
                    {loanLoading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>{isUpdateMode ? "Updating, Please wait..." : "Saving, Please wait..."}</span>
                      </>
                    ) : (
                      isUpdateMode ? 'Update' : 'Save')}
                  </Button>

                </Modal.Footer>


                <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>

                </div>
                {/* <div className="text-right modal-effect">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <div className="d-flex justify-content-start align-items-center">
              <div className="mr-2">Search:</div>
              <input
                type="search"
                value={searchTerm}
                className="form-control form-control-sm"
                aria-controls="DataTables_Table_0"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button style={{ marginLeft: 10 }} variant="success" onClick={() => fetchSearch(searchTerm)}>
                {searchLoading ? <Spinner size="sm" /> : "Search"}
              </Button>
            </div>
          </div>
        </div> */}
                <div className={classes.spaceb} />
                <div className="mainTable">
                  {staffLoading ? (
                    <p>Fetching data...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                          <th>S/N</th>
                                  <th>Code</th>
                                  <th>Description</th>
                                  <th>Interest Rate</th>
                                  <th>Opening Balance</th>
                                  <th>Report To</th>
                                  <th>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap' }}>
                          {staffLoan.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td style={{textAlign: "left"}}>{item.code}</td>
                                    <td>{item.description}</td>
                                    <td style={{textAlign: "left"}}>{item.interest}</td>
                                    <td style={{textAlign: "right"}}>{parseFloat(item.opening_balance).toLocaleString('en-US', {
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}</td>
                                    <td>{item.report?.gl_name}</td>
                                    <td>
                                    {/* {(isAdmin || permittedHeaders.includes('update-loan-account')) && ( */}
                                      <div onClick={() => handleEyeClick1(item.id)}  className="btn btn-success-soft btn-sm mr-1">
                                        <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize:12}}></i>
                                      </div>
                                    {/* )} */}
                                    {/* {(isAdmin || permittedHeaders.includes('delete-loan-account')) && ( */}
                                      <div  className="btn btn-danger-soft btn-sm">
                                        <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize:12}}></i>
                                      </div>
                                    {/* )} */}
                                    </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className={classes.space1b} />
                <div className="d-flex justify-content-between align-items-center">
                  <p>Showing {startIndexx} to {endIndexx} of {totalEntries} entries</p>
                  <div className="d-flex">
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary">
          Save Changes
        </Button>
      </Modal.Footer> */}
            </Modal>
            <Modal show={show1} onHide={handleClose1} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>User Modules</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                  <Form.Label>User</Form.Label>
                  <Form.Select name="user" className="form-control" required="" value={selectedUser} onChange={handleUserChange}>
                    <option value="">Select User</option>
                    {tableData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form>
                <div className='modal-footer' style={{ marginTop: 20 }} />
                <div style={{ display: "flex", gap: 5 }}>
                  <ToggleSlider disabled={isLoading} checked={checkAll} onChange={handleCheckAllToggle} />
                  <p>Check All</p>


                </div>
                <div className='modal-footer' style={{ marginTop: 20 }} />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5, alignItems: "center", justifyContent: "center" }}>
                  {isLoading ? (
                    <>
                      <Spinner size='sm' animation="border" role="status" />

                    </>
                  ) : (
                    <>
                      {permissions?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            width: '130px',
                            height: '130px',
                            margin: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                            boxSizing: 'border-box', // Include padding and border in the width
                            marginBottom: 20,
                            overflow: 'hidden'
                          }}
                        >
                          <ToggleSlider
                            checked={toggleStates[item.id]}
                            onChange={() => handleToggleChange(item.id)}
                          />
                          <p style={{ fontSize: 13.5, margin: '5px 0', textAlign: "center", whiteSpace: "normal", wordWrap: "break-word", overflowWrap: "break-word", maxWidth: "100%" }}>{item.module?.name}</p>
                        </div>
                      ))}
                    </>
                  )}
                </div>


              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Close
                </Button>
                <Button variant="success" className={classes.btn2} onClick={createModule}>
                  {moduleLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Assign User Module"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

          </div>
          <div className={classes.moduleSelectorsCont}>
            <div className={classes.moduleSelectors}>
              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: '100%', paddingRight: '30px' }}>
                <p className={classes.moduleText}>SELECT A MODULE</p>

                {/* <DropdownButton syle={{backgroundColor:"unset", color:"black"}} id="dropdown-item-button" title="Dropdown button">
                  <Dropdown.ItemText syle={{backgroundColor:"unset", color:"black"}}>Dropdown item text</Dropdown.ItemText>
                  <Dropdown.Item as="button">Action</Dropdown.Item>
                  <Dropdown.Item as="button">Another action</Dropdown.Item>
                  <Dropdown.Item as="button">Something else</Dropdown.Item>
                </DropdownButton> */}

                {/* <Navbar.Collapse id="navbar-dark-example"> */}
                {/* <Nav> */}

                {(admin !== 'Super Admin' && admin !== 'Compliance') && (
                  <>
                    {monthLoading && <Spinner animation="border" />}
                    <NavDropdown style={{ color: 'green', fontWeight: '800' }} title={formattDate(currentMonth)}>
                      <NavDropdown.Item onClick={handleTrashClick1}>Change Working Month</NavDropdown.Item>
                      <NavDropdown.Item onClick={handleTrashClick2}>Reset Working Month</NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}


                {/* </Nav> */}
                {/* </Navbar.Collapse> */}


              </div>
              <div className={classes.moduleCont}>
                <div className={classes.module1} onClick={() => handleModuleClick('Final Accounts')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Accounting</h1>
                    <p>Account Receivables , Account Payables , Banking and Cash Management, Accounting reports</p>
                  </div>
                </div>
                <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Payroll</h1>
                    <p>Manage employees record , run monthly payroll , generate payslip, integrate with GL</p>
                  </div>
                </div>

                <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Inventory</h1>
                    <p>Stock Management, Sales & Purchase Invoices, e.t.c.</p>
                  </div>
                </div>

              </div>

              <div className={classes.moduleCont}>
                <div className={classes.module1} onClick={() => handleModuleClick('Reconciliation')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Reconciliation </h1>
                    <p>Reconcile Accounts, Generate Reconcilation Summary Report, e.t.c.</p>
                  </div>
                </div>

                <div className={classes.module1} onClick={() => handleModuleClick('Fixed Assets')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Fixed Asset Management</h1>
                    <p>Fixed Assets Register, Asset Disposal, Depreciations, e.t.c.</p>
                  </div>
                </div>

                <div className={classes.module1} onClick={() => handleModuleClick('Budgets')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Budgets</h1>
                    <p>Upload Budgets, View Performance Reports, e.t.c.</p>
                  </div>

                </div>

              </div>

              
              <div className={classes.moduleCont}>
              {admin === "Super Admin" && (
        <>
                <div className={classes.module1} onClick={() => handleModuleClick('Administration')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Administration </h1>
                    <p>Manage Users, Continents, Regions, Provinces, e.t.c.</p>
                  </div>
                </div>
                </>
              )}
                <div className={classes.module1} >
                  {/* <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div> */}
                  <div className={classes.modButton}>
                    {/* <h1>Fixed Asset Management</h1>
                    <p>Fixed Assets Register, Asset Disposal, Depreciations, e.t.c.</p> */}
                  </div>
                </div>

                <div className={classes.module1} >
                  {/* <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div> */}
                  <div className={classes.modButton}>
                    {/* <h1>Budgets</h1>
                    <p>Upload Budgets, View Performance Reports, e.t.c.</p> */}
                  </div>

                </div>

              </div>


            </div>

          </div>




        </div>


      </div>
    </div>

  );
}

export default ModuleSelector1;