import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import JournalDash from '../Journal Dashboard/JournalDash';
import LatestDash from '../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MobileMenu from '../../Component/Mobile/MobileNav';


function UploadJournalEntry() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, accountName: '', accountCode: '', DR_CR: '', amount: '', description: '', date: '' }]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [totalAmountCredit, setTotalAmountCredit] = useState('');
  const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState(false);
  const [isEqual, setIsEqual] = useState(false);
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [jvNumber, setJvNumber] = useState('');
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false); 
   const [openNav,setOpenNav] = useState(false);


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);

      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  //   console.log("user:", user)

  useEffect(() => {
    readData();
  }, []);




  const calculateTotalDebit = () => {
    const total = balances.reduce((acc, item) => {
      return item.debit ? acc + parseFloat(item.debit || 0) : acc;
    }, 0);

    const formattedTotal1 = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountDebit(formattedTotal1);
  };
  const calculateTotalCredit = () => {
    const total1 = balances.reduce((acc, item) => {
      return item.credit ? acc + parseFloat(item.credit || 0) : acc;
    }, 0);

    const formattedTotal11 = total1.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountCredit(formattedTotal11);
  };




  useEffect(() => {
    calculateTotalDebit();
    calculateTotalCredit();

  }, [balances]);



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  useEffect(() => {
    // Compare the total debit and total credit values
    setIsEqual(totalAmountDebit === totalAmountCredit);
  }, [totalAmountDebit, totalAmountCredit]);

  const [selectedDate, setSelectedDate] = useState('');
  const [show, setShow] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [createLoading, setCreateLoading] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const uploadStatement = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('jv_number', jvNumber);


      const response = await axios.post(
        `${BASE_URL}/account/upload-journal-entries`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response, "heeee");
      setBalances(response.data.data);
      handleClose();
      // Reset form fields
      setSelectedImage("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  console.log(balances[0]?.uuid);

  const fetchInvoiceCode = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setJvNumber(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);  


  const postBalances = async () => {

    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to save this journal entry.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return;
    }
    
    setBalanceLoading(true);
    const dates = balances.map((row) => row.transaction_date).filter((name) => name !== undefined);
    const descriptions = balances.map((row) => row.description).filter((name) => name !== undefined);
    const accountCodes = balances.map((row) => row.gl_code).filter((name) => name !== undefined);
    const debits = balances.map((row) => row.debit).filter((name) => name !== undefined);
    const credits = balances.map((row) => row.credit).filter((name) => name !== undefined);

    console.log(dates)

    try {
      //  return;
      const response = await axios.post(
        `${BASE_URL}/account/save-journal-entries`,
        {
          total_debit: totalAmountDebit,
          total_credit: totalAmountCredit,
          uuid: balances[0]?.uuid
          // gl_code: accountCodes,
          // debit: debits,
          // credit: credits,
          // transaction_date: dates,
          // description: descriptions,



        },
        { headers }
      );


      console.log(response.data?.message, "heeee");
      setBalances([]);
      setSelectedDate('');

      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });

      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setBalanceLoading(false);
    }
  };


  return (

    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>
            <div className={classes.finishedbodyCont}>
              <div className='newBody'>
                <div className={classes.newWidth}>
                  <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  20px" }}>Upload Journal Entries</h3>
                      <p style={{ margin: " 0 10px 20px 20px" }}>Upload, View, Save journal entries</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: 10, zIndex: 1, marginTop: "30px", marginRight: 60 }} className={classes.flexendtialign}>
                      <OverlayTrigger
                        placement="top"
                        className={classes.btndf1}
                        overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to upload journal entries</Tooltip>}
                      >
                        <Button
                          style={{ width: 100, height: "30px", padding: "0", borderRadius: 5 }} variant='success' onClick={handleShow}>
                          Upload
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        className={classes.btndf1}
                        overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to download template</Tooltip>}
                      >
                        <a href="https://account-api.patna.ng/api/v1/download-journal-entries" download>
                          <Button
                            className={classes.btn1}
                            style={{ width: 200, height: "30px", padding: "0", borderRadius: 5 }} variant='success' >
                            Download Template
                          </Button>
                        </a>
                      </OverlayTrigger>


                    </div>
                  </div>
                  {/* <h3 style={{ marginTop: '1rem', fontSize: 20, fontWeight: 700 }}>Journal Entries</h3> */}


                  <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                      <div className={`card-body ${classes.awrow112}`} style={{ border: 'none' }}>


                        <div className={`row ${classes.awrow1}`} >
                          <Modal show={show} onHide={handleClose} animation={false}>
                            <Modal.Header closeButton>
                              <Modal.Title>Upload Journal Entries</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form style={{ marginTop: 20 }}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <div style={{ marginTop: 10 }} />
                                  <Form.Label>JV Number</Form.Label>
                                    {/* <input className="form-control" required="" type="text" value={jvNumber} onChange={(e) => setJvNumber(e.target.value)} name="jvnumber" disabled />                                   */}
                                  <Form.Control
                                    type="text"
                                    placeholder="Please enter JV Number"
                                    onChange={(e) => setJvNumber(e.target.value)}
                                    value={jvNumber}
                                    disabled

                                  />
                                  <div style={{ marginTop: 10 }} />
                                  <Form.Label>File</Form.Label>
                                  <Form.Control
                                    type="file"
                                    placeholder="Choose file"
                                    accept=".xlsx"
                                    onChange={handleImageChange}

                                  />
                                </Form.Group>
                              </Form>
                            </Modal.Body>

                            <Modal.Footer>
                              <Button variant="danger" onClick={handleClose}>
                                Go back
                              </Button>
                              <Button variant="success" onClick={uploadStatement}>
                                {createLoading ? (
                                  <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                                  </>
                                ) : (
                                  "Upload"
                                )}
                              </Button>
                            </Modal.Footer>
                          </Modal>



                          <div className={classes.mainform}>
                            <div className={`row ${classes.awrow1}`}>
                              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                <div className={`col-md-4 ${classes.mblrow}`}>
                                  <div className="form-group row">
                                    {/* <label for="example-text-input" className={`col-sm-6 col-form-label font-weight-400 ${classes.mbllbl}`}>Opening Balance as at:</label>
                                <div className="col-sm-6">
                                  <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate} style={{ width: "300px", marginLeft: 5 }} />
                                </div> */}
                                  </div>

                                </div>

                              </div>


                              {/* <div style={{ marginTop: 20 }} /> */}
                              <div className={classes.mainTable}>
                                <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto', }}>
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                    <thead style={{ whiteSpace: "nowrap", textAlign: "left", }}>
                                      <tr>
                                        <th >Transaction Date</th>
                                        <th >Description</th>
                                        <th >GL Code</th>
                                        <th >Cheque/Teller No.</th>
                                        <th style={{ textAlign: "right", width: "50px" }}>Debit</th>
                                        <th style={{ textAlign: "right", width: "50px" }}>Credit</th>
                                      </tr>
                                    </thead>
                                    <tbody >

                                      {balances.map((item, index) => (
                                        <tr key={index}>
                                          <td >
                                            {item.transaction_date}

                                          </td>
                                          <td>
                                            {item.description}
                                          </td>
                                          <td>
                                            {item.gl_code}
                                          </td>
                                          <td>
                                            {item.cheque_number}
                                          </td>
                                          <td style={{ textAlign: "right", }}>{parseFloat(item.debit).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                          </td>
                                          <td style={{ textAlign: "right", }}>{parseFloat(item.credit).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                          </td>
                                        </tr>
                                      ))}

                                    </tbody>
                                  </table>

                                </div>
                              </div>

                            </div>

                            <div style={{ marginTop: 20 }} />
                            <div className={classes.form_align}>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Debit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountDebit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right' }}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Credit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountCredit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right' }}
                                />
                              </Form.Group>
                            </div>


                            <div class={`modal-footer ${classes.overlaybtn100}`} style={{ marginTop: '20px', paddingRight: '20px' }}>
                              <Button
                                className={classes.longbtn}
                                variant='success'
                                onClick={postBalances}
                                disabled={!isEqual}
                                style={{ padding: '5px 30px' }}>
                                {balanceLoading ? (
                                  <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                                  </>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>













              </div>
            </div>
            {/* <!--/.main content--> */}
          </div>
        </div>
        {/* <InfoFooter /> */}
        {/* <!--/.footer content--> */}
        <div className="overlay"></div>
      </div>
      {/* <!--/.wrapper--> */}


    </div>


  );
}

export default UploadJournalEntry;