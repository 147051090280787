import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MobileAdmin.module.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { NavLink, Link } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { BASE_URL } from "../../Pages/api/api";

const MobileAdmin = ({ setOpenNav }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [bearer, setBearer] = useState("");
  const [permits, setPermits] = useState([]);
  const [user, setUser] = useState("");
  const [validity, setValidity] = useState("");
  const [admin, setAdmin] = useState("");
  const [company, setCompany] = useState("");
  const [currMonth, setCurrMonth] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpenNav(false);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value3 = await AsyncStorage.getItem("admin");
      const value7 = await AsyncStorage.getItem("companyName");
      const value8 = await AsyncStorage.getItem("validity");
      const curr = await AsyncStorage.getItem("currentMonth");
      const permitsss = await AsyncStorage.getItem("modules");

      if (value !== null && value.trim() !== "") {
        setBearer(value);
      } else {
        navigate("/login");
        return;
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map((permit) => permit.name);
        setPermits(permitNames);
      }
      if (value1 !== null) setUser(value1);
      if (value8 !== null) setValidity(value8);
      if (value3 !== null) setAdmin(value3);
      if (value7 !== null) setCompany(value7);
      if (curr !== null) setCurrMonth(curr);
    } catch (e) {
      alert("Failed to fetch data from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      navigate("/login");
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.mobileMenu}>
      <div className={classes.mobileBgr}>
        <div className={classes.closeMenu}>
          <button onClick={() => setOpenNav(false)}>
            <IoMdClose size={24} />
          </button>
        </div>
        <ul className={classes.menuMobile}>
          <li className={classes.menuItem}>
            <Link to="/admin" onClick={handleNavigation}>
              Dashboard
            </Link>
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("setup")}
            >
              <span>Setup</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "setup" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/admin/exchange_rate"
                  onClick={() => handleNavigation("/admin/exchange_rate")}
                  className={classes.dropdownItem}
                >
                 Manage Currency Rate
                </Link>
                <Link
                  to="/admin/tax"
                  onClick={() => handleNavigation("/admin/tax")}
                  className={classes.dropdownItem}
                >
                 Manage Tax
                </Link>
                <Link
                  to="/admin/continent"
                  onClick={() => handleNavigation("/admin/continent")}
                  className={classes.dropdownItem}
                >
                 Manage Continents
                </Link>
                <Link
                  to="/admin/region"
                  onClick={() => handleNavigation("/admin/region")}
                  className={classes.dropdownItem}
                >
                  Manage Region
                </Link>
                <Link
                  to="/admin/province"
                  onClick={() => handleNavigation("/admin/province")}
                  className={classes.dropdownItem}
                >
                 Manage Province
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("approvals")}
            >
              <span> Users Management</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "approvals" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/admin/users"
                  onClick={() =>
                    handleNavigation("/admin/users")
                  }
                  className={classes.dropdownItem}
                >
                 Manage Users
                </Link>
                <Link
                  to="/admin/assigned_users"
                  onClick={() =>
                    handleNavigation("/admin/assigned_users")
                  }
                  className={classes.dropdownItem}
                >
                Assigned Users to Province
                </Link>
                <Link
                  to="/admin/change_working_month_approvals"
                  onClick={() =>
                    handleNavigation("/admin/change_working_month_approvals")
                  }
                  className={classes.dropdownItem}
                >
               Change Working Month Approvals
                </Link>
                <Link
                  to="/admin/roles_and_permissions"
                  onClick={() =>
                    handleNavigation("/admin/roles_and_permissions")
                  }
                  className={classes.dropdownItem}
                >
              Roles and Permission
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("records")}
            >
              <span> Province Records</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "records" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/admin/customers"
                  onClick={() => handleNavigation("/admin/customers")}
                  className={classes.dropdownItem}
                >
                  Customers
                </Link>
                <Link
                  to="/admin/supplier"
                  onClick={() => handleNavigation("/admin/supplier")}
                  className={classes.dropdownItem}
                >
                  Suppliers
                </Link>
                <Link
                  to="/admin/loan"
                  onClick={() => handleNavigation("/admin/loan")}
                  className={classes.dropdownItem}
                >
                 Loan Beneficiaries
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("financial")}
            >
              <span>Accounts</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "financial" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/admin/account_categories"
                  onClick={() => handleNavigation("/admin/account_categories")}
                  className={classes.dropdownItem}
                >
                 Account Categories
                </Link>
                <Link
                  to="/admin/charts_of_account"
                  onClick={() => handleNavigation("/admin/charts_of_account")}
                  className={classes.dropdownItem}
                >
                 Charts of Account
                </Link>
                <Link
                  to=""
                  onClick={() => handleNavigation("")}
                  className={classes.dropdownItem}
                >
                Bank Statements
                </Link>
              </div>
            )}
          </li>
          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("assets")}
            >
              <span> Fixed Asset Register</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "assets" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/admin/fixed_asset_report"
                  onClick={() => handleNavigation("/admin/fixed_asset_report")}
                  className={classes.dropdownItem}
                >
                Fixed Asset Report
                </Link>
              </div>
            )}
          </li>
          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("reports")}
            >
              <span> Reports</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "reports" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/accounting/cashbook_admin"
                  onClick={() => handleNavigation("/accounting/cashbook_admin")}
                  className={classes.dropdownItem}
                >
               Cashbook
                </Link>

                <Link
                  to="/accounting/trialbalance_admin"
                  onClick={() => handleNavigation("/accounting/trialbalance_admin")}
                  className={classes.dropdownItem}
                >
              Trial Balance
                </Link>
                <Link
                  to="/accounting/general_ledger_admin"
                  onClick={() => handleNavigation("/accounting/general_ledger_admin")}
                  className={classes.dropdownItem}
                >
             General Ledger
                </Link>

                <Link
                  to="/accounting/income_statement_admin"
                  onClick={() => handleNavigation("/accounting/income_statement_admin")}
                  className={classes.dropdownItem}
                >
            Income Statement - Summary
                </Link>

                <Link
                  to="/accounting/income_statement_admin_detail"
                  onClick={() => handleNavigation("/accounting/income_statement_admin_detail")}
                  className={classes.dropdownItem}
                >
           Income Statement - Detailed
                </Link>
                <Link
                  to="/accounting/activity_report"
                  onClick={() => handleNavigation("/accounting/activity_report")}
                  className={classes.dropdownItem}
                >
         Account Activity Report
                </Link>

                <Link
                  to="/accounting/aged_analysis_payables_admin"
                  onClick={() => handleNavigation("/accounting/aged_analysis_payables_admin")}
                  className={classes.dropdownItem}
                >
        Age Analysis - Payables
                </Link>
                <Link
                  to="/accounting/aged_analysis_receivables_admin"
                  onClick={() => handleNavigation("/accounting/aged_analysis_receivables_admin")}
                  className={classes.dropdownItem}
                >
       Age Analysis - Receivables
                </Link>

                <Link
                  to="/statement_of_financial_position_admin"
                  onClick={() => handleNavigation("/statement_of_financial_position_admin")}
                  className={classes.dropdownItem}
                >
      Statement of Financial Position - Summary
                </Link>

                <Link
                  to="/admin_financial_position_detail"
                  onClick={() => handleNavigation("/admin_financial_position_detail")}
                  className={classes.dropdownItem}
                >
      Statement of Financial Position - Detailed
                </Link>
                <Link
                  to="/admin_financial_position_detail"
                  onClick={() => handleNavigation("/admin_financial_position_detail")}
                  className={classes.dropdownItem}
                >
     Bank Position Summary
                </Link>
                <Link
                  to="/accounting/statement_of_cashflow_summary_admin"
                  onClick={() => handleNavigation("/accounting/statement_of_cashflow_summary_admin")}
                  className={classes.dropdownItem}
                >
     Statement of Cash Flow
                </Link>
                <Link
                  to="/accounting/sales_report"
                  onClick={() => handleNavigation("/accounting/sales_report")}
                  className={classes.dropdownItem}
                >
    Sales Report
                </Link>
                <Link
                  to="/accounting/purchase_report"
                  onClick={() => handleNavigation("/accounting/purchase_report")}
                  className={classes.dropdownItem}
                >
   Purchases Report
                </Link>
                <Link
                  to="/accounting/reconciliation_summary_report"
                  onClick={() => handleNavigation("/accounting/reconciliation_summary_report")}
                  className={classes.dropdownItem}
                >
  Reconciliation Summary Report
                </Link>
              </div>
            )}
          </li>

          <li className={classes.menuItem}>
            <Link to="/welcome" onClick={() => handleNavigation("/welcome")}>
              Switch Account
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link to="/accounting" onClick={handleNavigation}>
              Change Password
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link
              to="/accounting"
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              {loading ? "Logging out..." : "Logout"}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileAdmin;