import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../Reports Others/StatementofCash.module.css'
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';


function Statement() {
    return (
        <div className={classes.thcontainer}>
            <div className={classes.topHead}>
                <h3 className={classes.company}>
                    THE REDEEMED CHRISTIAN CHURCH OF GOD
                </h3>
                <h2 className={classes.cashflow}>STATEMENT OF CASH FLOW</h2>
            </div>
            <div className={classes.topRow}>
                <div className={classes.topRow1}>
                    <label className={classes.toplabl} htmlFor='year_ending'>For the year ending:</label>
                    <input className={classes.firstInput} type='date' id='year_ending'></input>
                </div>
                <div className={classes.topRow2}>
                    <label className={classes.toplabl} htmlFor='amount'>Cash at the beginning of the year: </label>
                    <input className={classes.tInput} type='number' id='amount' ></input>
                </div>
            </div>
            <table className={classes.firsttable}>

                <tr className={classes.tbleRw}>
                    <th className={classes.operate}>
                        Operations
                    </th>
                    <th>


                    </th>
                </tr >
                <tr className={classes.solos}>
                    <td className={classes.notOperate}>
                        Cash receipts from
                    </td>
                    <td>

                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Customers
                    </td>
                    <td className={classes.boxInput}>
                    90,000
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Other Operations
                    </td>
                    <td className={classes.boxInput}>
                    
                    </td>
                </tr>
                <tr className={classes.solos}>
                    <td className={classes.notOperate}>
                        Cash paid for
                    </td>
                    <td>

                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Iventory purchases
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        General operating and administrative expenses
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Wage expenses
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Interest
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Income taxes
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Tsolos}>
                    <td className={classes.notDOperate}>
                        Net Cash Flow from Operation
                    </td>
                    <td className={classes.boxInput2}>
                    90,000
                    </td>
                </tr>
            </table>
            

            <table className={classes.firsttable}>

                <tr className={classes.tbleRw}>
                    <th className={classes.operate}>
                        Investing Activities
                    </th>
                    <th>


                    </th>
                </tr >
                <tr className={classes.solos}>
                    <td className={classes.notOperate}>
                        Cash receipts from
                    </td>
                    <td>

                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Sales of Property and Equipment
                    </td>
                    <td className={classes.boxInput}>
                    90,000
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Collection of Principal on loan
                    </td>
                    <td className={classes.boxInput}>
                    
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Sale of Investment securities
                    </td>
                    <td className={classes.boxInput}>
                    
                    </td>
                </tr>
                <tr className={classes.solos}>
                    <td className={classes.notOperate}>
                        Cash paid for
                    </td>
                    <td>

                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Sales of Property and Equipment
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Making loan to other entities
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Purchase of Investment securities
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>

                <tr className={classes.Tsolos}>
                    <td className={classes.notDOperate}>
                        Net Cash Flow from Investing Activities
                    </td>
                    <td className={classes.boxInput2}>
                    90,000
                    </td>
                </tr>
            </table>
            
            <table className={classes.firsttable}>

                <tr className={classes.tbleRw}>
                    <th className={classes.operate}>
                        Financial Activities
                    </th>
                    <th>


                    </th>
                </tr >
                <tr className={classes.solos}>
                    <td className={classes.notOperate}>
                        Cash receipts from
                    </td>
                    <td>

                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Issuance of Stock
                    </td>
                    <td className={classes.boxInput}>
                    90,000
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Borrowing
                    </td>
                    <td className={classes.boxInput}>
                    90,000
                    </td>
                </tr>
                <tr className={classes.solos}>
                    <td className={classes.notOperate}>
                        Cash paid for
                    </td>
                    <td>

                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Purchase of stock(treasure loans)
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Repayment of loans
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>
                <tr className={classes.Hsolos}>
                    <td className={classes.dRow}>
                        Dividend
                    </td>
                    <td className={classes.boxInput1}>
                    (90,000)
                    </td>
                </tr>

                <tr className={classes.Tsolos}>
                    <td className={classes.notDOperate1}>
                        Net Cash Flow from Financing Activities
                    </td>
                    <td className={classes.boxInput2}>
                    90,000
                    </td>
                </tr>
                <br />
                
                <tr className={classes.Thsolos}>
                    <td className={classes.notDOperate}>
                        Net Increase in Cash
                    </td>
                    <td className={classes.boxInput}>
                    90,000
                    </td>
                </tr>
            </table>
            <br />
            

            <div className={classes.topRow2}>
                <label className={classes.toplabl} htmlFor='amount'>Cash at End of the year:  </label>
                <p className={classes.tInput}> 90,000</p>
            </div>

        </div>
    )
}
export default Statement;