import React, { useState, useEffect } from 'react';
import classes from '../Process-General-ledger/ProcessGeneral.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintSales() {
  const [bearer, setBearer] = useState('');
  const [company, setCompany] = useState('');
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('companyName');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setCompany(value1);
        console.log(company);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const { document, selectedDate, selectedEndDate } = location.state || {};
  console.log(selectedDate, selectedEndDate, document, "printing");
  // const startDate = new Date(inputs.start_date);
  // const endDate = new Date(inputs.end_date);

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13
      ? 'th'
      : suffixes[v % 10] || 'th';
  };

  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (document) {
      const debitTotal = document.reduce((total, item) => total + parseFloat(item.amount), 0);
      // const creditTotal = document.reduce((total, item) => total + parseFloat(item.credit), 0);

      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      // const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2
      // });

      setTotalDebit(formattedDebitTotal);
      // setTotalCredit(formattedCreditTotal);
    }
  }, [document]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  const allNamesSame = document.every(doc => doc.account?.gl_name === document[0].account?.gl_name);
  const displayName = allNamesSame ? document[0].account?.gl_name : "All Accounts";

  const title = selectedDate && selectedEndDate 
    ? `SALES REPORT BETWEEN ${selectedDate} AND ${selectedEndDate}` 
    : `SALES REPORT AS AT ${currentDateTime}`;

  return (
    <div>
      <div className='newBody'>
        <div className='newWidth'>
          <div className="wrapper">
            <div className={classes.a4}>
              <div className={classes.header}>
                <h1>{company.toLocaleUpperCase()}</h1>
               
                <h6>
                  {title}
                 
                </h6>
                <h6 style={{ fontWeight: 700 }}>{displayName}</h6>
              </div>
              <p><span style={{ fontWeight: 700, color: "#000" }}>DATE PRINTED:</span> {currentDateTime} </p>
              <table className={`${classes.table} print-table`}>
                <thead>
                  <tr>
                    <th style={{ width: '200px' }}>Transacation Date</th>
                    <th style={{ width: '500px' }}>Invoice Number</th>
                    <th style={{ width: '500px' }}>Customer</th>
                    <th style={{ width: '500px' }}>Description</th>
                    <th style={{ width: '500px' }}>Discount</th>
                    <th style={{ textAlign: "right", width: '200px' }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {document && document.map((item, index) => (
                    <tr key={index}>
                      <td>{item.transaction_date}</td>
                      <td>{item.invoice_number}</td>
                      <td>{item.customer?.name}</td>
                      <td >{item.description}</td>
                      <td >{item?.discount === null ? "" : item?.discount}</td>
                      <td style={{ textAlign: "right", width: '200px' }}>{parseFloat(item.amount).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</td>
                      
                    </tr>
                  ))}
                </tbody>
                
                  <tr>
                    <td colSpan="6" style={{ borderBottom: "2px solid #000" }}></td>
                  </tr>
                  <tr>
                    <td colSpan="5">Total</td>
                    <td style={{ textAlign: "right", fontWeight: "bold", width: '150px' }}>{totalDebit}</td>
                  </tr>
                  <tr>
                    <td colSpan="6" style={{ borderBottom: "2px solid #000" }}></td>
                  </tr>
               
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
